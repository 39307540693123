import styled from 'styled-components';

const { DARK_TEXT, GREEN } = GLOBALS.colors;

const Container = styled.div`
  border: 2px solid transparent;
  ${({ selected, color }) => selected && `border-color: ${color ? color : GREEN};`} width: 100%;
  margin: 0 30px 0 -34px;
  padding-left: 30px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    user-select: none;
  }
`;

const Title = styled.div`
  font-family: 'SF Display Heavy';
  font-size: 13px;
  color: ${({ color }) => color};
  margin-bottom: 5px;
`;

const ItemsNo = styled.div`
  font-family: 'SF Display Bold';
  font-size: 13.5px;
  color: ${DARK_TEXT};
  font-family: 'SF Display Bold';
`;

export { Container, Title, ItemsNo };
