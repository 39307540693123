import React, { useState, useEffect } from 'react';
import { DatePicker, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import './styles.css';

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

export default ({ error, touched, label, value, name = '', ...rest }) => {
  const hasError = error && touched;

  const [localValue, setLocalValue] = useState(value ? moment(value) : null);

  useEffect(() => {
    setLocalValue(null);
  }, [value]);

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      {(label || hasError) && <Label hasError={hasError}>{hasError ? error : label}</Label>}
      <div className="arrowsContainer">
        <div
          className="customArrow"
          style={{ marginRight: '8px', transform: 'rotate(90deg)' }}
          onClick={() => {
            const val = moment(localValue ? localValue : value).subtract(1, 'day');
            setLocalValue(val);
            rest.onChange(val);
          }}
        />

        <DatePicker
          data-cy={'field-datepicker-' + name}
          allowClear={false}
          ref={datepicker => {
            if (datepicker) {
              const input = datepicker.picker.input;
              input.setAttribute('disabled', true);
            }
          }}
          value={localValue ? localValue : value ? moment(value).parseZone() : null}
          {...rest}
          disabledDate={current => {
            if (name === 'graduationDate') {
              let customDate = moment().add(1, 'days').format('YYYY-MM-DD');
              return current > moment(customDate, 'YYYY-MM-DD');
            }
          }}
        />

        <div
          className="customArrow"
          style={{ marginLeft: '8px', transform: 'rotate(-90deg)' }}
          onClick={() => {
            const val = moment(localValue ? localValue : value).add(1, 'day');
            setLocalValue(val);
            rest.onChange(val);
          }}
        />
      </div>
    </Form.Item>
  );
};
