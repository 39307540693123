import React, { Component } from 'react';
import { Button } from 'antd';
import SignaturePad from 'react-signature-pad';

import { Container, Label } from './styles';

class Signature extends Component {
  render() {
    const { onChange, error, label } = this.props;

    return (
      <div>
        <Label hasError={!!error}>{error || label}</Label>
        <Container hasError={!!error}>
          <SignaturePad
            ref="signature"
            onEnd={() => {
              let signature = this.refs.signature.toDataURL();
              signature = signature.split(',')[1];
              onChange(signature, this.refs.signature);
            }}
            minWidth={2}
            maxWidth={4}
          />
          <Button
            type="link"
            className="m-signature-pad--footer"
            size="small"
            style={{
              color: '#707070',
              fontSize: 12,
              padding: 0,
              height: 'auto'
            }}
            onClick={() => {
              this.refs.signature.clear();
              onChange('');
            }}
          >
            Clear
          </Button>
        </Container>
      </div>
    );
  }
}

export default Signature;
