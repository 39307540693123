import { string, object, mixed } from 'yup';

const schema = object().shape({
  password: string().required('Password is required'),
  // .min(8, 'Password is too short.It should be at least 8 characters'),

  newPassword: string()
    .required('New password is required')
    .min(8, 'Password is too short.It should be at least 8 characters'),

  confirmPassword: mixed().test(
    'match',
    'Passwords do not match', // your error message
    function () {
      return this.parent.newPassword === this.parent.confirmPassword;
    }
  )
});

export default { create: schema };
