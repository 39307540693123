import styled from 'styled-components';
import { AsyncTable } from '_src/components';

const ModalTitle = styled.div`
  font-size: 24px;
  color: rgb(55, 159, 255);
  font-weight: bolder;
  display: inline-block;
  vertical-align: middle;

  div {
    display: grid;
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    margin-left: 13px;
  }
`;

const HourDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  column-gap: 25px;
`;

const HourDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
  display: grid;
  font-weight: 500;

  span {
    font-weight: 300;
    min-height: 23px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
`;

const TotalCard = styled.div`
  padding: 15px 10px;
  background-color: #1990c5;
  color: #fff;
  border-radius: 7px;
  width: 150px;
  margin-right: 0px;
  margin-left: auto;

  span {
    font-size: 18px;
  }
`;

const EvaluationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EvaluationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
`;

const StyledAsyncTable = styled(AsyncTable)`
  & .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 5px !important;
  }
`;
// .ant-table-fixed-header .ant-table-scroll .ant-table-header

export {
  ModalTitle,
  HourDetailContainer,
  HourDetail,
  ButtonContainer,
  Row,
  TotalCard,
  EvaluationButtonContainer,
  EvaluationsContainer,
  StyledAsyncTable
};
