import React, { useState, useContext } from 'react';
import { Button, Card, notification } from 'antd';
import { Context, useSave } from 'store';

import { AsyncTable, AsyncModal } from '_src/components';
import { PageContainer, ActionRow } from '_src/shared/styles';
import services from '_src/services';
import { Restricted } from '_src/shared/restrict';

export default ({ resource }) => {
  const { setShown, getData } = useContext(Context);
  const [initialValues, setInitialValues] = useState({});
  const [hasOrderedChanged, setOrderChanged] = useState(false);
  const [newOrder, setNewOrder] = useState([]);
  const config = services[resource];

  const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    ? { marginTop: 30 }
    : { marginTop: 0 };

  let ordered;

  if (config.ordering) {
    const reqObj = useSave(config.ordering);
    ordered = reqObj.save;
  }

  const closeModal = () => {
    setShown(resource, false);
  };

  const onRowClick = record => {
    setInitialValues({ ...record });
    setShown(resource);
  };

  const addNew = () => {
    const { initial = [] } = config;
    const initialData = initial.reduce((acc, { path, name, transform }) => {
      const data = getData(path);
      return { ...acc, [name]: transform ? transform(data) : data };
    }, {});

    setShown(resource);
    setInitialValues({ initial: initialData });
  };

  const saveOrder = () => {
    let questions = newOrder.map(({ id }, index) => ({
      questionId: id,
      ordering: index + 1
    }));

    ordered({
      questions
    })
      .then(({ data }) => {
        setOrderChanged(false);
        notification.success({
          message: data.message,
          duration: 3
        });
      })
      .catch(() => {
        notification.warning({
          message: 'There was a problem',
          duration: 3
        });
      });
  };

  const renderCreateButton = () => {
    const { restrict = resource } = config;

    const createButton = !!config.createButton ? (
      <Button type="primary" onClick={addNew}>
        {config.createButton}
      </Button>
    ) : null;

    return restrict ? (
      <Restricted resource={restrict} method="POST">
        {createButton}
      </Restricted>
    ) : (
      createButton
    );
  };

  return (
    <PageContainer style={stylesTablet}>
      <Card title={config.title}>
        <ActionRow>
          {config.sortable && hasOrderedChanged ? (
            <Button type="primary" onClick={saveOrder}>
              Save Order
            </Button>
          ) : null}
          {renderCreateButton()}
        </ActionRow>

        <AsyncTable
          resource={resource}
          onRowClick={onRowClick}
          onOrderChange={(hasChanged = false, dndRowData = []) => {
            setOrderChanged(hasChanged);
            setNewOrder(dndRowData);
          }}
        />

        <AsyncModal resource={resource} closeModal={closeModal} initialValues={initialValues} />
      </Card>
    </PageContainer>
  );
};
