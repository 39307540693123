import { transformAll } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'branches';
const path = 'branches';

const save = {
  name,
  path,
  transform: ({ classroomId, yardId, ...branch }) => ({ branch, classroomId, yardId })
};

export default {
  title: 'Branches',
  createButton: 'Create a new branch',
  get: {
    name,
    path: ['regions', path],
    transform: transformAll,
    defaultValue: [],
    params: [{}, { branchAdditionalInfo: true }]
  },
  create: save,
  edit: { ...save, method: 'put', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save Branches',
    fields,
    schema,
    initial: {
      classroomId: -1,
      yardId: -1
    }
  }
};
