import moment from 'moment';

export default [
  {
    name: 'hoursDriven',
    label: 'Hours Driven',
    component: 'timepicker',
    allowClear: false,
    format: 'HH:mm',
    placeholder: 'Hours driven'
  }
];
