import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 13px;
  font-family: 'SF Display Bold';
  background-color: #fff;
  ${({ filled, shadow, color, height, width, margin, disabled, alignSelf }) => `
		color: ${filled ? 'white' : color};
		border: ${filled ? 'none' : 'solid'};
		border-width: ${filled ? '0px' : '2px'};
		border-radius: ${height / 2}px;
		background-color: ${filled ? color : 'white'};
		border-color: ${color};
		box-shadow: ${shadow ? `2px 2px 5px ${color}` : 'none'};
		width: ${width ? width : '100%'}px;
		height: ${height ? height : '100%'}px;
		margin: ${margin};
		align-self: ${alignSelf};

		${
      disabled &&
      `
	 		box-shadow: 2px 2px 5px #C1C1C1;
	  		border: 1px solid #C1C1C1;
			color: #C1C1C1;
			background-color: white;
		`
    }
	`};
  box-sizing: border-box;
  outline: none;
`;

export default Button;
