// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrowsContainer {
  display: flex;
  flex-direction: row;
}

.customArrow {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 9px 7px 10px;
  position: relative;
  z-index: 10;
}

.customArrow::after {
  border: 0;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-left: -10px;
  left: 50%;
  border-top: 10px solid rgb(89, 89, 89);
  bottom: -10px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/form-helpers/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,WAAW;EACX,cAAc;EACd,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,mCAAmC;EACnC,oCAAoC;EACpC,kBAAkB;EAClB,SAAS;EACT,sCAAsC;EACtC,aAAa;AACf","sourcesContent":[".arrowsContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.customArrow {\n  background: #fff;\n  border: 1px solid #fff;\n  border-radius: 5px;\n  font-size: 14px;\n  font-weight: 700;\n  padding: 8px 9px 7px 10px;\n  position: relative;\n  z-index: 10;\n}\n\n.customArrow::after {\n  border: 0;\n  content: '';\n  display: block;\n  height: 0;\n  position: absolute;\n  width: 0;\n  z-index: 10;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  margin-left: -10px;\n  left: 50%;\n  border-top: 10px solid rgb(89, 89, 89);\n  bottom: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
