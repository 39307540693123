import { transformAll, transformStudentProgress, transformBeforeSave } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'employersList';
const path = 'driver_score/employers';

export default {
  restrict: 'driver_score/employers',
  title: 'Employers list',
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  create: { method: 'post', path },
  update: { method: 'patch', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save',
    fields,
    schema
  }
};
