import React from 'react';
import { Tag } from 'antd';

const frequencyMap = {
  1: 'Daily noon',
  2: 'Daily morning',
  3: 'Every monday morning',
  4: 'Every wednesday at noon',
  5: 'Every friday at noon'
};

const reportMap = {
  1: 'Student Progress',
  2: 'Daily Attendance Report'
};

export default [
  {
    title: 'Title',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    sorter: (a, b) => a.frequency - b.frequency,
    render: frequency => frequencyMap[frequency]
  },
  {
    title: 'Report',
    dataIndex: 'type',
    sorter: (a, b) => a.type - b.type,
    render: type => reportMap[type]
  },
  {
    title: 'Recipients',
    dataIndex: 'emails',
    render: emails => (
      <>
        {emails.map(email => (
          <Tag color="blue" key={email}>
            {email}
          </Tag>
        ))}
      </>
    )
  }
];
