export const amazon = require('./amazon.png');
export const logo = require('./logo.png');
export const logoTagline = require('./logoTagline.png');
export const needsImprovementReportStatus = require('./report_status_needs_improvement.png');
export const proficientReportStatus = require('./report_status_proficient.png');
export const satisfactoryReportStatus = require('./report_status_satisfactory.png');
export const unsuccessfulReportStatus = require('./report_status_unsuccessful.png');
export const truck = require('./truck.jpg');
export const userPlaceHolder = require('./user-placeholder.png');
export const needsImprovementPerformance = require('./performance_needs_improvement.png');
export const proficientPerformance = require('./performance_proficient.png');
export const unsuccessfulPerformance = require('./performance_unsuccesful.png');
export const satisfactoryPerformance = require('./performance_satisfactory.png');
export const improvingChartStatus = require('./status_improving.png');
export const decliningChartStatus = require('./status_declining.png');
export const noInfoFace = require('./no_info_face.png');
export const deleteImage = require('./delete.png');
