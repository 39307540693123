import { stripNullObj } from 'utils';

const transformAll = attendaceRes => {
  if (attendaceRes.code === 500 || attendaceRes.code === 400) {
    return [];
  }

  const total = attendaceRes.data.students?.total || 0;
  const data = attendaceRes.data.students?.responses
    ? attendaceRes.data.students?.responses.map(student => {
        const {
          id,
          firstName,
          lastName,
          preferredNickname,
          license,
          licenseId,
          licenseIdPdf417,
          branchId,
          locationType,
          permitRecDate,
          status,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          weekendClass,
          nightClass,
          yardStartDate,
          groups
        } = stripNullObj(student);

        return {
          id,
          studentId: id,
          name: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
          license,
          licenseId,
          licenseIdPdf417,
          branchId,
          locationType,
          permitRecDate,
          status,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          weekendClass,
          nightClass,
          yardStartDate,
          groups
        };
      })
    : [];
  return {
    data,
    total
  };
};

export { transformAll };
