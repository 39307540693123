import styled from 'styled-components';
import { deleteImage } from '_src/assets/images/index.js';

const ProfileImage = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 50%;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 2px;
  left: -8px;
  color: orange;
  width: 30px;
  height 30px;
  background-image: url('${deleteImage}');
  background-size: contain;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  ${({ fixed=true }) => fixed && `
    position: absolute;
    top: 10px;
    left: 20px;
  `}
  cursor: pointer;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export { ProfileImage, VideoContainer, ImageContainer, DeleteButton, Container };
