import table from './table';
import { transform } from './transform';

const name = 'submissionReport';
const path = 'student_infos/submission_report';

export default {
  table,
  get: {
    name,
    path,
    transform,
    defaultValue: []
  },
  downloadSubmissionReport: {
    path: 'reports/fmcsa_report'
  }
};
