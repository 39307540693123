import table from './table';
import schema from './schema';

export default {
  restrict: false,
  table,
  sponsors: {
    name: 'sponsors',
    label: 'Filter by Sponsor',
    component: 'select',
    async: true,
    get: {
      name: 'sponsors',
      path: 'students/sponsors',
      transform: ({ data }) => data.sponsors.map(sponsor => ({ id: sponsor, label: sponsor })),
      defaultValue: []
    }
  },
  modal: {
    title: 'Checkin students',
    fields: [
      { name: 'time', label: 'Time', component: 'timepicker', format: 'HH:mm', minuteStep: 15 }
    ],
    schema
  },
  create: {
    path: 'attendance/admin_attendance'
  },
  update: {
    method: 'patch',
    path: 'attendance/admin_checkout'
  },
  generateAttendanceLink: {
    path: 'self-attendance/generate-link',
    method: 'post'
  }
};
