import {
  transformAll,
  transformBeforeSave,
  transformGroups,
  transformAllGroups,
  transformAllBranches
} from './transform';
import schema from './schema';
import table from './table';
import fields from './fields';

const name = 'users';
const path = 'users';
const save = {
  name,
  path,
  transform: transformBeforeSave
};

export default {
  title: 'Users',
  createButton: 'Create User',
  get: {
    name,
    path: ['users', 'groups', 'branches'],
    transform: transformAll,
    defaultValue: [],
    params: { privileges: 1 }
  },
  getUser: { method: 'get', path: `${path}/:id` },
  create: save,
  edit: { ...save, method: 'patch', path: `${path}/:id` },
  delete: { name, path: `${path}/:id` },
  table,
  tableAccessKey: 'users',
  modal: {
    title: 'Save User',
    fields,
    schema,
    load: {
      name: 'userModalGroups',
      path: 'groups',
      transform: transformGroups,
      params: { privileges: 1 }
    }
  },
  activateUser: {
    method: 'put',
    path: `${path}/activate/:id`
  },
  getGroups: {
    method: 'get',
    path: 'groups',
    transform: transformAllGroups
  },
  getBranches: {
    name,
    path: 'branches',
    transform: transformAllBranches,
    defaultValue: [],
    params: [{}, { branchAdditionalInfo: true }]
  }
};
