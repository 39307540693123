import React from 'react';
import { Icon } from 'antd';

import styled from 'styled-components';
import './styles.css';

// Note: dbFieldName needed on orderBy call

export default [
  {
    title: 'STUDENT',
    dataIndex: 'studentName',
    dbFieldName: 'first_name',
    sorter: true,
    className: 'cursorPointer',
    render: (value, row) => {
      return (
        <>
          <b>{value}</b>
          <p>{row.studentType}</p>
        </>
      );
    }
  },
  {
    title: 'CURRENT LOCATION',
    dataIndex: 'locationCity',
    dbFieldName: 'branch_name',
    sorter: true,
    className: 'cursorPointer',
    render: (value, row) => {
      return (
        <>
          <b>{value}</b>
          <p>{row.locationState}</p>
        </>
      );
    }
  },

  {
    title: 'CLASS HOURS',
    dataIndex: 'classHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },

  {
    title: 'ROAD HOURS',
    dataIndex: 'roadHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: 'SKILLS HOURS',
    dataIndex: 'skillsHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: 'PRE TRIP HOURS',
    dataIndex: 'preTripHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },

  {
    title: 'OBS ROAD HOURS',
    dataIndex: 'obsRoadHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },

  {
    title: 'OBS SKILL HOURS',
    dataIndex: 'obsSkillHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },

  {
    title: 'REMEDIAL HOURS',
    dataIndex: 'remedialHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: 'SESSION TOTAL',
    dataIndex: 'totalHours',
    dbFieldName: 'total_hours_trained',
    sorter: true,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    className: 'cursorPointer',
    render: (value, row) => {
      return (
        <>
          <b>{value}</b>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.hasPermit ? (
              <Icon type="check" style={{ color: '#5AB55B', marginRight: 4 }} />
            ) : (
              <Icon type="close" style={{ color: '#C62020', marginRight: 4 }} />
            )}
            <p>Permit</p>
          </div>
        </>
      );
    }
  },
  {
    title: 'CLASS STARTED',
    dataIndex: 'classStarted',
    dbFieldName: 'date_class_begins',
    sorter: true,
    className: 'cursorPointer',
    render: value => {
      return (
        <>
          <b>{value}</b>
        </>
      );
    }
  },
  {
    title: 'GRADUATION DATE',
    dataIndex: 'graduationDate',
    dbFieldName: 'graduation_date',
    sorter: true,
    // className: 'cursorPointer',
    render: value => {
      return (
        <>
          <b>{value}</b>
        </>
      );
    }
  }
];
