import { string, object, boolean } from 'yup';

const confirmGraduationSchema = object().shape({
  studentSignatureNote: string(),
  studentSignature: string(),
  instructorSignatureNote: string(),
  instructorSignature: string()
});

const schema = object({
  email: string().email('Email is not valid'),
  phone: string().nullable(),
  birthdate: string().nullable(),
  gender: string().nullable(),
  license: string().nullable(),
  licenseState: string().nullable(),
  mailingAddress: object().nullable(),
  permitExpiration: string().nullable(),
  graduationDate: string().nullable(),
  cdlDate: string().nullable(),
  profilePicture: string().nullable(),
  automaticGear: string().nullable()
});

const graduationSchema = object({
  email: string().email('Email is not valid'),
  phone: string().nullable(),
  birthdate: string().nullable(),
  gender: string().nullable(),
  license: string().nullable(),
  licenseState: string().nullable(),
  mailingAddress: object().nullable(),
  permitExpiration: string().nullable(),
  graduationDate: string().required('Graduation Date is required').nullable(),
  cdlDate: string().nullable(),
  profilePicture: string().nullable(),
  automaticGear: string().nullable()
});

export default { create: schema, edit: schema, confirmGraduationSchema: confirmGraduationSchema, graduationSchema: graduationSchema };
