// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}
.textCapitalize {
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/styles.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;;AAEA;EACE,yCAAyC;AAC3C;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["tr.drop-over-downward td {\n  border-bottom: 2px dashed #1890ff !important;\n}\n\ntr.drop-over-upward td {\n  border-top: 2px dashed #1890ff !important;\n}\n.textCapitalize {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
