const allOptions = {
  preTripReasons: [
    {
      id: 'Incorrect vocab (Please select other as well and describe)',
      label: 'Incorrect vocab (Please select other as well and describe)'
    },
    {
      id: 'Break Test',
      label: 'Break Test'
    },
    {
      id: 'Didn’t turn key on during applied leakage test for power',
      label: 'Didn’t turn key on during applied leakage test for power'
    },
    {
      id: 'Did not release breaks',
      label: 'Did not release breaks'
    },
    {
      id: 'Didn’t secure vehicle',
      label: 'Didn’t secure vehicle'
    },
    {
      id: 'Stalling',
      label: 'Stalling'
    },
    {
      id: '',
      label: ''
    }
  ],
  skillReasons: [
    {
      id: 'Too many Pull up’s',
      label: 'Too many Pull up’s'
    },
    {
      id: 'Too many Get out and looks',
      label: 'Too many Get out and looks'
    },
    {
      id: 'Not enough GOALs',
      label: 'Not enough GOALs'
    },
    {
      id: 'Wrong final position',
      label: 'Wrong final position'
    },
    {
      id: 'Signal completion too early',
      label: 'Signal completion too early'
    },
    {
      id: 'Didn’t signal completion',
      label: 'Didn’t signal completion'
    },
    {
      id: 'Too many points (please select “other” as well and describe)',
      label: 'Too many points (please select “other” as well and describe)'
    },
    {
      id: 'Examiner ended test (please select “other” as well and describe)',
      label: 'Examiner ended test (please select “other” as well and describe)'
    }
  ],
  roadReasons: [
    {
      id: 'Shifting issues',
      label: 'Shifting issues'
    },
    {
      id: 'Losing a gear for too long (coasting)',
      label: 'Losing a gear for too long (coasting)'
    },
    {
      id: 'Grinding',
      label: 'Grinding'
    },
    {
      id: 'Failure to double clutch',
      label: 'Failure to double clutch'
    },
    {
      id: 'Stalling',
      label: 'Stalling'
    },
    {
      id: 'Speeding',
      label: 'Speeding'
    },
    {
      id: 'Rolling back',
      label: 'Rolling back'
    },
    {
      id: 'Examiner intervention (please select “other” as well and describe)',
      label: 'Examiner intervention (please select “other” as well and describe)'
    },
    {
      id: 'Following road lights failure',
      label: 'Following road lights failure'
    },
    {
      id: 'Following road signs failure',
      label: 'Following road signs failure'
    },
    {
      id: 'Stopping behind white stopping lines failure',
      label: 'Stopping behind white stopping lines failure'
    },
    {
      id: 'Complete stop failure',
      label: 'Complete stop failure'
    },
    {
      id: 'Two hands on the wheel failure',
      label: 'Two hands on the wheel failure'
    },
    {
      id: 'Driving too slowly',
      label: 'Driving too slowly'
    },
    {
      id: 'Not confident / decisive',
      label: 'Not confident / decisive'
    },
    {
      id: 'Hitting a curb',
      label: 'Hitting a curb'
    },
    {
      id: 'Hitting an object',
      label: 'Hitting an object'
    }
  ]
};

export default [
  {
    name: 'branchId',
    label: 'Current Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    showSearch: true
  },
  {
    name: 'student',
    label: 'Student',
    component: 'select',
    async: true,
    get: {
      name: 'users',
      path: 'users',
      transform: ({ data }) => data.users.map(({ email }) => ({ id: email, label: email })),
      defaultValue: []
    },
    labelKey: 'email'
  },
  {
    name: 'testingCategory',
    label: 'Testing Category',
    component: 'select',
    options: [
      { id: 'preTrip', label: 'PreTrip' },
      { id: 'skills', label: 'Skills' },
      { id: 'road', label: 'Road' }
    ]
  },
  { name: 'testingDate', label: 'Testing Date', component: 'date' },
  {
    name: 'results',
    label: 'Results',
    component: 'select',
    options: [
      { id: 'failed', label: 'Failed' },
      { id: 'passed', label: 'Passed' }
    ]
  },
  {
    name: 'failedReason',
    label: 'Failed reason',
    component: 'select',
    options: []
  }
];
