import styled from 'styled-components';

const Logo = styled.img`
  width: 80%;
  margin: 20px 10%;
`;

const MenuWrapper = styled.div`
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: space-between;
`;

export { Logo, MenuWrapper };
