import React, { useState, useEffect } from 'react';
import { useSave } from 'store';
import { Form } from 'form';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, notification } from 'antd';
import { Fields, VersionNumber } from '_src/components';
import services from '_src/services';

import { Container, FormContainer, Logo } from './styles';
import logoTagline from '_src/assets/images/logoTagline.png';

export default () => {
  const config = services.forgotPassword;
  let fieldsConfig = config.form.fields;

  const { save, busy } = useSave({ ...config.save, validate: () => formApi.current.validate() });
  const { save: resetPassword } = useSave({
    ...config.forgotPassword
  });
  const [formApi, setFormApi] = useState();
  const [mode, setMode] = useState();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const resetToken = params.get('token');

  //   useEffect(() => {
  //   }, []);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  });

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      mode === 'firstStep' ? sendLink() : resetNewPassword();
    }
  };

  const resetNewPassword = () => {
    const { newPassword } = formApi.values();
    resetPassword({ resetPasswordToken: resetToken, newPassword: newPassword })
      .then(response => {
        if (response.success === 1) {
          notification.success({
            message: response.data.message,
            duration: 2.5
          });
          history.push('/login');
        } else {
          handleError(response.data.message);
        }
      })
      .catch(err => {});
  };

  const handleError = message => {
    notification.error({
      message,
      //   description: 'Something went wrong',
      duration: 2.5
    });
  };

  return (
    <Container>
      <FormContainer>
        <Logo src={logoTagline} />
        <Form schema={config.form.schema['forgotPassword']} formApi={form => setFormApi(form)}>
          <Fields fields={fieldsConfig['forgotPassword']} />
          <Button type="primary" loading={busy} onClick={resetNewPassword}>
            Reset Password
          </Button>
        </Form>
        <VersionNumber plain style={{ position: 'absolute', bottom: '24px', right: '24px' }} />
      </FormContainer>
    </Container>
  );
};
