import { string, object, array } from 'yup';

const schema = object().shape({
  name: string().required('Name is required'),
  type: string().required('Type is required'),
  frequency: string().required('Frequency is required'),
  emails: array().required('Add at least one email'),
  branches: array().nullable(),
  sponsors: string().nullable(),
  subject: string().required('Subject is required')
});

export default { create: schema, edit: schema };
