import moment from 'moment';

const transformAll = ({ data }) => {
  data = (data.employers || [])
    .filter(employer => !employer.deletedAt)
    .map(({ emails, ...rest }) => ({
      ...rest,
      emails: emails == -1 ? [] : emails
    }))
    .sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1;
    });

  return data;
};

const transformBeforeSave = ({ branches, sponsors, ...data }) => {};

export { transformAll, transformBeforeSave };
