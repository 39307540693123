import { string, object, ref, number } from 'yup';

const schema = object().shape({
  sticker: string(),
  odometerStart: number()
    .positive('Odometer must be a positive number')
    .typeError('Odometer must be a number')
    .required('Odometer is required'),
  odometerEnd: number()
    .positive('Odometer must be a positive number')
    .typeError('Odometer must be a number')
    .required('Odometer is required')
    .moreThan(ref('odometerStart'), 'Odometer end should be bigger than start')
});

export default schema;
