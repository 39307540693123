import React, { useState } from 'react';

import { Container, Wrapper, PlainVersion } from './styles';
import { VersionModal } from './VersionModal';
import packageJson from '../../../package.json';

function VersionNumber({ plain = false, style = {}, light = false }) {
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const openVersionModal = () => setIsVersionModalOpen(true);

  const closeVersionModal = () => setIsVersionModalOpen(false);

  if (plain) {
    return (
      <>
        <PlainVersion
          style={{ ...style, color: light ? '#ffffff80' : '#939393' }}
          onClick={openVersionModal}
        >
          Version {packageJson.version}
        </PlainVersion>

        <VersionModal visible={isVersionModalOpen} onClose={closeVersionModal} />
      </>
    );
  }
  return (
    <>
      <Wrapper>
        <Container onClick={openVersionModal}>
          Version {packageJson.version} <span style={{ marginLeft: '10px' }} />
        </Container>
      </Wrapper>

      <VersionModal visible={isVersionModalOpen} onClose={closeVersionModal} />
    </>
  );
}

export default VersionNumber;
