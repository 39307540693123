import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: 300px;

  .m-signature-pad {
    position: absolute;
    font-size: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 4px;
    user-select: none;
    border-color: ${({ hasError }) => (hasError ? '#f5222d' : '#e8e8e8')};
  }

  .m-signature-pad--body {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 60px;
    border: 1px solid #f4f4f4;
  }

  .m-signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

  .m-signature-pad--footer {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 40px;
  }

  .m-signature-pad--footer .description {
    color: #c3c3c3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

  .m-signature-pad--footer .button {
    position: absolute;
    bottom: 0;
  }

  .m-signature-pad--footer .button.clear {
    left: 0;
  }

  .m-signature-pad--footer .button.save {
    right: 0;
  }

  @media screen and (max-width: 1024px) {
    .m-signature-pad {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      min-width: 250px;
      min-height: 140px;
    }
    #github {
      display: none;
    }
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
      margin: 10%;
    }
  }

  @media screen and (max-height: 320px) {
    .m-signature-pad--body {
      left: 0;
      right: 0;
      top: 0;
      bottom: 32px;
    }
    .m-signature-pad--footer {
      left: 20px;
      right: 20px;
      bottom: 4px;
      height: 28px;
    }
    .m-signature-pad--footer .description {
      font-size: 1em;
      margin-top: 1em;
    }
  }
`;

const Label = styled.div`
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

export { Container, Label };
