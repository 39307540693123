import table from './table';
import schema from './schema';
import fields from './fields';
import { transformAll, transformCreate, transformBeforeUpdate } from './transform';

const name = 'preTripsQuestions';
const path = 'testing/questions';
const save = {
  name,
  path
};

export default {
  restrict: 'testing/questions',
  title: 'Pre Trips Questions',
  createButton: 'Create a new question',
  sortable: true,
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: [],
    params: {
      type: 1
    }
  },
  create: {
    ...save,
    transform: transformCreate
  },
  edit: {
    ...save,
    method: 'patch',
    transform: transformBeforeUpdate,
    path: `${path}/:id`,
    params: {
      type: 1
    }
  },
  // delete: {
  //   name,
  //   path: `${path}/:id`,
  //   params: {
  //     type: 1
  //   }
  // },
  ordering: {
    ...save,
    method: 'post',
    path: `${path}/ordering`
  },
  table,
  modal: {
    title: 'Save Pre Trip question',
    fields,
    restrict: false,
    schema
  }
};
