import styled from 'styled-components';

const Container = styled.div`
  align-self: stretch;
  display: flex;
  height: 120px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Bubble = styled.div`
  position: relative;
  ${({ selected, first, upcoming, absent, hasOpenSessions, isExcused }) => `
    margin-left: 1.3vw;
    width: ${selected ? '40px' : '18px'};
    height: ${selected ? '40px' : '18px'};
    border-radius: ${selected ? '20px' : '9px'}
    background-color: ${upcoming ? '#CECECE' : (absent && !isExcused) ? '#EF5350' : (absent && isExcused) ? '#FFC400' : hasOpenSessions ? '#ff8400' : '#5AB55B'
    };
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all 200ms ease-in;

    ${!first &&
    `&::before {
        content: "";
        width: 1.3vw;
        height: 6px;
        background-color: ${upcoming ? '#E2E2E2' : '#5AB55B'};
        margin-left: -1.3vw;
  }`}
`};
`;

const TextHolder = styled.div`
  position: absolute;
  width: ${({ selected }) => (selected ? '40px' : '18px')};
  height: 100px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TopLabel = styled.div`
  color: #3b3b3b;
  font-size: 16px;
  font-weight: bold;
  font-family: 'SF Display Bold';
  @media (max-width: 1200px) {
    margin-right: ${({ isLast }) => (isLast ? '60px' : '0')};
    margin-left: ${({ isFirst }) => (isFirst ? '60px' : '0')};
  }
`;

const DateLabel = styled.div`
  font-family: 'SF Display Bold';
  font-size: 16px;
  font-weight: bold;
  color: ${({ absent, isExcused }) => ((absent && !isExcused) ? '#EF5350' : (absent && isExcused) ? '#FFC400' : '#5AB55B')};
`;

export { Container, Bubble, TextHolder, TopLabel, DateLabel };
