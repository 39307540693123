import styled from 'styled-components';

const Container = styled.div`
  flex: 8.5;
  display: flex;
  flex-direction: column;
`;

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: ${({ isNote }) => (isNote ? '10px' : 'auto')};
  }
  ::-webkit-scrollbar-track {
    background: ${({ isNote }) => (isNote ? '#f1f1f1' : 'auto')};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ isNote }) => (isNote ? '#888' : 'auto')};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ isNote }) => (isNote ? '#555' : 'auto')};
  }
`;

const NotesContainer = styled.div``;

const NotesField = styled.span`
  font-family: 'SF Display Medium';
  font-size: 13px;
`;

const NotesFieldBold = styled(NotesField)`
  font-family: 'SF Display Heavy';
  color: ${({ fmcsa }) => fmcsa && 'green'};
`;

const InstructorCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentField = styled.span`
  font-family: 'SF Display Medium';
  font-size: 13px;
  display: flex;
  margin-top: 5px;
  color: ${({ fmcsa }) => fmcsa && 'green'};
`;

const EditIconContainer = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditComentTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditIcon = styled.img`
  height: 13px;
  width: 13px;
`;

export {
  Container,
  LabelsContainer,
  NotesContainer,
  NotesField,
  NotesFieldBold,
  InstructorCommentContainer,
  CommentField,
  EditIconContainer,
  EditComentTextAreaContainer,
  EditIcon
};
