import React, { useState, useEffect, useContext } from 'react';
import { useFetch, Context } from 'store';

import { Button, Icon } from 'antd';
import moment from 'moment';

import { Refresh, AsyncTable } from '_src/components';
import { PersistentRepo } from '_src/shared/repo';
import { objToSnakeCase } from '_src/utils';
import services from '_src/services';
import { RangePicker, Select } from '_src/shared/form-helpers';
import { PageWrapper, ActionRow } from '_src/shared/styles';

import { PageTitle, Label, Search } from './styles';

export default () => {
  const baseApi = GLOBALS.BASE_API;
  const resource = 'submissionReport';
  const config = services[resource];
  const locationsConfig = services['studentRecords'];

  const { getData } = useContext(Context);

  const getInitialLocation = () => {
    const allBranchesSelected = PersistentRepo.get('allBranches');

    const location = allBranchesSelected ? allBranchesSelected : PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(getInitialLocation());
  const [loading, setLoading] = useState(true);
  const { get: getLocations, busy } = useFetch({ ...locationsConfig.getLocations });

  const calendarDates = {
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  const disabledDate = current => current.isAfter(moment(), 'day');

  useEffect(() => {
    getLocations().then(data => {
      setLocations([{ id: `all`, label: `All Branches` }, ...data]);
      setLoading(false);
    });
  }, []);

  const [filterParams, setFilterParams] = useState({
    branchId: getInitialId('branchId'),
    locationType:
      getInitialId('locationType') === 'all' ? 0 : getInitialId('locationType') === 'yard' ? 1 : 2,
    startDate: calendarDates.startDate,
    endDate: calendarDates.endDate
  });

  const onChange = ([startDate, endDate]) => {
    const range = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    };

    setFilterParams({ ...filterParams, ...range });
  };

  const downloadSubmissionReport = () => {
    fetch(`${baseApi}/${config.downloadSubmissionReport.path}`, {
      method: 'POST',
      body: JSON.stringify(objToSnakeCase(calendarDates)),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Submission_Report_${calendarDates.startDate}_${calendarDates.endDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return;
      });
  };

  return (
    <PageWrapper>
      <PageTitle>Submission Reports</PageTitle>

      <Search>
        <div>
          <Label>Select the range of dates to view submissions</Label>
          <RangePicker
            allowClear={false}
            ranges={{
              Today: [moment(), moment()],
              'Last Week': [moment().subtract(7, 'd'), moment()],
              'Last Month': [moment().subtract(1, 'months'), moment()]
            }}
            defaultValue={[moment().subtract(1, 'months'), moment()]}
            onChange={onChange}
            disabledDate={disabledDate}
          />
        </div>

        <Select
          label="Branches"
          onChange={locationSelected => {
            setLocation(locationSelected);
            if (locationSelected === 'all') {
              setFilterParams();
              PersistentRepo.set('allBranches', locationSelected);
            } else {
              const [locationType, locationId, branchId] = locationSelected.split('x');
              setFilterParams(() => {
                return {
                  ...filterParams,
                  branchId: locationSelected ? branchId : getInitialId('branchId'),
                  locationType: locationSelected
                    ? locationType === 'all'
                      ? 0
                      : locationType === 'yard'
                      ? 1
                      : 2
                    : getInitialId('locationType')
                };
              });
              PersistentRepo.set('location', locationSelected);
              PersistentRepo.delete('allBranches');
            }
          }}
          options={locations}
          value={location}
        />
      </Search>

      <ActionRow style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
        <Button type="default" onClick={() => downloadSubmissionReport()}>
          <Icon type="download" /> Download report
        </Button>
      </ActionRow>

      <div style={{ overflowX: 'auto' }}>
        <AsyncTable
          resource={resource}
          pagination
          config={{
            ...config,
            table: [
              ...config.table,
              {
                title: 'Location',
                render: (_, { branchId, locationType }) => {
                  const a = locations.find(
                    loc => branchId === loc.branchId && locationType === loc.type
                  );

                  return a?.label;
                }
              }
            ]
          }}
          fileName={resource}
          params={filterParams}
        />
      </div>
    </PageWrapper>
  );
};
