import React from 'react';
import { Consumer, ResultContext } from './context';

const Restricted = ({ children, resource, method, hide = true, ...rest }) => {
  const hasPrivilege = (privileges, resource) => {
    const availableMethods = Array.isArray(method) ? method : [method];
    const resources = Array.isArray(resource) ? resource : [resource];
    const resourcePrivileges = privileges.filter(({ resource }) => resources.includes(resource));

    return !resource || resourcePrivileges.some(rp => availableMethods.includes(rp.method));
  };

  const renderChildren = privileges => {
    const hasAccess = hasPrivilege(privileges, resource);

    if (hide) {
      return hasAccess
        ? React.Children.map(children, child => (child ? React.cloneElement(child, rest) : null))
        : null;
    }

    return (
      <ResultContext.Provider value={{ hasPrivileges: hasAccess, ...rest }}>
        <ResultContext.Consumer>{children}</ResultContext.Consumer>
      </ResultContext.Provider>
    );
  };

  return <Consumer>{privileges => renderChildren(privileges)}</Consumer>;
};

export default Restricted;
