import React from 'react';
import refresh from '_src/assets/images/refresh.svg';
import { RefreshDiv } from './style';

const refreshPage = () => {
  window.location.reload(true);
};
/* add refresh on tablets only */

function Refresh(errorBoundery) {
 if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    return (
      <RefreshDiv table={errorBoundery ? errorBoundery : false}>
        <img
          style={{ width: 25, margin: '4px 5px 3px 0px', float: 'right' }}
          src={refresh}
          onClick={() => refreshPage()}
        />
      </RefreshDiv>
    );
  } else {
    return null;
  }
}
export default Refresh;
