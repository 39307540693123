import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
// import { DndProvider, DragSource, DropTarget } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import update from 'immutability-helper';

import './styles.css';
import 'antd/dist/antd.css';

const Row = props => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999
        }
      : {})
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export default ({ dataSource, columns, onClick = () => {}, onOrderChange = () => {} }) => {
  const [dndRowData, setDndRowData] = useState([]);

  useEffect(() => {
    if (dndRowData.length !== dataSource.length) {
      setDndRowData(dataSource);
    } else {
      const hasDataSourceChanged = dataSource.some(({ id, question, sectionType, branchIds }) => {
        const old = dndRowData.find(row => row.id === id);

        return (
          question !== old.question ||
          sectionType !== old.sectionType ||
          branchIds !== old.branchIds
        );
      });

      if (hasDataSourceChanged) {
        setDndRowData(dataSource);
      }
    }
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1
      }
    })
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDndRowData(prev => {
        const activeIndex = prev.findIndex(i => i.key === active.id);
        const overIndex = prev.findIndex(i => i.key === over?.id);
        const newData = arrayMove(prev, activeIndex, overIndex);
        onOrderChange(true, newData);
        return newData;
      });
    }
  };

  return (
    <>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dndRowData.map(i => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={dndRowData}
            components={{
              body: {
                row: Row
              }
            }}
            onRow={(record, index) => ({
              index,
              onClick: () => onClick(record)
            })}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>
    </>
  );
};
