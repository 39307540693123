import table from './table';
import { transformLocations, transformInstructor } from './transform';

export default {
  table,
  get: {
    name: 'activityLogs',
    path: 'logs',
    transform: ({ data }) => data,
    defaultValue: []
  },
  location: {
    placeholder: 'Location',
    component: 'select',
    async: true,
    options: [{ id: 'all', label: 'All' }],
    get: {
      path: 'branches',
      transform: transformLocations,
      defaultValue: [],
      memoize: false
    }
  },
  instructors: {
    name: 'logsInsrtuctors',
    path: 'users?group_id=4', // id = 4 to get only instrtuctors
    transform: transformInstructor,
    defaultValue: []
  }
};
