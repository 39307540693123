import table from './table';
import schema from './schema';
import fields from './fields';

import { transform } from './transform';
const buttonColor = GLOBALS.colors;

const name = 'evaluationTests';
const path = 'testing/for_question';

export default {
  table,
  schema,
  fields,
  get: {
    name,
    path: '/student_evaluations/performance',
    transform: transform,
    defaultValue: []
  },

  getStudentMileage: {
    name: 'student_evaluations',
    path: 'student_evaluations',
    transform: res => (res.data ? res.data.studentEvaluations : [])
  },

  createMileage: {
    path: 'student_evaluations'
  },

  editMileage: { method: 'patch', path: `student_evaluations/:id` },

  deleteMileage: {
    name: 'student_evaluations',
    path: 'student_evaluations/:id'
  },

  types: [
    { id: 1, label: 'PRE TRIP' },
    { id: 3, label: 'SKILLS' },
    { id: 2, label: 'ROAD' },
    { id: 4, label: 'CHARACTER', showForBranch: true }
  ],

  performanceColor: {
    UNSUCCESFUL: buttonColor.RED,
    'NEEDS IMPROVEMENT': buttonColor.ORANGE,
    SATISFACTORY: buttonColor.YELLOW,
    PROFICIENT: buttonColor.GREEN
  },

  answerId: {
    name,
    path
  },

  edit: { name, method: 'put', path: 'testing/responses/:id' },

  deleteTest: {
    name,
    path: 'testing/responses/:id'
  },

  getResponses: {
    name,
    path: 'testing/responses',
    transform: res => (res.data ? res.data.testingResponses : [])
  }
};
