import styled from 'styled-components';

const FilterRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export { FilterRow, ButtonsContainer };
