import styled from 'styled-components';

const FilterRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, auto));
  grid-gap: 20px;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  width: 50%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
`;

const HistoricalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HistoricalRecord = styled.div`
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;

  &:last-child {
    border-bottom: none;
  }
`;

const StudentsContainer = styled(HistoricalDataContainer)`
  margin-top: 20px;
`;

const StudentDetail = styled(HistoricalRecord)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export {
  FilterRow,
  ButtonContainer,
  Row,
  HistoricalDataContainer,
  HistoricalRecord,
  StudentsContainer,
  StudentDetail
};
