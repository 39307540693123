const transformLocations = branchesRes => {
  return (branchesRes.data?.branches || [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, { id, name }) => {
      let items = [
        {
          id,
          label: `${name} School`
        }
      ];
      return [...acc, ...items];
    }, []);
};
const transformInstructor = ({ data }) => {
  return (data.users?.responses || [])
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .reduce((acc, { id, firstName, lastName }) => {
      let items = [
        {
          id,
          label: `${firstName} ${lastName}`
        }
      ];
      return [...acc, ...items];
    }, []);
};

export { transformLocations, transformInstructor };
