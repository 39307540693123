import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';
import { Form, Field } from 'form';
import { useFetch } from 'store';

import { PageContainer, PageTitle, Row } from './styles';
import { Table } from '_src/components';
import services from '_src/services';
import { Input } from '_src/shared/form-helpers';
import { debounce } from '_src/utils';
import { Refresh } from '_src/components';

export default () => {
  const config = services.drivers;

  const [formApi, setFormApi] = useState();
  const [loading, setLoading] = useState(false);
  const [allDrivers, setAllDrivers] = useState();
  
  const { get: getData } = useFetch(config.get);

  useEffect(() => {
    getDrivers();
  }, []);

  const getDrivers = () => {
    const { text = '' } = formApi ? formApi.values() : {};
    setLoading(true);

    getData({ params: { text } })
      .then(drivers => {
        setLoading(false);
        setAllDrivers(drivers);
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: err.message,
          duration: 3
        });
      });
  };

  return (
    <PageContainer>
      <Refresh />
      <PageTitle>Drivers List</PageTitle>

      <Row>
        <Form formApi={api => setFormApi(api)} schema={config.schema}>
          <Field
            name="text"
            label="Name, driver's code, employer or email address"
            render={Input}
            onChange={debounce(getDrivers, 1000, false)}
            style={{ width: '400px', borderColor: '#000' }}
          />
        </Form>
      </Row>

      <Table columns={config.table} data={allDrivers} loading={loading} />
    </PageContainer>
  );
};
