import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
  position: fixed;
  height: 100vh;
  width: 100vw;
`;

const ErrorMessage = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 23px;
  margin-bottom: 20px;
`;

export { Container, ErrorMessage, Title };
