import { transformAll, transformBeforeUpdate } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'regions';
const path = 'regions';

const save = {
  name,
  path
};

export default {
  title: 'Regions',
  createButton: 'Create a new region',
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  create: save,
  edit: { ...save, method: 'put', path: `${path}/:id`, transform: transformBeforeUpdate },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save Region',
    fields,
    schema
  }
};
