import styled from 'styled-components';

const FilterRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { FilterRow, ButtonContainer };
