import { stripNullObj } from 'utils';

const transformAll = attendaceRes => {
  if (attendaceRes.code === 500 || attendaceRes.code === 400) {
    return [];
  }
  const data = attendaceRes.data.students
    ? attendaceRes.data.students
        .map(student => {
          const {
            id,
            firstName,
            lastName,
            preferredNickname,
            license,
            licenseId,
            licenseIdPdf417,
            branchId,
            locationType,
            permitRecDate,
            status,
            groups,
            sponsor,
            yardStartDate
          } = stripNullObj(student);

          const studentGroups = groups
            ? {
                [id]: groups
              }
            : [];

          return {
            id,
            studentId: id,
            name: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
            license,
            licenseId,
            licenseIdPdf417,
            branchId,
            locationType,
            permitRecDate,
            status,
            sponsor,
            groups: studentGroups,
            yardStartDate
          };
        })
        .filter(
          ({ locationType, permitRecDate, status, yardStartDate }) =>
            (status != 'Hold' && locationType === 1 && !!yardStartDate) || locationType === 2
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return data;
};

export { transformAll };
