export default [
  { name: 'name', label: 'Title', component: 'input' },
  {
    name: 'type',
    label: 'Report Type',
    component: 'select',
    options: [
      { id: 1, label: 'Student Progress' },
      { id: 2, label: 'Daily Attendance Report' }
    ]
  },
  {
    name: 'frequency',
    label: 'How often do you want to send it ?',
    component: 'select',
    options: [
      { id: 1, label: 'Daily noon' },
      { id: 2, label: 'Daily morning' },
      { id: 3, label: 'Every monday morning' },
      { id: 4, label: 'Every wednesday at noon' },
      { id: 5, label: 'Every friday at noon' }
    ]
  },
  {
    name: 'emails',
    label: 'Send to',
    component: 'select',
    mode: 'tags',
    async: true,
    pagination: true,
    get: {
      name: 'users',
      path: 'users',
      transform: ({ data }) => {
        const users = data.users.responses.map(({ email }) => ({ id: email, label: email }));
        const total = data.users.total;
        const limit = data.users.limit;
        return { data: users, total, limit };
      },
      defaultValue: {},
      memoize: false
    }
  },
  {
    name: 'branches',
    label: 'Which branch information you want to see ? (Leave blank to see them all)',
    placeholder: 'Branch name',
    component: 'select',
    mode: 'multiple',
    async: true,
    resource: 'branches',
    labelKey: 'name'
  },
  {
    name: 'sponsors',
    label: 'Sponsor (Leave blank to see them all)',
    placeholder: 'Sponsors',
    component: 'select',
    mode: 'multiple',
    async: true,
    get: {
      name: 'sponsors',
      path: 'students/sponsors',
      transform: ({ data }) =>
        data.sponsors
          .filter(sponsor => !!sponsor)
          .sort((a, b) => a.localeCompare(b))
          .map(sponsor => ({ id: sponsor, label: sponsor })),
      defaultValue: []
    }
  },
  { name: 'subject', label: 'Subject', component: 'input' },
  { name: 'message', label: 'Message', component: 'textarea', rows: 4 }
];
