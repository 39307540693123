import table from './table';
import { transformAll } from './transform';

const name = 'bulkYardManagement';
const path = 'students/';

export default {
  restrict: false,
  title: 'Bulk Yard Management',
  get: {
    name,
    path: `${path}student-yard-location`,
    transform: transformAll,
    defaultValue: []
  },
  table,
  update: {
    method: 'patch',
    path: `${path}bulk`
  }
};
