import React, { useState, useEffect } from 'react';
import { notification, Select as AntSelect } from 'antd';
import { useFetch } from 'store';
import moment from 'moment';

import { PageWrapper } from '_src/shared/styles';
import { RangePicker, Select as SelectLms } from '_src/shared/form-helpers';
import { Table } from '_src/components';
import services from '_src/services';
import { Refresh } from '_src/components';
import { PageTitle, Row, SelectContainer, SelectLabel } from './styles';

const now = moment();
const resource = 'activityLogs';

export default () => {
  const config = services[resource];
  const { get: getLogs } = useFetch(config.get);
  const { get: getInstructors } = useFetch(config.instructors);

  const [logs, setLogs] = useState([]);
  const [allInstructors, setAllIndtructors] = useState([{ id: 'all', label: 'All' }]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('all');
  const [instructor, setInstructor] = useState('all');
  const [dateRanges, setDateRanges] = useState({
    startDate: moment().subtract(7, 'd'),
    endDate: moment()
  });
  const { startDate, endDate } = dateRanges;

  let params = {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    userType: 'lms',
    method: 'POST',
    page: 1,
    limit: 101
  };

  if (instructor !== 'all') {
    params.userId = instructor;
  }
  if (location !== 'all') {
    params.branchId = location;
  }

  useEffect(() => {
    fetchInstructors();
  }, []);

  const disabledDate = current => current.isAfter(moment(), 'day');

  const fetchInstructors = () => {
    getInstructors().then(res => {
      res.unshift({ id: 'all', label: 'All' });
      setAllIndtructors(res);
      fetchLogs({ inst: res });
    });
  };

  const fetchLogs = ({ ...args }) => {
    const { param, inst } = args;
    const qparams = {
      ...params,
      ...param
    };
    setLoading(true);
    getLogs({ params: qparams })
      .then(res => {
        setLoading(false);
        setLogs(res.logs);
        transformLogs(res.logs, inst || allInstructors);
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: err.message,
          duration: 3
        });
      });
  };

  const transformLogs = (_logs, _inst) => {
    const currentLogs = _logs || logs;
    const mapedData = currentLogs.map(log => {
      const matchInstructor = _inst.find(i => i.id === log.userId);
      if (matchInstructor) {
        return {
          ...log,
          name: matchInstructor.label
        };
      } else {
        return log;
      }
    });
    setLogs(mapedData);
  };

  return (
    <PageWrapper>
      <Refresh />
      <PageTitle>School / Instructor Audit</PageTitle>
      <Row>
        <RangePicker
          label="Date"
          allowClear={false}
          ranges={{
            Today: [now, now],
            'Last Week': [moment().subtract(7, 'd'), moment()],
            'Last Month': [moment().subtract(1, 'months'), moment()]
          }}
          defaultValue={[startDate, endDate]}
          onChange={dates => {
            const [startDate, endDate] = dates;
            setDateRanges({
              startDate,
              endDate
            });
            fetchLogs({
              param: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
              }
            });
          }}
          disabledDate={disabledDate}
        />
        <SelectLms
          showSearch
          label="Schools"
          onChange={loc => {
            setLocation(loc);
            let qparams = params;
            if (loc !== 'all') {
              fetchLogs({ param: { branchId: loc } });
            } else {
              delete qparams.branchId;
              fetchLogs({ param: qparams });
            }
          }}
          {...config.location}
          value={location}
        />
        <SelectLms
          showSearch
          allowClear
          label="Instructor"
          placeholder="Select an instructor"
          onChange={i => {
            setInstructor(i);
            let qparams = params;
            if (i !== 'all') {
              fetchLogs({ param: { userId: i } });
            } else {
              delete qparams.userId;
              fetchLogs({ param: qparams });
            }
          }}
          options={allInstructors}
          value={instructor}
        />
      </Row>
      <Table columns={config.table} data={logs} bordered={false} loading={loading} dataKey="_id" />
    </PageWrapper>
  );
};
