import { chunk } from 'utils';
import moment from 'moment';
import usdlRules from '_src/utils/usdlRules';

export default [
  { name: 'firstName', label: 'First Name', component: 'viewOnly' },
  { name: 'lastName', label: 'Last Name', component: 'viewOnly' },
  { name: 'mailingAddress', label: 'Address', component: 'googleAutocomplete' },
  { name: 'phone', label: 'Phone', component: 'input' },
  { name: 'email', label: 'Email', component: 'input' },
  { name: 'birthdate', label: 'Birthdate', component: 'date' },
  {
    name: 'gender',
    label: 'Gender',
    component: 'select',
    options: [
      { id: 'male', label: 'Male' },
      { id: 'female', label: 'Female' },
      { id: 'hidden', label: 'Prefer not to say' }
    ]
  },
  { name: 'status', label: 'Status', component: 'viewOnly' },
  {
    name: 'license',
    label: 'Driver License',
    component: 'input',
    onChange: (value = '', { setField }) => {
      const license = value.replace(/-/g, '');
      const rule = usdlRules.find(({ rule }) => new RegExp(rule).test(license));

      if (rule) {
        const state = GLOBALS.states.find(state => state.id === rule.state);
        setField('licenseState', state.label);
      } else {
        setField('licenseState', '');
      }
    },
    normalize: value => {
      let normalizedValue = value.toUpperCase();
      normalizedValue = normalizedValue.replace(/-/g, '');
      normalizedValue = chunk(normalizedValue.split(''), 4);
      normalizedValue = normalizedValue.map((arrChunk, index) => {
        let postFix = arrChunk.length === 4 && index !== normalizedValue.length - 1 ? '-' : '';

        return arrChunk.join('') + postFix;
      });

      return normalizedValue.join('');
    }
  },
  { name: 'licenseState', label: 'Drivers License State', component: 'viewOnly' },
  {
    name: 'dateClassBegins',
    label: 'Date Class Begins',
    component: 'viewOnly',
    format: value => (value ? moment(value).parseZone().format('MM/DD/YYYY') : '')
  },
  { name: 'graduationDate', label: 'Graduation Date', component: 'date' },
  { name: 'carrierRecruiterName', label: "Recruiter's Name", component: 'viewOnly' },
  { name: 'cdlDate', label: 'CDL Date', component: 'date' },
  { name: 'permitExpiration', label: 'Permit Expiration', component: 'date' },
  { name: 'currentEmployer', label: 'Current Employer', component: 'viewOnly' },
  { name: 'classType', label: 'Class type', component: 'viewOnly' },
  { name: 'profilePicture', label: 'Student picture', component: 'profilePicture', editable: true },
  {
    name: 'automaticGear',
    label: 'Automatic transmission',
    component: 'checkbox'
  }
];
