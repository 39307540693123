import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Context, useFetch, useSave } from 'store';
import { PageContainer } from '_src/shared/styles';
import { Override, Absent } from '_src/modules/Attendance/components';
import { StudentTimeline } from '_src/modules/Students/components';
import { Card, Button, notification, Checkbox, Menu, Dropdown, DatePicker } from 'antd';
import { Refresh, AsyncTable, Modal } from '_src/components';
import { Select, Input, RangePicker } from '_src/shared/form-helpers';
import services from '_src/services';
import { PersistentRepo } from '_src/shared/repo';
import { Row, ButtonsContainer, BtnContainer } from './styles';
import { debounce } from '_src/utils';
import moment from 'moment';

export default () => {
  const { getData, setData, refreshResource, getShown, setShown } = useContext(Context);

  const getInitialLocation = () => {
    const allBranchesSelected = PersistentRepo.get('allBranches');

    const location = allBranchesSelected ? allBranchesSelected : PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const config = services['studentAgreements'];
  const locationsConfig = services['studentRecords'];
  const resource = 'studentAgreements';
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState('all');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [studentOverride, setStudentOverride] = useState();
  const [dateRanges, setDateRanges] = useState({
    startDate: '',
    // startDate: moment('01/01/2023', 'MM/DD/YYYY'),
    endDate: ''
  });

  const [hazmatStartDate, setHazmatStartDate] = useState(moment().parseZone());
  const { startDate, endDate } = dateRanges;

  const [filterParams, setFilterParams] = useState({
    branchId: location === 'all' ? 0 : getInitialId('branchId'),

    locationType:
      getInitialId('locationType') === 'all' ? 0 : getInitialId('locationType') === 'yard' ? 1 : 2,

    ...(startDate && { startDate: startDate.format('YYYY-MM-DD') }),
    ...(endDate && { endDate: endDate.format('YYYY-MM-DD') }),
    hazmatStartDate: hazmatStartDate.format('YYYY-MM-DD'),
    orderBy: 'desc'
  });

  const { save: submitFmcsaAction, busy: busySubmitFmcsa } = useSave(config.submitFmcsa);
  const { save: editAttendance } = useSave(config.editAttendance);
  const { get: getLocations, busy } = useFetch({ ...locationsConfig.getLocations });

  const debounceCallback = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );

  useEffect(() => {
    getLocations().then(data => {
      setLocations([{ id: `all`, label: `All Branches` }, ...data]);
      PersistentRepo.set('locations', [{ id: `all`, label: `All Branches` }, ...data]);
      setData('locations', [{ id: `all`, label: `All Branches` }, ...data]);
    });
  }, []);

  const submitFMCSA = () => {
    submitFmcsaAction({ studentId: currentStudent.studentId, hazmatGrade: grade ? grade : 0 })
      .then(res => {
        if (res.success === 1) {
          notification.success({
            message: `Successfully submitted ${currentStudent.firstName} to FMCSA`,
            duration: 5
          });
          setShowConfirmModal(false);
          refreshResource(resource);
        }
        if (res.success === 0) {
          setShowConfirmModal(false);
          notification.error({
            message: res.data.message,
            duration: 5
          });
        }
      })
      .catch(err => {
        notification.error({
          message: 'An error has occurred',
          duration: 5
        });
      });
  };

  const handleAttendanceChange = (checked, student) => {
    editAttendance({ id: student.id, hazmatAttendanceCompleted: checked })
      .then(res => {
        if (res.success === 1) {
          refreshResource(resource);
          notification.success({
            message: `Successfully updated ${student.firstName}'s attendance.`,
            duration: 5
          });
        }
      })
      .catch(err => {
        notification.error({
          message: 'An error has occurred',
          duration: 5
        });
      });
  };

  const renderDropdownCell = (_, record) => {
    const { firstName, lastName, studentId, ...rest } = record;
    const options = (
      <Menu>
        <Menu.Item
          onClick={() => {
            setData('studentTimeline', {
              name: `${firstName} ${lastName}`,
              ...rest,
              id: studentId
            });

            setShown('hazmatStudentTimeline');
          }}
        >
          View Report Card
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={options}>
        <div>
          {firstName} {lastName}
        </div>
      </Dropdown>
    );
  };

  const [grade, setGrade] = useState(null);
  const [errors, setError] = useState({});

  const renderClassroomScore = () => (
    <Input
      name="grade"
      label="Classroom grade"
      placeholder="Classroom grade"
      value={grade}
      onChange={grade => {
        const numberGrade = Number(grade);
        grade ? setGrade(numberGrade) : setGrade(null);
        setError({
          grade:
            grade && (numberGrade < 80 || numberGrade > 100)
              ? 'Grade must be between 80 and 100'
              : null
        });
      }}
      error={errors.grade ? errors.grade : ''}
      touched
      type="number"
      style={{ marginTop: 20 }}
    />
  );

  config.table.find(({ dataIndex }) => dataIndex === 'firstName').render = renderDropdownCell;

  return (
    <PageContainer>
      <Refresh />
      <Card title="Hazmat Portal">
        <Row>
          <Select
            showSearch
            label="Branches"
            onChange={locationSelected => {
              setLocation(locationSelected);
              if (locationSelected === 'all') {
                setFilterParams({ ...filterParams, branchId: 0 });
                PersistentRepo.set('allBranches', locationSelected);
              } else {
                const [locationType, locationId, branchId] = locationSelected.split('x');

                setFilterParams(() => {
                  return {
                    ...filterParams,
                    branchId: locationSelected ? branchId : getInitialId('branchId'),
                    locationType: locationSelected
                      ? locationType === 'all'
                        ? 0
                        : locationType === 'yard'
                        ? 1
                        : 2
                      : getInitialId('locationType')
                  };
                });

                PersistentRepo.set('location', locationSelected);
                PersistentRepo.delete('allBranches');
              }
            }}
            options={locations}
            value={location}
          />
          <Input
            label="Full name"
            onChange={e => {
              const [first, last] = e.split(' ');
              setFirstName(e);
              debounceCallback({ ...filterParams, firstName: first, lastName: last });
            }}
            value={firstName}
          />

          <Input
            label="Email"
            onChange={e => {
              setEmail(e);
              debounceCallback({ ...filterParams, email: e });
            }}
            value={email}
          />
          {/* <Input
            label="Last name"
            onChange={e => {
              setLastName(e);
              debounceCallback({ ...filterParams, lastName: e });
            }}
            value={lastName}
          /> */}
          <RangePicker
            label="Hazmat Registered Date"
            allowClear={false}
            ranges={{
              Today: [moment().parseZone(), moment().parseZone()],
              'Last Week': [moment().parseZone().subtract(7, 'd'), moment().parseZone()],
              'Last Month': [moment().parseZone().subtract(1, 'months'), moment().parseZone()]
            }}
            defaultValue={[startDate, endDate]}
            onChange={dates => {
              const [startDate, endDate] = dates;
              setDateRanges({
                startDate,
                endDate
              });
              debounceCallback({
                ...filterParams,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
              });
            }}
          />
          <span>
            <span>Hazmat Start Date</span>
            <DatePicker
              allowClear={true}
              label="Hazmat Start Date"
              name="hazmatStartDate"
              value={hazmatStartDate}
              onChange={value => {
                setHazmatStartDate(value?.parseZone() || moment().parseZone());
                debounceCallback({
                  ...filterParams,
                  hazmatStartDate: value
                    ? value.format('YYYY-MM-DD')
                    : moment().parseZone().format('YYYY-MM-DD')
                });
              }}
            />
          </span>
        </Row>
        <AsyncTable
          resource={resource}
          pagination
          config={{
            ...config,
            table: [
              ...config.table,
              {
                title: 'Attendance completed',
                key: 'hazmatAttendanceCompleted',
                render: (_, student) => (
                  <Checkbox
                    onChange={e => handleAttendanceChange(e.target.checked, student)}
                    checked={student.hazmatAttendanceCompleted}
                  />
                )
              },
              {
                id: 'download',
                render: (_, student) => {
                  const permitRecDateBefore = student.permitRecDate
                    ? moment(student.permitRecDate).parseZone().isSameOrBefore('2022-02-06', 'day')
                    : true;
                  return (
                    <ButtonsContainer>
                      <BtnContainer>
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(student.filename);
                          }}
                          style={{ width: '200px' }}
                          disabled={!student.filename}
                        >
                          Download agreement
                        </Button>
                      </BtnContainer>

                      <BtnContainer>
                        <Button
                          type="primary"
                          disabled={
                            student.hazmatSubmittedToFmcsa ||
                            !student.hazmatAttendanceCompleted ||
                            !student.hazmatStartDate
                          }
                          onClick={() => {
                            setCurrentStudent(student);
                            setShowConfirmModal(true);
                          }}
                          style={{
                            width: '200px',
                            backgroundColor: student.hazmatSubmittedToFmcsa ? '#5AB55B' : '',
                            borderColor: student.hazmatSubmittedToFmcsa ? '#5AB55B' : ''
                          }}
                        >
                          {!student.hazmatSubmittedToFmcsa
                            ? 'Submit to FMCSA'
                            : 'Submitted to FMCSA'}
                        </Button>
                      </BtnContainer>

                      <BtnContainer>
                        <Button
                          type="primary"
                          onClick={() => {
                            student.locationType = 2;
                            student.id = student.studentId;
                            setStudentOverride(student);
                            setShown('override');
                          }}
                          disabled={student.hazmatAttendanceCompleted}
                          style={{ width: '200px' }}
                        >
                          Manage Attendance
                        </Button>
                      </BtnContainer>
                    </ButtonsContainer>
                  );
                }
              }
            ]
          }}
          fileName={resource}
          params={filterParams}
          resetPageOnFilter={[location]}
          onChange={(pagination, filters, sorter) => {
            if (sorter.field) {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  orderBy: sorter.order === 'ascend' ? 'asc' : 'desc',
                  order: sorter.field
                })
              });
            }
          }}
        />
      </Card>
      <Modal
        className="submitFmcsa"
        title="Submit to FMCSA"
        visible={showConfirmModal}
        onCancel={() => {
          setCurrentStudent(null);
          setShowConfirmModal(false);
        }}
        footer={[
          <Button
            key="submitClose"
            type="default"
            onClick={() => {
              setCurrentStudent(null);
              setShowConfirmModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submitEdit"
            type="primary"
            loading={busySubmitFmcsa}
            onClick={submitFMCSA}
            disabled={busySubmitFmcsa || errors.grade || !grade}
          >
            Submit
          </Button>
        ]}
      >
        Are you sure you want to submit {currentStudent?.firstName} {currentStudent?.lastName} to
        FMCSA?
        {renderClassroomScore()}
      </Modal>

      {getShown('override') && (
        <Override
          student={studentOverride}
          onModalClose={() => setStudentOverride(null)}
          isHazmat={true}
        />
      )}

      {getShown('hazmatStudentTimeline') && <StudentTimeline isHazmat={true} />}
    </PageContainer>
  );
};
