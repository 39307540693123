import React from 'react';

import { StyledRadio, RadionOption, Label, RadioContainer } from './styles';

const Radio = ({ value, onChange, disabled }) => {
  const hasValue = typeof value !== 'undefined';
  value = hasValue ? !!value : undefined;

  return (
    <RadioContainer>
      <RadionOption>
        <Label
          disabled={disabled}
          type={1}
          value={value}
          onClick={() => !disabled && onChange(value ? undefined : true)}
        >
          Yes
        </Label>
        <StyledRadio
          disabled={disabled}
          type={1}
          onClick={() => !disabled && onChange(value ? undefined : true)}
          value={value}
        />
      </RadionOption>

      <RadionOption>
        <Label
          disabled={disabled}
          type={0}
          value={value}
          onClick={() => !disabled && onChange(hasValue ? undefined : false)}
        >
          No
        </Label>
        <StyledRadio
          disabled={disabled}
          type={0}
          onClick={() => !disabled && onChange(hasValue ? undefined : false)}
          value={value}
        />
      </RadionOption>
    </RadioContainer>
  );
};

export default Radio;
