import React from 'react';

import close from '_src/assets/images/close.svg';
import releaseNotes from '../../constants/releaseNotes';
import {
  ModalComponent,
  CloseImage,
  ModalBody,
  ModalTitle,
  VersionItem,
  VersionItemDetails,
  VersionNumberText,
  VersionDateText,
  VersionNotesText
} from './styles';

const VersionModal = ({ visible, onClose }) => {
  return (
    <ModalComponent visible={visible} closable={true} onCancel={onClose} footer={null}>
      <CloseImage src={close} onClick={onClose} />

      <ModalBody>
        <ModalTitle>Version History</ModalTitle>

        {releaseNotes.map(({ versionNumber, versionDate, notes }) => (
          <VersionItem key={versionNumber}>
            <VersionItemDetails>
              <VersionNumberText>{versionNumber}</VersionNumberText>

              <VersionDateText>{versionDate}</VersionDateText>
            </VersionItemDetails>

            {notes.map((note, index) => (
              <VersionNotesText key={`${versionNumber}_${index}`}>{note}</VersionNotesText>
            ))}
          </VersionItem>
        ))}
      </ModalBody>
    </ModalComponent>
  );
};

export { VersionModal };
