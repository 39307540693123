import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Context } from 'store';
import { Card, Button } from 'antd';
import moment from 'moment';

import { AsyncTable, Refresh } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select, Input } from '_src/shared/form-helpers';
import { debounce } from '_src/utils';
import { camelToSnakeCase, snakeToCamelCase } from '../../utils/helpers';

import { FilterRow, ButtonContainer } from './styles';

const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };
const resource = 'manageLocationType';
const attendanceConfig = services.attendance;

var timezone = moment().utcOffset() / 60;

if (timezone > 0) {
  timezone = 'GMT+' + timezone;
} else if (timezone < 0) {
  timezone = 'GMT' + timezone;
} else if (timezone === 0) {
  timezone = 'GMT 0';
}
export default () => {
  const daysOfTheWeek = [
    { id: 'all', label: 'All' },
    { id: 'monday', label: 'Monday' },
    { id: 'tuesday', label: 'Tuesday' },
    { id: 'wednesday', label: 'Wednesday' },
    { id: 'thursday', label: 'Thursday' },
    { id: 'friday', label: 'Friday' },
    { id: 'saturday', label: 'Saturday' },
    { id: 'sunday', label: 'Sunday' },
    { id: 'night_class', label: 'Night Class' }
  ];
  const allBranchesLocation = PersistentRepo.get('allBranches');
  const initialLocation = allBranchesLocation
    ? allBranchesLocation
    : PersistentRepo.get('location', 'allxallx1');
  const [selectedGroup, setGroup] = useState('all');
  const { getData, refreshResource } = useContext(Context);
  const [location, setLocation] = useState(
    initialLocation === 'allxallx1' ? 'All Branches' : initialLocation
  );
  const [name, setName] = useState('');
  const [fileterByStatus, setFileterByStatus] = useState('All');
  const [trainingDays, setTrainingDays] = useState('All');

  const [filterParams, setFilterParams] = useState({
    ...filterParams,
    // enrolled: true,
    branchId: location.split('x')[2],
    locationType: location.split('x')[0] === 'all' ? 0 : location.split('x')[0] === 'yard' ? 1 : 2,
    attendance: true,
    ...(selectedGroup !== 'all' && { groups: selectedGroup }),
    ...(name !== '' && { student: name }),
    ...(fileterByStatus !== 'All' && { fileterByStatus }),
    ...(trainingDays !== 'All' && { trainingDays }),
    isHazmat: true
  });

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      if (!allLocations.find(({ id }) => id === location) && location !== 'all') {
        onLocationChange(allLocations[0].id);
      }
    }
  }, []);

  const onLocationChange = loc => {
    const isAllBranches = loc === 'all';
    if (isAllBranches) {
      PersistentRepo.set('allBranches', loc);
    } else {
      allBranchesLocation && PersistentRepo.delete('allBranches');
    }

    setLocation(loc);
    setFilterParams(prevState => {
      return {
        ...prevState,
        branchId: isAllBranches ? undefined : loc.split('x')[2],
        locationType: loc.split('x')[0] === 'all' ? 0 : loc.split('x')[0] === 'yard' ? 1 : 2
      };
    });
  };

  const locations = {
    options: [{ id: 'all', label: 'All Branches' }],
    ...attendanceConfig.location
  };

  const onGroupChange = group => {
    setGroup(group);
    setFilterParams(prevState => {
      return {
        ...prevState,
        groups: group
      };
    });
  };

  const debounceCallbackSetName = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );

  return (
    <PageContainer style={stylesTablet}>
      <Refresh />
      <Card title="Yard Schedule">
        <FilterRow>
          <Input
            label="Search by name"
            value={name}
            onChange={e => {
              setName(e);
              debounceCallbackSetName({ ...filterParams, student: e });
            }}
          />

          <Select
            options={daysOfTheWeek}
            label="Filter by day"
            showArrow
            value={trainingDays !== 'all' ? trainingDays.split(',') : 'all'}
            onChange={day => {
              const filterAll =
                day[day.length - 1] === 'all'
                  ? 'all'
                  : day.filter(e => e.toLowerCase() !== 'all').join(',');

              setTrainingDays(!!filterAll ? filterAll : 'all');
              setFilterParams(prevState => {
                return {
                  ...prevState,
                  trainingDays: !!filterAll ? filterAll : 'all'
                };
              });
            }}
            mode="multiple"
            style={{ width: '150px' }}
          />
          <Select
            options={[
              { id: 'all', label: 'All' },
              { id: 'Hold', label: 'Hold' }
            ]}
            label="Filter by status"
            showArrow
            value={fileterByStatus}
            onChange={status => {
              setFileterByStatus(status);
              setFilterParams(prevState => {
                return {
                  ...prevState,
                  fileterByStatus: status
                };
              });
            }}
          />
          <div style={{ width: 160 }}>
            <Select
              options={[
                { id: 'all', label: 'All' },
                { id: 'Group 1', label: 'Group 1' },
                { id: 'Group 2', label: 'Group 2' },
                { id: 'Group 3', label: 'Group 3' },
                { id: 'Group 4', label: 'Group 4' },
                { id: 'Group 5', label: 'Group 5' },
                { id: 'Group 6', label: 'Group 6' },
                { id: 'Group 7', label: 'Group 7' },
                { id: 'Group 8', label: 'Group 8' },
                { id: 'Group 9', label: 'Group 9' },
                { id: 'Group 10', label: 'Group 10' },
                { id: 'Group 11', label: 'Group 11' },
                { id: 'Group 12', label: 'Group 12' }
              ]}
              label="Filter by groups"
              showArrow
              value={selectedGroup !== 'all' ? selectedGroup.split(',') : 'all'}
              onChange={group => {
                const filterAll =
                  group[group.length - 1] === 'all'
                    ? 'all'
                    : group.filter(e => e.toLowerCase() !== 'all').join(',');
                onGroupChange(!!filterAll ? filterAll : 'all');
              }}
              mode="multiple"
            />
          </div>
          <Select
            showSearch
            onChange={onLocationChange}
            {...locations}
            value={location}
            label="Filter by branch"
            style={{ width: '250px' }}
          />
        </FilterRow>

        <AsyncTable
          pagination
          fileName={resource}
          resource={resource}
          config={{
            ...services[resource],
            table: [
              ...services[resource].yardTable,
              {
                id: 'daysOfTraining',
                title: 'Days of Training',
                render: (_, student) => {
                  return (
                    <ButtonContainer>
                      {daysOfTheWeek.map(
                        (e, i) =>
                          student[snakeToCamelCase(e.id)] && (
                            <Button
                              style={{
                                border: '1px solid #d4d3d4',
                                background: 'white',
                                color: '#736974'
                              }}
                              type="primary"
                            >
                              {e.label}
                            </Button>
                          )
                      )}
                    </ButtonContainer>
                  );
                }
              }
            ]
          }}
          resetPageOn={[filterParams]}
          params={{
            // enrolled: filterParams.enrolled,
            branchId: filterParams.branchId,
            attendance: filterParams.attendance,
            locationType: filterParams.locationType,
            ...(filterParams.groups !== 'all' &&
              filterParams.groups !== '' && { groups: filterParams.groups }),
            ...(filterParams.student !== '' && { student: filterParams.student }),
            ...(filterParams.fileterByStatus !== 'all' && {
              fileterByStatus: filterParams.fileterByStatus
            }),
            ...(filterParams.trainingDays !== 'all' &&
              filterParams.trainingDays !== '' && {
                trainingDays: filterParams.trainingDays
              }),
            orderBy: filterParams.orderBy,
            order: filterParams.order,
            isHazmat: filterParams.isHazmat
          }}
          onChange={(pagination, filters, sorter) => {
            if (sorter.field) {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  orderBy: camelToSnakeCase(sorter.field === 'name' ? 'first_name' : sorter.field),
                  order: sorter.order === 'ascend' ? 'asc' : 'desc'
                })
              });
            }
          }}
        />
      </Card>
    </PageContainer>
  );
};
