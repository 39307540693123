import LoginConfig from './login';
import GroupsConfig from './groups';
import UsersConfig from './users';
import RegionsConfig from './regions';
import BranchesConfig from './branches';
import YardsConfig from './yards';
import ClassroomsConfig from './classrooms';
import ChangePasswordConfig from './changePassword';
import AttendanceConfig from './attendance';
import ReportsConfig from './reports';
import QuestionsConfig from './evaluationQuestions';
import RoadQuestionsConfig from './roadQuestions';
import PreTripsQuestionsConfig from './preTripsQuestions';
import SkillQuestionsConfig from './skillQuestions';
import CharacterQuestionsConfig from './characterQuestions';
import StudentRecordsConfig from './studentRecords';
import SingleStudentRecordConfig from './singleStudentRecord';
import AutomatedEmails from './automatedEmails';
import Drivers from './drivers';
import EmployersList from './employersList';
import LmsUsage from './lmsUsage';
import CustomReports from './customReports';
import StudentPerformanceEvaluator from './studentPerformanceEvaluator';
import hoursField from './hoursField';
import Profile from './profile';
import AdminAttendance from './adminAttendance';
import BulkYardManagement from './bulkYardManagement';
import ActivityLogs from './activityLogs';
import ManageLocationType from './manageLocationType';
import ManageDuplicates from './manageDuplicates';
import ForgotPassword from './forgotPassword';
import InstructorReportsConfig from './instructorReports';
import ClassroomScoreConfig from './classroomScore';
import FmcsaSubmissions from './fmcsaSubmissions';
import SubmissionReport from './submissionReport';
import EvaluationTestsConfig from './evaluationTests';
import CDLTesting from './cdlTesting';
import StudentGroupsConfig from './studentGroups';
import StudentAgreements from './studentAgreements';
import Documents from './documents';
import AFPDriversReport from './driversReport';
import StudentIntervention from './studentIntervention';

const config = {
  login: LoginConfig,
  groups: GroupsConfig,
  users: UsersConfig,
  regions: RegionsConfig,
  branches: BranchesConfig,
  yards: YardsConfig,
  classrooms: ClassroomsConfig,
  changePassword: ChangePasswordConfig,
  attendance: AttendanceConfig,
  reports: ReportsConfig,
  evaluation_questions: QuestionsConfig,
  roadQuestions: RoadQuestionsConfig,
  preTripsQuestions: PreTripsQuestionsConfig,
  skillQuestions: SkillQuestionsConfig,
  characterQuestions: CharacterQuestionsConfig,
  studentRecords: StudentRecordsConfig,
  singleStudentRecord: SingleStudentRecordConfig,
  automatedEmails: AutomatedEmails,
  drivers: Drivers,
  employersList: EmployersList,
  lmsUsage: LmsUsage,
  customReports: CustomReports,
  studentPerformanceEvaluator: StudentPerformanceEvaluator,
  profile: Profile,
  adminAttendance: AdminAttendance,
  bulkYardManagement: BulkYardManagement,
  activityLogs: ActivityLogs,
  manageLocationType: ManageLocationType,
  manageDuplicates: ManageDuplicates,
  hoursField: hoursField,
  forgotPassword: ForgotPassword,
  instructorReports: InstructorReportsConfig,
  classroomScore: ClassroomScoreConfig,
  fmcsaSubmissions: FmcsaSubmissions,
  submissionReport: SubmissionReport,
  evaluationTests: EvaluationTestsConfig,
  cdlTesting: CDLTesting,
  studentGroups: StudentGroupsConfig,
  studentAgreements: StudentAgreements,
  documents: Documents,
  studentIntervention: StudentIntervention,
  driversReport: AFPDriversReport
};

export default config;
