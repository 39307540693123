import styled from 'styled-components';

const Container = styled.div`
  background-color: ${GLOBALS.colors.YELLOW};
  border-radius: 10px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0.5%;
  right: 1%;
`;

const Label = styled.span`
  color: ${GLOBALS.colors.DARK_TEXT};
  padding-left: 10px;
`;

export { Container, Label };
