import table from './table';
import yardTable from './yardTable';
import { transformAll } from './transform';

export default {
  table,
  yardTable,
  get: {
    name: 'manageLocationType',
    path: 'students',
    transform: transformAll,
    defaultValue: []
  }
};
