import { string, number, object } from 'yup';

const schema = object().shape({
  name: string().required('Name is required'),
  regionId: number().required('Region is required'),
  city: string().required('City is required'),
  zip: string().required('Zip is required'),
  state: string().required('State is required'),
  streetAddress: string().required('Street address is required'),
  yardId: string().required('Yard is required'),
  classroomId: string().required('Classroom is required'),
  crmBranchId: string().required('Salesforce branch is required'),
  timezone: string().required('Timezone is required')
});

export default { create: schema, edit: schema };
