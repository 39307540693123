import styled from 'styled-components';

const PageContainer = styled.div`
  background: #fff;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding-top: 34px;
`;
const ExpiredLinkText = styled.span`
  margin: 34px 20px 0px 20px;
  text-align: center;
  font-family: 'SF Display Bold';
  font-size: 18px;
`;

const Sidebar = styled.div`
  text-align: center;
  width: 300px;
  padding: 0 20px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1;
`;

const Student = styled.div`
  background: #f0f0f0;
  text-align: center;
  width: 250px;
  padding: 15px 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'SF Display Bold';
  font-size: 17px;
`;

const CardContainer = styled.div`
  padding: 25px;
  padding-top: 0;
  margin-top: -10px;
  width: calc(100% - 300px);
  margin-left: 300px;
`;

const Logo = styled.img`
  height: 70px;
`;

const Title = styled.div`
  font-family: 'SF Display Bold';
  font-size: 20px;
  margin-bottom: 20px;
`;

const TimezoneLabel = styled(Title)`
  font-size: 15px;
`

const NoTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StudentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 50px;
  margin-top: 50px;
`;

const ProfileText = styled.div`
  color: #fff;
  font-family: Arial;
  font-weight: bold;
`;

const Profile = styled.div`
  background-color: #ff8400;
  padding: 20px 43px;
  margin-bottom: 25px;
  width: 300px;
`;

const Name = styled(ProfileText)`
  font-size: 32px;
`;

const Hours = styled(ProfileText)`
  font-size: 18px;
  margin-top: -8px;
`;

const Miles = styled(ProfileText)`
  font-size: 18px;
  border-bottom: 1px solid #fff;
  padding-bottom: 7px;
  margin-bottom: 10px;
`;

const Branch = styled(ProfileText)`
  font-size: 16px;
`;

const Sponsor = styled(ProfileText)`
  font-size: 16px;
`;

const StudentsIcon = styled.img`
  width: 90px;
  margin: 50px 0 20px 0;
`;

const SidebarTitle = styled.div`
  color: #000;
  font-family: Avenir;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 15px;
`;

const LogoContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: 50px;
  background: #fff;
  width: 100%;
  text-align: center;

  img {
    margin: 0 auto;
  }
`;

export {
  PageContainer,
  Sidebar,
  Student,
  Container,
  CardContainer,
  Logo,
  Title,
  NoTimelineContainer,
  StudentsContainer,
  Profile,
  Name,
  Hours,
  Miles,
  Branch,
  Sponsor,
  StudentsIcon,
  SidebarTitle,
  LogoContainer,
  NoDataContainer,
  ExpiredLinkText,
  LoadingContainer,
  TimezoneLabel
};
