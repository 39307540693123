import moment from 'moment';
import { PersistentRepo } from '_src/shared/repo';

const transform = res => {
  const getTimezone = PersistentRepo.get('getTimezone');
  const data = res?.data?.info?.studentInfos || [];

  const transformedData = data.map(item => {
    return {
      ...item,
      createdAt: moment
        .tz(
          moment.parseZone(moment(item.createdAt)).format('MM/DD/YYYY hh:mm A'),
          'MM/DD/YYYY hh:mm A',
          getTimezone
        )
        .format('MM/DD/YYYY hh:mm A')
    };
  });

  return { data: transformedData, total: res.data.info.total };
};

export { transform };
