import React, { useState, useContext } from 'react';
import { Context, useSave } from 'store';
import { Form, Field } from 'form';
import { notification } from 'antd';

import { Textarea, SignaturePad } from '_src/shared/form-helpers';
import { Modal } from '_src/components';
import services from '_src/services';

import { ConfirmGraduationFormContainer, NoteSignaturePanel } from './styles';

export default ({ studentId, resourceFrom }) => {
  const config = services.reports;
  const resourceGraduation = 'confirmGraduation';
  const resource = 'reports';
  const { setShown, refreshResource } = useContext(Context);
  const [formApi, setFormApi] = useState();
  const { save: create, busy } = useSave({ ...config.saveConfirmGraduation });

  const handleOk = () => {
    const {
      studentSignatureNote,
      studentSignature,
      instructorSignatureNote,
      instructorSignature
    } = formApi.values();
    create({
      id: studentId,
      studentSignatureNote,
      studentSignature,
      instructorSignatureNote,
      instructorSignature
    }).then(({ success, data }) => {
      if (success === 1) {
        notification.success({
          message: data.message,
          duration: 2.5
        });
        refreshResource(resource);
        closeModal();
        if (resourceFrom) {
          refreshResource('attendance');
          setShown('studentTimeline', false);
          setShown('StudentProfileModal', false);
        }
      } else {
        notification.error({
          message: data.message,
          duration: 2.5
        });
      }
    });
  };

  const closeModal = () => {
    setShown(resourceGraduation, false);
  };

  return (
    <Modal
      visible
      width="70%"
      title="Confirm Graduation"
      onCancel={closeModal}
      onOk={handleOk}
      confirmLoading={busy}
      okText="Continue"
    >
      <Form schema={config.confirmGraduationModalSchema} formApi={api => setFormApi(api)}>
        <ConfirmGraduationFormContainer>
          <NoteSignaturePanel>
            <Field name="studentSignatureNote" label="Student Notes" render={Textarea} />
            <Field name="studentSignature" label="Student Signature" render={SignaturePad} />
          </NoteSignaturePanel>
          <NoteSignaturePanel>
            <Field name="instructorSignatureNote" label="Instructor Notes" render={Textarea} />
            <Field name="instructorSignature" label="Instructor Signature" render={SignaturePad} />
          </NoteSignaturePanel>
        </ConfirmGraduationFormContainer>
      </Form>
    </Modal>
  );
};
