import React from 'react';
import { TimePicker, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

export default ({ error, value, touched, label, ...rest }) => {
  const hasError = error && touched;

  if (value && typeof value === 'string') {
    value = moment(value);
  }

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      {(label || hasError) && <Label hasError={hasError}>{hasError ? error : label}</Label>}
      <TimePicker value={value ? value : null} {...rest} />
    </Form.Item>
  );
};
