export default [
  { name: 'name', label: 'Employer name', component: 'input' },
  {
    name: 'emails',
    label: 'Emails',
    component: 'select',
    mode: 'tags',
    async: true,
    get: {
      name: 'users',
      path: 'users',
      transform: ({ data }) =>
        data.users.responses.map(({ email }) => ({ id: email, label: email })),
      defaultValue: []
    }
  }
];
