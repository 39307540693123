import React from 'react';

export default [
  {
    title: 'First name',
    dataIndex: 'studentFirstName',
    key: 'studentFirstName',
    render: (_, { studentFirstName }) => <div>{studentFirstName}</div>
  },
  {
    title: 'Last name',
    dataIndex: 'studentLastName',
    key: 'studentLastName',
    render: (_, { studentLastName }) => <div> {studentLastName}</div>
  },
  {
    title: 'Staff member',
    dataIndex: 'submittedBy'
  },
  {
    title: 'Time/Date',
    dataIndex: 'createdAt',
    sorter: true
  },
  {
    title: 'Submitted for',
    dataIndex: 'submittedFor'
  }
];
