import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, notification, Modal, Skeleton, Button as AntButton } from 'antd';
import { Context, State, useSave, useFetch, Request } from 'store';
import QRCode from 'qrcode.react';
import moment from 'moment';
import 'moment-timezone';
import services from '_src/services';
import { Select } from '_src/shared/form-helpers';
import { PersistentRepo } from '_src/shared/repo';
import { Button } from '_src/ui';
import { Restricted } from '_src/shared/restrict';
import StudentTimeline from '_src/modules/Students/components/Timeline/StudentTimeline';
import { Refresh } from '_src/components';
import StyledButton from '../../ui/Button/styles';
import {
  Absent,
  Override,
  StudentPerformanceEvaluator,
  WarningLabel,
  StudentIntervention
} from './components';

import {
  TwoColumns,
  OneColumn,
  PageContainer,
  PageTitle,
  CenterCol,
  TableContainer,
  THeader,
  FirstCol,
  MiddleCol,
  ThirdCol,
  TRow,
  Name,
  AttendanceValue,
  GridImage,
  TitleRow,
  Row,
  CardBlock,
  Totals,
  LabelCard,
  StudentRow,
  StudentContainer,
  AttendanceIcons,
  ClockIcon,
  CenterText,
  StudentIcons,
  ButtonContainer
} from './styles';
import greenMan from '_src/assets/images/green-man-user.png';
import redMan from '_src/assets/images/red-man-user.png';
import orangeMan from '_src/assets/images/man-user.png';
import checked from '_src/assets/images/checked.png';
import absentImg from '_src/assets/images/absent.png';
import sessionClosed from '_src/assets/images/sessionClosed.png';
import doubleCheck from '_src/assets/images/double-checked.png';
import clock from '_src/assets/images/clock.png';

const buttonColor = GLOBALS.colors;

const testingConfig = [
  { id: 1, label: 'PRE TRIP' },
  { id: 3, label: 'SKILLS' },
  { id: 2, label: 'ROAD' },
  { id: 4, label: 'CHARACTER', showForBranch: true }
];

var timezoneParam = moment().utcOffset() / 60;

if (timezoneParam > 0) {
  timezoneParam = 'GMT ' + timezoneParam;
} else if (timezoneParam < 0) {
  timezoneParam = 'GMT' + timezoneParam;
} else if (timezoneParam === 0) {
  timezoneParam = 'GMT 0';
}

const getBranchTimezone = PersistentRepo.get('getTimezone');
const fmt = 'MM/DD/YYYY HH:mm';

const performanceToColor = {
  UNSUCCESFUL: buttonColor.RED,
  'NEEDS IMPROVEMENT': buttonColor.ORANGE,
  SATISFACTORY: buttonColor.YELLOW,
  PROFICIENT: buttonColor.GREEN
};

export default () => {
  const resource = 'attendance';
  const config = services[resource];
  const { setShown, getShown, refreshResource, getData, setData } = useContext(Context);
  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    ? { marginTop: 30 }
    : { marginTop: 0 };

  const [location, setLocation] = useState(getInitialLocation());
  const [getTimezone, setTimezone] = useState();

  const [classType, setClassType] = useState(
    PersistentRepo.get('day') ? PersistentRepo.get('day') : ['all']
  );
  const [userName, setUserName] = useState('');
  const [showOpenSessionDates, setShowOpenSessionDates] = useState(false);
  const [showUnfilledHBDDates, setShowUnfilledHBDDates] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState();
  const [testingType, setTestingType] = useState(-1);
  const [openSessionsList, setOpenSessionsList] = useState([]);
  const [unfilledHBDList, setUnfilledHBDList] = useState([]);
  const [selectedDateSession, setSelectedDateSession] = useState();
  const [unsignedUser, setUnsignedUser] = useState();
  const [selectedGroup, setGroup] = useState('all');
  const [rerender, setRenender] = useState(Date.now());

  const { save: create } = useSave(config.create);
  const { save: edit } = useSave(config.edit);
  const { get: openSessions } = useFetch(config.getOpenSessions);
  const { get: unfilledHBD } = useFetch(config.getUnfilledHBD);

  const { save: getStudentsPerformance, busy: studentsPerformanceBusy } = useSave(
    config.getStudentsPerformance
  );
  const { save: getStudentsProficiency, busy: studentsProficiencyBusy } = useSave(
    config.getStudentsProficiency
  );

  const hasLocation = () => !!location;
  const hasSelectedLocation = hasLocation();

  const instructorReportsConfig = services['instructorReports'];
  const [sponsors, setSponsor] = useState(['all']);

  const history = useHistory();
  const handleBulkAttendanceClick = () => history.push('/bulkAttendance');

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      let timezone = allLocations
        ? allLocations.find(({ id }) => id === location)
          ? allLocations.find(({ id }) => id === location).timezone
          : ''
        : '';
      setTimezone(timezone);
      PersistentRepo.set('getTimezone', getTimezone);

      if (!allLocations.find(({ id }) => id === location)) {
        onLocationChange(allLocations[0]?.id);
      }
    }

    const { firstName = '' } = PersistentRepo.get('user', {});
    setUserName(firstName);
  });

  const formatLatestResult = (latestResult, type) => {
    if (latestResult) {
      latestResult = type
        ? `${Math.round(latestResult.split('%')[0])}% Performance`
        : Math.round(latestResult * 100) + '% Completed';

      return latestResult;
    }

    return '-';
  };

  const onLocationChange = loc => {
    setLocation(loc);
    PersistentRepo.set('location', loc);
    PersistentRepo.get('allBranches') && PersistentRepo.delete('allBranches');
  };

  const saveAbsentStudents = (absent = []) => {
    const [locationType, locationId, branchId] = location.split('x');
    const allLocations = getData('locations');
    const allStudents = getData('attendance').reduce(
      (acc, { id, locationType }) => ({ ...acc, [id]: locationType }),
      {}
    );

    const requests = absent.map(({ id, isExcused, attendanceId }) => {
      let utcTimeNow = moment.parseZone(moment()).format(fmt);
      let data = {
        locationId,
        locationType: locationType === 'yard' ? 1 : 2,
        branchId,
        attendanceDate: moment.tz(utcTimeNow, fmt, getBranchTimezone).unix(),
        excusedAbsence: isExcused
      };

      if (locationType === 'all') {
        const studentLocationType = allStudents[id];
        const studentLocationId = allLocations.find(loc => loc.branchId == branchId).branchLocation[
          studentLocationType === 1 ? 'yardId' : 'classroomId'
        ];

        data.locationId = studentLocationId;
        data.locationType = studentLocationType;
      }

      if (attendanceId) {
        return edit({ excusedAbsence: isExcused, id: attendanceId });
      } else {
        return create({ ...data, studentId: id });
      }
    });

    Promise.all(requests).then(responses => {
      if (responses.length && responses.every(resp => resp.success === 1)) {
        refreshResource(resource);
        notification.success({
          message: `Successfully marked as absent`,
          duration: 5
        });
      }
    });
  };

  const updateModal = item => {
    if (openSessionsList.length > 0) {
      const _index = openSessionsList.findIndex(session => {
        return moment(session.attendanceDate).format('MM/DD/YYYY') === item.format('MM/DD/YYYY');
      });

      if (showOpenSessionDates && _index > -1) {
        openSessionsList.splice(_index, 1);
        if (openSessionsList.length === 0) {
          setShowOpenSessionDates(false);
        }
      }
    }
  };

  const renderAttendance = () => {
    const [locationType, locationId, branchId] = location.split('x');
    const date = moment().format('DD-MM-YYYY');
    let daysFilter = classType
      .filter(day => day !== 'all')
      .reduce((acc, curr) => ((acc[curr] = 1), acc), {});

    if (daysFilter.dayClass) {
      delete daysFilter.dayClass;
      daysFilter.nightClass = false;
    }

    const sponsorsFilter = sponsors.filter(sponsor => sponsor !== 'all');
    let attendanceParams = {
      date,
      timezone: timezoneParam,
      locationType: 0,
      locationId: branchId,
      ...(sponsorsFilter.length && { sponsors: sponsorsFilter.join(',') }),
      ...daysFilter,
      rerender,
      ...(selectedGroup && selectedGroup != 'all' && { groups: selectedGroup })
    };
    let reportParams = { branchId, date };
    if (locationType !== 'all') {
      attendanceParams.locationType = locationType === 'yard' ? 1 : 2;
      attendanceParams.locationId = locationId;
    }

    return (
      <>
        <Request.Get {...config.get} params={[attendanceParams, attendanceParams]}>
          {({ data, busy }) => (busy ? renderBusyForm() : renderTable(data))}
        </Request.Get>
        {renderTakeAttendance()}
      </>
    );
  };

  const renderTakeAttendance = () => (
    <Restricted resource="attendance" method={['POST', 'PATCH']}>
      <State name="attendance">
        {students => {
          let [locationType] = location.split('x');

          if (locationType !== 'all') {
            locationType = locationType === 'yard' ? 1 : 2;
            students = students.filter(student => student?.locationType === locationType);
          }
          return (
            <>
              {getShown('testing') && (
                <Restricted resource="testing/responses" method={['POST', 'PATCH']}>
                  <StudentPerformanceEvaluator
                    resource="testing"
                    type={testingType}
                    student={students.find(({ id }) => selectedStudent === id) || {}}
                  />
                </Restricted>
              )}

              {getShown('absence') && <Absent save={saveAbsentStudents} students={students} />}

              {getShown('override') && (
                <Override
                  locationType={locationType}
                  student={students.find(({ id }) => selectedStudent === id)}
                  selectedDateSession={selectedDateSession}
                  onModalClose={updateModal}
                />
              )}

              {getShown('studentTimeline') && (
                <StudentTimeline rerenderEvaluations={() => setRenender(Date.now())} />
              )}
            </>
          );
        }}
      </State>
    </Restricted>
  );

  const renderBusyForm = () => (
    <TableContainer>
      <Skeleton active title={false} paragraph={{ rows: 15 }} />
    </TableContainer>
  );

  const openUnclosedSessions = ({ id }) => {
    openSessions({
      replace: { id }
    }).then(response => {
      const res = response[0];
      if (res.success === 1) {
        res.data.openSessionsDates.sort((a, b) => {
          const aAttendanceDate = moment(a.attendanceDate);
          const bAttendanceDate = moment(b.attendanceDate);
          return aAttendanceDate.isBefore(bAttendanceDate) ? 1 : -1;
        });

        setOpenSessionsList(res.data.openSessionsDates);
        setShowOpenSessionDates(true);
        setSelectedStudent(id);
      }
    });
  };

  const openUnfilledHBD = ({ id }) => {
    unfilledHBD({
      replace: { id }
    }).then(response => {
      const res = response[0];
      if (res.success === 1) {
        res.data.students.sort((a, b) => {
          const aHoursBreakdownDate = moment(a.hoursBreakdownDate);
          const bHoursBreakdownDate = moment(b.hoursBreakdownDate);
          return aHoursBreakdownDate.isBefore(bHoursBreakdownDate) ? 1 : -1;
        });

        setUnfilledHBDList(res.data.students);
        setShowUnfilledHBDDates(true);
        setSelectedStudent(id);
      }
    });
  };

  const renderName = record => {
    const {
      id,
      branchId,
      name,
      numberOfOpenSessions,
      unfilledHoursBreakdownDate,
      unfilledHoursBreakdownCount
    } = record;
    const parsedDate = moment(unfilledHoursBreakdownDate).parseZone();
    const parsedNow = moment().utc();
    const manIcon =
      unfilledHoursBreakdownDate && parsedNow.isSame(parsedDate, 'day') ? redMan : greenMan;
    const showClockIcon =
      (parsedNow.isSame(parsedDate, 'day') && unfilledHoursBreakdownCount > 1) ||
      !parsedNow.isSame(parsedDate, 'day');

    return (
      <>
        <Tooltip
          title={
            unfilledHoursBreakdownDate && parsedNow.isSame(parsedDate, 'day')
              ? "This student has unfilled hours-breakdown for today's attendance"
              : ''
          }
        >
          <GridImage src={manIcon} w={16} h={21} />
        </Tooltip>

        <Name
          onClick={() => {
            const allLocations = getData('locations');

            const branchName = allLocations
              ? allLocations.find(loc => branchId === loc.branchId).label.split(' ')[0]
              : '';

            const studentBranch = allLocations
              ? allLocations.find(loc => branchId === loc.branchId).branchId
              : '';

            setData('studentTimeline', {
              ...record,
              branchId: branchName,
              studentBranch: studentBranch,
              id
            });
            setShown('studentTimeline');
          }}
        >
          {name}
        </Name>

        <StudentIcons>
          {showClockIcon && (
            <Tooltip title="This student has unfilled hours-breakdown. Click here to assign all unassigned hours">
              <ClockIcon src={clock} onClick={() => openUnfilledHBD(record)} />
            </Tooltip>
          )}
          {numberOfOpenSessions > 0 && (
            <Tooltip title="This student has open sessions. Click here to close all the open sessions">
              <AttendanceIcons src={sessionClosed} onClick={() => openUnclosedSessions(record)} />
            </Tooltip>
          )}
        </StudentIcons>
      </>
    );
  };

  const [attendanceData, setAttendanceData] = useState([]);
  const [studentPerformance, setStudentPerformance] = useState([]);
  let count = 0;
  useEffect(() => {
    if (attendanceData.length) {
      count++;
    }
    if (count === 1) {
      const [locationType, locationId, branchId] = location.split('x');

      getStudentsPerformance({
        students: attendanceData.map(e => e.id),
        branchId
      }).then(res => {
        setStudentPerformance(res.data.performance);
      });
    }
  }, [attendanceData]);

  const renderTable = data => {
    setAttendanceData(data);

    const totalCheckin = data.filter(
      item => item.attendanceStatus !== 'No attendance' && item.attendanceStatus !== 'Absent'
    ).length;
    const totalAbsent = data.filter(item => item.attendanceStatus === 'Absent').length;
    return (
      <>
        {location.includes('classroom') && <WarningLabel />}

        <Row>
          <CardBlock colSpan={33}>
            <LabelCard>Total Students</LabelCard>
            <Totals>{data.length}</Totals>
          </CardBlock>

          <CardBlock colSpan={33}>
            <LabelCard>Total Checked In</LabelCard>
            <Totals>{totalCheckin}</Totals>
          </CardBlock>

          <CardBlock colSpan={33}>
            <LabelCard>Total Absent</LabelCard>
            <Totals>{totalAbsent}</Totals>
          </CardBlock>
        </Row>

        <TableContainer>
          <THeader>
            <FirstCol>Students</FirstCol>
            <MiddleCol>Attendance</MiddleCol>
            <ThirdCol borderBottom={false}>Daily Evaluations</ThirdCol>
          </THeader>

          {data.map(item => (
            <TRow key={item.id}>
              <FirstCol>{renderName(item)}</FirstCol>

              <MiddleCol>
                <AttendanceValue
                  onClick={() => {
                    setSelectedStudent(item.id);
                    setSelectedDateSession(moment());
                    setShown('override');
                  }}
                >
                  {item.totalHoursTrained}/{item.programHours}
                </AttendanceValue>

                {item.attendanceStatus === 'Absent' && (
                  <Tooltip title={item.absent.isExcused ? 'Absent Excused' : 'Absent Unexcused'}>
                    <GridImage mLeft={15} src={absentImg} w={18} h={14} />
                  </Tooltip>
                )}

                {item.hasAttended && (
                  <Tooltip
                    title={
                      item.attendance.checkIn
                        ? 'This student is checked-in'
                        : 'This student is checked-out'
                    }
                  >
                    <GridImage
                      mLeft={15}
                      src={item.attendance.checkIn ? checked : doubleCheck}
                      h={item.attendance.checkIn ? 20 : 21}
                    />
                  </Tooltip>
                )}
              </MiddleCol>
              <ThirdCol borderBottom={true}>
                <Skeleton
                  loading={studentsPerformanceBusy || studentsProficiencyBusy}
                  paragraph={{ rows: 2 }}
                >
                  {!!studentPerformance &&
                    !!studentPerformance[item.id] &&
                    testingConfig.map(({ id, label, showForBranch }) => (
                      <ButtonContainer
                        key={id}
                        onClick={() => {
                          if (!(item?.locationType === 2 && !showForBranch)) {
                            setSelectedStudent(item.id);
                            setTestingType(id);
                            setShown('testing');
                          }
                        }}
                      >
                        <div style={{ marginBottom: '5px' }}>{label}</div>

                        <Tooltip
                          title={
                            studentPerformance[item.id][id]
                              ? studentPerformance[item.id][id].currPerformance
                              : ''
                          }
                        >
                          <StyledButton
                            key={id}
                            filled={true}
                            color={
                              studentPerformance[item.id][id]
                                ? performanceToColor[
                                    studentPerformance[item.id][id].currPerformance
                                  ]
                                : '#c1c1c1'
                            }
                            text={label}
                            height={55}
                            width={155}
                            disabled={item?.locationType === 2 && !showForBranch}
                            style={{ flexDirection: 'column' }}
                          >
                            <span>
                              {studentPerformance[item.id][id]
                                ? formatLatestResult(
                                    studentPerformance[item.id].testPercentages[id],
                                    0
                                  )
                                : ''}
                            </span>

                            <span>
                              {studentPerformance[item.id][id]
                                ? formatLatestResult(
                                    studentPerformance[item.id][id]?.lastestResult,
                                    1
                                  )
                                : ''}
                            </span>
                          </StyledButton>
                        </Tooltip>
                      </ButtonContainer>
                    ))}
                </Skeleton>
              </ThirdCol>
            </TRow>
          ))}
        </TableContainer>
      </>
    );
  };

  const renderTablePlaceholder = () => <div>Please select a location</div>;

  const buttons = () => (
    <>
      <Button
        filled={false}
        color={buttonColor.ORANGE}
        text="Bulk Attendance"
        height={30}
        width={130}
        disabled={!hasSelectedLocation}
        onClick={handleBulkAttendanceClick}
        alignSelf="end"
      />
    </>
  );

  return (
    <PageContainer>
      <Refresh />

      <Modal
        title="List of open sessions"
        visible={showOpenSessionDates}
        footer={[
          <AntButton key="close" type="ghost" onClick={() => setShowOpenSessionDates(false)}>
            Close
          </AntButton>
        ]}
      >
        <span> This student has open sessions on these dates: </span>
        <StudentContainer>
          {openSessionsList.map(({ attendanceDate }, _index) => {
            const formatedSessionDate = moment(attendanceDate).parseZone();

            return (
              <StudentRow
                key={_index}
                onClick={() => {
                  setSelectedDateSession(formatedSessionDate);
                  setShown('override');
                }}
              >
                <div>{formatedSessionDate.format('MM/DD/YYYY')}</div>
              </StudentRow>
            );
          })}
        </StudentContainer>
      </Modal>

      <Modal
        title="List of unfilled hours breakdown"
        visible={showUnfilledHBDDates}
        footer={[
          <AntButton key="close" type="ghost" onClick={() => setShowUnfilledHBDDates(false)}>
            Close
          </AntButton>
        ]}
      >
        <span> This student has unfilled hours breakdown on these dates: </span>
        <StudentContainer>
          {unfilledHBDList.map(({ hoursBreakdownDate }, _index) => {
            const formatedHBDDate = moment(hoursBreakdownDate).parseZone();

            return (
              <StudentRow
                key={_index}
                onClick={() => {
                  setShowUnfilledHBDDates(false);
                  setSelectedDateSession(formatedHBDDate);
                  setShown('override');
                  setShown('checkoutModal');
                }}
              >
                <div>{formatedHBDDate.format('MM/DD/YYYY')}</div>
              </StudentRow>
            );
          })}
        </StudentContainer>
      </Modal>

      <Modal
        title="Scan the code to sign"
        visible={unsignedUser}
        footer={[
          <AntButton key="close" type="ghost" onClick={() => setUnsignedUser(false)}>
            Close
          </AntButton>
        ]}
      >
        <CenterText>
          <QRCode value="" />
        </CenterText>
      </Modal>

      <TitleRow style={stylesTablet}>
        <CenterCol>
          <PageTitle>Welcome, Instructor {userName}</PageTitle>
        </CenterCol>

        <CenterCol>
          <TwoColumns>
            <OneColumn>
              <Select
                style={{ width: '200px' }}
                label="Filter by Sponsor"
                {...instructorReportsConfig.sponsors}
                value={sponsors}
                onChange={e => {
                  const lastSelection = e.length - 1;
                  const all = e.filter(s => s === 'all');
                  let sponsorSelected = [...e];
                  if (all.length > 0) {
                    if (e[lastSelection] === 'all') {
                      sponsorSelected = all;
                    } else {
                      sponsorSelected = e.filter(s => s !== 'all');
                    }
                  }
                  setSponsor([...sponsorSelected]);
                }}
                mode="multiple"
              />

              <Select
                options={[
                  { id: 'all', label: 'All' },
                  { id: 'monday', label: 'Monday' },
                  { id: 'tuesday', label: 'Tuesday' },
                  { id: 'wednesday', label: 'Wednesday' },
                  { id: 'thursday', label: 'Thursday' },
                  { id: 'friday', label: 'Friday' },
                  { id: 'saturday', label: 'Saturday' },
                  { id: 'sunday', label: 'Sunday' },
                  { id: 'night_class', label: 'Night class' },
                  { id: 'weekend_class', label: 'Weekend class' },
                  { id: 'dayClass', label: 'Day Class' },
                  { id: 'part_time', label: 'Part-Time Class' },
                  { id: 'half_day_am', label: 'Half-day AM' },
                  { id: 'half_day_pm', label: 'Half-day PM' }
                ]}
                mode="multiple"
                label="Filter by Day"
                value={classType}
                showArrow={false}
                style={{ width: '150px' }}
                placeholder="Select a day"
                onChange={e => {
                  const lastSelection = e.length - 1;
                  const all = e.filter(s => s === 'all' || s === 'dayClass');
                  let classSelected = [...e];

                  if (all.length > 0) {
                    if (e[lastSelection] === 'all') {
                      classSelected = all;
                    } else {
                      classSelected = e.filter(s => s !== 'all');
                    }
                  }
                  PersistentRepo.set(
                    'day',
                    classSelected.length === 0 ? ['all'] : [...classSelected]
                  );
                  setClassType(classSelected.length === 0 ? ['all'] : [...classSelected]);
                }}
              />

              <Select
                showSearch
                onChange={onLocationChange}
                label="Filter by Branch"
                {...config.location}
                value={location}
                showArrow={false}
                style={{ width: '250px' }}
              />

              <Select
                options={[
                  { id: 'all', label: 'All' },
                  { id: 'Group 1', label: 'Group 1' },
                  { id: 'Group 2', label: 'Group 2' },
                  { id: 'Group 3', label: 'Group 3' },
                  { id: 'Group 4', label: 'Group 4' },
                  { id: 'Group 5', label: 'Group 5' },
                  { id: 'Group 6', label: 'Group 6' },
                  { id: 'Group 7', label: 'Group 7' },
                  { id: 'Group 8', label: 'Group 8' },
                  { id: 'Group 9', label: 'Group 9' },
                  { id: 'Group 10', label: 'Group 10' },
                  { id: 'Group 11', label: 'Group 11' },
                  { id: 'Group 12', label: 'Group 12' }
                ]}
                label="Filter by Groups"
                showArrow={false}
                value={selectedGroup}
                onChange={group => setGroup(group)}
              />
            </OneColumn>
            <OneColumn justifyContent>
              {hasSelectedLocation ? (
                buttons()
              ) : (
                <Tooltip title="Select a yard or a classroom">{buttons()}</Tooltip>
              )}
            </OneColumn>
          </TwoColumns>
        </CenterCol>
      </TitleRow>

      {!!location ? renderAttendance() : renderTablePlaceholder()}

      <Modal width={'70%'} visible={getShown('studentIntervention')} footer={[]}>
        <StudentIntervention />
      </Modal>
    </PageContainer>
  );
};
