import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';

import { HourCounterItem, HoursCounterLabel } from './styles';
import './styles.css';

function HoursCounter(props) {
  const { disabled = false, onChange, changeGap = 0.25, label, defaultValue = 0, ...rest } = props;
  const [value, setValue] = useState(0.0);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onDecrement = () => {
    if (value - changeGap < 0) {
      return;
    }

    const decrementedValue = value % 0.25 === 0 ? value - changeGap : Math.round(value * 2) / 2;

    setValue(decrementedValue);
    handleChange(decrementedValue);
  };

  const onIncrement = () => {
    const incrementedValue = value % 0.25 === 0 ? value + changeGap : Math.round(value * 2) / 2;

    setValue(incrementedValue);
    handleChange(incrementedValue);
  };

  const handleChange = value => {
    onChange(+value);
  };

  return (
    <HourCounterItem isDisabled={disabled} className="hoursCounter">
      {label && <HoursCounterLabel>{label}:</HoursCounterLabel>}
      <Input
        disabled={disabled}
        type="number"
        style={{ width: 120 }}
        onChange={e => {
          handleChange(parseFloat(e.target.value).toFixed(2))
        }}
        onBlur={e => {
          let roundedValue = +(Math.round(e.target.value * 4) / 4).toFixed(2)
          if (roundedValue < 0) {
            roundedValue = 0
          }
          setValue(roundedValue)
          handleChange(roundedValue)
        }}
        value={value}
        prefix={
          <Button
            onClick={onDecrement}
            disabled={disabled}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: '#FF6C00',
              borderColor: '#FF6C00'
            }}
            type="primary"
            icon="minus"
          />
        }
        suffix={
          <Button
            onClick={onIncrement}
            disabled={disabled}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: '#5AB55B',
              borderColor: '#5AB55B'
            }}
            type="primary"
            icon="plus"
          />
        }
        {...rest}
      />
    </HourCounterItem>
  );
}

export default HoursCounter;
