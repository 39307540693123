import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  padding-top: 100px;
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div``;

const Details = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const Logo = styled.img`
  width: 150px;
  height: 200px;
`;

const FormContainer = styled.div`
  margintop: 80px;
`;

const FormHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const FormHeaderTitle = styled.div`
  font-weight: 600;
  color: black;
  font-size: 20px;
`;

const FormHeaderTitleDescription = styled.div`
  font-size: 20px;
`;

const FormBody = styled.div``;

const Paragraph = styled.div`
  margin-bottom: 30px;
`;

const UnderlineStudentName = styled.div`
  font-weight: 600;
  color: 'black';
`;

const FollowingText = styled.div`
  display: flex;
  font-size: 20px;
  margin-bottom: 20px;
`;

const FormFooter = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 40px;
`;

const SignatureContainer = styled.div``;

const UserNameToSign = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: black;
  margin-top: 20px;
`;

const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const WarningLabel = styled.div`
  font-size: 18px;
  color: red;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export {
  Container,
  Header,
  Details,
  Logo,
  TitleContainer,
  FormContainer,
  FormHeader,
  FormHeaderTitle,
  FormHeaderTitleDescription,
  FormBody,
  Paragraph,
  UnderlineStudentName,
  FollowingText,
  FormFooter,
  SignatureContainer,
  UserNameToSign,
  Footer,
  WarningLabel
};
