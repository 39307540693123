import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 18px 0;
`;

const Title = styled.span`
  font-size: 18px;
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
`;

const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 30px 4px 0;
`;

const BlockSection = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const InlineFlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-family: 'SF Display Light';
`;

const CardBlock = styled.div`
  padding: 10px;
  background-color: rgb(229, 95, 23);
  border-right: 1px solid #ddd;
  color: #fff;
  margin-bottom: 5px;

  ${({ borderRight, borderLeft }) => `
    ${
      borderLeft &&
      `border-top-left-radius: 7px;
       border-bottom-left-radius: 7px;
    `
    }

    ${
      borderRight &&
      `border-bottom-right-radius: 7px;
       border-top-right-radius: 7px;
    `
    }
  `}
`;

const Totals = styled.p`
  font-size: 24px;
`;

const LabelCard = styled.p`
  color: #f5f5f5;
`;

export {
  HeaderContainer,
  Title,
  StatsWrapper,
  StatColumn,
  BlockSection,
  InlineFlexRow,
  CardBlock,
  Totals,
  LabelCard
};
