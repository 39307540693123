import table from './table';
import { transform } from './transform';

const name = 'lmsUsage';
const path = 'reports/usage';

export default {
  table,
  get: {
    name,
    path,
    transform
  }
};
