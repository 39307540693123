import styled from 'styled-components';

const PageTitle = styled.p`
  font-family: 'SF Display Heavy';
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
`;

const SelectContainer = styled.div`
  display: block;
  align-items: center;
`;

const SelectLabel = styled.div`
  margin-bottom: 3px;
`;

export { PageTitle, Row, SelectContainer, SelectLabel };
