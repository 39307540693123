import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '_src/modules';
import { OfflinePage } from '_src/components';
const AppContainer = () => (
  <>
    <App />
    {navigator.onLine ? '' : <OfflinePage />}
  </>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<AppContainer />);
