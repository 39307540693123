const transformAll = ([regionRes, branchesRes]) => {
  const { branches } = branchesRes.data;
  const regions = regionRes.data.regions.reduce(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {}
  );

  return branches
    .map(
      ({
        id,
        name,
        regionId,
        city,
        zip,
        state,
        streetAddress,
        crmBranchId,
        yardBranchId,
        classroomBranchId,
        timezone
      }) => ({
        id,
        name,
        region: regions[regionId],
        regionId,
        city,
        zip,
        streetAddress,
        state,
        crmBranchId,
        classroomId: id === classroomBranchId ? -1 : classroomBranchId,
        yardId: id === yardBranchId ? -1 : yardBranchId,
        timezone
      })
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

const transformCrmBranches = crmBranchesRes => crmBranchesRes.data.crmBranches;

export { transformAll, transformCrmBranches };
