import React from 'react';
import { Form, Checkbox } from 'antd';

export default ({ onChange, label, value, touched, error, dataCy = '', name = '', ...rest }) => {
  return (
    <>
      <Form.Item>
        <Checkbox
          {...rest}
          value={value}
          onChange={e => onChange(e.target.checked)}
          data-cy={dataCy || 'field-checkbox-' + name}
          defaultChecked={value}
        >
          {label}
        </Checkbox>
      </Form.Item>
    </>
  );
};
