import React from 'react';

import { StatItemWrapper, StatTitle, StatValue } from '../styles';

const StatItem = ({ label, value }) => {
  return (
    <StatItemWrapper>
      <StatTitle>{label}:</StatTitle>
      <StatValue>{value}</StatValue>
    </StatItemWrapper>
  );
};

export default StatItem;
