import { formatDate } from '_src/utils';
import moment from 'moment';

const transformAll = res => {
  if (res.success !== 1) {
    return [];
  }

  const total = res.data.studentReport.total || 0;
  const parseToNumber = number => Number(number || 0).toFixed(2);

  const data = res.data.studentReport.responses.map((item, index) => {
    const {
      classHours,
      obsHours,
      totalHours,
      skillsHours,
      pretripsHours,
      roadHours,
      remedialHours,
      date,
      timeSessions,
      odometer,
      locationType,
      observationSkillHours,
      observationRoadHours,
      branchName
    } = item;
    return {
      id: index,
      date: moment(date).parseZone().format('MM/DD/YYYY'),
      sessionType: locationType === 1 ? 'Yard Session' : 'Class Session',
      timeSessions: timeSessions
        ? timeSessions.map(ts => {
            const [inHour, inMinute] = (ts.checkIn || '').split(':');
            const [outHour, outMinute] = (ts.checkOut || '').split(':');
            return {
              startAt: ts.checkIn ? `${inHour}:${inMinute}` : '-',
              endAt: ts.checkOut ? `${outHour}:${outMinute}` : '-'
            };
          })
        : [],
      odometer: (odometer || []).map(od => {
        return {
          start: od.odometerStart || '-',
          end: od.odometerEnd || '-',
          sticker: od.sticker
        };
      }),
      class: parseToNumber(classHours),
      road: parseToNumber(roadHours),
      skills: parseToNumber(skillsHours),
      obsSkillHours: parseToNumber(observationSkillHours),
      obsRoadHours: parseToNumber(observationRoadHours),
      preTrip: parseToNumber(pretripsHours),
      remedial: parseToNumber(remedialHours),
      totalHours: parseToNumber(totalHours),
      branchName
    };
  });

  return { data, total };
};

const transformStats = res => {
  if (res.success !== 1) {
    return {};
  }

  const {
    firstName,
    lastName,
    id,
    status,
    permitRecDate,
    graduationDate,
    dateClassBegins,
    preferredNickname
  } = res.data.student;

  return {
    id,
    fullName: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
    status: `${status} (${permitRecDate ? 'Permit' : 'No Permit'})`,
    graduationDate: graduationDate ? moment(graduationDate).parseZone().format('MM/DD/YYYY') : null,
    enrollmentDate: dateClassBegins
      ? moment(dateClassBegins).parseZone().format('MM/DD/YYYY')
      : null,
    dateClassBegins: dateClassBegins
      ? moment(dateClassBegins).parseZone().format('MM/DD/YYYY')
      : null
  };
};

export { transformAll, transformStats };
