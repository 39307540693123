import table from './table';
import fields from './fields';
import schema from './schema';
import { transformAll, transformCreate, transformUpdate, transformUpdateUrl } from './transform';

const name = 'evaluation_questions';
const path = 'evaluation_questions';

const save = {
  name,
  path
};

export default {
  title: 'Student Evaluation Questions',
  createButton: 'Create a new question',
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  create: { ...save, transform: transformCreate },
  edit: {
    ...save,
    method: 'patch',
    path: `${path}/:id`,
    transform: transformUpdate,
    transformUrl: transformUpdateUrl
  },
  table,
  showEditModal: false,
  modal: {
    title: 'Create Question',
    fields,
    schema
  }
};
