import React, { useContext } from 'react';
import { Context } from 'store';
import moment from 'moment';
import { formatDate } from '_src/utils';
const fmt = 'HH:mm';
const Location = ({ branchId, locationType }) => {
  const { getData } = useContext(Context);
  const locations = getData('locations') || [];
  const location = locations.find(loc => branchId === loc.branchId && locationType === loc.type);

  return location ? location.label : '';
};

export default [
  {
    title: 'Student',
    dataIndex: 'name',
    render: name => <div style={{ cursor: 'pointer', textDecoration: 'underline' }}>{name}</div>
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor'
  },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, { branchId, locationType }) => (
      <Location branchId={branchId} locationType={locationType} />
    )
  },
  {
    id: 'sessions',
    title: 'Sessions',
    dataIndex: 'attendances',
    render: (sessions, { absent }) => {
      if (absent) {
        const { isExcused } = absent;
        return (
          <div style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Absent {isExcused ? '' : 'un'}excused
          </div>
        );
      }

      return (
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {sessions.length === 0 && <div>No attendance</div>}
          {sessions.map(({ id, checkIn, checkOut }) => (
            <div key={id}>
              {checkIn ? moment.parseZone(checkIn).format(fmt) : ''} -{' '}
              {checkOut ? moment.parseZone(checkOut).format(fmt) : ''}
            </div>
          ))}
        </div>
      );
    }
  }
];
