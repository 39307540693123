import { transformAll } from './transform';
import table from './table';

export default {
  table,
  get: {
    name: 'studentGroups',
    path: ['attendance/daily'],
    transform: transformAll
  },
  assignGroups: {
    method: 'patch',
    path: 'students/:id'
  }
};
