import styled from 'styled-components';

const FilterRow = styled.div`
  display: flex;
  // justify-content: flex-end;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export { FilterRow, ButtonContainer };
