import styled from 'styled-components';

const HeaderModal = styled.div`
  .ant-avatar.ant-avatar-icon {
    top: 15px;
    position: absolute;
    z-index: 1;
  }
`;

const ModalTitle = styled.div`
  font-size: 24px;
  color: rgb(55, 159, 255);
  font-weight: bolder;
  display: inline-block;
  margin-left: 100px;
  vertical-align: middle;

  div {
    display: grid;
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    margin-left: 13px;
  }
`;

const Amazon = styled.img`
  height: 20px;
  margin: 10px 0 0 15px;
`;

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-gap: 0 15px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
`;

const InlineFlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-family: 'SF Display Light';
`;

const CardBlock = styled.div`
  padding: 20px 10px;
  background-color: #1990c5;
  border-right: 1px solid #ddd;
  color: #fff;
  margin-bottom: 5px;

  ${({ borderRight, borderLeft }) => `
    ${borderLeft &&
    `border-top-left-radius: 7px;
       border-bottom-left-radius: 7px;
    `}

    ${borderRight &&
    `border-bottom-right-radius: 7px;
       border-top-right-radius: 7px;
    `}
  `}
`;

const Totals = styled.p`
  font-size: 24px;
`;

const LabelCard = styled.p`
  color: #f5f5f5;
`;

const BlockSection = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export {
  HeaderModal,
  ModalTitle,
  Amazon,
  FieldsContainer,
  Row,
  InlineFlexRow,
  CardBlock,
  Totals,
  LabelCard,
  BlockSection
};
