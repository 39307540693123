import schema from './schema';
import fields from './fields';

const name = 'student_evaluations';
const path = 'student_evaluations';
const commentPath = 'testing/comments';
const save = {
  name: 'milage',
  path
};

export default {
  fields,
  schema,
  getTodayEvaluation: {
    name,
    path,
    transform: res => (res.data ? res.data.studentEvaluations : [])
  },
  createMileage: save,
  updateMileage: { ...save, method: 'patch', path: `${path}/:id` },
  updateComments: { method: 'post', path: `${commentPath}/:id` }
};
