import { transformAll } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'yards';
const path = 'yards';

const save = {
  name,
  path
};

export default {
  title: 'Yards',
  get: {
    name,
    path: [path, 'branches'],
    transform: transformAll,
    defaultValue: []
  },
  create: save,
  edit: { ...save, method: 'put', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save Yard',
    fields,
    schema
  }
};
