import schema from './schema';
import fields from './fields';

export default {
  restrict: false,
  modal: {
    title: 'Change Password',
    fields,
    schema
  },
  create: {
    path: 'profile',
    method: 'patch',
    transform: ({ newPassword, password }) => ({ password: newPassword, oldPassword: password })
  }
};
