import React, { useState, useEffect } from 'react';
import {
  BubbleTimeline,
  NoAttendanceInfo,
  SessionSection
} from '_src/modules/Students/components/Timeline/components';
import {
  Container,
  ProfileContainer,
  ReportContainer,
  BubbleTimelineContainer,
  DailyInfoContainer,
  VerticalSeparator,
  AbsenceStatus
} from '_src/modules/Students/components/Timeline/styles';

import StudentInfo from './StudentInfo';
import PerformanceCardsContainer from './PerformanceCardsContainer';
import { Profile, Name, Hours, Miles, Branch, Sponsor } from './styles';

export default ({ student }) => {
  const [selectedAttendance, selectAttendance] = useState({});
  const { sessions = [], absent } = selectedAttendance;
  const {
    name,
    totalHoursTrained,
    milesData,
    studentInfo,
    attendance: allAttendance,
    performance,
    branchName,
    programHours,
    sponsor
  } = student;
  const attendance = allAttendance.filter(({ upcoming }) => !upcoming);
  const milesDataSelected = milesData[selectedAttendance.date] || [];

  const renderTimeline = () => {
    if (attendance.length === 0) {
      return <NoAttendanceInfo />;
    }

    return <BubbleTimeline attendance={allAttendance} onBubbleSelect={selectAttendance} />;
  };

  return (
    <>
      <Container style={{ paddingTop: 0, marginBottom: 20 }}>
        <ProfileContainer>
          <Profile>
            <Name>
              {name} {sponsor === 'Self-pays' && '(SP)'}
            </Name>
            <Hours>
              {totalHoursTrained} out of {programHours} hours
            </Hours>
            <Miles>{(milesData.total || 0).toFixed(2)} miles driven</Miles>
            <Branch>{branchName} Branch</Branch>
            <Sponsor>Sponsor: {studentInfo.sponsor}</Sponsor>
          </Profile>

          <StudentInfo studentInfo={studentInfo} />
        </ProfileContainer>

        <ReportContainer>
          <BubbleTimelineContainer>
            <div style={{ paddingTop: 25 }}>{renderTimeline()}</div>
          </BubbleTimelineContainer>

          {absent && !!attendance.length && (
            <AbsenceStatus>{absent.isExcused ? 'Excused' : 'Not excused'} absence</AbsenceStatus>
          )}

          {!absent && !!attendance.length && (
            <DailyInfoContainer>
              <SessionSection sessionType="Hour" sessionData={sessions} />

              {!!milesDataSelected.length && <VerticalSeparator />}

              <SessionSection sessionType="Odometer" sessionData={milesDataSelected} />
            </DailyInfoContainer>
          )}
        </ReportContainer>
      </Container>

      <PerformanceCardsContainer performance={performance} />
    </>
  );
};
