import React, { Fragment } from 'react';

import { Container, Line, Circle, Label, LabelContainer } from './styles';

const GradientValue = ({ value, onChange, type, disabled }) => (
  <>
    <Container>
      {GLOBALS.testing.answers[type].map((option, index) => {
        const isActive = value === index;

        return (
          <Fragment key={option}>
            {index !== 0 && <Line />}

            <Circle
              value={value}
              disabled={disabled}
              onClick={() => !disabled && onChange(value === index ? undefined : index)}
              isActive={isActive}
            />
          </Fragment>
        );
      })}
    </Container>

    <LabelContainer>
      {GLOBALS.testing.answers[type].map((option, index) => (
        <Label
          key={option}
          value={value}
          disabled={disabled}
          onClick={() => !disabled && onChange(value === index ? undefined : index)}
          isActive={value === index}
        >
          {option}
        </Label>
      ))}
    </LabelContainer>
  </>
);

export default GradientValue;
