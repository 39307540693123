import React from 'react';

import offlineIcon from '_src/assets/images/offline.svg';
import { Container, ErrorMessage, Title } from './styles';

const Fallback = () => (
  <Container>
    <Title>Failed to open the LMS</Title>
    <ErrorMessage>You don't have internet connection</ErrorMessage>
    <svg width="323px" height="341px" viewBox="0 0 423 341" version="1.1">
      <g id="Ant-Design-Pro-3.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="404" transform="translate(-484.000000, -221.000000)">
          <g id="Group-4" transform="translate(464.000000, 222.000000)">
            <g
              id="Group-5"
              transform="translate(75.417348, 0.163879)"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              opacity="0.6"
              strokeLinejoin="round"
              strokeDasharray="12.15"
            >
              <path
                d="M169.575185,288.890375 C103.647066,288.890375 50.2010204,235.455806 50.2010204,169.540425 C50.2010204,103.625045 103.647066,50.1904762 169.575185,50.1904762 C235.50394,50.1904762 288.94935,103.625045 288.94935,169.540425 C288.94935,235.455806 235.50394,288.890375 169.575185,288.890375 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M169.405699,338.555288 C76.0274364,338.555288 0.329411882,262.872619 0.329411882,169.513299 C0.329411882,76.1539786 76.0274364,0.471309613 169.405699,0.471309613 C262.783961,0.471309613 338.481985,76.1539786 338.481985,169.513299 C338.481985,262.872619 262.783961,338.555288 169.405699,338.555288 Z"
                id="Stroke-3"
              ></path>
            </g>
            <polygon
              id="Fill-6"
              fill="#F5F5F5"
              transform="translate(106.914750, 199.432805) rotate(22.000000) translate(-106.914750, -199.432805) "
              points="97.7112298 198.803016 116.11827 190.231152 107.544667 208.634458"
            ></polygon>
            <polygon
              id="Stroke-7"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(106.914750, 199.432805) rotate(22.000000) translate(-106.914750, -199.432805) "
              points="97.7112298 198.803016 116.11827 190.231152 107.544667 208.634458"
            ></polygon>
            <polygon
              id="Fill-8"
              transform="translate(58.996118, 219.767028) rotate(22.000000) translate(-58.996118, -219.767028) "
              points="38.8543444 215.183828 54.4119879 199.629341 79.1378915 224.350228 63.580248 239.904716"
            ></polygon>
            <polygon
              id="Stroke-9"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(58.996118, 219.767028) rotate(22.000000) translate(-58.996118, -219.767028) "
              points="38.8543444 215.183828 54.4119879 199.629341 79.1378915 224.350228 63.580248 239.904716"
            ></polygon>
            <polygon
              id="Fill-10"
              fill="#F5F5F5"
              transform="translate(71.226096, 214.577614) rotate(22.000000) translate(-71.226096, -214.577614) "
              points="51.4642422 198.657893 55.3028278 194.820086 90.9879502 230.497335 87.1487294 234.335141"
            ></polygon>
            <polygon
              id="Stroke-11"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              fill="#F0F2F5"
              strokeLinejoin="round"
              transform="translate(71.226096, 214.577614) rotate(22.000000) translate(-71.226096, -214.577614) "
              points="51.4642422 198.657893 55.3028278 194.820086 90.9879502 230.497335 87.1487294 234.335141"
            ></polygon>
            <polygon
              id="Fill-12"
              transform="translate(84.698338, 273.903349) rotate(22.000000) translate(-84.698338, -273.903349) "
              points="56.0769883 259.690379 70.4821667 245.288122 113.319688 288.116319 98.9145099 302.518575"
            ></polygon>
            <polygon
              id="Stroke-13"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(84.698338, 273.903349) rotate(22.000000) translate(-84.698338, -273.903349) "
              points="56.0769883 259.690379 70.4821667 245.288122 113.319688 288.116319 98.9145099 302.518575"
            ></polygon>
            <polygon
              id="Fill-14"
              transform="translate(75.456149, 212.782520) rotate(22.000000) translate(-75.456149, -212.782520) "
              points="63.8911557 203.879723 66.5512281 201.22019 87.0211429 221.685318 84.3604352 224.34485"
            ></polygon>
            <polygon
              id="Stroke-15"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(75.456149, 212.782520) rotate(22.000000) translate(-75.456149, -212.782520) "
              points="63.8911557 203.879723 66.5512281 201.22019 87.0211429 221.685318 84.3604352 224.34485"
            ></polygon>
            <polygon
              id="Fill-16"
              fill="#F5F5F5"
              transform="translate(78.176142, 202.618192) rotate(22.000000) translate(-78.176142, -202.618192) "
              points="74.4026766 205.006817 80.5652518 198.845492 81.9496076 200.229567 75.7870324 206.390892"
            ></polygon>
            <polygon
              id="Stroke-17"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(78.176142, 202.618192) rotate(22.000000) translate(-78.176142, -202.618192) "
              points="74.4026766 205.006817 80.5652518 198.845492 81.9496076 200.229567 75.7870324 206.390892"
            ></polygon>
            <polygon
              id="Fill-18"
              fill="#F5F5F5"
              transform="translate(84.676979, 217.928886) rotate(22.000000) translate(-84.676979, -217.928886) "
              points="80.9035138 220.317511 87.066089 214.156186 88.4504448 215.540261 82.2878696 221.701586"
            ></polygon>
            <polygon
              id="Stroke-19"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(84.676979, 217.928886) rotate(22.000000) translate(-84.676979, -217.928886) "
              points="80.9035138 220.317511 87.066089 214.156186 88.4504448 215.540261 82.2878696 221.701586"
            ></polygon>
            <polygon
              id="Fill-20"
              transform="translate(70.238421, 242.404581) rotate(22.000000) translate(-70.238421, -242.404581) "
              points="67.8493111 238.631881 74.0118863 244.793206 72.6275305 246.177281 66.4649553 240.015956"
            ></polygon>
            <polygon
              id="Stroke-21"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(70.238421, 242.404581) rotate(22.000000) translate(-70.238421, -242.404581) "
              points="67.8493111 238.631881 74.0118863 244.793206 72.6275305 246.177281 66.4649553 240.015956"
            ></polygon>
            <polygon
              id="Fill-22"
              transform="translate(48.081755, 224.398789) rotate(22.000000) translate(-48.081755, -224.398789) "
              points="35.1127107 220.725179 36.3242602 211.432376 61.0507992 236.153899 51.756111 237.365203"
            ></polygon>
            <polygon
              id="Stroke-23"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(48.081755, 224.398789) rotate(22.000000) translate(-48.081755, -224.398789) "
              points="35.1127107 220.725179 36.3242602 211.432376 61.0507992 236.153899 51.756111 237.365203"
            ></polygon>
            <polygon
              id="Fill-24"
              transform="translate(38.612968, 228.416820) rotate(22.000000) translate(-38.612968, -228.416820) "
              points="32.9179858 217.470622 26.2496982 216.056058 50.9762371 240.777581 49.5613861 234.110647"
            ></polygon>
            <polygon
              id="Stroke-25"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(38.612968, 228.416820) rotate(22.000000) translate(-38.612968, -228.416820) "
              points="32.9179858 217.470622 26.2496982 216.056058 50.9762371 240.777581 49.5613861 234.110647"
            ></polygon>
            <polygon
              id="Fill-26"
              transform="translate(31.086342, 221.523909) rotate(22.000000) translate(-31.086342, -221.523909) "
              points="32.6469986 217.297684 26.8592604 218.37941 34.2314793 225.750133 35.3134242 219.963569"
            ></polygon>
            <polygon
              id="Stroke-27"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(31.086342, 221.523909) rotate(22.000000) translate(-31.086342, -221.523909) "
              points="32.6469986 217.297684 26.8592604 218.37941 34.2314793 225.750133 35.3134242 219.963569"
            ></polygon>
            <polygon
              id="Fill-28"
              transform="translate(38.344372, 238.617819) rotate(22.000000) translate(-38.344372, -238.617819) "
              points="39.9050284 234.391595 34.1172902 235.47332 41.4895091 242.844044 42.571454 237.05748"
            ></polygon>
            <polygon
              id="Stroke-29"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(38.344372, 238.617819) rotate(22.000000) translate(-38.344372, -238.617819) "
              points="39.9050284 234.391595 34.1172902 235.47332 41.4895091 242.844044 42.571454 237.05748"
            ></polygon>
            <polygon
              id="Fill-30"
              fill="#F0F2F5"
              transform="translate(75.352798, 203.766862) rotate(22.000000) translate(-75.352798, -203.766862) "
              points="71.4417859 201.708217 73.2937351 199.856644 79.2638092 205.824871 77.4118601 207.67708"
            ></polygon>
            <polygon
              id="Stroke-31"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(75.352798, 203.766862) rotate(22.000000) translate(-75.352798, -203.766862) "
              points="71.4417859 201.708217 73.2937351 199.856644 79.2638092 205.824871 77.4118601 207.67708"
            ></polygon>
            <polygon
              id="Fill-32"
              fill="#F0F2F5"
              transform="translate(81.871541, 219.119730) rotate(22.000000) translate(-81.871541, -219.119730) "
              points="77.9605297 217.061085 79.8124789 215.209512 85.7825531 221.177739 83.9306039 223.029948"
            ></polygon>
            <polygon
              id="Stroke-33"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(81.871541, 219.119730) rotate(22.000000) translate(-81.871541, -219.119730) "
              points="77.9605297 217.061085 79.8124789 215.209512 85.7825531 221.177739 83.9306039 223.029948"
            ></polygon>
            <polygon
              id="Fill-34"
              transform="translate(68.034935, 237.215417) rotate(22.000000) translate(-68.034935, -237.215417) "
              points="65.1594898 237.106164 67.9256601 234.340555 70.9103796 237.324669 68.1442092 240.090278"
            ></polygon>
            <polygon
              id="Stroke-35"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              fill="#F5F5F5"
              strokeLinejoin="round"
              transform="translate(68.034935, 237.215417) rotate(22.000000) translate(-68.034935, -237.215417) "
              points="65.1594898 237.106164 67.9256601 234.340555 70.9103796 237.324669 68.1442092 240.090278"
            ></polygon>
            <path
              d="M90.2556147,213.065319 C79.2271463,202.039088 74.6433339,188.153875 78.4889079,179.512775 C79.1248602,178.084237 80.8319571,175.429786 84.5339495,179.131027 C88.2353065,182.832268 120.560237,215.150007 123.931865,218.52095 C127.303492,221.891894 125.245065,224.192545 123.817508,224.827733 C115.174655,228.675068 101.284718,224.092186 90.2556147,213.065319 Z"
              id="Fill-36"
              fill="#F5F5F5"
              transform="translate(101.453924, 201.708917) rotate(22.000000) translate(-101.453924, -201.708917) "
            ></path>
            <path
              d="M90.2556147,213.065319 C79.2271463,202.039088 74.6433339,188.153875 78.4889079,179.512775 C79.1248602,178.084237 80.8319571,175.429786 84.5339495,179.131027 C88.2353065,182.832268 120.560237,215.150007 123.931865,218.52095 C127.303492,221.891894 125.245065,224.192545 123.817508,224.827733 C115.174655,228.675068 101.284718,224.092186 90.2556147,213.065319 Z"
              id="Stroke-38"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              fill="#F0F2F5"
              strokeLinejoin="round"
              transform="translate(101.453924, 201.708917) rotate(22.000000) translate(-101.453924, -201.708917) "
            ></path>
            <polygon
              id="Fill-40"
              fill="#F0F2F5"
              transform="translate(86.633853, 208.038961) rotate(22.000000) translate(-86.633853, -208.038961) "
              points="77.9716233 201.230061 79.8235724 199.378488 95.2960835 214.84786 93.4441343 216.699433"
            ></polygon>
            <polygon
              id="Stroke-42"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(86.633853, 208.038961) rotate(22.000000) translate(-86.633853, -208.038961) "
              points="77.9716233 201.230061 79.8235724 199.378488 95.2960835 214.84786 93.4441343 216.699433"
            ></polygon>
            <path
              d="M121.635514,196.068815 C120.517356,197.186746 118.704797,197.186746 117.586639,196.068815 C116.469116,194.950884 116.469116,193.138692 117.586639,192.020761 C118.704797,190.903465 120.517356,190.903465 121.635514,192.020761 C122.753672,193.138692 122.753672,194.950884 121.635514,196.068815"
              id="Fill-44"
              fill="#A3B1BF"
              transform="translate(119.611315, 194.045026) rotate(22.000000) translate(-119.611315, -194.045026) "
            ></path>
            <polygon
              id="Fill-46"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(81.250567, 248.056422) rotate(22.000000) translate(-81.250567, -248.056422) "
              points="81.2505672 249.672341 79.6343206 248.056422 81.2505672 246.440503 82.8668137 248.056422"
            ></polygon>
            <polygon
              id="Stroke-48"
              transform="translate(81.250567, 248.056422) rotate(22.000000) translate(-81.250567, -248.056422) "
              points="81.2505672 249.672341 79.6343206 248.056422 81.2505672 246.440503 82.8668137 248.056422"
            ></polygon>
            <polygon
              id="Fill-50"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(82.702196, 251.475282) rotate(22.000000) translate(-82.702196, -251.475282) "
              points="82.7021965 253.091201 81.0859499 251.475282 82.7021965 249.859363 84.318443 251.475282"
            ></polygon>
            <polygon
              id="Stroke-52"
              transform="translate(82.702196, 251.475282) rotate(22.000000) translate(-82.702196, -251.475282) "
              points="82.7021965 253.091201 81.0859499 251.475282 82.7021965 249.859363 84.318443 251.475282"
            ></polygon>
            <polygon
              id="Fill-54"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(84.153791, 254.894059) rotate(22.000000) translate(-84.153791, -254.894059) "
              points="84.1537906 256.509978 82.5375441 254.894059 84.1537906 253.278141 85.7700372 254.894059"
            ></polygon>
            <polygon
              id="Stroke-56"
              transform="translate(84.153791, 254.894059) rotate(22.000000) translate(-84.153791, -254.894059) "
              points="84.1537906 256.509978 82.5375441 254.894059 84.1537906 253.278141 85.7700372 254.894059"
            ></polygon>
            <polygon
              id="Fill-58"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(85.605385, 258.312837) rotate(22.000000) translate(-85.605385, -258.312837) "
              points="85.6053848 259.928756 83.9891383 258.312837 85.6053848 256.696918 87.2216313 258.312837"
            ></polygon>
            <polygon
              id="Stroke-60"
              transform="translate(85.605385, 258.312837) rotate(22.000000) translate(-85.605385, -258.312837) "
              points="85.6053848 259.928756 83.9891383 258.312837 85.6053848 256.696918 87.2216313 258.312837"
            ></polygon>
            <polygon
              id="Fill-62"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(78.094482, 249.395723) rotate(22.000000) translate(-78.094482, -249.395723) "
              points="78.0944819 251.011642 76.4782353 249.395723 78.0944819 247.779804 79.7107284 249.395723"
            ></polygon>
            <polygon
              id="Stroke-64"
              transform="translate(78.094482, 249.395723) rotate(22.000000) translate(-78.094482, -249.395723) "
              points="78.0944819 251.011642 76.4782353 249.395723 78.0944819 247.779804 79.7107284 249.395723"
            ></polygon>
            <polygon
              id="Fill-66"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(79.546052, 252.814559) rotate(22.000000) translate(-79.546052, -252.814559) "
              points="79.5460522 254.430478 77.9298057 252.814559 79.5460522 251.198641 81.1622988 252.814559"
            ></polygon>
            <polygon
              id="Stroke-68"
              transform="translate(79.546052, 252.814559) rotate(22.000000) translate(-79.546052, -252.814559) "
              points="79.5460522 254.430478 77.9298057 252.814559 79.5460522 251.198641 81.1622988 252.814559"
            ></polygon>
            <polygon
              id="Fill-70"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(80.997705, 256.233360) rotate(22.000000) translate(-80.997705, -256.233360) "
              points="80.9977053 257.849279 79.3814588 256.23336 80.9977053 254.617442 82.6139518 256.23336"
            ></polygon>
            <polygon
              id="Stroke-72"
              transform="translate(80.997705, 256.233360) rotate(22.000000) translate(-80.997705, -256.233360) "
              points="79.3814588 256.23336 80.9977053 254.617442 82.6139518 256.23336 80.9977053 257.849279"
            ></polygon>
            <polygon
              id="Fill-74"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(82.449276, 259.652197) rotate(22.000000) translate(-82.449276, -259.652197) "
              points="82.4492757 261.268115 80.8330292 259.652197 82.4492757 258.036278 84.0655222 259.652197"
            ></polygon>
            <polygon
              id="Stroke-76"
              transform="translate(82.449276, 259.652197) rotate(22.000000) translate(-82.449276, -259.652197) "
              points="82.4492757 261.268115 80.8330292 259.652197 82.4492757 258.036278 84.0655222 259.652197"
            ></polygon>
            <polygon
              id="Fill-78"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(74.938373, 250.735083) rotate(22.000000) translate(-74.938373, -250.735083) "
              points="74.9383727 252.351002 73.3221262 250.735083 74.9383727 249.119164 76.5546193 250.735083"
            ></polygon>
            <polygon
              id="Stroke-80"
              transform="translate(74.938373, 250.735083) rotate(22.000000) translate(-74.938373, -250.735083) "
              points="74.9383727 252.351002 73.3221262 250.735083 74.9383727 249.119164 76.5546193 250.735083"
            ></polygon>
            <polygon
              id="Fill-82"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(76.389967, 254.153860) rotate(22.000000) translate(-76.389967, -254.153860) "
              points="76.3899669 255.769779 74.7737204 254.15386 76.3899669 252.537942 78.0062134 254.15386"
            ></polygon>
            <polygon
              id="Stroke-84"
              transform="translate(76.389967, 254.153860) rotate(22.000000) translate(-76.389967, -254.153860) "
              points="76.3899669 255.769779 74.7737204 254.15386 76.3899669 252.537942 78.0062134 254.15386"
            ></polygon>
            <polygon
              id="Fill-86"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(77.841620, 257.572661) rotate(22.000000) translate(-77.841620, -257.572661) "
              points="77.84162 259.18858 76.2253735 257.572661 77.84162 255.956743 79.4578665 257.572661"
            ></polygon>
            <polygon
              id="Stroke-88"
              transform="translate(77.841620, 257.572661) rotate(22.000000) translate(-77.841620, -257.572661) "
              points="77.84162 259.18858 76.2253735 257.572661 77.84162 255.956743 79.4578665 257.572661"
            ></polygon>
            <polygon
              id="Fill-90"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(79.293190, 260.991498) rotate(22.000000) translate(-79.293190, -260.991498) "
              points="79.2931904 262.607416 77.6769438 260.991498 79.2931904 259.375579 80.9094369 260.991498"
            ></polygon>
            <polygon
              id="Stroke-92"
              transform="translate(79.293190, 260.991498) rotate(22.000000) translate(-79.293190, -260.991498) "
              points="79.2931904 262.607416 77.6769438 260.991498 79.2931904 259.375579 80.9094369 260.991498"
            ></polygon>
            <polygon
              id="Fill-94"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(71.782287, 252.074384) rotate(22.000000) translate(-71.782287, -252.074384) "
              points="71.7822874 253.690302 70.1660409 252.074384 71.7822874 250.458465 73.3985339 252.074384"
            ></polygon>
            <polygon
              id="Stroke-96"
              transform="translate(71.782287, 252.074384) rotate(22.000000) translate(-71.782287, -252.074384) "
              points="71.7822874 253.690302 70.1660409 252.074384 71.7822874 250.458465 73.3985339 252.074384"
            ></polygon>
            <polygon
              id="Fill-98"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(73.233882, 255.493161) rotate(22.000000) translate(-73.233882, -255.493161) "
              points="73.2338816 257.10908 71.6176351 255.493161 73.2338816 253.877242 74.8501281 255.493161"
            ></polygon>
            <polygon
              id="Stroke-100"
              transform="translate(73.233882, 255.493161) rotate(22.000000) translate(-73.233882, -255.493161) "
              points="73.2338816 257.10908 71.6176351 255.493161 73.2338816 253.877242 74.8501281 255.493161"
            ></polygon>
            <polygon
              id="Fill-102"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(74.685535, 258.911962) rotate(22.000000) translate(-74.685535, -258.911962) "
              points="74.6855347 260.527881 73.0692881 258.911962 74.6855347 257.296044 76.3017812 258.911962"
            ></polygon>
            <polygon
              id="Stroke-104"
              transform="translate(74.685535, 258.911962) rotate(22.000000) translate(-74.685535, -258.911962) "
              points="74.6855347 260.527881 73.0692881 258.911962 74.6855347 257.296044 76.3017812 258.911962"
            ></polygon>
            <polygon
              id="Fill-106"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(76.137105, 262.330799) rotate(22.000000) translate(-76.137105, -262.330799) "
              points="76.137105 263.946717 74.5208585 262.330799 76.137105 260.71488 77.7533516 262.330799"
            ></polygon>
            <polygon
              id="Stroke-108"
              transform="translate(76.137105, 262.330799) rotate(22.000000) translate(-76.137105, -262.330799) "
              points="76.137105 263.946717 74.5208585 262.330799 76.137105 260.71488 77.7533516 262.330799"
            ></polygon>
            <polygon
              id="Fill-110"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(68.626202, 253.413685) rotate(22.000000) translate(-68.626202, -253.413685) "
              points="68.6262021 255.029603 67.0099556 253.413685 68.6262021 251.797766 70.2424486 253.413685"
            ></polygon>
            <polygon
              id="Stroke-112"
              transform="translate(68.626202, 253.413685) rotate(22.000000) translate(-68.626202, -253.413685) "
              points="68.6262021 255.029603 67.0099556 253.413685 68.6262021 251.797766 70.2424486 253.413685"
            ></polygon>
            <polygon
              id="Fill-114"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(70.077796, 256.832462) rotate(22.000000) translate(-70.077796, -256.832462) "
              points="70.0777963 258.448381 68.4615497 256.832462 70.0777963 255.216543 71.6940428 256.832462"
            ></polygon>
            <polygon
              id="Stroke-116"
              transform="translate(70.077796, 256.832462) rotate(22.000000) translate(-70.077796, -256.832462) "
              points="70.0777963 258.448381 68.4615497 256.832462 70.0777963 255.216543 71.6940428 256.832462"
            ></polygon>
            <polygon
              id="Fill-118"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(71.529426, 260.251322) rotate(22.000000) translate(-71.529426, -260.251322) "
              points="71.5294256 261.867241 69.913179 260.251322 71.5294256 258.635403 73.1456721 260.251322"
            ></polygon>
            <polygon
              id="Stroke-120"
              transform="translate(71.529426, 260.251322) rotate(22.000000) translate(-71.529426, -260.251322) "
              points="71.5294256 261.867241 69.913179 260.251322 71.5294256 258.635403 73.1456721 260.251322"
            ></polygon>
            <polygon
              id="Fill-122"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(72.981020, 263.670099) rotate(22.000000) translate(-72.981020, -263.670099) "
              points="72.9810197 265.286018 71.3647732 263.670099 72.9810197 262.054181 74.5972663 263.670099"
            ></polygon>
            <polygon
              id="Stroke-124"
              transform="translate(72.981020, 263.670099) rotate(22.000000) translate(-72.981020, -263.670099) "
              points="72.9810197 265.286018 71.3647732 263.670099 72.9810197 262.054181 74.5972663 263.670099"
            ></polygon>
            <polygon
              id="Fill-126"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(88.852907, 265.961362) rotate(22.000000) translate(-88.852907, -265.961362) "
              points="88.8529067 267.577281 87.2366602 265.961362 88.8529067 264.345443 90.4691533 265.961362"
            ></polygon>
            <polygon
              id="Stroke-128"
              transform="translate(88.852907, 265.961362) rotate(22.000000) translate(-88.852907, -265.961362) "
              points="88.8529067 267.577281 87.2366602 265.961362 88.8529067 264.345443 90.4691533 265.961362"
            ></polygon>
            <polygon
              id="Fill-130"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(90.304501, 269.380139) rotate(22.000000) translate(-90.304501, -269.380139) "
              points="90.3045009 270.996058 88.6882544 269.380139 90.3045009 267.764221 91.9207474 269.380139"
            ></polygon>
            <polygon
              id="Stroke-132"
              transform="translate(90.304501, 269.380139) rotate(22.000000) translate(-90.304501, -269.380139) "
              points="90.3045009 270.996058 88.6882544 269.380139 90.3045009 267.764221 91.9207474 269.380139"
            ></polygon>
            <polygon
              id="Fill-134"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(91.756130, 272.798999) rotate(22.000000) translate(-91.756130, -272.798999) "
              points="91.7561302 274.414918 90.1398837 272.798999 91.7561302 271.183081 93.3723767 272.798999"
            ></polygon>
            <polygon
              id="Stroke-136"
              transform="translate(91.756130, 272.798999) rotate(22.000000) translate(-91.756130, -272.798999) "
              points="91.7561302 274.414918 90.1398837 272.798999 91.7561302 271.183081 93.3723767 272.798999"
            ></polygon>
            <polygon
              id="Fill-138"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(93.207724, 276.217777) rotate(22.000000) translate(-93.207724, -276.217777) "
              points="93.2077244 277.833695 91.5914778 276.217777 93.2077244 274.601858 94.8239709 276.217777"
            ></polygon>
            <polygon
              id="Stroke-140"
              transform="translate(93.207724, 276.217777) rotate(22.000000) translate(-93.207724, -276.217777) "
              points="93.2077244 277.833695 91.5914778 276.217777 93.2077244 274.601858 94.8239709 276.217777"
            ></polygon>
            <polygon
              id="Fill-142"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(85.696821, 267.300663) rotate(22.000000) translate(-85.696821, -267.300663) "
              points="85.6968214 268.916582 84.0805749 267.300663 85.6968214 265.684744 87.313068 267.300663"
            ></polygon>
            <polygon
              id="Stroke-144"
              transform="translate(85.696821, 267.300663) rotate(22.000000) translate(-85.696821, -267.300663) "
              points="85.6968214 268.916582 84.0805749 267.300663 85.6968214 265.684744 87.313068 267.300663"
            ></polygon>
            <polygon
              id="Fill-146"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(87.148416, 270.719440) rotate(22.000000) translate(-87.148416, -270.719440) "
              points="87.1484156 272.335359 85.5321691 270.71944 87.1484156 269.103521 88.7646621 270.71944"
            ></polygon>
            <polygon
              id="Stroke-148"
              transform="translate(87.148416, 270.719440) rotate(22.000000) translate(-87.148416, -270.719440) "
              points="87.1484156 272.335359 85.5321691 270.71944 87.1484156 269.103521 88.7646621 270.71944"
            ></polygon>
            <polygon
              id="Fill-150"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(88.599986, 274.138276) rotate(22.000000) translate(-88.599986, -274.138276) "
              points="88.599986 275.754195 86.9837394 274.138276 88.599986 272.522358 90.2162325 274.138276"
            ></polygon>
            <polygon
              id="Stroke-152"
              transform="translate(88.599986, 274.138276) rotate(22.000000) translate(-88.599986, -274.138276) "
              points="88.599986 275.754195 86.9837394 274.138276 88.599986 272.522358 90.2162325 274.138276"
            ></polygon>
            <polygon
              id="Fill-154"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(90.051639, 277.557078) rotate(22.000000) translate(-90.051639, -277.557078) "
              points="90.0516391 279.172996 88.4353925 277.557078 90.0516391 275.941159 91.6678856 277.557078"
            ></polygon>
            <polygon
              id="Stroke-156"
              transform="translate(90.051639, 277.557078) rotate(22.000000) translate(-90.051639, -277.557078) "
              points="90.0516391 279.172996 88.4353925 277.557078 90.0516391 275.941159 91.6678856 277.557078"
            ></polygon>
            <polygon
              id="Fill-158"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(82.540736, 268.639964) rotate(22.000000) translate(-82.540736, -268.639964) "
              points="82.5407361 270.255882 80.9244896 268.639964 82.5407361 267.024045 84.1569826 268.639964"
            ></polygon>
            <polygon
              id="Stroke-160"
              transform="translate(82.540736, 268.639964) rotate(22.000000) translate(-82.540736, -268.639964) "
              points="82.5407361 270.255882 80.9244896 268.639964 82.5407361 267.024045 84.1569826 268.639964"
            ></polygon>
            <polygon
              id="Fill-162"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(83.992306, 272.058800) rotate(22.000000) translate(-83.992306, -272.058800) "
              points="83.9923065 273.674719 82.3760599 272.0588 83.9923065 270.442881 85.608553 272.0588"
            ></polygon>
            <polygon
              id="Stroke-164"
              transform="translate(83.992306, 272.058800) rotate(22.000000) translate(-83.992306, -272.058800) "
              points="83.9923065 273.674719 82.3760599 272.0588 83.9923065 270.442881 85.608553 272.0588"
            ></polygon>
            <polygon
              id="Fill-166"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(85.443901, 275.477577) rotate(22.000000) translate(-85.443901, -275.477577) "
              points="85.4439007 277.093496 83.8276541 275.477577 85.4439007 273.861659 87.0601472 275.477577"
            ></polygon>
            <polygon
              id="Stroke-168"
              transform="translate(85.443901, 275.477577) rotate(22.000000) translate(-85.443901, -275.477577) "
              points="85.4439007 277.093496 83.8276541 275.477577 85.4439007 273.861659 87.0601472 275.477577"
            ></polygon>
            <polygon
              id="Fill-170"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(86.895554, 278.896378) rotate(22.000000) translate(-86.895554, -278.896378) "
              points="86.8955537 280.512297 85.2793072 278.896378 86.8955537 277.28046 88.5118003 278.896378"
            ></polygon>
            <polygon
              id="Stroke-172"
              transform="translate(86.895554, 278.896378) rotate(22.000000) translate(-86.895554, -278.896378) "
              points="86.8955537 280.512297 85.2793072 278.896378 86.8955537 277.28046 88.5118003 278.896378"
            ></polygon>
            <polygon
              id="Fill-174"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(79.384651, 269.979265) rotate(22.000000) translate(-79.384651, -269.979265) "
              points="79.3846508 271.595183 77.7684043 269.979265 79.3846508 268.363346 81.0008973 269.979265"
            ></polygon>
            <polygon
              id="Stroke-176"
              transform="translate(79.384651, 269.979265) rotate(22.000000) translate(-79.384651, -269.979265) "
              points="79.3846508 271.595183 77.7684043 269.979265 79.3846508 268.363346 81.0008973 269.979265"
            ></polygon>
            <polygon
              id="Fill-178"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(80.836221, 273.398101) rotate(22.000000) translate(-80.836221, -273.398101) "
              points="80.8362212 275.01402 79.2199746 273.398101 80.8362212 271.782182 82.4524677 273.398101"
            ></polygon>
            <polygon
              id="Stroke-180"
              transform="translate(80.836221, 273.398101) rotate(22.000000) translate(-80.836221, -273.398101) "
              points="80.8362212 275.01402 79.2199746 273.398101 80.8362212 271.782182 82.4524677 273.398101"
            ></polygon>
            <polygon
              id="Fill-182"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(82.287815, 276.816878) rotate(22.000000) translate(-82.287815, -276.816878) "
              points="82.2878153 278.432797 80.6715688 276.816878 82.2878153 275.20096 83.9040619 276.816878"
            ></polygon>
            <polygon
              id="Stroke-184"
              transform="translate(82.287815, 276.816878) rotate(22.000000) translate(-82.287815, -276.816878) "
              points="82.2878153 278.432797 80.6715688 276.816878 82.2878153 275.20096 83.9040619 276.816878"
            ></polygon>
            <polygon
              id="Fill-186"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(83.739445, 280.235738) rotate(22.000000) translate(-83.739445, -280.235738) "
              points="83.7394446 281.851657 82.1231981 280.235738 83.7394446 278.61982 85.3556912 280.235738"
            ></polygon>
            <polygon
              id="Stroke-188"
              transform="translate(83.739445, 280.235738) rotate(22.000000) translate(-83.739445, -280.235738) "
              points="83.7394446 281.851657 82.1231981 280.235738 83.7394446 278.61982 85.3556912 280.235738"
            ></polygon>
            <polygon
              id="Fill-190"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(76.228483, 271.318601) rotate(22.000000) translate(-76.228483, -271.318601) "
              points="76.2284828 272.934519 74.6122362 271.318601 76.2284828 269.702682 77.8447293 271.318601"
            ></polygon>
            <polygon
              id="Stroke-192"
              transform="translate(76.228483, 271.318601) rotate(22.000000) translate(-76.228483, -271.318601) "
              points="76.2284828 272.934519 74.6122362 271.318601 76.2284828 269.702682 77.8447293 271.318601"
            ></polygon>
            <polygon
              id="Fill-194"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(77.680136, 274.737402) rotate(22.000000) translate(-77.680136, -274.737402) "
              points="77.6801358 276.353321 76.0638893 274.737402 77.6801358 273.121483 79.2963824 274.737402"
            ></polygon>
            <polygon
              id="Stroke-196"
              transform="translate(77.680136, 274.737402) rotate(22.000000) translate(-77.680136, -274.737402) "
              points="77.6801358 276.353321 76.0638893 274.737402 77.6801358 273.121483 79.2963824 274.737402"
            ></polygon>
            <polygon
              id="Fill-198"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(79.131730, 278.156179) rotate(22.000000) translate(-79.131730, -278.156179) "
              points="79.13173 279.772098 77.5154835 278.156179 79.13173 276.540261 80.7479765 278.156179"
            ></polygon>
            <polygon
              id="Stroke-200"
              transform="translate(79.131730, 278.156179) rotate(22.000000) translate(-79.131730, -278.156179) "
              points="79.13173 279.772098 77.5154835 278.156179 79.13173 276.540261 80.7479765 278.156179"
            ></polygon>
            <polygon
              id="Fill-202"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(80.583359, 281.575039) rotate(22.000000) translate(-80.583359, -281.575039) "
              points="80.5833593 283.190958 78.9671128 281.575039 80.5833593 279.959121 82.1996058 281.575039"
            ></polygon>
            <polygon
              id="Stroke-204"
              transform="translate(80.583359, 281.575039) rotate(22.000000) translate(-80.583359, -281.575039) "
              points="80.5833593 283.190958 78.9671128 281.575039 80.5833593 279.959121 82.1996058 281.575039"
            ></polygon>
            <polygon
              id="Fill-206"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(96.455246, 283.866302) rotate(22.000000) translate(-96.455246, -283.866302) "
              points="96.4552463 285.48222 94.8389998 283.866302 96.4552463 282.250383 98.0714929 283.866302"
            ></polygon>
            <polygon
              id="Stroke-208"
              transform="translate(96.455246, 283.866302) rotate(22.000000) translate(-96.455246, -283.866302) "
              points="96.4552463 285.48222 94.8389998 283.866302 96.4552463 282.250383 98.0714929 283.866302"
            ></polygon>
            <polygon
              id="Fill-210"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(97.906840, 287.285079) rotate(22.000000) translate(-97.906840, -287.285079) "
              points="97.9068405 288.900998 96.290594 287.285079 97.9068405 285.66916 99.523087 287.285079"
            ></polygon>
            <polygon
              id="Stroke-212"
              transform="translate(97.906840, 287.285079) rotate(22.000000) translate(-97.906840, -287.285079) "
              points="97.9068405 288.900998 96.290594 287.285079 97.9068405 285.66916 99.523087 287.285079"
            ></polygon>
            <polygon
              id="Fill-214"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(99.358435, 290.703856) rotate(22.000000) translate(-99.358435, -290.703856) "
              points="99.3584347 292.319775 97.7421881 290.703856 99.3584347 289.087938 100.974681 290.703856"
            ></polygon>
            <polygon
              id="Stroke-216"
              transform="translate(99.358435, 290.703856) rotate(22.000000) translate(-99.358435, -290.703856) "
              points="99.3584347 292.319775 97.7421881 290.703856 99.3584347 289.087938 100.974681 290.703856"
            ></polygon>
            <polygon
              id="Fill-218"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(100.810064, 294.122716) rotate(22.000000) translate(-100.810064, -294.122716) "
              points="100.810064 295.738635 99.1938174 294.122716 100.810064 292.506798 102.42631 294.122716"
            ></polygon>
            <polygon
              id="Stroke-220"
              transform="translate(100.810064, 294.122716) rotate(22.000000) translate(-100.810064, -294.122716) "
              points="100.810064 295.738635 99.1938174 294.122716 100.810064 292.506798 102.42631 294.122716"
            ></polygon>
            <polygon
              id="Fill-222"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(93.299102, 285.205579) rotate(22.000000) translate(-93.299102, -285.205579) "
              points="93.2991021 286.821497 91.6828556 285.205579 93.2991021 283.58966 94.9153486 285.205579"
            ></polygon>
            <polygon
              id="Stroke-224"
              transform="translate(93.299102, 285.205579) rotate(22.000000) translate(-93.299102, -285.205579) "
              points="93.2991021 286.821497 91.6828556 285.205579 93.2991021 283.58966 94.9153486 285.205579"
            ></polygon>
            <polygon
              id="Fill-226"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(94.750755, 288.624380) rotate(22.000000) translate(-94.750755, -288.624380) "
              points="94.7507552 290.240299 93.1345086 288.62438 94.7507552 287.008461 96.3670017 288.62438"
            ></polygon>
            <polygon
              id="Stroke-228"
              transform="translate(94.750755, 288.624380) rotate(22.000000) translate(-94.750755, -288.624380) "
              points="94.7507552 290.240299 93.1345086 288.62438 94.7507552 287.008461 96.3670017 288.62438"
            ></polygon>
            <polygon
              id="Fill-230"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(96.202326, 292.043216) rotate(22.000000) translate(-96.202326, -292.043216) "
              points="96.2023255 293.659135 94.586079 292.043216 96.2023255 290.427297 97.8185721 292.043216"
            ></polygon>
            <polygon
              id="Stroke-232"
              transform="translate(96.202326, 292.043216) rotate(22.000000) translate(-96.202326, -292.043216) "
              points="96.2023255 293.659135 94.586079 292.043216 96.2023255 290.427297 97.8185721 292.043216"
            ></polygon>
            <polygon
              id="Fill-234"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(97.653979, 295.462017) rotate(22.000000) translate(-97.653979, -295.462017) "
              points="97.6539786 297.077936 96.0377321 295.462017 97.6539786 293.846099 99.2702252 295.462017"
            ></polygon>
            <polygon
              id="Stroke-236"
              transform="translate(97.653979, 295.462017) rotate(22.000000) translate(-97.653979, -295.462017) "
              points="97.6539786 297.077936 96.0377321 295.462017 97.6539786 293.846099 99.2702252 295.462017"
            ></polygon>
            <polygon
              id="Fill-238"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(90.143017, 286.544880) rotate(22.000000) translate(-90.143017, -286.544880) "
              points="90.1430168 288.160798 88.5267702 286.54488 90.1430168 284.928961 91.7592633 286.54488"
            ></polygon>
            <polygon
              id="Stroke-240"
              transform="translate(90.143017, 286.544880) rotate(22.000000) translate(-90.143017, -286.544880) "
              points="90.1430168 288.160798 88.5267702 286.54488 90.1430168 284.928961 91.7592633 286.54488"
            ></polygon>
            <polygon
              id="Fill-242"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(91.594670, 289.963681) rotate(22.000000) translate(-91.594670, -289.963681) "
              points="91.5946698 291.5796 89.9784233 289.963681 91.5946698 288.347762 93.2109164 289.963681"
            ></polygon>
            <polygon
              id="Stroke-244"
              transform="translate(91.594670, 289.963681) rotate(22.000000) translate(-91.594670, -289.963681) "
              points="91.5946698 291.5796 89.9784233 289.963681 91.5946698 288.347762 93.2109164 289.963681"
            ></polygon>
            <polygon
              id="Fill-246"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(93.046240, 293.382517) rotate(22.000000) translate(-93.046240, -293.382517) "
              points="93.0462402 294.998436 91.4299937 293.382517 93.0462402 291.766598 94.6624868 293.382517"
            ></polygon>
            <polygon
              id="Stroke-248"
              transform="translate(93.046240, 293.382517) rotate(22.000000) translate(-93.046240, -293.382517) "
              points="93.0462402 294.998436 91.4299937 293.382517 93.0462402 291.766598 94.6624868 293.382517"
            ></polygon>
            <polygon
              id="Fill-250"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(94.497893, 296.801318) rotate(22.000000) translate(-94.497893, -296.801318) "
              points="94.4978933 298.417237 92.8816468 296.801318 94.4978933 295.1854 96.1141398 296.801318"
            ></polygon>
            <polygon
              id="Stroke-252"
              transform="translate(94.497893, 296.801318) rotate(22.000000) translate(-94.497893, -296.801318) "
              points="94.4978933 298.417237 92.8816468 296.801318 94.4978933 295.1854 96.1141398 296.801318"
            ></polygon>
            <polygon
              id="Fill-254"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(86.986931, 287.884181) rotate(22.000000) translate(-86.986931, -287.884181) "
              points="86.9869315 289.500099 85.3706849 287.884181 86.9869315 286.268262 88.603178 287.884181"
            ></polygon>
            <polygon
              id="Stroke-256"
              transform="translate(86.986931, 287.884181) rotate(22.000000) translate(-86.986931, -287.884181) "
              points="86.9869315 289.500099 85.3706849 287.884181 86.9869315 286.268262 88.603178 287.884181"
            ></polygon>
            <polygon
              id="Fill-258"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(88.438585, 291.302982) rotate(22.000000) translate(-88.438585, -291.302982) "
              points="88.4385845 292.9189 86.822338 291.302982 88.4385845 289.687063 90.0548311 291.302982"
            ></polygon>
            <polygon
              id="Stroke-260"
              transform="translate(88.438585, 291.302982) rotate(22.000000) translate(-88.438585, -291.302982) "
              points="88.4385845 292.9189 86.822338 291.302982 88.4385845 289.687063 90.0548311 291.302982"
            ></polygon>
            <polygon
              id="Fill-262"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(89.890155, 294.721818) rotate(22.000000) translate(-89.890155, -294.721818) "
              points="89.8901549 296.337737 88.2739084 294.721818 89.8901549 293.105899 91.5064014 294.721818"
            ></polygon>
            <polygon
              id="Stroke-264"
              transform="translate(89.890155, 294.721818) rotate(22.000000) translate(-89.890155, -294.721818) "
              points="89.8901549 296.337737 88.2739084 294.721818 89.8901549 293.105899 91.5064014 294.721818"
            ></polygon>
            <polygon
              id="Fill-266"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(91.341749, 298.140595) rotate(22.000000) translate(-91.341749, -298.140595) "
              points="91.3417491 299.756514 89.7255025 298.140595 91.3417491 296.524677 92.9579956 298.140595"
            ></polygon>
            <polygon
              id="Stroke-268"
              transform="translate(91.341749, 298.140595) rotate(22.000000) translate(-91.341749, -298.140595) "
              points="91.3417491 299.756514 89.7255025 298.140595 91.3417491 296.524677 92.9579956 298.140595"
            ></polygon>
            <polygon
              id="Fill-270"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(83.830846, 289.223482) rotate(22.000000) translate(-83.830846, -289.223482) "
              points="83.8308461 290.8394 82.2145996 289.223482 83.8308461 287.607563 85.4470927 289.223482"
            ></polygon>
            <polygon
              id="Stroke-272"
              transform="translate(83.830846, 289.223482) rotate(22.000000) translate(-83.830846, -289.223482) "
              points="82.2145996 289.223482 83.8308461 287.607563 85.4470927 289.223482 83.8308461 290.8394"
            ></polygon>
            <polygon
              id="Fill-274"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(85.282417, 292.642318) rotate(22.000000) translate(-85.282417, -292.642318) "
              points="85.2824165 294.258236 83.66617 292.642318 85.2824165 291.026399 86.898663 292.642318"
            ></polygon>
            <polygon
              id="Stroke-276"
              transform="translate(85.282417, 292.642318) rotate(22.000000) translate(-85.282417, -292.642318) "
              points="85.2824165 294.258236 83.66617 292.642318 85.2824165 291.026399 86.898663 292.642318"
            ></polygon>
            <polygon
              id="Fill-278"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(86.734070, 296.061119) rotate(22.000000) translate(-86.734070, -296.061119) "
              points="86.7340696 297.677038 85.1178231 296.061119 86.7340696 294.4452 88.3503161 296.061119"
            ></polygon>
            <polygon
              id="Stroke-280"
              transform="translate(86.734070, 296.061119) rotate(22.000000) translate(-86.734070, -296.061119) "
              points="86.7340696 297.677038 85.1178231 296.061119 86.7340696 294.4452 88.3503161 296.061119"
            ></polygon>
            <polygon
              id="Fill-282"
              fillOpacity="0.6"
              fill="#A3B1BF"
              transform="translate(88.185664, 299.479896) rotate(22.000000) translate(-88.185664, -299.479896) "
              points="88.1856638 301.095815 86.5694172 299.479896 88.1856638 297.863978 89.8019103 299.479896"
            ></polygon>
            <polygon
              id="Stroke-284"
              transform="translate(88.185664, 299.479896) rotate(22.000000) translate(-88.185664, -299.479896) "
              points="88.1856638 301.095815 86.5694172 299.479896 88.1856638 297.863978 89.8019103 299.479896"
            ></polygon>
            <polygon
              id="Fill-286"
              transform="translate(37.779995, 163.402514) rotate(22.000000) translate(-37.779995, -163.402514) "
              points="51.9961666 192.018058 66.401345 177.615802 23.5644587 134.78697 9.15864501 149.189862"
            ></polygon>
            <polygon
              id="Fill-290"
              transform="translate(50.400318, 195.682118) rotate(22.000000) translate(-50.400318, -195.682118) "
              points="54.1737837 198.070743 48.0112085 191.909418 46.6268527 193.293493 52.7894279 199.454818"
            ></polygon>
            <polygon
              id="Stroke-292"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(50.400318, 195.682118) rotate(22.000000) translate(-50.400318, -195.682118) "
              points="54.1737837 198.070743 48.0112085 191.909418 46.6268527 193.293493 52.7894279 199.454818"
            ></polygon>
            <polygon
              id="Fill-294"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(52.603532, 200.871447) rotate(22.000000) translate(-52.603532, -200.871447) "
              points="52.712807 203.746308 55.4789773 200.980699 52.4942579 197.996585 49.7280876 200.762195"
            ></polygon>
            <polygon
              id="Stroke-296"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              fill="#FFFFFF"
              strokeLinejoin="round"
              transform="translate(52.603532, 200.871447) rotate(22.000000) translate(-52.603532, -200.871447) "
              points="52.712807 203.746308 55.4789773 200.980699 52.4942579 197.996585 49.7280876 200.762195"
            ></polygon>
            <polygon
              id="Fill-298"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(53.983257, 183.836778) rotate(22.000000) translate(-53.983257, -183.836778) "
              points="52.3670108 183.836778 53.9832574 185.452697 55.5995039 183.836778 53.9832574 182.22086"
            ></polygon>
            <polygon
              id="Stroke-300"
              transform="translate(53.983257, 183.836778) rotate(22.000000) translate(-53.983257, -183.836778) "
              points="52.3670108 183.836778 53.9832574 185.452697 55.5995039 183.836778 53.9832574 182.22086"
            ></polygon>
            <polygon
              id="Fill-302"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(52.531663, 180.418001) rotate(22.000000) translate(-52.531663, -180.418001) "
              points="50.9154167 180.418001 52.5316632 182.03392 54.1479097 180.418001 52.5316632 178.802082"
            ></polygon>
            <polygon
              id="Stroke-304"
              transform="translate(52.531663, 180.418001) rotate(22.000000) translate(-52.531663, -180.418001) "
              points="50.9154167 180.418001 52.5316632 182.03392 54.1479097 180.418001 52.5316632 178.802082"
            ></polygon>
            <polygon
              id="Fill-306"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(51.080034, 176.999141) rotate(22.000000) translate(-51.080034, -176.999141) "
              points="49.4637874 176.999141 51.0800339 178.61506 52.6962805 176.999141 51.0800339 175.383222"
            ></polygon>
            <polygon
              id="Stroke-308"
              transform="translate(51.080034, 176.999141) rotate(22.000000) translate(-51.080034, -176.999141) "
              points="49.4637874 176.999141 51.0800339 178.61506 52.6962805 176.999141 51.0800339 175.383222"
            ></polygon>
            <polygon
              id="Fill-310"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(49.628440, 173.580364) rotate(22.000000) translate(-49.628440, -173.580364) "
              points="48.0121932 173.580364 49.6284397 175.196282 51.2446863 173.580364 49.6284397 171.964445"
            ></polygon>
            <polygon
              id="Stroke-312"
              transform="translate(49.628440, 173.580364) rotate(22.000000) translate(-49.628440, -173.580364) "
              points="48.0121932 173.580364 49.6284397 175.196282 51.2446863 173.580364 49.6284397 171.964445"
            ></polygon>
            <polygon
              id="Fill-314"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(50.827172, 185.176079) rotate(22.000000) translate(-50.827172, -185.176079) "
              points="49.2109255 185.176079 50.8271721 186.791998 52.4434186 185.176079 50.8271721 183.560161"
            ></polygon>
            <polygon
              id="Stroke-316"
              transform="translate(50.827172, 185.176079) rotate(22.000000) translate(-50.827172, -185.176079) "
              points="49.2109255 185.176079 50.8271721 186.791998 52.4434186 185.176079 50.8271721 183.560161"
            ></polygon>
            <polygon
              id="Fill-318"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(49.375578, 181.757302) rotate(22.000000) translate(-49.375578, -181.757302) "
              points="47.7593313 181.757302 49.3755779 183.373221 50.9918244 181.757302 49.3755779 180.141383"
            ></polygon>
            <polygon
              id="Stroke-320"
              transform="translate(49.375578, 181.757302) rotate(22.000000) translate(-49.375578, -181.757302) "
              points="47.7593313 181.757302 49.3755779 183.373221 50.9918244 181.757302 49.3755779 180.141383"
            ></polygon>
            <polygon
              id="Fill-322"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(47.923949, 178.338442) rotate(22.000000) translate(-47.923949, -178.338442) "
              points="46.3077021 178.338442 47.9239486 179.954361 49.5401951 178.338442 47.9239486 176.722523"
            ></polygon>
            <polygon
              id="Stroke-324"
              transform="translate(47.923949, 178.338442) rotate(22.000000) translate(-47.923949, -178.338442) "
              points="46.3077021 178.338442 47.9239486 179.954361 49.5401951 178.338442 47.9239486 176.722523"
            ></polygon>
            <polygon
              id="Fill-326"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(46.472354, 174.919665) rotate(22.000000) translate(-46.472354, -174.919665) "
              points="44.8561079 174.919665 46.4723544 176.535583 48.088601 174.919665 46.4723544 173.303746"
            ></polygon>
            <polygon
              id="Stroke-328"
              transform="translate(46.472354, 174.919665) rotate(22.000000) translate(-46.472354, -174.919665) "
              points="44.8561079 174.919665 46.4723544 176.535583 48.088601 174.919665 46.4723544 173.303746"
            ></polygon>
            <polygon
              id="Fill-330"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(47.671087, 186.515380) rotate(22.000000) translate(-47.671087, -186.515380) "
              points="46.0548402 186.51538 47.6710867 188.131299 49.2873333 186.51538 47.6710867 184.899461"
            ></polygon>
            <polygon
              id="Stroke-332"
              transform="translate(47.671087, 186.515380) rotate(22.000000) translate(-47.671087, -186.515380) "
              points="46.0548402 186.51538 47.6710867 188.131299 49.2873333 186.51538 47.6710867 184.899461"
            ></polygon>
            <polygon
              id="Fill-334"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(46.219493, 183.096603) rotate(22.000000) translate(-46.219493, -183.096603) "
              points="44.603246 183.096603 46.2194926 184.712521 47.8357391 183.096603 46.2194926 181.480684"
            ></polygon>
            <polygon
              id="Stroke-336"
              transform="translate(46.219493, 183.096603) rotate(22.000000) translate(-46.219493, -183.096603) "
              points="44.603246 183.096603 46.2194926 184.712521 47.8357391 183.096603 46.2194926 181.480684"
            ></polygon>
            <polygon
              id="Fill-338"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(44.767839, 179.677802) rotate(22.000000) translate(-44.767839, -179.677802) "
              points="43.1515929 179.677802 44.7678395 181.29372 46.384086 179.677802 44.7678395 178.061883"
            ></polygon>
            <polygon
              id="Stroke-340"
              transform="translate(44.767839, 179.677802) rotate(22.000000) translate(-44.767839, -179.677802) "
              points="43.1515929 179.677802 44.7678395 181.29372 46.384086 179.677802 44.7678395 178.061883"
            ></polygon>
            <polygon
              id="Fill-342"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(43.316269, 176.258965) rotate(22.000000) translate(-43.316269, -176.258965) "
              points="41.7000226 176.258965 43.3162691 177.874884 44.9325156 176.258965 43.3162691 174.643047"
            ></polygon>
            <polygon
              id="Stroke-344"
              transform="translate(43.316269, 176.258965) rotate(22.000000) translate(-43.316269, -176.258965) "
              points="41.7000226 176.258965 43.3162691 177.874884 44.9325156 176.258965 43.3162691 174.643047"
            ></polygon>
            <polygon
              id="Fill-346"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(44.514978, 187.854740) rotate(22.000000) translate(-44.514978, -187.854740) "
              points="42.8987311 187.85474 44.5149776 189.470659 46.1312242 187.85474 44.5149776 186.238821"
            ></polygon>
            <polygon
              id="Stroke-348"
              transform="translate(44.514978, 187.854740) rotate(22.000000) translate(-44.514978, -187.854740) "
              points="42.8987311 187.85474 44.5149776 189.470659 46.1312242 187.85474 44.5149776 186.238821"
            ></polygon>
            <polygon
              id="Fill-350"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(43.063348, 184.435880) rotate(22.000000) translate(-43.063348, -184.435880) "
              points="41.4471018 184.43588 43.0633483 186.051799 44.6795949 184.43588 43.0633483 182.819961"
            ></polygon>
            <polygon
              id="Stroke-352"
              transform="translate(43.063348, 184.435880) rotate(22.000000) translate(-43.063348, -184.435880) "
              points="41.4471018 184.43588 43.0633483 186.051799 44.6795949 184.43588 43.0633483 182.819961"
            ></polygon>
            <polygon
              id="Fill-354"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(41.611754, 181.017103) rotate(22.000000) translate(-41.611754, -181.017103) "
              points="39.9955076 181.017103 41.6117542 182.633021 43.2280007 181.017103 41.6117542 179.401184"
            ></polygon>
            <polygon
              id="Stroke-356"
              transform="translate(41.611754, 181.017103) rotate(22.000000) translate(-41.611754, -181.017103) "
              points="39.9955076 181.017103 41.6117542 182.633021 43.2280007 181.017103 41.6117542 179.401184"
            ></polygon>
            <polygon
              id="Fill-358"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(40.160184, 177.598266) rotate(22.000000) translate(-40.160184, -177.598266) "
              points="38.5439373 177.598266 40.1601838 179.214185 41.7764303 177.598266 40.1601838 175.982348"
            ></polygon>
            <polygon
              id="Stroke-360"
              transform="translate(40.160184, 177.598266) rotate(22.000000) translate(-40.160184, -177.598266) "
              points="38.5439373 177.598266 40.1601838 179.214185 41.7764303 177.598266 40.1601838 175.982348"
            ></polygon>
            <polygon
              id="Fill-362"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(41.358892, 189.194041) rotate(22.000000) translate(-41.358892, -189.194041) "
              points="39.7426458 189.194041 41.3588923 190.80996 42.9751388 189.194041 41.3588923 187.578122"
            ></polygon>
            <polygon
              id="Stroke-364"
              transform="translate(41.358892, 189.194041) rotate(22.000000) translate(-41.358892, -189.194041) "
              points="39.7426458 189.194041 41.3588923 190.80996 42.9751388 189.194041 41.3588923 187.578122"
            ></polygon>
            <polygon
              id="Fill-366"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(39.907263, 185.775181) rotate(22.000000) translate(-39.907263, -185.775181) "
              points="38.2910165 185.775181 39.907263 187.3911 41.5235095 185.775181 39.907263 184.159262"
            ></polygon>
            <polygon
              id="Stroke-368"
              transform="translate(39.907263, 185.775181) rotate(22.000000) translate(-39.907263, -185.775181) "
              points="38.2910165 185.775181 39.907263 187.3911 41.5235095 185.775181 39.907263 184.159262"
            ></polygon>
            <polygon
              id="Fill-370"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(38.455669, 182.356404) rotate(22.000000) translate(-38.455669, -182.356404) "
              points="36.8394223 182.356404 38.4556688 183.972322 40.0719154 182.356404 38.4556688 180.740485"
            ></polygon>
            <polygon
              id="Stroke-372"
              transform="translate(38.455669, 182.356404) rotate(22.000000) translate(-38.455669, -182.356404) "
              points="36.8394223 182.356404 38.4556688 183.972322 40.0719154 182.356404 38.4556688 180.740485"
            ></polygon>
            <polygon
              id="Fill-374"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(37.004016, 178.937602) rotate(22.000000) translate(-37.004016, -178.937602) "
              points="35.3877692 178.937602 37.0040158 180.553521 38.6202623 178.937602 37.0040158 177.321684"
            ></polygon>
            <polygon
              id="Stroke-376"
              transform="translate(37.004016, 178.937602) rotate(22.000000) translate(-37.004016, -178.937602) "
              points="35.3877692 178.937602 37.0040158 180.553521 38.6202623 178.937602 37.0040158 177.321684"
            ></polygon>
            <polygon
              id="Fill-378"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(46.380894, 165.931897) rotate(22.000000) translate(-46.380894, -165.931897) "
              points="44.7646475 165.931897 46.380894 167.547816 47.9971405 165.931897 46.380894 164.315979"
            ></polygon>
            <polygon
              id="Stroke-380"
              transform="translate(46.380894, 165.931897) rotate(22.000000) translate(-46.380894, -165.931897) "
              points="44.7646475 165.931897 46.380894 167.547816 47.9971405 165.931897 46.380894 164.315979"
            ></polygon>
            <polygon
              id="Fill-382"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(44.929324, 162.513061) rotate(22.000000) translate(-44.929324, -162.513061) "
              points="43.3130771 162.513061 44.9293236 164.12898 46.5455702 162.513061 44.9293236 160.897143"
            ></polygon>
            <polygon
              id="Stroke-384"
              transform="translate(44.929324, 162.513061) rotate(22.000000) translate(-44.929324, -162.513061) "
              points="43.3130771 162.513061 44.9293236 164.12898 46.5455702 162.513061 44.9293236 160.897143"
            ></polygon>
            <polygon
              id="Fill-386"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(43.477729, 159.094284) rotate(22.000000) translate(-43.477729, -159.094284) "
              points="41.8614829 159.094284 43.4777295 160.710203 45.093976 159.094284 43.4777295 157.478365"
            ></polygon>
            <polygon
              id="Stroke-388"
              transform="translate(43.477729, 159.094284) rotate(22.000000) translate(-43.477729, -159.094284) "
              points="41.8614829 159.094284 43.4777295 160.710203 45.093976 159.094284 43.4777295 157.478365"
            ></polygon>
            <polygon
              id="Fill-390"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(42.026100, 155.675424) rotate(22.000000) translate(-42.026100, -155.675424) "
              points="40.4098536 155.675424 42.0261002 157.291342 43.6423467 155.675424 42.0261002 154.059505"
            ></polygon>
            <polygon
              id="Stroke-392"
              transform="translate(42.026100, 155.675424) rotate(22.000000) translate(-42.026100, -155.675424) "
              points="40.4098536 155.675424 42.0261002 157.291342 43.6423467 155.675424 42.0261002 154.059505"
            ></polygon>
            <polygon
              id="Fill-394"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(43.224809, 167.271198) rotate(22.000000) translate(-43.224809, -167.271198) "
              points="41.6085622 167.271198 43.2248087 168.887117 44.8410552 167.271198 43.2248087 165.65528"
            ></polygon>
            <polygon
              id="Stroke-396"
              transform="translate(43.224809, 167.271198) rotate(22.000000) translate(-43.224809, -167.271198) "
              points="41.6085622 167.271198 43.2248087 168.887117 44.8410552 167.271198 43.2248087 165.65528"
            ></polygon>
            <polygon
              id="Fill-398"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(41.773238, 163.852362) rotate(22.000000) translate(-41.773238, -163.852362) "
              points="40.1569918 163.852362 41.7732383 165.468281 43.3894848 163.852362 41.7732383 162.236443"
            ></polygon>
            <polygon
              id="Stroke-400"
              transform="translate(41.773238, 163.852362) rotate(22.000000) translate(-41.773238, -163.852362) "
              points="40.1569918 163.852362 41.7732383 165.468281 43.3894848 163.852362 41.7732383 162.236443"
            ></polygon>
            <polygon
              id="Fill-402"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(40.321585, 160.433561) rotate(22.000000) translate(-40.321585, -160.433561) "
              points="38.7053387 160.433561 40.3215852 162.04948 41.9378318 160.433561 40.3215852 158.817642"
            ></polygon>
            <polygon
              id="Stroke-404"
              transform="translate(40.321585, 160.433561) rotate(22.000000) translate(-40.321585, -160.433561) "
              points="38.7053387 160.433561 40.3215852 162.04948 41.9378318 160.433561 40.3215852 158.817642"
            ></polygon>
            <polygon
              id="Fill-406"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(38.869991, 157.014784) rotate(22.000000) translate(-38.869991, -157.014784) "
              points="37.2537445 157.014784 38.8699911 158.630702 40.4862376 157.014784 38.8699911 155.398865"
            ></polygon>
            <polygon
              id="Stroke-408"
              transform="translate(38.869991, 157.014784) rotate(22.000000) translate(-38.869991, -157.014784) "
              points="37.2537445 157.014784 38.8699911 158.630702 40.4862376 157.014784 38.8699911 155.398865"
            ></polygon>
            <polygon
              id="Fill-410"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(40.068723, 168.610499) rotate(22.000000) translate(-40.068723, -168.610499) "
              points="38.4524768 168.610499 40.0687234 170.226418 41.6849699 168.610499 40.0687234 166.994581"
            ></polygon>
            <polygon
              id="Stroke-412"
              transform="translate(40.068723, 168.610499) rotate(22.000000) translate(-40.068723, -168.610499) "
              points="38.4524768 168.610499 40.0687234 170.226418 41.6849699 168.610499 40.0687234 166.994581"
            ></polygon>
            <polygon
              id="Fill-414"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(38.617129, 165.191722) rotate(22.000000) translate(-38.617129, -165.191722) "
              points="37.0008827 165.191722 38.6171292 166.807641 40.2333757 165.191722 38.6171292 163.575803"
            ></polygon>
            <polygon
              id="Stroke-416"
              transform="translate(38.617129, 165.191722) rotate(22.000000) translate(-38.617129, -165.191722) "
              points="37.0008827 165.191722 38.6171292 166.807641 40.2333757 165.191722 38.6171292 163.575803"
            ></polygon>
            <polygon
              id="Fill-418"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(37.165500, 161.772862) rotate(22.000000) translate(-37.165500, -161.772862) "
              points="35.5492534 161.772862 37.1654999 163.388781 38.7817464 161.772862 37.1654999 160.156943"
            ></polygon>
            <polygon
              id="Stroke-420"
              transform="translate(37.165500, 161.772862) rotate(22.000000) translate(-37.165500, -161.772862) "
              points="35.5492534 161.772862 37.1654999 163.388781 38.7817464 161.772862 37.1654999 160.156943"
            ></polygon>
            <polygon
              id="Fill-422"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(35.713906, 158.354085) rotate(22.000000) translate(-35.713906, -158.354085) "
              points="34.0976592 158.354085 35.7139057 159.970003 37.3301523 158.354085 35.7139057 156.738166"
            ></polygon>
            <polygon
              id="Stroke-424"
              transform="translate(35.713906, 158.354085) rotate(22.000000) translate(-35.713906, -158.354085) "
              points="34.0976592 158.354085 35.7139057 159.970003 37.3301523 158.354085 35.7139057 156.738166"
            ></polygon>
            <polygon
              id="Fill-426"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(36.912638, 169.949800) rotate(22.000000) translate(-36.912638, -169.949800) "
              points="35.2963915 169.9498 36.912638 171.565719 38.5288846 169.9498 36.912638 168.333882"
            ></polygon>
            <polygon
              id="Stroke-428"
              transform="translate(36.912638, 169.949800) rotate(22.000000) translate(-36.912638, -169.949800) "
              points="35.2963915 169.9498 36.912638 171.565719 38.5288846 169.9498 36.912638 168.333882"
            ></polygon>
            <polygon
              id="Fill-430"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(35.461068, 166.530964) rotate(22.000000) translate(-35.461068, -166.530964) "
              points="33.8448211 166.530964 35.4610677 168.146883 37.0773142 166.530964 35.4610677 164.915045"
            ></polygon>
            <polygon
              id="Stroke-432"
              transform="translate(35.461068, 166.530964) rotate(22.000000) translate(-35.461068, -166.530964) "
              points="33.8448211 166.530964 35.4610677 168.146883 37.0773142 166.530964 35.4610677 164.915045"
            ></polygon>
            <polygon
              id="Fill-434"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(34.009415, 163.112163) rotate(22.000000) translate(-34.009415, -163.112163) "
              points="32.3931681 163.112163 34.0094146 164.728081 35.6256611 163.112163 34.0094146 161.496244"
            ></polygon>
            <polygon
              id="Stroke-436"
              transform="translate(34.009415, 163.112163) rotate(22.000000) translate(-34.009415, -163.112163) "
              points="32.3931681 163.112163 34.0094146 164.728081 35.6256611 163.112163 34.0094146 161.496244"
            ></polygon>
            <polygon
              id="Fill-438"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(32.557820, 159.693385) rotate(22.000000) translate(-32.557820, -159.693385) "
              points="30.9415739 159.693385 32.5578204 161.309304 34.174067 159.693385 32.5578204 158.077467"
            ></polygon>
            <polygon
              id="Stroke-440"
              transform="translate(32.557820, 159.693385) rotate(22.000000) translate(-32.557820, -159.693385) "
              points="30.9415739 159.693385 32.5578204 161.309304 34.174067 159.693385 32.5578204 158.077467"
            ></polygon>
            <polygon
              id="Fill-442"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(33.756553, 171.289101) rotate(22.000000) translate(-33.756553, -171.289101) "
              points="32.1403062 171.289101 33.7565527 172.90502 35.3727993 171.289101 33.7565527 169.673182"
            ></polygon>
            <polygon
              id="Stroke-444"
              transform="translate(33.756553, 171.289101) rotate(22.000000) translate(-33.756553, -171.289101) "
              points="32.1403062 171.289101 33.7565527 172.90502 35.3727993 171.289101 33.7565527 169.673182"
            ></polygon>
            <polygon
              id="Fill-446"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(32.304959, 167.870324) rotate(22.000000) translate(-32.304959, -167.870324) "
              points="30.688712 167.870324 32.3049586 169.486242 33.9212051 167.870324 32.3049586 166.254405"
            ></polygon>
            <polygon
              id="Stroke-448"
              transform="translate(32.304959, 167.870324) rotate(22.000000) translate(-32.304959, -167.870324) "
              points="30.688712 167.870324 32.3049586 169.486242 33.9212051 167.870324 32.3049586 166.254405"
            ></polygon>
            <polygon
              id="Fill-450"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(30.853329, 164.451464) rotate(22.000000) translate(-30.853329, -164.451464) "
              points="29.2370827 164.451464 30.8533293 166.067382 32.4695758 164.451464 30.8533293 162.835545"
            ></polygon>
            <polygon
              id="Stroke-452"
              transform="translate(30.853329, 164.451464) rotate(22.000000) translate(-30.853329, -164.451464) "
              points="29.2370827 164.451464 30.8533293 166.067382 32.4695758 164.451464 30.8533293 162.835545"
            ></polygon>
            <polygon
              id="Fill-454"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(29.401735, 161.032686) rotate(22.000000) translate(-29.401735, -161.032686) "
              points="27.7854886 161.032686 29.4017351 162.648605 31.0179816 161.032686 29.4017351 159.416768"
            ></polygon>
            <polygon
              id="Stroke-456"
              transform="translate(29.401735, 161.032686) rotate(22.000000) translate(-29.401735, -161.032686) "
              points="27.7854886 161.032686 29.4017351 162.648605 31.0179816 161.032686 29.4017351 159.416768"
            ></polygon>
            <polygon
              id="Fill-458"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(38.778613, 148.026981) rotate(22.000000) translate(-38.778613, -148.026981) "
              points="37.1623668 148.026981 38.7786133 149.6429 40.3948599 148.026981 38.7786133 146.411063"
            ></polygon>
            <polygon
              id="Stroke-460"
              transform="translate(38.778613, 148.026981) rotate(22.000000) translate(-38.778613, -148.026981) "
              points="37.1623668 148.026981 38.7786133 149.6429 40.3948599 148.026981 38.7786133 146.411063"
            ></polygon>
            <polygon
              id="Fill-462"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(37.326984, 144.608121) rotate(22.000000) translate(-37.326984, -144.608121) "
              points="35.7107375 144.608121 37.3269841 146.22404 38.9432306 144.608121 37.3269841 142.992203"
            ></polygon>
            <polygon
              id="Stroke-464"
              transform="translate(37.326984, 144.608121) rotate(22.000000) translate(-37.326984, -144.608121) "
              points="35.7107375 144.608121 37.3269841 146.22404 38.9432306 144.608121 37.3269841 142.992203"
            ></polygon>
            <polygon
              id="Fill-466"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(35.875390, 141.189344) rotate(22.000000) translate(-35.875390, -141.189344) "
              points="34.2591433 141.189344 35.8753899 142.805263 37.4916364 141.189344 35.8753899 139.573425"
            ></polygon>
            <polygon
              id="Stroke-468"
              transform="translate(35.875390, 141.189344) rotate(22.000000) translate(-35.875390, -141.189344) "
              points="34.2591433 141.189344 35.8753899 142.805263 37.4916364 141.189344 35.8753899 139.573425"
            ></polygon>
            <polygon
              id="Fill-470"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(34.423796, 137.770567) rotate(22.000000) translate(-34.423796, -137.770567) "
              points="32.8075492 137.770567 34.4237957 139.386485 36.0400422 137.770567 34.4237957 136.154648"
            ></polygon>
            <polygon
              id="Stroke-472"
              fill="#B2BECA"
              transform="translate(34.423796, 137.770567) rotate(22.000000) translate(-34.423796, -137.770567) "
              points="34.4237957 139.386485 36.0400422 137.770567 34.4237957 136.154648 32.8075492 137.770567"
            ></polygon>
            <polygon
              id="Fill-474"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(35.622528, 149.366282) rotate(22.000000) translate(-35.622528, -149.366282) "
              points="34.0062815 149.366282 35.622528 150.982201 37.2387746 149.366282 35.622528 147.750364"
            ></polygon>
            <polygon
              id="Stroke-476"
              transform="translate(35.622528, 149.366282) rotate(22.000000) translate(-35.622528, -149.366282) "
              points="34.0062815 149.366282 35.622528 150.982201 37.2387746 149.366282 35.622528 147.750364"
            ></polygon>
            <polygon
              id="Fill-478"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(34.170875, 145.947481) rotate(22.000000) translate(-34.170875, -145.947481) "
              points="32.5546284 145.947481 34.1708749 147.5634 35.7871215 145.947481 34.1708749 144.331563"
            ></polygon>
            <polygon
              id="Stroke-480"
              transform="translate(34.170875, 145.947481) rotate(22.000000) translate(-34.170875, -145.947481) "
              points="32.5546284 145.947481 34.1708749 147.5634 35.7871215 145.947481 34.1708749 144.331563"
            ></polygon>
            <polygon
              id="Fill-482"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(32.719305, 142.528645) rotate(22.000000) translate(-32.719305, -142.528645) "
              points="31.103058 142.528645 32.7193046 144.144564 34.3355511 142.528645 32.7193046 140.912726"
            ></polygon>
            <polygon
              id="Stroke-484"
              transform="translate(32.719305, 142.528645) rotate(22.000000) translate(-32.719305, -142.528645) "
              points="31.103058 142.528645 32.7193046 144.144564 34.3355511 142.528645 32.7193046 140.912726"
            ></polygon>
            <polygon
              id="Fill-486"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(31.267651, 139.109844) rotate(22.000000) translate(-31.267651, -139.109844) "
              points="29.651405 139.109844 31.2676515 140.725763 32.883898 139.109844 31.2676515 137.493925"
            ></polygon>
            <polygon
              id="Stroke-488"
              transform="translate(31.267651, 139.109844) rotate(22.000000) translate(-31.267651, -139.109844) "
              points="29.651405 139.109844 31.2676515 140.725763 32.883898 139.109844 31.2676515 137.493925"
            ></polygon>
            <polygon
              id="Fill-490"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(32.466384, 150.705559) rotate(22.000000) translate(-32.466384, -150.705559) "
              points="30.8501373 150.705559 32.4663838 152.321478 34.0826303 150.705559 32.4663838 149.089641"
            ></polygon>
            <polygon
              id="Stroke-492"
              transform="translate(32.466384, 150.705559) rotate(22.000000) translate(-32.466384, -150.705559) "
              points="30.8501373 150.705559 32.4663838 152.321478 34.0826303 150.705559 32.4663838 149.089641"
            ></polygon>
            <polygon
              id="Fill-494"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(31.014790, 147.286782) rotate(22.000000) translate(-31.014790, -147.286782) "
              points="29.3985431 147.286782 31.0147896 148.902701 32.6310362 147.286782 31.0147896 145.670863"
            ></polygon>
            <polygon
              id="Stroke-496"
              transform="translate(31.014790, 147.286782) rotate(22.000000) translate(-31.014790, -147.286782) "
              points="29.3985431 147.286782 31.0147896 148.902701 32.6310362 147.286782 31.0147896 145.670863"
            ></polygon>
            <polygon
              id="Fill-498"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(29.563219, 143.867946) rotate(22.000000) translate(-29.563219, -143.867946) "
              points="27.9469727 143.867946 29.5632192 145.483865 31.1794658 143.867946 29.5632192 142.252027"
            ></polygon>
            <polygon
              id="Stroke-500"
              transform="translate(29.563219, 143.867946) rotate(22.000000) translate(-29.563219, -143.867946) "
              points="27.9469727 143.867946 29.5632192 145.483865 31.1794658 143.867946 29.5632192 142.252027"
            ></polygon>
            <polygon
              id="Fill-502"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(28.111566, 140.449145) rotate(22.000000) translate(-28.111566, -140.449145) "
              points="26.4953196 140.449145 28.1115662 142.065063 29.7278127 140.449145 28.1115662 138.833226"
            ></polygon>
            <polygon
              id="Stroke-504"
              transform="translate(28.111566, 140.449145) rotate(22.000000) translate(-28.111566, -140.449145) "
              points="26.4953196 140.449145 28.1115662 142.065063 29.7278127 140.449145 28.1115662 138.833226"
            ></polygon>
            <polygon
              id="Fill-506"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(29.310298, 152.044860) rotate(22.000000) translate(-29.310298, -152.044860) "
              points="27.6940519 152.04486 29.3102985 153.660779 30.926545 152.04486 29.3102985 150.428942"
            ></polygon>
            <polygon
              id="Stroke-508"
              transform="translate(29.310298, 152.044860) rotate(22.000000) translate(-29.310298, -152.044860) "
              points="27.6940519 152.04486 29.3102985 153.660779 30.926545 152.04486 29.3102985 150.428942"
            ></polygon>
            <polygon
              id="Fill-510"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(27.858704, 148.626083) rotate(22.000000) translate(-27.858704, -148.626083) "
              points="26.2424578 148.626083 27.8587043 150.242002 29.4749508 148.626083 27.8587043 147.010164"
            ></polygon>
            <polygon
              id="Stroke-512"
              transform="translate(27.858704, 148.626083) rotate(22.000000) translate(-27.858704, -148.626083) "
              points="26.2424578 148.626083 27.8587043 150.242002 29.4749508 148.626083 27.8587043 147.010164"
            ></polygon>
            <polygon
              id="Fill-514"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(26.407134, 145.207247) rotate(22.000000) translate(-26.407134, -145.207247) "
              points="24.7908874 145.207247 26.4071339 146.823166 28.0233805 145.207247 26.4071339 143.591328"
            ></polygon>
            <polygon
              id="Stroke-516"
              transform="translate(26.407134, 145.207247) rotate(22.000000) translate(-26.407134, -145.207247) "
              points="24.7908874 145.207247 26.4071339 146.823166 28.0233805 145.207247 26.4071339 143.591328"
            ></polygon>
            <polygon
              id="Fill-518"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(24.955481, 141.788446) rotate(22.000000) translate(-24.955481, -141.788446) "
              points="23.3392343 141.788446 24.9554808 143.404364 26.5717274 141.788446 24.9554808 140.172527"
            ></polygon>
            <polygon
              id="Stroke-520"
              transform="translate(24.955481, 141.788446) rotate(22.000000) translate(-24.955481, -141.788446) "
              points="23.3392343 141.788446 24.9554808 143.404364 26.5717274 141.788446 24.9554808 140.172527"
            ></polygon>
            <polygon
              id="Fill-522"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(26.154213, 153.384161) rotate(22.000000) translate(-26.154213, -153.384161) "
              points="24.5379666 153.384161 26.1542132 155.00008 27.7704597 153.384161 26.1542132 151.768243"
            ></polygon>
            <polygon
              id="Stroke-524"
              transform="translate(26.154213, 153.384161) rotate(22.000000) translate(-26.154213, -153.384161) "
              points="24.5379666 153.384161 26.1542132 155.00008 27.7704597 153.384161 26.1542132 151.768243"
            ></polygon>
            <polygon
              id="Fill-526"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(24.702619, 149.965384) rotate(22.000000) translate(-24.702619, -149.965384) "
              points="23.0863724 149.965384 24.702619 151.581303 26.3188655 149.965384 24.702619 148.349465"
            ></polygon>
            <polygon
              id="Stroke-528"
              transform="translate(24.702619, 149.965384) rotate(22.000000) translate(-24.702619, -149.965384) "
              points="23.0863724 149.965384 24.702619 151.581303 26.3188655 149.965384 24.702619 148.349465"
            ></polygon>
            <polygon
              id="Fill-530"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(23.250966, 146.546583) rotate(22.000000) translate(-23.250966, -146.546583) "
              points="21.6347194 146.546583 23.2509659 148.162502 24.8672124 146.546583 23.2509659 144.930664"
            ></polygon>
            <polygon
              id="Stroke-532"
              transform="translate(23.250966, 146.546583) rotate(22.000000) translate(-23.250966, -146.546583) "
              points="21.6347194 146.546583 23.2509659 148.162502 24.8672124 146.546583 23.2509659 144.930664"
            ></polygon>
            <polygon
              id="Fill-534"
              fillOpacity="0.8"
              fill="#A3B1BF"
              transform="translate(21.799396, 143.127747) rotate(22.000000) translate(-21.799396, -143.127747) "
              points="20.183149 143.127747 21.7993955 144.743665 23.4156421 143.127747 21.7993955 141.511828"
            ></polygon>
            <polygon
              id="Stroke-536"
              transform="translate(21.799396, 143.127747) rotate(22.000000) translate(-21.799396, -143.127747) "
              points="20.183149 143.127747 21.7993955 144.743665 23.4156421 143.127747 21.7993955 141.511828"
            ></polygon>
            <path
              d="M129.314819,189.942425 C126.372666,187.000233 124.490222,184.112668 125.110927,183.492089 C125.731631,182.87151 128.619783,184.753573 131.562571,187.695129 C134.504724,190.637321 136.387169,193.524886 135.766464,194.145465 C135.145759,194.766044 132.257608,192.883981 129.314819,189.942425 Z"
              id="Fill-538"
              fill="#F5F5F5"
              transform="translate(130.438695, 188.818777) rotate(22.000000) translate(-130.438695, -188.818777) "
            ></path>
            <path
              d="M129.314819,189.942425 C126.372666,187.000233 124.490222,184.112668 125.110927,183.492089 C125.731631,182.87151 128.619783,184.753573 131.562571,187.695129 C134.504724,190.637321 136.387169,193.524886 135.766464,194.145465 C135.145759,194.766044 132.257608,192.883981 129.314819,189.942425 Z"
              id="Stroke-540"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(130.438695, 188.818777) rotate(22.000000) translate(-130.438695, -188.818777) "
            ></path>
            <path
              d="M136.083433,187.715086 C130.20421,181.837055 126.443133,176.067006 127.683272,174.827118 C128.92341,173.587231 134.69463,177.347545 140.573854,183.225576 C146.453713,189.104243 150.21479,194.874292 148.974651,196.114179 C147.734513,197.354066 141.963293,193.593752 136.083433,187.715086 Z"
              id="Fill-542"
              fill="#F5F5F5"
              transform="translate(138.328961, 185.470648) rotate(22.000000) translate(-138.328961, -185.470648) "
            ></path>
            <path
              d="M136.083433,187.715086 C130.20421,181.837055 126.443133,176.067006 127.683272,174.827118 C128.92341,173.587231 134.69463,177.347545 140.573854,183.225576 C146.453713,189.104243 150.21479,194.874292 148.974651,196.114179 C147.734513,197.354066 141.963293,193.593752 136.083433,187.715086 Z"
              id="Stroke-544"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(138.328961, 185.470648) rotate(22.000000) translate(-138.328961, -185.470648) "
            ></path>
            <path
              d="M146.697288,183.650834 C138.818086,175.773231 133.777481,168.04045 135.43947,166.378798 C137.101459,164.717146 144.835809,169.756729 152.715011,177.634332 C160.594213,185.511936 165.634818,193.244716 163.972829,194.906369 C162.310839,196.568021 154.57649,191.528438 146.697288,183.650834 Z"
              id="Fill-546"
              fillOpacity="0.35"
              fill="#F5F5F5"
              transform="translate(149.706149, 180.642583) rotate(22.000000) translate(-149.706149, -180.642583) "
            ></path>
            <path
              d="M146.697288,183.650834 C138.818086,175.773231 133.777481,168.04045 135.43947,166.378798 C137.101459,164.717146 144.835809,169.756729 152.715011,177.634332 C160.594213,185.511936 165.634818,193.244716 163.972829,194.906369 C162.310839,196.568021 154.57649,191.528438 146.697288,183.650834 Z"
              id="Stroke-548"
              stroke="#A3B1BF"
              strokeWidth="1.62"
              strokeLinejoin="round"
              transform="translate(149.706149, 180.642583) rotate(22.000000) translate(-149.706149, -180.642583) "
            ></path>
            <path
              d="M65.2279553,90.2345285 C62.4548336,90.0320836 60.4105231,89.3345405 60.4105231,88.5051511 C60.4105231,87.676673 62.4503446,86.9797514 65.2188187,86.7764428 C65.4149139,84.0068366 66.0879499,81.9659221 66.8880741,81.9659221 C67.6881984,81.9659221 68.3612344,84.0068366 68.5573295,86.7764428 C71.3258036,86.9797514 73.3656251,87.676673 73.3656251,88.5051511 C73.3656251,89.3345405 71.3213146,90.0320836 68.548193,90.2345285 C68.3410329,92.9408238 67.6762073,94.9210242 66.8880741,94.9210242 C66.0999409,94.9210242 65.4351154,92.9408238 65.2279553,90.2345285 Z"
              id="Oval-80-Copy-3"
              fillOpacity="0.4"
              fill="#A3B1BF"
              opacity="0.85"
              transform="translate(66.888074, 88.443473) rotate(-340.000000) translate(-66.888074, -88.443473) "
            ></path>
            <path
              d="M118.775569,329.140492 L118.424988,329.526146 C117.522244,330.5192 115.985396,330.592411 114.992341,329.689667 C114.596934,329.330218 114.331035,328.850456 114.235803,328.324641 L114.143075,327.812647 L113.669257,327.599091 C112.445737,327.047635 111.900921,325.608734 112.452377,324.385214 C112.672015,323.8979 113.046204,323.496601 113.516991,323.243469 L113.97521,322.997095 L114.032158,322.478985 C114.178786,321.144967 115.379087,320.182398 116.713105,320.329026 C117.24418,320.387399 117.741214,320.61917 118.127299,320.988476 L118.503957,321.348765 L119.01334,321.242962 C120.327346,320.970031 121.613813,321.813989 121.886744,323.127995 C121.995449,323.651349 121.928561,324.195943 121.696437,324.677436 L121.470742,325.145594 L121.728812,325.597409 C122.394441,326.762759 121.989337,328.247061 120.823987,328.91269 C120.359975,329.177726 119.82154,329.282421 119.292023,329.21057 L118.775569,329.140492 Z"
              id="Star-1-Copy-16"
              fillOpacity="0.4"
              fill="#A3B1BF"
            ></path>
            <path
              d="M432.83752,120.004816 L432.837087,120.005044 L432.837087,120.005044 C432.092472,120.396429 431.171561,120.110079 430.780176,119.365464 C430.624337,119.068978 430.570573,118.729391 430.627206,118.399266 L430.627206,118.399266 L430.627206,118.399266 L430.627206,118.399266 C430.024845,117.812233 430.01242,116.848039 430.599453,116.245678 C430.833262,116.005765 431.139652,115.849637 431.471171,115.801474 L431.471471,115.801431 L431.471471,115.801431 L431.471471,115.801431 C431.843891,115.046982 432.757399,114.737288 433.511848,115.109709 C433.812195,115.25797 434.055308,115.501083 434.20357,115.801431 L434.20357,115.801431 L434.203869,115.801474 L434.203869,115.801474 C435.036229,115.922398 435.612963,116.695187 435.492039,117.527547 C435.443876,117.859067 435.287748,118.165457 435.047835,118.399266 L435.047835,118.399266 L435.047835,118.399266 L435.047835,118.399266 C435.190066,119.228364 434.63325,120.015783 433.804152,120.158014 C433.474027,120.214647 433.134439,120.160883 432.837954,120.005044 L432.83752,120.004816 Z"
              id="Star-1-Copy-18"
              fillOpacity="0.4"
              fill="#A3B1BF"
              transform="translate(432.837619, 117.566125) rotate(-3.000000) translate(-432.837619, -117.566125) "
            ></path>
            <path
              d="M99.7727703,38.762372 L99.0266308,39.1545584 L99.0266308,39.1545584 C97.8386845,39.7789675 96.36948,39.3221304 95.7450708,38.134184 C95.4964477,37.6611755 95.4106732,37.1194035 95.5010241,36.5927278 L95.6433921,35.7628333 L95.0407812,35.1755563 L95.0407812,35.1755563 C94.0796578,34.2388893 94.059832,32.7004261 94.996499,31.7393027 C95.369563,31.3564979 95.8584376,31.1073808 96.3874089,31.0305329 L97.2206742,30.9094778 L97.5937913,30.1536188 L97.5937913,30.1536188 C98.1878378,28.950202 99.6449697,28.4562097 100.848387,29.0502562 C101.327469,29.2867472 101.715258,29.6745363 101.951749,30.1536188 L102.324866,30.9094778 L103.158132,31.0305329 L103.158132,31.0305329 C104.486241,31.223478 105.406474,32.4565366 105.213529,33.7846464 C105.136681,34.3136177 104.887564,34.8024923 104.504759,35.1755563 L103.902148,35.7628333 L104.044516,36.5927278 L104.044516,36.5927278 C104.27143,37.9154575 103.383095,39.1716915 102.060366,39.398605 C101.53369,39.4889559 100.991918,39.4031814 100.51891,39.1545584 L99.7727703,38.762372 Z"
              id="Star-1-Copy-19"
              fillOpacity="0.4"
              fill="#A3B1BF"
              transform="translate(99.772928, 34.116388) rotate(19.000000) translate(-99.772928, -34.116388) "
            ></path>
            <path
              d="M396.631073,305.744172 L396.098125,305.608596 C394.797498,305.277731 394.01135,303.955146 394.342215,302.654518 C394.473956,302.136643 394.772729,301.676633 395.192288,301.345694 L395.623376,301.00566 L395.587617,300.458372 C395.500116,299.119176 396.514815,297.962609 397.854012,297.875108 C398.387399,297.840257 398.917395,297.982247 399.361919,298.279089 L399.818473,298.583965 L400.329362,298.380373 C401.576068,297.883555 402.989473,298.491459 403.486291,299.738164 C403.684076,300.23448 403.712777,300.782145 403.567956,301.296416 L403.418882,301.825788 L403.76981,302.247972 C404.627682,303.280038 404.486471,304.812135 403.454406,305.670007 C403.043346,306.011688 402.531095,306.208301 401.996987,306.229395 L401.44896,306.251039 L401.15585,306.715309 C400.439401,307.850123 398.938655,308.189275 397.80384,307.472826 C397.351988,307.187555 397.006767,306.761296 396.821614,306.260029 L396.631073,305.744172 Z"
              id="Star-1-Copy-20"
              fillOpacity="0.4"
              fill="#A3B1BF"
            ></path>
            <path
              d="M439.755536,146.347757 L439.755103,146.347984 L439.755103,146.347984 C439.010488,146.739369 438.089577,146.45302 437.698192,145.708405 C437.542353,145.411919 437.488589,145.072331 437.545222,144.742206 L437.545222,144.742206 L437.545222,144.742206 L437.545222,144.742206 C436.942861,144.155173 436.930436,143.190979 437.517469,142.588619 C437.751277,142.348705 438.057668,142.192577 438.389187,142.144415 L438.389487,142.144371 L438.389487,142.144371 L438.389487,142.144371 C438.761907,141.389923 439.675415,141.080228 440.429864,141.452649 C440.730211,141.600911 440.973324,141.844024 441.121586,142.144371 L441.121586,142.144371 L441.121885,142.144415 L441.121885,142.144415 C441.954245,142.265338 442.530978,143.038128 442.410055,143.870488 C442.361892,144.202007 442.205764,144.508398 441.965851,144.742206 L441.965851,144.742206 L441.965851,144.742206 L441.965851,144.742206 C442.108082,145.571305 441.551266,146.358723 440.722168,146.500955 C440.392043,146.557588 440.052455,146.503823 439.755969,146.347984 L439.755536,146.347757 Z"
              id="Star-1-Copy-21"
              fillOpacity="0.4"
              fill="#A3B1BF"
              transform="translate(439.755635, 143.909066) rotate(-12.000000) translate(-439.755635, -143.909066) "
            ></path>
            <path
              d="M244.621971,220.61341 C277.15945,220.61341 303.536288,194.242112 303.536288,161.711467 C303.536288,129.180822 277.15945,102.809524 244.621971,102.809524 C212.084491,102.809524 185.707653,129.180822 185.707653,161.711467 C185.707653,194.242112 212.084491,220.61341 244.621971,220.61341 Z"
              id="Oval-8-Copy-2"
              fillOpacity="0.66"
              fill="#A3B1BF"
            ></path>
            <path
              d="M245.345696,141.060063 C241.146656,141.060063 237.880736,142.342349 235.547936,144.906921 C233.215136,147.354921 232.107056,150.618921 232.107056,154.698921 L236.714336,154.698921 C236.714336,151.842921 237.355856,149.628063 238.638896,147.996063 C240.038576,146.072635 242.196416,145.140063 245.112416,145.140063 C247.561856,145.140063 249.486416,145.781206 250.827776,147.180063 C252.110816,148.462349 252.810656,150.269206 252.810656,152.600635 C252.810656,154.232635 252.227456,155.748063 251.061056,157.205206 C250.711136,157.671492 250.011296,158.370921 249.078176,159.303492 C245.928896,162.101206 244.004336,164.316063 243.187856,166.064635 C242.488016,167.521778 242.138096,169.212063 242.138096,171.135492 L242.138096,172.476063 L246.803696,172.476063 L246.803696,171.135492 C246.803696,169.561778 247.153616,168.162921 247.911776,166.880635 C248.494976,165.831492 249.369776,164.782349 250.652816,163.674921 C253.218896,161.401778 254.793536,159.886349 255.376736,159.186921 C256.834736,157.263492 257.592896,154.990349 257.592896,152.367492 C257.592896,148.870349 256.484816,146.130921 254.326976,144.149206 C252.052496,142.050921 249.078176,141.060063 245.345696,141.060063 Z M244.470896,176.556063 C243.479456,176.556063 242.662976,176.847492 241.963136,177.546921 C241.263296,178.188063 240.971696,179.004063 240.971696,179.994921 C240.971696,180.985778 241.263296,181.801778 241.963136,182.501206 C242.662976,183.142349 243.479456,183.492063 244.470896,183.492063 C245.462336,183.492063 246.278816,183.142349 246.978656,182.501206 C247.678496,181.860063 248.028416,181.044063 248.028416,179.994921 C248.028416,179.004063 247.678496,178.188063 247.036976,177.546921 C246.337136,176.847492 245.462336,176.556063 244.470896,176.556063 Z"
              id="？"
              fill="#FFFFFF"
            ></path>
            <path
              d="M223.261662,133.139924 C229.077338,129.782947 239.631808,125.828273 236.274126,120.013819 C232.916444,114.199364 220.565868,115.043746 214.750193,118.400722 C208.934517,121.757699 202.729834,131.623951 206.087516,137.438405 C209.445198,143.252859 217.445986,136.4969 223.261662,133.139924"
              id="Oval-8-Copy-2"
              fill="#FAFAFA"
            ></path>
            <path
              d="M249.679003,119.820589 C252.365149,119.820589 254.5427,117.643495 254.5427,114.957913 C254.5427,112.272332 252.365149,110.095238 249.679003,110.095238 C246.992857,110.095238 244.815306,112.272332 244.815306,114.957913 C244.815306,117.643495 246.992857,119.820589 249.679003,119.820589 Z"
              id="Oval-10-Copy"
              fill="#FAFAFA"
            ></path>
            <path
              d="M421.5,321.5 L423,323"
              id="Line-2"
              stroke="#979797"
              strokeLinecap="square"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </Container>
);

export default Fallback;
