import { string, object } from 'yup';

const forgotPasswordSchema = object().shape({
  newPassword: string()
    .required('Password is required')
    .min(8, 'Password is too short.It should be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,32}$/,
      'At least 1 number,1 symbol (@$!#%*?&) and 1 uppercase letter'
    ),
  confirmPassword: string()
    .required('Password is required')
    .min(8, 'Password is too short.It should be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,32}$/,
      'At least 1 number,1 symbol (@$!#%*?&) and 1 uppercase letter'
    )
});

export default {
  forgotPassword: forgotPasswordSchema
};
