import React from 'react';

import { Performance } from '_src/ui';
import { improvingChartStatus, decliningChartStatus } from '_src/assets/images';
import { Container, Title, StatusContainer, StatusTitle, Img, RightBorder } from './styles';

const { RED, GREEN, GREY } = GLOBALS.colors;

const statusConfig = {
  DECLINING: {
    title: 'Declining',
    imgSrc: decliningChartStatus,
    color: RED
  },
  IMPROVING: {
    title: 'Improving',
    imgSrc: improvingChartStatus,
    color: GREEN
  },
  SAME: {
    title: 'Same',
    imgSrc: '',
    color: GREY
  },
  NEUTRAL: {
    title: 'Neutral',
    imgSrc: '',
    color: GREY
  }
};

const renderEvaluationTitle = (title, index) => <Title>{`${index + 1}. ${title}`}</Title>;

const renderStatus = type => {
  const { color, title, imgSrc } = statusConfig[type];
  return (
    <StatusContainer>
      <StatusTitle color={color}>{title}</StatusTitle>
      <Img src={imgSrc} />
    </StatusContainer>
  );
};

const Evaluation = ({
  title,
  performance,
  status = 'SAME',
  index,
  hasRightBorder = true,
  createdAt
}) => (
  <Container>
    {renderEvaluationTitle(title, index)}
    <div style={{ width: '210px' }}>
      <Performance type={performance} createdAt={createdAt} hasRightBorder={true} />
    </div>
    {hasRightBorder ? <RightBorder /> : <div />}
    {renderStatus(status)}
  </Container>
);

export default Evaluation;
