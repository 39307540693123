import { string, object } from 'yup';

const schema = object({
  email: string()
    .required('Email is required')
    .email('Email is not valid'),
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  branchId: string().nullable(),
  pin: string()
    .test('len', 'Pin must be exactly 4 characters', pin => (pin ? pin.length === 4 : true))
    .nullable(),
  license: string().nullable()
});

export default schema;
