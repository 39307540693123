import { transformAll } from './transform';
import schema from './schema';
import table from './table';
import cdlTestingOfStudentTable from './cdlTestingOfStudentTable';
import fields from './fields';

const name = 'cdlTesting';
const path = 'cdl_tests';

export default {
  restrict: false,
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  table,
  cdlTestingOfStudentTable,
  edit: {
    method: 'patch',
    path: `${path}/:id`
  },
  create: {
    method: 'POST',
    path: `${path}`
  },
  modal: {
    title: 'Create CDL Testings',
    fields,
    schema,
    load: {
      name: 'CDLTesting',
      path: path,
      params: { privileges: 1 }
    }
  },
  getStudentsByBranch: {
    name: 'studentsByBranch',
    path: `students`,
    defaultValue: []
  },
  getCdlTestingById: {
    name: 'getCdlTestingById',
    path: `cdl_tests/student/:id`,
    defaultValue: []
  }
};
