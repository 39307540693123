import React, { Component } from 'react';
import { Context } from 'store';
import * as Sentry from '@sentry/browser';

import Fallback from './Fallback';

class ErrorBoundary extends Component {
  state = { hasError: false };
  static contextType = Context;

  componentDidCatch(error) {
    const { getData } = this.context;

    Sentry.withScope(scope => {
      const { user, token } = getData(['user', 'token']);

      scope.setExtra('token', token);
      scope.setUser(user);
      Sentry.captureException(error);
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <Fallback /> : children;
  }
}

export default ErrorBoundary;
