export default [
  { name: 'name', label: 'Name', component: 'input' },
  {
    name: 'privileges',
    label: 'Privileges',
    component: 'select',
    mode: 'multiple',
    options: GLOBALS.GROUPED_PRIVILEGE_RESOURCES
  }
];
