import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useFetch } from 'store';

import services from '_src/services';
import logo from '_src/assets/images/logoTagline.png';
import { Refresh } from '_src/components';
import {
  PageContainer,
  Sidebar,
  Student,
  Container,
  CardContainer,
  Logo,
  Title,
  NoTimelineContainer,
  StudentsContainer,
  SidebarTitle,
  LogoContainer,
  NoDataContainer,
  ExpiredLinkText,
  LoadingContainer,
  TimezoneLabel
} from './styles';
import ReportCard from './ReportCard';

const config = services.automatedEmails;

export default ({ match }) => {
  const { token } = match.params;
  const [selectedStudent, setStudent] = useState();
  const [data, setData] = useState([]);
  const { get: getStudentProgress, busy: loading } = useFetch({
    ...config.getStudentProgress
  });
  const [linkExpired, setLinkExpired] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  useEffect(() => {
    let sidebar = document.querySelector('.ant-layout-sider');

    if (sidebar) {
      sidebar.style.display = 'none';
    }

    getStudentProgress({ replace: { token } }).then(res => {
      setLoadingData(false);
      if (res == null) {
        setLinkExpired(true);
      } else {
        if (res.length === 1) {
          setStudent(res[0]);
        }
        setData(res);
      }
    });
  }, []);

  if (loadingData) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }

  if (linkExpired && !loadingData) {
    return (
      <NoDataContainer>
        <Logo src={logo} />
        <ExpiredLinkText>
          The report you opened has expired, please check your email for an updated link.
          <br />
          To protect the privacy of our students and partners all report links are temporary.
          <br />
          Old reports expire each time a new report is sent to you.
        </ExpiredLinkText>
      </NoDataContainer>
    );
  }

  if (!selectedStudent && !loadingData) {
    return (
      <PageContainer>
        <NoTimelineContainer>
          <LogoContainer>
            <Logo src={logo} />
            <Title>Please, select a student below</Title>
            <TimezoneLabel>Session times are shown based on branch local time</TimezoneLabel>
          </LogoContainer>

          <StudentsContainer>
            {data.map(student => (
              <Student key={student.id} onClick={() => setStudent(student)}>
                {student.name}
              </Student>
            ))}
          </StudentsContainer>
        </NoTimelineContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Refresh />
      <Container>
        <Sidebar>
          <LogoContainer>
            <Logo style={{ marginBottom: 0 }} src={logo} />
          </LogoContainer>
          <SidebarTitle>Students</SidebarTitle>

          {data.map(student => (
            <Student key={student.id} onClick={() => setStudent(student)}>
              {student.name}
            </Student>
          ))}
        </Sidebar>

        {selectedStudent && (
          <CardContainer>
            <ReportCard student={selectedStudent}></ReportCard>
          </CardContainer>
        )}
      </Container>
    </PageContainer>
  );
};
