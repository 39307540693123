export default [
  { name: 'question', label: 'Question', component: 'input' },
  {
    name: 'locationType',
    label: 'Location Type',
    component: 'select',
    options: [{ id: 1, label: 'Classroom' }, { id: 2, label: 'Yard' }, { id: 3, label: 'Both' }]
  },
  {
    name: 'questionType',
    label: 'Type',
    component: 'select',
    options: [{ id: 1, label: 'Skills Evaluation' }, { id: 2, label: 'Student Characteristics' }]
  }
];
