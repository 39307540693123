import table from './table';

export default {
  table,
  getDriversReport: {
    path: 'driver_score/sections/scores'
  },
  downloadDocument: {
    path: 'afp_documents/download'
  }
};
