export default [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Region',
    dataIndex: 'region'
  }
];
