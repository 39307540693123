import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;

  ${({ iconOnly }) =>
    iconOnly &&
    `
    justify-content: center;
    padding-right: 0;
  `}
`;

const Title = styled.div`
  color: ${({ color }) => color};
  font-size: 14.5px;
  font-family: 'SF Display Bold';
`;

const Img = styled.img`
  width: 30px;

  ${({ iconOnly }) => !iconOnly && ` margin-left: 23px; width: 15px;`}
`;

const Date = styled.div`
  color: ${({ color }) => color};
  font-size: 14px;
  margin-left: 15px;
`;

export { Container, Title, Img, Date };
