import { string, object } from 'yup';

const loginSchema = object().shape({
  email: string().required('Email or username is required'),
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short.It should be at least 8 characters')
});

const licenseSchema = object().shape({
  pin: string().required('Pin is required')
});

const forgotPasswordSchema = object().shape({
  forgotEmail: string().required('Pin is required')
});

export default {
  default: loginSchema,
  license: licenseSchema,
  forgotPassword: forgotPasswordSchema
};
