import qs from 'qs';
import moment from 'moment';

import {
  transformAll,
  transformSponsors,
  transformLocations,
  transformDuration,
} from './transform';

const name = 'instructorReports';
const path = 'reports/attendance';

export default {
  restrict: false,
  title: 'Instructor Reports',
  location: {
    placeholder: 'Location',
    component: 'select',
    async: true,
    options: [{ id: 'all', label: 'All' }],
    get: {
      path: 'branches',
      transform: transformLocations,
      defaultValue: [],
      memoize: false
    }
  },
  sponsors: {
    component: 'select',
    async: true,
    get: {
      name: 'sponsors',
      path: 'students/sponsors',
      transform: transformSponsors,
      defaultValue: [],
      memoize: false
    },
    placeholder: 'Select a sponsor',
    options: [{ id: 'all', label: 'All' }]
  },
  get: {
    name,
    path: path,
    transform: transformAll,
    defaultValue: [],
    paramsSerializer: params => qs.stringify({ ...params }, { arrayFormat: 'comma' })
  },
  downloadEntries: {
    path: 'reports/entries'
  },
  downloadEvaluations: {
    path: 'reports/evaluations'
  },
  numberOfEvaluations: {
    path: 'reports/evaluations/instructor_student'
  },
  studentHours: {
    path: 'reports/student-hours'
  }
};
