import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 5px;
`;

const Value = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 0px 3px 11px;
  ${props => props.disabled && 'color: rgba(0, 0, 0, 0.25);'}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 53px;
  width: 100%;
`;

export default ({ value, label, format, disabled }) => (
  <Container>
    <Label>{label}</Label>
    <Value disabled={disabled}>{format ? format(value) : value}</Value>
  </Container>
);
