import React, { useState, useEffect, useContext, useRef } from 'react';
import { notification, Button, Popconfirm, Icon } from 'antd';
import { useFetch, useSave, useDelete, Context } from 'store';

import { PageContainer, PageTitle, PageSubtitle, CreateButton } from './styles';
import { Table, Modal, Fields } from '_src/components';
import { Form } from 'form';
import { Refresh } from '_src/components';
import services from '_src/services';
const resource = 'employersList';

export default () => {
  const { getShown, setShown } = useContext(Context);
  const config = services.employersList;
  const [loading, setLoading] = useState(false);
  const [allEmployers, setAllEmployers] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const { get: getData } = useFetch(config.get);
  const { save: createEmployerApi, busy: busyCreate } = useSave(config.create);
  const { save: updateEmployerApi, busy: busyUpdate } = useSave(config.update);
  const { destroy: deleteEmployerApi, busy: busyDelete } = useDelete(config.delete);
  const formApi = useRef();

  const mode = selectedRow?.id ? 'edit' : 'create';

  useEffect(() => {
    getEmployers();
  }, []);

  const getEmployers = async () => {
    setLoading(true);
    try {
      const employers = await getData();

      setAllEmployers(employers);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: err.message,
        duration: 3
      });
    }
  };

  const onError = (error, defaultErrorMessage) => {
    let message;
    const errorMessage = error?.message;
    if (typeof errorMessage === 'string') {
      message = errorMessage;
    } else if (errorMessage?.name) {
      message = errorMessage?.name?.[0];
    } else if (errorMessage?.emails) {
      const errorList = Object.values(errorMessage?.emails);
      message = errorList?.[0];
    } else {
      message = defaultErrorMessage;
    }

    notification.error({ message, duration: 5 });
  };

  const handleCreateEmployer = async payload => {
    try {
      const { success, data } = await createEmployerApi(payload);

      if (success === 1) {
        notification.success({
          message: `Employer created successfully`,
          duration: 5
        });
        getEmployers();
        setSelectedRow();
        closeModal();
      } else {
        throw data;
      }
    } catch (error) {
      onError(error, 'Failed to create employer');
    }
  };

  const handleEditEmployer = async payload => {
    try {
      const { success, data } = await updateEmployerApi(payload);

      if (success === 1) {
        notification.success({
          message: `Employer updated successfully`,
          duration: 5
        });
        getEmployers();
        setSelectedRow();
        closeModal();
      } else {
        throw data;
      }
    } catch (error) {
      onError(error, 'Failed to update employer');
    }
  };

  const handleDeleteEmployer = async payload => {
    try {
      const { success, data } = await deleteEmployerApi(payload);

      if (success === 1) {
        notification.success({
          message: `Employer deleted successfully`,
          duration: 5
        });
        getEmployers();
        setSelectedRow();
        closeModal();
      } else {
        throw data;
      }
    } catch (error) {
      onError(error, 'Failed to delete employer');
    }
  };

  const openCreateModal = () => {
    setSelectedRow();
    setShown(resource, true);
  };

  const closeModal = () => {
    setShown(resource, false);
  };

  const submitEmployer = () => {
    if (formApi.current.validate()) {
      const { id, name, emails } = formApi.current.values();
      if (mode === 'create') {
        handleCreateEmployer({ name, emails });
      } else {
        handleEditEmployer({ id, name, emails });
      }
    }
  };

  return (
    <PageContainer>
      <Refresh />
      <PageTitle>Employers List</PageTitle>

      <PageSubtitle>
        Check the checkboxes below to send Driver Score certificate to Employer’s email
      </PageSubtitle>

      <CreateButton type="primary" onClick={openCreateModal}>
        Create Employer
      </CreateButton>

      <Table
        columns={config.table}
        data={allEmployers}
        loading={loading}
        onRow={rowData => {
          return {
            onClick: () => {
              setSelectedRow(rowData);
              setShown(resource);
            }
          };
        }}
      />

      <Modal
        title={mode === 'create' ? 'Create Employer' : 'Edit Employer'}
        visible={getShown(resource)}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          mode === 'edit' && (
            <Popconfirm
              title="Are you sure？"
              okType="danger"
              okText="Delete"
              onConfirm={() => handleDeleteEmployer(selectedRow)}
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Button type="danger" loading={busyDelete}>
                Delete
              </Button>
            </Popconfirm>
          ),
          <Button type="primary" loading={busyUpdate || busyCreate} onClick={submitEmployer}>
            Save
          </Button>
        ]}
      >
        <Form
          formApi={api => (formApi.current = api)}
          initialValues={selectedRow}
          schema={config.modal.schema[mode]}
        >
          <Fields fields={config.modal.fields} />
        </Form>
      </Modal>
    </PageContainer>
  );
};
