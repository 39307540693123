import React from 'react';
import styled from 'styled-components';

import './styles.css';

const TableTitle = styled.span`
  white-space: nowrap;
  color: #878787;
  fixed: top;
`;

export default [
  {
    title: () => <TableTitle>LOCATION:</TableTitle>,
    dataIndex: 'date',
    className: 'cellTopAligned',
    render: (value, row) => {
      return (
        <>
          <b>{row.branchName}</b>
        </>
      );
    }
  },
  {
    title: () => <TableTitle>DATE:</TableTitle>,
    dataIndex: 'date',
    className: 'cellTopAligned',
    render: (value, row) => {
      return (
        <>
          <b>{value}</b>
          <p>{row.sessionType}</p>
        </>
      );
    }
  },
  {
    title: () => <TableTitle>TIME SESSIONS:</TableTitle>,
    dataIndex: 'timeSessions',
    className: 'cellTopAligned',
    render: value => {
      return value.map((sesion, index) => {
        if (sesion.startAt === '-' && sesion.endAt === '-') return;
        return (
          <div key={index} style={{ display: 'block' }}>
            <span>
              <b>{sesion.startAt}</b> to <b>{sesion.endAt}</b>
            </span>
          </div>
        );
      });
    }
  },
  {
    title: () => <TableTitle>ODOMETER:</TableTitle>,
    dataIndex: 'odometer',
    className: 'cellTopAligned',
    render: value => {
      if (value && value.length) {
        return value.map((odometer, index) => (
          <div key={index} style={{ display: 'block' }}>
            <span>
              <b>Session {index + 1}:&nbsp;</b>
              {odometer.start} to {odometer.end}
            </span>
            <span style={{ marginLeft: 10 }}>
              <b>SOS Sticker:&nbsp;</b>
              {odometer.sticker}
            </span>
          </div>
        ));
      }
      return <b>No recorded driving sessions.</b>;
    }
  },
  {
    title: () => <TableTitle>CLASS HOURS:</TableTitle>,
    dataIndex: 'class',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>ROAD HOURS:</TableTitle>,
    dataIndex: 'road',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>SKILLS HOURS:</TableTitle>,
    dataIndex: 'skills',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>PRE TRIP HOURS:</TableTitle>,
    dataIndex: 'preTrip',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>OBS ROAD HOURS:</TableTitle>,
    dataIndex: 'obsRoadHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>OBS SKILL HOURS:</TableTitle>,
    dataIndex: 'obsSkillHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>REMEDIAL HOURS:</TableTitle>,
    dataIndex: 'remedial',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  },
  {
    title: () => <TableTitle>SESSION TOTAL:</TableTitle>,
    dataIndex: 'totalHours',
    // dbFieldName: 'total_hours_trained',
    sorter: false,
    className: 'cursorPointer',
    render: value => {
      return <b>{value}</b>;
    }
  }
];
