import styled from 'styled-components';

import backgroundImage from '_src/assets/images/truck.jpg';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .ant-form-item {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  width: 45%;
  height: 100%;
  overflow-y: auto;
  z-index: 10;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 50px 0 50px;
}
`;

const Logo = styled.img`
  height: 100px;
  margin-bottom: 30px;
`;

const LicenseLogin = styled.div`
  color: #1890ff;
  padding-top: 2%;
  align-content: center;
  text-decoration: underline;
  :hover {
    color: #006699;
  }
  user-select: none;
  cursor: pointer;
`;

const ForgotPassword = styled.div`
  width: 100%;
  text-align: right;
  padding: 0;
  color: #1890ff;
  text-decoration: underline;
  :hover {
    color: #006699;
  }
  user-select: none;
  cursor: pointer;
`;

const ScanTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export { Container, FormContainer, Logo, LicenseLogin, ForgotPassword, ScanTitle };
