import styled from 'styled-components';

const ModalTitle = styled.div`
  font-size: 24px;
  color: rgb(55, 159, 255);
  font-weight: bolder;
  display: inline-block;
  vertical-align: middle;

  div {
    display: grid;
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    margin-left: 13px;
  }
`;

const HourDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  column-gap: 30px;
`;

const HourDetail = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  display: inline;
  font-weight: 500;

  span {
    font-weight: 300;
    min-height: 23px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
`;

export { ModalTitle, HourDetailContainer, HourDetail, Row };
