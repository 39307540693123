import schema from './schema';
import fields from './fields';

const name = 'student_evaluations';
const path = 'student_evaluations';
const save = {
  name: 'milage',
  path
};

export default {
  fields,
  schema,
  getTodayEvaluation: {
    name,
    path,
    transform: res => res.data.studentEvaluations
  },
  createMileage: save,
  updateMileage: { ...save, method: 'patch', path: `${path}/:id` }
};
