import { transformAll, transformBeforeSave } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'groups';
const path = 'groups';

const save = {
  name,
  path,
  transform: transformBeforeSave
};

export default {
  title: 'Groups',
  createButton: 'Create a new group',
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: [],
    params: { privileges: 1 }
  },
  create: save,
  edit: { ...save, method: 'patch', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save Group',
    fields,
    schema
  }
};
