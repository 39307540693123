import { string, object, ref, array, mixed } from 'yup';

const schema = object({
  username: string().required('Username is required'),
  email: string().required('Email is required').email('Email is not valid'),
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  branchId: string().nullable(),
  groupId: string().required('Group is required'),
  password: string().min(8, 'Password is too short.It should be at least 8 characters'),
  confirmPassword: mixed().test(
    'match',
    'Passwords do not match', // your error message
    function () {
      return this.parent.password === this.parent.confirmPassword;
    }
  ),
  privileges: array(),
  pin: string()
});

const createSchema = schema.shape({
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short.It should be at least 8 characters'),
  confirmPassword: mixed().test(
    'match',
    'Passwords do not match', // your error message
    function () {
      return this.parent.password === this.parent.confirmPassword;
    }
  ),
  pin: string()
    .required('Pin is required')
    .test('len', 'Pin must be exactly 4 characters', (pin = '') => pin.length === 4)
});

export default { create: createSchema, edit: schema };
