export default [
  { name: 'username', label: 'Username', component: 'input' },
  { name: 'email', label: 'Email', component: 'input' },
  { name: 'firstName', label: 'First Name', component: 'input' },
  { name: 'lastName', label: 'Last Name', component: 'input' },
  {
    name: 'branchId',
    label: 'Current Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    showSearch: true
  },
  {
    name: 'groupId',
    label: 'Group',
    component: 'select',
    async: true,
    resource: 'groups',
    labelKey: 'name',
    onChange: (value, formApi) => {
      const group = formApi.getField('groups')[value];
      const { privileges } = group;

      formApi.setField('privileges', privileges);
    }
  },
  {
    name: 'privileges',
    label: 'Privileges',
    component: 'select',
    mode: 'multiple',
    options: GLOBALS.GROUPED_PRIVILEGE_RESOURCES
  },
  {
    name: 'password',
    label: 'Password',
    component: 'input',
    type: 'password',
    autoComplete: 'new-password'
  },
  { name: 'confirmPassword', label: 'Confirm Password', component: 'input', type: 'password' },
  { name: 'pin', label: 'Pin', component: 'input', type: 'password' }
];
