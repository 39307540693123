import React from 'react';
import { Input, Icon } from 'antd';
import moment from 'moment';

import config from './index';
import { FullRow } from '../../modules/DrivingScore/styles';
import { Button } from '_src/ui';

const baseApi = GLOBALS.BASE_API;
let searchInput;

const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <FullRow>
        <Button
          filled={true}
          onClick={() => confirm()}
          color="#1890ff"
          text="Search"
          height={30}
          width={80}
        />
        <Button
          filled={false}
          onClick={() => clearFilters()}
          color="#1890ff"
          text="Reset"
          height={30}
          width={80}
        />
      </FullRow>
    </div>
  ),
  filterIcon: filtered => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  }
});

export default [
  {
    title: 'Name',
    dataIndex: 'name',
    ...getColumnSearchProps('name')
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    ...getColumnSearchProps('email')
  },
  {
    title: 'Driver Code',
    dataIndex: 'code',
    ...getColumnSearchProps('code')
  },
  {
    title: 'Employer',
    dataIndex: 'employer',
    ...getColumnSearchProps('employer')
  },
  {
    title: 'Address',
    dataIndex: 'address',
    ...getColumnSearchProps('address')
  },
  {
    title: 'Certificate',
    dataIndex: 'certificate',
    key: 'certificate',
    render: (certificate, record) => (
      <div key={record.id}>
        <dl>
          {certificate.map(({ token, testingDate }) => (
            <dd key={token}>
              <a target="_blank" href={baseApi + '/' + config.downloadPdf.path + token}>
                {moment(testingDate).format('MM/DD/YYYY')} - View certificate
              </a>
            </dd>
          ))}
        </dl>
      </div>
    )
  }
];
