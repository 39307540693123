import { string, object, array } from 'yup';

const schema = object().shape({
  name: string().min(3, 'Name must be at least 3 characters').required('Name is required'),
  emails: array()
    .of(string().email(({ value }) => `${value} is not a valid email`))
    .nullable()
});

export default { create: schema, edit: schema };
