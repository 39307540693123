import React, { useRef } from 'react';
import { Button, Icon } from 'antd';

import { PersistentRepo } from '_src/shared/repo';

export default ({
  value = [],
  label = 'Add new attachments',
  onFilePicked,
  imageUrl = '',
  type = '',
  toBase64 = true,
  buttonStyles,
  loading,
  onChange = () => {}
}) => {
  const filePicker = useRef(null);
  const token = PersistentRepo.get('token');

  return (
    <>
      {type === 'excel' && (
        <>
          <div>{label}</div>
          <Button onClick={() => filePicker.current.click()} style={buttonStyles} loading={loading}>
            {loading ? (
              <></>
            ) : (
              <>
                <Icon type="file-image" style={{ margin: '0 5px 0 0' }} />
                Upload new file
              </>
            )}
          </Button>
          <input
            ref={filePicker}
            type="file"
            accept={'.xlsx'}
            style={{ display: 'none' }}
            onChange={e => {
              if (!toBase64) {
                onChange(e);
              } else {
                const ext = (e.target.value || []).match(/\.([^\.]+)$/)[1];
                if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg') {
                  return;
                }
                const { files } = e.target;
                const file = files.length > 0 ? files[0] : undefined;
                const reader = new FileReader();
                reader.onload = res => {
                  onChange(res.target.result);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </>
      )}

      {type !== 'excel' && (
        <>
          <div>{label}</div>

          {value.map((image, i) => (
            <div key={i} style={{ margin: '10px 0', fontSize: '13px' }}>
              <Icon type="file-image" style={{ margin: '0 5px 0 0' }} />
              <a target="_blank" href={`${GLOBALS.BASE_API}/${imageUrl}/${image}/${token}`}>
                {image}
              </a>
            </div>
          ))}

          <Button onClick={() => filePicker.current.click()}>Add new attachment</Button>
          <input
            ref={filePicker}
            type="file"
            accept=".png,.jpg,.pdf"
            style={{ display: 'none' }}
            onChange={e => {
              const { files } = e.target;
              const file = files.length > 0 ? files[0] : undefined;
              var reader = new FileReader();
              reader.onload = e => {
                onFilePicked(e.target.result.split(',')[1]);
              };
              reader.readAsDataURL(file);
            }}
          />
        </>
      )}
    </>
  );
};
