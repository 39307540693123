const transformAll = ({ data }) => {
  const total = data.students?.total;

  if (data.students?.cdlTests?.length)
    data = data.students?.cdlTests?.map(({ ...rest }) => ({
      ...rest
    }));

  return { data, total };
};

export { transformAll };
