import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context, useSave, useFetch, useGet } from 'store';
import { Card, notification, Button as AntButton } from 'antd';
import moment from 'moment';

import { Override, StudentIntervention } from '_src/modules/Attendance/components';
import { Table, Modal } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select } from '_src/shared/form-helpers';
import { Button } from '_src/ui';
import { Refresh } from '_src/components';
import StudentTimeline from '_src/modules/Students/components/Timeline/StudentTimeline';
import {
  ButtonContainer,
  Row,
  StudentsContainer,
  StudentDetail,
  HistoricalDataContainer,
  HistoricalRecord
} from './styles';

var timezoneParam = moment().utcOffset() / 60;

if (timezoneParam > 0) {
  timezoneParam = 'GMT ' + timezoneParam;
} else if (timezoneParam < 0) {
  timezoneParam = 'GMT' + timezoneParam;
} else if (timezoneParam === 0) {
  timezoneParam = 'GMT 0';
}
const buttonColor = GLOBALS.colors;
const resource = 'bulkYardManagement';
const attendanceConfig = services.attendance;
const config = services[resource];

export default () => {
  const { getData, getShown, setShown, setData, refreshResource } = useContext(Context);
  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };
  const [currentLocation, setCurrentLocation] = useState(getInitialLocation());
  const [destinationYard, setDestinationYard] = useState(0);
  const [allYards, setAllYards] = useState([]);
  const [sponsor, setSponsor] = useState('all');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedGroup, setGroup] = useState('all');
  const [studentOverride, setStudentOverride] = useState();
  const [date, setDate] = useState(moment());
  const { save: updateLocation, busy: busyUpdate } = useSave(config.update);

  const { get: getLocations } = useFetch({ ...attendanceConfig.location.get });

  useEffect(() => {
    getLocations().then(data => {
      setData('locations', data);
      const yards = data.filter(item => item.id.startsWith('yard'));
      setAllYards(yards);

      const locationId = PersistentRepo.get('location');
      const defaultBranchId = locationId ? +locationId.split('x')[2] : 1;

      setCurrentLocation(yards.find(item => item.branchId === defaultBranchId)?.id || yards[0]?.id);
      setDestinationYard(yards.find(item => item.branchId === defaultBranchId)?.id || yards[0]?.id);
    });
  }, []);

  const getAttendanceParams = () => {
    const [locationType, locationId, branchId] = currentLocation.split('x');

    let attendanceParams = {
      branchId: branchId
    };

    return attendanceParams;
  };

  const { data, busy } = useGet({
    ...config.get,
    params: getAttendanceParams()
  });

  const onCurrentLocationChange = loc => {
    setCurrentLocation(loc);
    setDestinationYard(
      allYards.find(item => item.branchId === +loc.split('x')[2])?.id || yards[0]?.id
    );
    PersistentRepo.set('location', loc);
  };

  const onYardChange = loc => {
    setDestinationYard(loc);
  };

  const closeModal = () => {
    setShown('confirmMoveToYard', false);
  };

  const submitMoveToYard = () => {
    const [locationType, locationId, branchId] = destinationYard.split('x');
    let dataToSubmit = {
      studentIds: selectedStudents.map(student => student.id),
      branchId: +branchId
    };

    updateLocation(dataToSubmit).then(({ success }) => {
      if (success === 1) {
        refreshResource('bulkYardManagement');
        setSelectedRowKeys([]);
        setSelectedStudents([]);
        closeModal();

        const students = selectedStudents.map(student => student.studentName).join(', ');
        const newYard = allYards.find(item => item.branchId === +destinationYard.split('x')[2])
          ?.label;

        notification.success({
          message: `${students} have been moved to ${newYard}`,
          duration: 5
        });
      } else {
        notification.error({
          message: 'Failed to move students!',
          duration: 5
        });
      }
    });
  };

  config.table.find(({ dataIndex }) => dataIndex === 'studentName').onCell = student => ({
    onClick: () => {
      const [locationType, locationId, branchId] = currentLocation.split('x');
      setSelectedRowKeys([]);
      setSelectedStudents([]);

      setData('studentTimeline', {
        ...student,
        name: `${student.studentName}`,
        branchId: student.branchName,
        studentBranch: +branchId,
        studentId: student.id
      });
      setShown('studentTimeline');
    }
  });

  return (
    <PageContainer>
      <Refresh />
      <Card title="Student Yard Location">
        <Row>
          <Select
            showSearch
            onChange={onCurrentLocationChange}
            label="Filter by Yard"
            placeholder="Select yard"
            options={allYards}
            value={currentLocation}
            showArrow={false}
            style={{ width: '250px' }}
          />

          <ButtonContainer>
            <Button
              filled={true}
              color={buttonColor.ORANGE}
              text="Move to New Yard"
              height={30}
              width={150}
              disabled={!selectedRowKeys.length}
              onClick={() => setShown('confirmMoveToYard')}
              alignSelf="flex-end"
            />
          </ButtonContainer>
        </Row>

        <Table
          columns={[
            ...config.table,
            {
              title: 'Historical data',
              dataIndex: 'historicalYardMovement',
              render: data => (
                <HistoricalDataContainer>
                  {data.map(record => (
                    <HistoricalRecord key={record.id}>
                      Student was moved to yard <b>{record.branchName}</b> by{' '}
                      <b>{record.instructorName}</b> on{' '}
                      <b>{moment(record.movedDate).format('MM/DD/YYYY hh:mm')}</b>
                    </HistoricalRecord>
                  ))}
                </HistoricalDataContainer>
              )
            }
          ]}
          data={data}
          loading={busy}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              const validRows = selectedRows.filter(row => !row.yardSubmittedToFmsca);
              setSelectedRowKeys(validRows.map(row => row.key));
              setSelectedStudents(validRows);
            },
            onSelect: (record, selectedValue, selectedRows, nativeEvent) => {
              if (record.yardSubmittedToFmsca) {
                notification.warning({
                  message: `${record.studentName} can not be moved to another yard as they are already submitted to road.`,
                  duration: 5
                });
                return;
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              const validRows = selectedRows.filter(row => !row.yardSubmittedToFmsca);
              const notAbleToMove = selectedRows.filter(row => row.yardSubmittedToFmsca);
              const showError =
                (validRows.length !== selectedStudents.length && notAbleToMove.length > 0) ||
                (validRows.length === 0 && selectedStudents.length === 0);

              if (showError) {
                const names = notAbleToMove.map(row => row.studentName).join(', ');
                notification.warning({
                  message: `${names} cannot be moved to another yard as they are already submitted to road.`,
                  duration: 5
                });
              }

              if (validRows.length === selectedStudents.length) {
                setSelectedRowKeys([]);
                setSelectedStudents([]);
              } else {
                setSelectedRowKeys(validRows.map(row => row.key));
                setSelectedStudents(validRows);
              }
            }
          }}
        />

        <Modal
          title="Moving students to yard"
          visible={getShown('confirmMoveToYard')}
          footer={[
            <AntButton key="submitClose" type="default" onClick={closeModal}>
              Cancel
            </AntButton>,
            <AntButton
              key="move"
              type="primary"
              loading={busyUpdate}
              onClick={submitMoveToYard}
              disabled={!destinationYard}
            >
              Move
            </AntButton>
          ]}
        >
          <Select
            showSearch
            onChange={onYardChange}
            label="Select destination yard"
            placeholder="Select yard"
            options={allYards}
            value={destinationYard}
            showArrow={false}
            style={{ width: '250px' }}
          />
          <StudentsContainer>
            {selectedStudents.map(student => (
              <StudentDetail>{student.studentName}</StudentDetail>
            ))}
          </StudentsContainer>
        </Modal>

        {getShown('override') && (
          <Override student={studentOverride} onModalClose={() => setStudentOverride(null)} />
        )}

        {getShown('studentTimeline') && <StudentTimeline rerenderEvaluations={() => {}} />}

        <Modal width={'70%'} visible={getShown('studentIntervention')} footer={[]}>
          <StudentIntervention />
        </Modal>
      </Card>
    </PageContainer>
  );
};
