import moment from 'moment';
import { stripNullObj } from 'utils';
const fmt = 'HH:mm';

const transformLocations = ([branchesRes, yardsRes, classroomsRes, locationsRes]) => {
  const branchLocations = locationsRes.data?.branchLocations || [];

  const classroomToBranch =
    (classroomsRes.data?.classrooms || []).reduce(
      (acc, { branchId, id }) => ({ ...acc, [branchId]: id }),
      {}
    ) || [];

  const yardToBranch =
    yardsRes.data?.yards.reduce((acc, { branchId, id }) => ({ ...acc, [branchId]: id }), {}) || [];

  const classrooms = branchLocations.reduce((acc, { branchId, classroomId }) => {
    if (classroomId === classroomToBranch[branchId]) {
      return { ...acc, [branchId]: `classroomx${classroomId}x${branchId}` };
    }

    return acc;
  }, {});

  const yards = branchLocations.reduce((acc, { branchId, yardId }) => {
    if (yardId === yardToBranch[branchId]) {
      return { ...acc, [branchId]: `yardx${yardId}x${branchId}` };
    }

    return acc;
  }, {});

  return branchesRes.data?.branches
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, { id, name, timezone }) => {
      let items = [
        {
          id: `allxallx${id}`,
          label: `${name} Branch`,
          type: 0,
          branchId: id,
          branchLocation: branchLocations.find(({ branchId }) => branchId === id),
          timezone
        }
      ];

      if (yards[id]) {
        items.push({
          id: `${yards[id]}`,
          label: `${name} Yard`,
          type: 1,
          branchId: id,
          timezone
        });
      }

      if (classrooms[id]) {
        items.push({
          id: `${classrooms[id]}`,
          label: `${name} Classroom`,
          type: 2,
          branchId: id,
          timezone
        });
      }

      return [...acc, ...items];
    }, []);
};

const transformYards = ([branchesRes, yardsRes, locationsRes]) => {
  const branchLocations = locationsRes.data?.branchLocations || [];

  const yardToBranch =
    yardsRes.data?.yards.reduce((acc, { branchId, id }) => ({ ...acc, [branchId]: id }), {}) || [];

  const yards = branchLocations.reduce((acc, { branchId, yardId }) => {
    if (yardId === yardToBranch[branchId]) {
      return { ...acc, [branchId]: `yardx${yardId}x${branchId}` };
    }

    return acc;
  }, {});

  return branchesRes.data?.branches
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, { id, name, timezone }) => {
      let items = [];

      if (yards[id]) {
        items.push({
          id: `${yards[id]}`,
          label: `${name} Yard`,
          type: 1,
          branchId: id,
          timezone
        });
      }
      return [...acc, ...items];
    }, []);
};

const transformAll = ([attendaceRes, resUnfilledStudentHours]) => {
  if (
    attendaceRes.code === 500 ||
    attendaceRes.code === 400 ||
    resUnfilledStudentHours.code === 500 ||
    resUnfilledStudentHours.code === 400
  ) {
    return [];
  }
  const resUnfilledStudents = resUnfilledStudentHours.data?.students || [];

  const allResults = (attendaceRes.data?.students || []).map(t1 => ({
    ...t1,
    ...resUnfilledStudents.find(t2 => t2?.studentId === t1.id)
  }));

  const students = allResults
    ? allResults.map(student => {
        const {
          id,
          firstName,
          lastName,
          licenseId,
          licenseIdPdf417,
          branchId,
          locationType,
          totalHoursTrained = 0,
          numberOfOpenSessions = 0,
          performance = {},
          attendance: attendances = [],
          sponsor = '',
          dcRegistration,
          dcRegistrationRequestDate,
          programHours,
          preferredNickname,
          archived,
          max,
          count,
          programType,
          classSubmittedToFmsca,
          permitRecDate,
          email,
          gender,
          birthdate,
          phone,
          contactNumber,
          status,
          license,
          branch,
          automaticGear,
          yardStartDate,
          hazmatSubmittedToFmcsa,
          hazmatStartDate,
          endorsement,
          endorsementCode,
          yardSubmittedToFmsca,
          classOnly,
          yardOnly
        } = stripNullObj(student);

        const lastAttendance = attendances[attendances.length - 1];
        const hasAttended = attendances.some(({ checkIn }) => !!checkIn);
        const absent = attendances.find(({ checkIn, checkOut }) => !checkIn && !checkOut);

        return {
          id,
          studentId: id,
          name: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
          licenseId,
          licenseIdPdf417,
          branchId,
          locationType,
          totalHoursTrained: Math.round(totalHoursTrained * 10) / 10,
          numberOfOpenSessions,
          performance,
          attendances,
          attendance: !lastAttendance || lastAttendance.checkOut ? {} : lastAttendance,
          attendanceStatus: lastAttendance ? getStatus(lastAttendance) : 'No attendance',
          hasAttended,
          absent: absent ? { isExcused: absent?.excusedAbsence || false } : false,
          sponsor,
          dcRegistration,
          dcRegistrationRequestDate,
          programHours: programHours ? programHours : '160',
          archived,
          unfilledHoursBreakdownDate: max,
          unfilledHoursBreakdownCount: count,
          programType,
          classSubmittedToFmsca,
          permitRecDate,
          email,
          gender,
          birthdate,
          phone,
          contactNumber,
          status,
          license,
          branch,
          automaticGear,
          yardStartDate,
          hazmatSubmittedToFmcsa,
          hazmatStartDate,
          endorsement,
          endorsementCode,
          yardSubmittedToFmsca,
          classOnly,
          yardOnly
        };
      })
    : [];

  return students
    .filter(({ locationType, permitRecDate, status, yardStartDate }) =>
      status != 'Hold'
        ? (locationType === 1 && !!yardStartDate) || locationType === 2
        : (locationType === 1 && !!yardStartDate) || locationType === 2
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

const transformOverride = res =>
  res.success
    ? res.data.attendance
        .sort((a, b) => {
          const aCheckIn = moment(a.checkIn);
          const bCheckIn = moment(b.checkIn);

          return aCheckIn.isBefore(bCheckIn) ? -1 : 1;
        })

        .map(session => ({
          ...session,
          checkIn: session.checkIn ? moment.parseZone(session.checkIn) : null,
          checkOut: session.checkOut ? moment.parseZone(session.checkOut) : null,
          isAbsent: !session.checkIn && !session.checkOut
        }))
    : [];

const getStatus = attendance =>
  attendance.checkOut ? 'Checked out' : attendance.checkIn ? 'Present' : 'Absent';

const transformSingleAttendance = ({ data = [] }) => {
  const { checkIn } = data?.attendance[0] || [];
  return {
    checkIn
  };
};

const transformTodaysHours = ({ data = {} }) => {
  const { attendance = [], evaluation = [], studentRecordHours } = data.students || {};

  const attendanceDate = attendance[0]?.attendanceDate || null;

  const sessions = attendance.map(attend => {
    return {
      checkIn: attend.checkIn ? moment.parseZone(attend.checkIn).format(fmt) : '-',
      checkOut: attend.checkOut ? moment.parseZone(attend.checkOut).format(fmt) : '-'
    };
  });

  let sessionsTotal = 0;
  attendance.map(attend => {
    if (attend.checkIn && attend.checkOut) {
      var duration = moment.duration(moment(attend.checkOut).diff(moment(attend.checkIn)));
      sessionsTotal += duration.asHours();
    }
  });

  const odometer = evaluation.flatMap(evaluation => {
    return (evaluation.details.trucks || []).map(truck => {
      return {
        start: truck.odometerStart,
        end: truck.odometerEnd,
        sticker: truck.sticker
      };
    });
  });

  return {
    attendanceDate,
    sessions,
    locationType: attendance[0]?.locationType,
    odometer,
    recordHours: studentRecordHours,
    sessionsTotal
  };
};

export {
  transformLocations,
  transformAll,
  transformOverride,
  transformSingleAttendance,
  transformTodaysHours,
  transformYards
};
