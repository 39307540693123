export default [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    // sorter: (a, b) => {
    //   const aName = `${a.lastName} ${a.firstName}`;
    //   const bName = `${b.lastName} ${b.firstName}`;

    //   return aName.localeCompare(bName);
    // },
    renderExport: (_, { lastName, firstName }) => `${lastName} ${firstName}`
  },
  {
    title: 'Branch',
    dataIndex: 'branchName',
    sorter: true
    // sorter: (a, b) => a.branchName.localeCompare(b.branchName)
  },
  {
    title: 'Location',
    dataIndex: 'locationType',
    render: locationType => (locationType === 1 ? 'Yard' : 'Classroom')
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor'
  },
  {
    title: 'Total hours in classroom',
    dataIndex: 'totalHoursTrainedClassroom',
    filters: [
      { text: 'More than 160', value: 160 },
      { text: 'Less than 160', value: -160 },
      { text: 'More than 40', value: 40 },
      { text: 'Less than 40', value: -40 }
    ],
    onFilter: (value, { totalHoursTrainedClassroom }) =>
      value > 0 ? totalHoursTrainedClassroom > value : totalHoursTrainedClassroom < -value,
    render: totalHoursTrainedClassroom => totalHoursTrainedClassroom.toFixed(0)
  },
  {
    title: 'Total hours in yard',
    dataIndex: 'totalHoursTrainedYard',
    filters: [
      { text: 'More than 160', value: 160 },
      { text: 'Less than 160', value: -160 },
      { text: 'More than 40', value: 40 },
      { text: 'Less than 40', value: -40 }
    ],
    onFilter: (value, { totalHoursTrainedYard }) =>
      value > 0 ? totalHoursTrainedYard > value : totalHoursTrainedYard < -value,
    render: totalHoursTrainedYard => totalHoursTrainedYard.toFixed(0)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filterType: false,
    sorter: true
    // sorter: (a, b) => a.status.localeCompare(b.status)
  },
  {
    title: 'Pretrips scores',
    dataIndex: 'pretripsScore'
  },
  {
    title: 'Skills scores',
    dataIndex: 'skillsScore'
  },
  {
    title: 'Road scores',
    dataIndex: 'roadsScore'
  },
  {
    title: 'Absences',
    dataIndex: 'absences',
    sorter: true
    // sorter: (a, b) => (a.absences > b.absences ? -1 : 1)
  }
];
