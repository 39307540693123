import React, { useContext } from 'react';
import { Context } from 'store';

const Location = ({ branchId, locationType }) => {
  const { getData } = useContext(Context);

  const locations = getData('locations');
  const location = (locations || []).find(
    loc => branchId === loc.branchId && locationType === loc.type
  );

  return location ? location.label : '';
};

export default [
  { title: 'First Name', dataIndex: 'firstName', sorter: true },
  { title: 'Last Name', dataIndex: 'lastName', sorter: true },
  { title: 'Intervention Date', dataIndex: 'submitDate', sorter: true },
  {
    title: 'Branch Name',
    sorter: true,
    dataIndex: 'branchId',
    render: (_, { branchId, locationType }) => (
      <Location branchId={branchId} locationType={locationType} />
    )
  }
];
