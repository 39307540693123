import React from 'react';
import { Field } from 'form';
import styled from 'styled-components';
import {
  Input,
  Select,
  Textarea,
  Radio,
  InputNumber,
  Datepicker,
  Camera,
  ProfilePicture,
  GoogleAutocomplete,
  Checkbox,
  Filepicker,
  SignaturePad,
  ViewOnly,
  Timepicker
} from '_src/shared/form-helpers';

const FieldContainer = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '15px')};
  width: 100%;
`;

export default ({ fields, namePrefix = '' }) => {
  const defaulComponent = () => <span>wooot</span>;
  const typeToComponent = {
    input: Input,
    select: Select,
    textarea: Textarea,
    radio: Radio,
    inputNumber: InputNumber,
    checkbox: Checkbox,
    filepicker: Filepicker,
    signaturePad: SignaturePad,
    date: Datepicker,
    viewOnly: ViewOnly,
    profilePicture: ProfilePicture,
    googleAutocomplete: GoogleAutocomplete,
    timepicker: Timepicker
  };
  return (
    <>
      {fields.map(({ component, noMargin = false, name, ...props }) => (
        <FieldContainer key={name} noMargin={noMargin}>
          <Field
            {...props}
            dataCy={'field-' + component + '-' + name}
            name={`${namePrefix}${name}`}
            render={typeToComponent[component] || defaulComponent}
          />
        </FieldContainer>
      ))}
    </>
  );
};
