import { string, object, number } from 'yup';

const schema = object({
  firstName: string().required().max(50, 'First name should not be longer than 50 chars.'),
  lastName: string().required().max(50, 'Last name should not be longer than 50 chars.'),
  completionDate: string().required(),
  studentNumber: string().required().max(15, 'Last name should not be longer than 15 chars.'),
  location: string().required(),
  completionDate: string().required(),
  birthdate: string().required(),
  status: string().required(),
  contactNumber: string().notRequired(),
  branch: number().notRequired(),
  endorsementCode: string().required(),
  road: number()
    .nullable()
    .when('location', {
      is: (val = '') => val === 'Yard',
      then: number()
        .moreThan(0, 'You can’t enter 0 hours.')
        .lessThan(101, 'Road should be max 100')
        .typeError('Road must be number')
        .required()
    }),
  skills: number()
    .nullable()
    .when('location', {
      is: (val = '') => val === 'Yard',
      then: number()
        .moreThan(0, 'You can’t enter 0 hours.')
        .lessThan(101, 'Skills should be max 100')
        .typeError('Skills must be number')
        .required()
    }),
  score: number()
    .nullable()
    .when('location', {
      is: (val = '') => val === 'Classroom',
      then: number()
        .moreThan(0, 'You can’t enter 0 hours.”')
        .lessThan(101, 'Classroom score should be max 100')
        .typeError('Classroom score must be number')
        .required()
    }),
  licenseState: string().required()
});

export default { create: schema, edit: schema };
