export default [
  {
    state: 'AL',
    rule: '^[0-9]{1,8}$',
    description: ['1-8 Numeric']
  },
  {
    state: 'AK',
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numbers']
  },
  {
    state: 'AZ',
    rule: '(^[A-Z]{1}[0-9]{1,8}$)|(^[A-Z]{2}[0-9]{2,5}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 1-8 Numeric', '2 Alpha + 2-5 Numeric', '9 Numeric']
  },
  {
    state: 'AR',
    rule: '^[0-9]{4,9}$',
    description: ['4-9 Numeric']
  },
  {
    state: 'CA',
    rule: '^[A-Z]{1}[0-9]{7}$',
    description: ['1 Alpha + 7 Numeric']
  },
  {
    state: 'CO',
    rule: '(^[0-9]{9}$)|(^[A-Z]{1}[0-9]{3,6}$)|(^[A-Z]{2}[0-9]{2,5}$)',
    description: ['9 Numeric', '1 Alpha + 3-6 Numeric', '2 Alpha + 2-5 Numeric']
  },
  {
    state: 'CT',
    rule: '^[0-9]{9}$',
    description: ['9 Numeric']
  },
  {
    state: 'DE',
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numeric']
  },
  {
    state: 'DC',
    rule: '(^[0-9]{7}$)|(^[0-9]{9}$)',
    description: ['7 Numeric', '9 Numeric']
  },
  {
    state: 'FL',
    rule: '^[A-Z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric']
  },
  {
    state: 'GA',
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric']
  },
  {
    state: 'GU',
    rule: '^[A-Z]{1}[0-9]{14}$',
    description: ['1 Alpha + 14 Numeric']
  },
  {
    state: 'HI',
    rule: '(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '9 Numeric']
  },
  {
    state: 'ID',
    rule: '(^[A-Z]{2}[0-9]{6}[A-Z]{1}$)|(^[0-9]{9}$)',
    description: ['2 Alpha + 6 Numeric + 1 Alpha', '9 Numeric']
  },
  {
    state: 'IL',
    rule: '^[A-Z]{1}[0-9]{11,12}$',
    description: ['1 Alpha + 11-12 Numeric']
  },
  {
    state: 'IN',
    rule: '(^[A-Z]{1}[0-9]{9}$)|(^[0-9]{9,10}$)',
    description: ['1 Alpha + 9 Numeric', '9-10 Numeric']
  },
  {
    state: 'IA',
    rule: '^([0-9]{9}$)|([0-9]{3}[A-Z]{2}[0-9]{4})$',
    description: ['9 Numeric', '3 Numeric + 2 Alpha + 4 Numeric']
  },
  {
    state: 'KS',
    rule: '(^([A-Z]{1}[0-9]{1}){2}[A-Z]{1}$)|(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 1 Numeric + 1 Alpha + 1 Numeric + 1 Alpha',
      '1 Alpha + 8 Numeric',
      '9 Numeric'
    ]
  },
  {
    state: 'KY',
    rule: '(^[A-Z]{1}[0-9]{8,9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8-9 Numeric', '9 Numeric']
  },
  {
    state: 'LA',
    rule: '^[0-9]{1,9}$',
    description: ['1-9 Numeric']
  },
  {
    state: 'ME',
    rule: '(^[0-9]{7,8}$)|(^[0-9]{7}[A-Z]{1}$)',
    description: ['7-8 Numeric', '7 Numeric + 1 Alpha']
  },
  {
    state: 'MD',
    rule: '^[A-Z]{1}[0-9]{12}$',
    description: ['1Alpha+12Numeric']
  },
  {
    state: 'MA',
    rule: '(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '9 Numeric']
  },
  {
    state: 'MI',
    rule: '(^[A-Z]{1}[0-9]{10}$)|(^[A-Z]{1}[0-9]{12}$)',
    description: ['1 Alpha + 10 Numeric', '1 Alpha + 12 Numeric']
  },
  {
    state: 'MN',
    rule: '^[A-Z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric']
  },
  {
    state: 'MS',
    rule: '^[0-9]{9}$',
    description: ['9 Numeric']
  },
  {
    state: 'MO',
    rule:
      '(^[A-Z]{1}[0-9]{5,9}$)|(^[A-Z]{1}[0-9]{6}[R]{1}$)|(^[0-9]{8}[A-Z]{2}$)|(^[0-9]{9}[A-Z]{1}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 5-9 Numeric',
      "1 Alpha + 6 Numeric + 'R'",
      '8 Numeric + 2 Alpha',
      '9 Numeric + 1 Alpha',
      '9 Numeric'
    ]
  },
  {
    state: 'MT',
    rule: '(^[A-Z]{1}[0-9]{8}$)|(^[0-9]{13}$)|(^[0-9]{9}$)|(^[0-9]{14}$)',
    description: ['1 Alpha + 8 Numeric', '13 Numeric', '9 Numeric', '14 Numeric']
  },
  {
    state: 'NE',
    rule: '(^[0-9]{1,7}$)|(^[A-Z]{1}[0-9]{6,8}$)',
    description: ['1-7 Numeric', '1Alpha+6-8Numeric']
  },
  {
    state: 'NV',
    rule: '(^[0-9]{9,10}$)|(^[0-9]{12}$)|(^[X]{1}[0-9]{8}$)',
    description: ['9 Numeric', '10 Numeric', '12 Numeric', "'X' + 8 Numeric"]
  },
  {
    state: 'NH',
    rule: '(^[0-9]{2}[A-Z]{3}[0-9]{5}$)|(^[A-Z]{3}[0-9]{8}$)',
    description: ['2 Numeric + 3 Alpha + 5 Numeric']
  },
  {
    state: 'NJ',
    rule: '^[A-Z]{1}[0-9]{14}$',
    description: ['1 Alpha + 14 Numeric']
  },
  {
    state: 'NM',
    rule: '^[0-9]{8,9}$',
    description: ['8 Numeric', '9 Numeric']
  },
  {
    state: 'NY',
    rule:
      '(^[A-Z]{1}[0-9]{7}$)|(^[A-Z]{1}[0-9]{18}$)|(^[0-9]{8}$)|(^[0-9]{9}$)|(^[0-9]{16}$)|(^[A-Z]{8}$)',
    description: [
      '1 Alpha + 7 Numeric',
      '1 Alpha + 18 Numeric',
      '8 Numeric',
      '9 Numeric',
      '16 Numeric',
      '8 Alpha'
    ]
  },
  {
    state: 'NC',
    rule: '^[0-9]{1,12}$',
    description: ['1-12 Numeric']
  },
  {
    state: 'ND',
    rule: '(^[A-Z]{3}[0-9]{6}$)|(^[0-9]{9}$)',
    description: ['3 Alpha + 6 Numeric', '9 Numeric']
  },
  {
    state: 'OH',
    rule: '(^[A-Z]{1}[0-9]{4,8}$)|(^[A-Z]{2}[0-9]{3,7}$)|(^[0-9]{8}$)',
    description: ['1 Alpha + 4-8 Numeric', '2 Alpha + 3-7 Numeric', '8 Numeric']
  },
  {
    state: 'OK',
    rule: '(^[A-Z]{1}[0-9]{9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 9 Numeric', '9 Numeric']
  },
  {
    state: 'OR',
    rule: '(^[0-9]{1,9}$)|(^A[0-9]{6}$)',
    description: ['1-9 Numeric', 'A+6 Numeric']
  },
  {
    state: 'PA',
    rule: '^[0-9]{8}$',
    description: ['8 Numeric']
  },
  {
    state: 'PR',
    rule: '(^[0-9]{9}$)|(^[0-9]{5,7}$)',
    description: ['5-7 Numeric', '9 Numeric']
  },
  {
    state: 'RI',
    rule: '^([0-9]{7,8}$)|(^[A-Z]{1}[0-9]{6}$)',
    description: ['7-8 Numeric', '1 Alpha + 6 Numeric']
  },
  {
    state: 'SC',
    rule: '^[0-9]{5,11}$',
    description: ['5-11 Numeric']
  },
  {
    state: 'SD',
    rule: '(^[0-9]{6,10}$)|(^[0-9]{12}$)',
    description: ['6-10 Numeric', '12 Numeric']
  },
  {
    state: 'TN',
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric']
  },
  {
    state: 'TX',
    rule: '^[0-9]{7,8}$',
    description: ['7-8 Numeric']
  },
  {
    state: 'UT',
    rule: '^[0-9]{4,10}$',
    description: ['4-10 Numeric']
  },
  {
    state: 'VT',
    rule: '(^[0-9]{8}$)|(^[0-9]{7}[A]$)',
    description: ['8 Numeric', "7 Numeric + 'A'"]
  },
  {
    state: 'VA',
    rule: '(^[A-Z]{1}[0-9]{8,11}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 8 Numeric',
      '1 Alpha + 9 Numeric',
      '1 Alpha + 10 Numeric',
      '1 Alpha + 11 Numeric',
      '9 Numeric'
    ]
  },
  {
    state: 'WA',
    rule: '^(?=.{12}$)[A-Z]{1,7}[A-Z0-9\\*]{4,11}$',
    description: [
      '1-7 Alpha + any combination of Alpha, Numeric, and * for a total of 12 characters'
    ]
  },
  {
    state: 'WV',
    rule: '(^[0-9]{7}$)|(^[A-Z]{1,2}[0-9]{5,6}$)',
    description: ['7 Numeric', '1-2 Alpha + 5-6 Numeric']
  },
  {
    state: 'WI',
    rule: '^[A-Z]{1}[0-9]{13}$',
    description: ['1 Alpha + 13 Numeric']
  },
  {
    state: 'WY',
    rule: '^[0-9]{9,10}$',
    description: ['9-10 Numeric']
  }
];
