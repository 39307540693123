import React from 'react';
import { Spin } from 'antd';

const style = {
  position: 'fixed',
  top: 0,
  left: 100, // From Sidebar
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50,
  zIndex: 10000,
};

function Loading(props) {
  return (
    <div style={{ ...style, ...props.style }}>
      <Spin size="large" />
    </div>
  );
}

export default Loading;
