import styled from 'styled-components';

const performanceToColor = {
  0: GLOBALS.colors.RED,
  1: GLOBALS.colors.ORANGE,
  2: GLOBALS.colors.YELLOW,
  3: GLOBALS.colors.GREEN
};

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
`;

const Line = styled.div`
  display: flex;
  flex: 1;
  height: 7px;
  background-color: #dedede;
`;

const Circle = styled.div`
  background-color: #a7a7a7;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({ isActive, value, disabled }) =>
    isActive && `background-color:  ${performanceToColor[value]};`}
`;

const Label = styled.div`
  cursor: pointer;
  user-select: none;
  ${({ isActive, value, disabled }) => `
  ${
    isActive &&
    `
  color: ${performanceToColor[value]};
  font-weight: ${disabled && 'bold'}
  `
  }
  `}
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0 45px 0px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.27);
  margin-bottom: 30px;

  & > div:nth-child(2) {
    margin-left: -30px;
  }
`;

export { Container, Line, Circle, Label, LabelContainer };
