import table from './table';
import { transform } from './transform';

export default {
  table,
  getDocuments: {
    path: 'afp_documents',
    transform
  },
  downloadDocument: {
    path: 'afp_documents/download'
  }
};
