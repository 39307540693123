import { privilegesToResources, resourcesToPrivileges } from '_src/utils';

const transformAll = ({ data }) =>
  data.groups.map(({ id, name, privileges }) => ({
    id,
    name,
    privileges: privilegesToResources(privileges)
  }));

const transformBeforeSave = ({ privileges = [], ...rest }) => ({
  ...rest,
  privileges: resourcesToPrivileges(privileges)
});

export { transformAll, transformBeforeSave };
