import React, { useContext, useState } from 'react';
import { Context, useSave } from 'store';
import { Textarea } from '_src/shared/form-helpers';
import { SignaturePad } from '_src/components';
import services from '_src/services';

import { Button as AntButton, notification } from 'antd';
import moment from 'moment';

import logoBadge from '_src/assets/images/logo-badge.png';

import {
  Container,
  Header,
  Details,
  Logo,
  TitleContainer,
  FormContainer,
  FormHeader,
  FormHeaderTitle,
  FormHeaderTitleDescription,
  FormBody,
  Paragraph,
  UnderlineStudentName,
  FollowingText,
  FormFooter,
  SignatureContainer,
  UserNameToSign,
  Footer,
  WarningLabel
} from './styles';

export default () => {
  const { getData, setShown } = useContext(Context);
  const { name, id } = getData('studentTimeline');
  const todayDate = moment().format('MM-DD-YYYY');
  const attendanceConfig = services['attendance'];
  const { save: sendStudentIntervention, busy: busyStudentIntervention } = useSave(
    attendanceConfig.sendStudentIntervention
  );

  const [observation, setObservation] = useState('');
  const [action, setAction] = useState('');
  const [studentSignature, setStudentSignature] = useState('');
  const [instructorSignature, setInstructorSignature] = useState('');
  const [witnessSignature, setWitnessSignature] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [studentSignatureRef, setStudentSignatureRef] = useState();
  const [instructorSignatureRef, setInstructorSignatureRef] = useState();
  const [witnessSignatureRef, setWitnessSignatureRef] = useState();

  const messageSuccess = 'Intervention has been sent successfully';
  const messageFail = 'Intervention send failed';

  const sendIntervention = () => {
    sendStudentIntervention({
      studentId: id,
      answers: {
        studentName: name,
        firstParagraph: observation,
        secondParagraph: action,
        date: moment().format('YYYY-MM-DD')
      },
      studentSignature: studentSignature,
      instructorSignature: instructorSignature,
      witnessSignature: witnessSignature
    })
      .then(res => {
        if (res.code === 200) {
          notification.success({
            message: messageSuccess,
            duration: 3
          });
        } else {
          notification.error({
            message: messageFail,
            duration: 3
          });
        }
        setObservation('');
        setAction('');
        setStudentSignature('');
        setInstructorSignature('');
        setWitnessSignature('');
        setFieldErrors({});
        setShown('studentIntervention', false);
        studentSignatureRef?.clear();
        instructorSignatureRef?.clear();
        witnessSignatureRef?.clear();
      })
      .catch(() => {
        notification.error({
          message: messageFail,
          duration: 3
        });
        setObservation('');
        setAction('');
        setStudentSignature('');
        setInstructorSignature('');
        setWitnessSignature('');
        setFieldErrors({});
        setShown('studentIntervention', false);
      });
  };

  const fieldsValidation = () => {
    const observationValid = observation.length > 0;
    const actionValid = action.length > 0;
    const studentSignatureValid = studentSignature.length > 0;
    const instructorSignatureValid = instructorSignature.length > 0;

    if (observationValid && actionValid && studentSignatureValid && instructorSignatureValid) {
      sendIntervention();
    } else {
      const validationError = {
        observationField: !observationValid,
        actionField: !actionValid,
        studentSignatureField: !studentSignatureValid,
        instructorSignatureField: !instructorSignatureValid
      };

      setFieldErrors(validationError);
    }
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Details>Student Name: {name}</Details>
          <Details>Date: {todayDate}</Details>
        </TitleContainer>

        <Logo src={logoBadge} />
      </Header>

      <FormContainer>
        <FormHeader>
          <FormHeaderTitle>Student Intervention Form</FormHeaderTitle>

          <FormHeaderTitleDescription>
            (please include dates with descriptions)
          </FormHeaderTitleDescription>
        </FormHeader>

        <FormBody>
          <Paragraph>
            <FollowingText>
              {`${name} was observered to have the following needs for improvement while under 160
              Driving Academy's instruction (s):`}
            </FollowingText>

            {fieldErrors.observationField && <WarningLabel>*This field is required</WarningLabel>}

            <Textarea
              style={{ height: '200px' }}
              value={observation}
              onChange={note => {
                setObservation(note);
              }}
            />
          </Paragraph>

          <Paragraph>
            <FollowingText>
              {`Given the above observations(s), 160 will take the following action in regards to ${name}:`}
            </FollowingText>

            {fieldErrors.actionField && <WarningLabel>*This field is required</WarningLabel>}

            <Textarea
              style={{ height: '200px' }}
              value={action}
              onChange={note => {
                setAction(note);
              }}
            />
          </Paragraph>
        </FormBody>

        <FormFooter>
          <SignatureContainer>
            {fieldErrors.studentSignatureField && (
              <WarningLabel>*This field is required</WarningLabel>
            )}

            <SignaturePad
              onChange={(signature, ref) => {
                setStudentSignatureRef(ref);
                setStudentSignature(signature);
              }}
            />
            <UserNameToSign>Student Signature</UserNameToSign>
          </SignatureContainer>

          <SignatureContainer>
            {fieldErrors.instructorSignatureField && (
              <WarningLabel>*This field is required</WarningLabel>
            )}

            <SignaturePad
              onChange={(signature, ref) => {
                setInstructorSignatureRef(ref);
                setInstructorSignature(signature);
              }}
            />
            <UserNameToSign>160 Staff Signature</UserNameToSign>
          </SignatureContainer>

          <SignatureContainer>
            {fieldErrors.witnessSignatureField && (
              <WarningLabel>*This field is required</WarningLabel>
            )}

            <SignaturePad
              onChange={(signature, ref) => {
                setWitnessSignatureRef(ref);
                setWitnessSignature(signature);
              }}
            />
            <UserNameToSign>Witness Signature</UserNameToSign>
          </SignatureContainer>
        </FormFooter>
      </FormContainer>

      <Footer>
        <AntButton
          style={{ marginRight: '20px' }}
          key="intervention"
          type="primary"
          onClick={() => fieldsValidation()}
          disabled={busyStudentIntervention}
          loading={busyStudentIntervention}
        >
          Send Student Intervention
        </AntButton>

        <AntButton
          key="close"
          type="ghost"
          onClick={() => {
            setObservation('');
            setAction('');
            setStudentSignature('');
            setInstructorSignature('');
            setWitnessSignature('');
            setFieldErrors({});
            setShown('studentIntervention', false);
            studentSignatureRef?.clear();
            instructorSignatureRef?.clear();
            witnessSignatureRef?.clear();
          }}
        >
          Close
        </AntButton>
      </Footer>
    </Container>
  );
};
