import React, { useState } from 'react';
import { Button, notification } from 'antd';
import download from 'downloadjs';
import { PersistentRepo } from '_src/shared/repo';
import { objToSnakeCase } from '_src/utils';

const baseApi = GLOBALS.BASE_API;

const DownloadButton = ({ file }) => {
  const [loadingDownload, setLoading] = useState(false);
  const token = PersistentRepo.get('token');
  const handleDocumentDownload = async () => {
    try {
      setLoading(true);
      const resp = await fetch(`${baseApi}/afp_documents/download`, {
        method: 'POST',
        body: JSON.stringify(
          objToSnakeCase({
            name: file.name
          })
        ),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const blob = await resp.blob();

      download(blob, file.name);
      notification.success({
        message: `Successfully downloaded ${file.name}`,
        duration: 3
      });
      setLoading(false);
    } catch (error) {
      notification.error({
        message: 'Something went wrong!',
        duration: 3
      });
      setLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      icon="download"
      size="large"
      loading={loadingDownload}
      onClick={handleDocumentDownload}
    >
      Download
    </Button>
  );
};

export default [
  {
    title: 'Document',
    dataIndex: 'name'
  },
  {
    title: 'Action',
    dataIndex: '',
    render: file => {
      return <DownloadButton file={file} />;
    }
  }
];
