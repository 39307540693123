import React, { useContext, useEffect, useState, useCallback } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Context, useFetch } from 'store';
import { Card, Button, Menu, Dropdown } from 'antd';

import { PageContainer } from '_src/shared/styles';
import { Refresh, AsyncTable } from '_src/components';
import { PersistentRepo } from '_src/shared/repo';
import { Select, Input } from '_src/shared/form-helpers';
import services from '_src/services';
import { debounce } from '_src/utils';

import { Row, HourDetailContainer, HourDetail, BtnContainer } from './styles';

export default () => {
  const history = useHistory();
  const { getData } = useContext(Context);

  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const config = services['cdlTesting'];
  const locationsConfig = services['studentRecords'];
  const resource = 'cdlTesting';
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(getInitialLocation());
  const [fullName, setFullName] = useState('');
  const [firstTimePass, setFirstTimePass] = useState('');

  const [firstRecord, setFirstRecord] = useState(null);

  const [filterParams, setFilterParams] = useState({
    locationId: getInitialId('branchId'),
    locationType:
      getInitialId('locationType') === 'all' ? 0 : getInitialId('locationType') === 'yard' ? 1 : 2
  });

  const { get: getLocations } = useFetch({ ...locationsConfig.getLocations });

  const debounceCallback = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );

  useEffect(() => {
    getLocations().then(data => {
      setLocations(data);
    });
  }, []);

  const renderDropdownCell = (_, record) => {
    const { studentFullName, studentId } = record;

    const options = (
      <Menu>
        <Menu.Item
          onClick={() => {
            history.push(`/cdlTestings/${studentId}`);
          }}
        >
          View Details
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={options}>
        <div style={{ color: status === 'Hold' ? GLOBALS.colors.DARK_YELLOW : 'unset' }}>
          {studentFullName}
        </div>
      </Dropdown>
    );
  };

  config.table.find(({ dataIndex }) => dataIndex === 'studentFullName').render = renderDropdownCell;

  const renderEditButton = studentId => {
    return (
      <Button
        type="primary"
        style={{
          marginLeft: 20
        }}
        onClick={() => {
          history.push(`/cdlTestings/${studentId}`);
        }}
      >
        Edit
      </Button>
    );
  };

  return (
    <PageContainer>
      <Refresh />
      <Card title="CDL Testing">
        <Row>
          <Select
            showSearch
            label="Branches"
            onChange={locationSelected => {
              setLocation(locationSelected);
              if (locationSelected === 'all') {
                setFilterParams();
                PersistentRepo.set('allBranches', locationSelected);
              } else {
                const [locationType, locationId, branchId] = locationSelected.split('x');
                setFilterParams(() => {
                  return {
                    ...filterParams,
                    locationId: locationSelected ? branchId : getInitialId('branchId'),
                    locationType: locationSelected
                      ? locationType === 'all'
                        ? 0
                        : locationType === 'yard'
                        ? 1
                        : 2
                      : getInitialId('locationType')
                  };
                });
                PersistentRepo.set('location', locationSelected);
                PersistentRepo.delete('allBranches');
              }
            }}
            options={locations}
            value={location}
          />

          <Input
            label="Full Name"
            onChange={e => {
              setFullName(e);
              debounceCallback({ ...filterParams, studentFullName: e });
            }}
            value={fullName}
          />

          <Select
            label="First time pass"
            value={firstTimePass}
            options={[
              { id: '', label: 'All' },
              { id: 'true', label: 'First time pass' },
              { id: 'false', label: 'Other students' }
            ]}
            onChange={optionSelected => {
              setFirstTimePass(optionSelected);
              if (optionSelected) {
                setFilterParams({
                  ...filterParams,
                  firstTimePass: optionSelected
                });
              } else {
                let filters = { ...filterParams };
                delete filters.firstTimePass;
                setFilterParams(filters);
              }
            }}
          />
        </Row>
        <HourDetailContainer>
          {firstRecord && firstRecord.testingDate && (
            <HourDetail>
              TESTING DATE:{' '}
              <span>{moment.parseZone(firstRecord.testingDate).format('MM/DD/YYYY')}</span>
            </HourDetail>
          )}
          <HourDetail>
            TESTING LOCATION:{' '}
            <span>
              {getInitialId('locationType') === 'all'
                ? 'Branch'
                : getInitialId('locationType') === 'yard'
                ? 'Yard'
                : 'Classroom'}
            </span>
          </HourDetail>
        </HourDetailContainer>
        <AsyncTable
          resource={resource}
          pagination
          config={{
            ...config,
            table: [
              ...config.table,
              {
                title: 'First Time Pass',
                dataIndex: 'firstTimePass',
                render: (_, { firstTimePass, testStatuses, studentId, appointmentCanceled }) => (
                  <BtnContainer>
                    {firstTimePass ? 'Yes' : 'No'}
                    {testStatuses &&
                      !appointmentCanceled &&
                      testStatuses.toLowerCase().includes('pending') &&
                      renderEditButton(studentId)}
                  </BtnContainer>
                )
              }
            ]
          }}
          onChange={(_, __, sorter) => {
            if (sorter.field === 'studentFullName') {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  orderBy: 'student_full_name',
                  order: sorter.order === 'ascend' ? 'asc' : 'desc'
                })
              });
            }
            if (sorter.field === 'appointmentDate') {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  orderBy: 'appointment_date',
                  order: sorter.order === 'ascend' ? 'asc' : 'desc'
                })
              });
            }
          }}
          fileName={resource}
          params={filterParams}
          onFirstRecord={setFirstRecord}
          resetPageOnFilter={[location]}
        />
      </Card>
    </PageContainer>
  );
};
