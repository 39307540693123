import React, { useContext } from 'react';
import { Context } from 'store';

const Location = ({ branchId, locationType }) => {
  const { getData } = useContext(Context);
  const locations = getData('locations') || [];
  const location = locations.find(loc => branchId === loc.branchId && locationType === loc.type);

  return location ? location.label : '';
};

export default [
  {
    title: 'Student',
    dataIndex: 'name'
  },
  { title: 'Status', dataIndex: 'status' },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, { branchId, locationType }) => (
      <Location branchId={branchId} locationType={locationType} />
    )
  }
];
