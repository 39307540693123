import { formatDate } from '_src/utils';

export default [
  {
    title: 'Student',
    dataIndex: 'name',
    className: 'custom-td'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    render: (_, { attendance: { createdAt } }) => formatDate(createdAt, 'MM/DD/YYYY')
  },
  {
    title: 'Check in',
    dataIndex: 'checkin',
    render: (_, { attendance: { checkIn } }) => formatDate(checkIn, 'HH:mm')
  },
  {
    title: 'Check out',
    dataIndex: 'checkout',
    render: (_, { attendance: { checkOut } }) => formatDate(checkOut, 'HH:mm')
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];
