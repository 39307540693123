import React, { useContext } from 'react';
import { Context } from 'store';

import { AsyncModal } from '_src/components';

const ChangePassword = () => {
  const { setShown } = useContext(Context);
  const resource = 'changePassword';

  return <AsyncModal resource={resource} closeModal={() => setShown(resource, false)} />;
};

export default ChangePassword;
