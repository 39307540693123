import React, { useContext, useEffect, useState } from 'react';
import { Context, useGet, useSave, useFetch } from 'store';
import { Card, Button, Popconfirm, Icon, notification } from 'antd';
import moment from 'moment';

import { AsyncTable, Refresh } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select } from '_src/shared/form-helpers';

import { FilterRow, ButtonContainer } from './styles';

const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };
const resource = 'manageLocationType';
const attendanceConfig = services.attendance;
const config = services[resource];
var timezone = moment().utcOffset() / 60;

if (timezone > 0) {
  timezone = 'GMT+' + timezone;
} else if (timezone < 0) {
  timezone = 'GMT' + timezone;
} else if (timezone === 0) {
  timezone = 'GMT 0';
}
export default () => {
  const allBranchesLocation = PersistentRepo.get('allBranches');
  const initialLocation = allBranchesLocation
    ? allBranchesLocation
    : PersistentRepo.get('location', 'allxallx1');

  const { getData, refreshResource } = useContext(Context);
  const [location, setLocation] = useState(initialLocation);
  const { save: editStudent } = useSave(attendanceConfig.editStudent);
  const [loading, setLoading] = useState({});
  const { get: getStudentAttendance } = useFetch(attendanceConfig.override);

  const [filterParams, setFilterParams] = useState({
    ...filterParams,
    enrolled: true,
    branchId: location.split('x')[2],
    locationType: location.split('x')[0] === 'all' ? 0 : location.split('x')[0] === 'yard' ? 1 : 2,
    attendance: true
  });

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      if (!allLocations.find(({ id }) => id === location) && location !== 'all') {
        onLocationChange(allLocations[0].id);
      }
    }
  });

  const onLocationChange = loc => {
    const isAllBranches = loc === 'all';
    if (isAllBranches) {
      PersistentRepo.set('allBranches', loc);
    } else {
      allBranchesLocation && PersistentRepo.delete('allBranches');
    }

    setLocation(loc);
    setFilterParams(prevState => {
      return {
        ...prevState,
        branchId: isAllBranches ? undefined : loc.split('x')[2],
        locationType: loc.split('x')[0] === 'all' ? 0 : loc.split('x')[0] === 'yard' ? 1 : 2
      };
    });
  };

  const changeLocationType = (student, mode) => {
    const { studentId, name } = student;
    getStudentAttendance({
      params: { studentId, date: moment.parseZone().format('DD-MM-YYYY') }
    }).then(attendance => {
      let hasOpenSession = false;
      attendance.map(({ checkIn, checkOut }) => {
        if (checkIn && !checkOut) {
          hasOpenSession = true;
        }
      });

      if (hasOpenSession) {
        notification.error({
          message: `Failed to move student to ${mode}`,
          description: `You have to close this student open sessions before being able to move them`,
          duration: 5
        });
      } else {
        setLoading({ ...loading, [studentId]: true });

        editStudent({ id: studentId, locationType: mode === 'yard' ? 1 : 2 }).then(res => {
          setLoading({ ...loading, [studentId]: false });

          if (res.success === 1) {
            notification.success({
              message: `Successfully moved ${name} to ${mode}`,
              duration: 5
            });
            refreshResource(resource);
          }
        });
      }
    });
  };

  const clearLicense = student => {
    const { studentId, name, license, licenseId } = student;

    if (!licenseId) {
      notification.error({
        message: `Failed to clear student's license`,
        description: `${name} does not have a license assigned!`,
        duration: 5
      });
    } else {
      setLoading({ ...loading, [studentId]: true });

      editStudent({ id: studentId, clearLicense: true }).then(res => {
        setLoading({ ...loading, [studentId]: false });

        if (res.success === 1) {
          notification.success({
            message: `Successfully cleared ${name}'s license`,
            duration: 5
          });
          refreshResource(resource);
        } else {
          notification.error({
            message: `An error has happened!`,
            duration: 5
          });
        }
      });
    }
  };

  const locations = {
    options: [{ id: 'all', label: 'All Branches' }],
    ...attendanceConfig.location
  };

  return (
    <PageContainer style={stylesTablet}>
      <Refresh />
      <Card title="Manage student location">
        <FilterRow>
          <Select
            showSearch
            onChange={onLocationChange}
            {...locations}
            value={location}
            label="Branch"
            style={{ width: '250px' }}
          />
        </FilterRow>

        <AsyncTable
          pagination
          fileName={resource}
          resource={resource}
          config={{
            ...services[resource],
            table: [
              ...services[resource].table,
              {
                id: 'manage',
                title: 'Manage location',
                render: (_, student) => {
                  const { locationType, name, studentId } = student;
                  const destination = locationType === 1 ? 'classroom' : 'yard';

                  return (
                    <Popconfirm
                      title={`Are you sure you want to move ${name} to ${destination}`}
                      okType="warning"
                      okText="Move"
                      loading={loading[studentId]}
                      onConfirm={() => changeLocationType(student, destination)}
                      icon={<Icon type="question-circle-o" style={{ color: 'orange' }} />}
                    >
                      <ButtonContainer>
                        <Button style={{ width: 200 }} type="primary" loading={loading[studentId]}>
                          Move to {destination}
                        </Button>
                      </ButtonContainer>
                    </Popconfirm>
                  );
                }
              },
              {
                id: 'manage',
                title: 'Manage license',
                render: (_, student) => {
                  const { name, studentId, licenseId } = student;

                  return (
                    <Popconfirm
                      title={`Are you sure you want to clear ${name}'s license?`}
                      okType="warning"
                      okText="Clear"
                      loading={loading[studentId]}
                      onConfirm={() => clearLicense(student)}
                      icon={<Icon type="question-circle-o" style={{ color: 'orange' }} />}
                    >
                      <ButtonContainer>
                        <Button
                          style={{ width: 200 }}
                          type="primary"
                          loading={loading[studentId]}
                          disabled={!licenseId}
                        >
                          Clear license
                        </Button>
                      </ButtonContainer>
                    </Popconfirm>
                  );
                }
              }
            ]
          }}
          resetPageOn={['locationType', 'branchId']}
          params={{
            enrolled: filterParams.enrolled,
            branchId: filterParams.branchId,
            attendance: filterParams.attendance,
            locationType: filterParams.locationType
          }}
        />
      </Card>
    </PageContainer>
  );
};
