import React, { useState, useEffect } from 'react';
import { useFetch } from 'store';
import moment from 'moment';

import { PageWrapper } from '_src/shared/styles';
import { RangePicker } from '_src/shared/form-helpers';
import { Table } from '_src/components';
import services from '_src/services';
import { Refresh } from '_src/components';
import { Row, CardBlock, Totals, LabelCard, Search, PageTitle, Label } from './styles';

export default () => {
  const config = services.lmsUsage;
  const [usages, setUsages] = useState();

  const { get: getUsages, busy } = useFetch(config.get);

  const onChange = ([startDate, endDate]) => {
    const range = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    };

    getUsages({ params: range })
      .then(res => setUsages(res))
      .catch(err => { });
  };

  const disabledDate = current => current.isAfter(moment(), 'day');

  useEffect(() => {
    getUsages({
      params: {
        startDate: moment().subtract(1, 'months'),
        endDate: moment()
      }
    })
      .then(res => setUsages(res))
      .catch(err => { });
  }, []);

  return (
    <PageWrapper>
      <Refresh />
      <PageTitle>LMS Usage</PageTitle>
      <Label>Select the range of dates to view the usages</Label>
      <Search>
        <RangePicker
          allowClear={false}
          ranges={{
            Today: [moment(), moment()],
            'Last Week': [moment().subtract(7, 'd'), moment()],
            'Last Month': [moment().subtract(1, 'months'), moment()]
          }}
          defaultValue={[moment().subtract(1, 'months'), moment()]}
          onChange={onChange}
          disabledDate={disabledDate}
        />
      </Search>

      {usages && (
        <>
          <Label>Company overview</Label>
          <Row>
            <CardBlock borderLeft={true} borderRight={false} colSpan={20}>
              <LabelCard>Total Checkins</LabelCard>
              <Totals>{usages.company.totalAttendance}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Open Sessions</LabelCard>
              <Totals>{usages.company.openSessions}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Number Of PreTrips Test Taken</LabelCard>
              <Totals>{usages.company.companyPretrips}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Number Of Skill Test Taken</LabelCard>
              <Totals>{usages.company.companySkills}</Totals>
            </CardBlock>

            <CardBlock borderLeft={false} borderRight={true} colSpan={20}>
              <LabelCard>Number Of Road Test Taken</LabelCard>
              <Totals>{usages.company.companyRoads}</Totals>
            </CardBlock>
          </Row>

          <Row>
            <CardBlock borderLeft={true} borderRight={false} colSpan={20}>
              <LabelCard>Number Of Students</LabelCard>
              <Totals>{usages.company.studentsWhoAttended}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Class / Yard Hours Trained</LabelCard>
              <Totals>{usages.company.hourstraining}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>PreTrips Scores</LabelCard>
              <Totals>{usages.company.companyScorePretrips}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Skill Scores</LabelCard>
              <Totals>{usages.company.companyScoreSkills}</Totals>
            </CardBlock>

            <CardBlock borderLeft={false} borderRight={true} colSpan={20}>
              <LabelCard>Road Score</LabelCard>
              <Totals>{usages.company.companyScoreRoads}</Totals>
            </CardBlock>
          </Row>

          <Label>Overview for each school</Label>

          <div style={{ overflowX: 'auto' }}>
            <Table
              loading={busy}
              columns={config.table}
              data={usages.usage}
              bordered={false}
              pagination={true}
              scroll={{ y: 500 }}
            />
          </div>
        </>
      )}
    </PageWrapper>
  );
};
