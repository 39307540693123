import styled from 'styled-components';

export const RefreshDiv = styled.div`
  display: flow-root;
  margin-bottom: 10px;
  ${({ table }) =>
    table
      ? `& {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
  }`
      : ''};
`;
