import styled from 'styled-components';

const Row = styled.div`
  display: inline-flex;
  width: 100%;
  font-family: 'SF Display Light';
`;

const CardBlock = styled.div`
  padding: 20px 10px;
  background-color: #359ac8;
  border-right: 1px solid #ddd;
  color: #fff;
  margin-bottom: 5px;

  ${({ borderRight, borderLeft, colSpan }) => `
    width: ${colSpan}%;
    ${
      borderLeft
        ? `border-top-left-radius: 7px;
       border-bottom-left-radius: 7px;
       
    `
        : `border-radius: none`
    }

    ${
      borderRight
        ? `border-bottom-right-radius: 7px;
       border-top-right-radius: 7px;
    `
        : `border-radius: none`
    }
  `}
`;

const Totals = styled.p`
  font-size: 24px;
`;

const LabelCard = styled.p`
  color: #f5f5f5;
`;

const PageTitle = styled.p`
  font-family: 'SF Display Heavy';
  margin-bottom: 20px;
`;

const Search = styled.div`
  display: inline-flex;
  margin-bottom: 20px;

  button {
    align-self: flex-end;
    margin-left: 20px;
  }
`;

const Label = styled.div`
  margin: 30px 0 20px 0;
  color: rgba(73, 71, 71, 0.65);
`;

export { Row, CardBlock, Totals, LabelCard, Search, PageTitle, Label };
