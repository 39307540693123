import React, { useState, useContext, useEffect } from 'react';
import { Card, Menu, Dropdown, Button, Icon, notification } from 'antd';
import moment from 'moment';
import { Context, useFetch } from 'store';
import download from 'downloadjs';
import qs from 'qs';

import services from '_src/services';
import { Refresh } from '_src/components';
import statisticsCards from './statistics';
import { objToSnakeCase } from '_src/utils';
import { PersistentRepo } from '_src/shared/repo';
import amazonLogo from '_src/assets/images/amazon.png';
import { AsyncTable, AsyncModal, Modal } from '_src/components';
import timezoneJson from '../../services/branches/timezones';
import { AdminStudentTimeline } from '_src/modules/AdminReport';
import { PageContainer, ActionRow, Space } from '_src/shared/styles';
import { Select, Input, RangePicker } from '_src/shared/form-helpers';
import { camelToSnakeCase, snakeToCamelCase } from '../../utils/helpers';

import {
  HeaderModal,
  ModalTitle,
  Amazon,
  Row,
  InlineFlexRow,
  CardBlock,
  Totals,
  LabelCard,
  BlockSection
} from './styles';
import './styles.css';

const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };

const baseApi = GLOBALS.BASE_API;

export default () => {
  const now = moment();
  const resource = 'reports';
  const resourceTimeline = 'adminStudentTimeline';
  const token = PersistentRepo.get('token');
  const config = services[resource];

  const { get: getStats } = useFetch(config.statistics);

  const { setShown, getShown, getBusy, setData } = useContext(Context);
  const [initialValues, setInitialValues] = useState({});
  const [statistics, setStatistics] = useState();
  const [student, setStudent] = useState({});

  const [fullName, setFullName] = useState('');
  const [sponsors, setSponsor] = useState(['all']);
  const [status, setStatus] = useState('all');
  const [timezone, setTimezone] = useState('all');
  const [location, setLocation] = useState(['all']);
  const [locationType, setLocationType] = useState('all');
  const [disableDailyReport, setDisabledDailyReport] = useState(false);
  const [dateRanges, setDateRanges] = useState({
    startDate: moment().subtract(7, 'd'),
    endDate: moment()
  });
  const intinalTableParams = {
    fullName,
    sponsors,
    status,
    timezone,
    location,
    locationType,
    startDate: dateRanges.startDate,
    endDate: dateRanges.endDate
  };

  const getTableParams = () => {
    const { startDate, endDate, location, sponsors, status, fullName, locationType, timezone } =
      intinalTableParams;

    let params = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    };

    if (!location.includes('all') && location.length > 0) {
      params.branchIds = location;
    }

    if (!sponsors.includes('all') && sponsors.length > 0) {
      params.sponsors = sponsors;
    }

    if (status !== 'all') {
      params.status = status;
    }

    if (fullName !== '') {
      params.fullName = fullName;
    }

    if (locationType !== 'all') {
      params.locationType = locationType;
    }

    if (timezone !== 'all') {
      params.timezone = timezone;
    }

    return params;
  };

  const [tableParams, setTableParams] = useState(getTableParams());

  const { startDate, endDate } = dateRanges;

  useEffect(() => {
    const { startDate, endDate } = dateRanges;

    getStatistics({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    });
  }, []);

  const getStatistics = ({ ...args }) => {
    const { startDate, endDate } = args;

    getStats({
      params: {
        startDate,
        endDate
      }
    })
      .then(setStatistics)
      .catch(err => {
        notification.error({
          message: err.message,
          duration: 3
        });
      });
  };

  const renderDropdownCell = (_, record) => {
    const { firstName, lastName, status, preferredNickname, ...rest } = record;

    const options = (
      <Menu>
        <Menu.Item
          onClick={() => {
            setData('studentTimeline', {
              name: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
              ...rest
            });

            setShown(resourceTimeline);
          }}
        >
          View Report Card
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            setStudent({ name: `${firstName} ${lastName}`, ...rest });
            setInitialValues({ ...record });
            setShown(resource);
          }}
        >
          Edit student information
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={options}>
        <div style={{ color: status === 'Hold' ? GLOBALS.colors.DARK_YELLOW : 'unset' }}>
          {lastName} {firstName}
        </div>
      </Dropdown>
    );
  };

  async function getDailyReports() {
    const paramsCopy = { ...getTableParams() };
    if (paramsCopy.startDate) {
      delete paramsCopy.startDate;
    }
    if (paramsCopy.endDate) {
      delete paramsCopy.endDate;
    }

    try {
      const resp = await fetch(`${baseApi}/${config.dowloadDaily.path}`, {
        method: 'POST',
        body: JSON.stringify(objToSnakeCase(paramsCopy)),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const filename = resp.headers.get('x-suggested-filename');
      const blob = await resp.blob();

      download(blob, filename);
    } catch (error) {
      notification.error({
        message: 'Something went wrong!',
        duration: 3
      });
    }
  }

  const getParams = () => {
    return qs.stringify({ ...objToSnakeCase(getTableParams()) }, { arrayFormat: 'comma' });
  };

  config.table.find(({ dataIndex }) => dataIndex === 'name').render = renderDropdownCell;

  return (
    <PageContainer style={stylesTablet}>
      <Refresh />
      <Card title={config.title}>
        <Row>
          <Input
            label="Full Name"
            onChange={e => {
              setFullName(e);
            }}
            value={fullName}
          />

          <Select
            label="Sponsors"
            {...config.sponsors}
            value={sponsors}
            onChange={e => {
              const lastSelection = e.length - 1;
              const all = e.filter(s => s === 'all');
              let sponsorSelected = [...e];
              if (all.length > 0) {
                if (e[lastSelection] === 'all') {
                  sponsorSelected = all;
                } else {
                  sponsorSelected = e.filter(s => s !== 'all');
                }
              }
              setSponsor([...sponsorSelected]);
            }}
            mode="multiple"
          />

          <Select
            mode="multiple"
            label="Branches"
            onChange={loc => {
              const lastSelection = loc.length - 1;
              const all = loc.filter(l => l === 'all');
              let locationSelected = [...loc];

              if (all.length > 0) {
                if (loc[lastSelection] === 'all') {
                  locationSelected = all;
                } else {
                  locationSelected = loc.filter(l => l !== 'all');
                }
              }
              setLocation([...locationSelected]);
            }}
            {...config.location}
            value={location}
          />

          <Select
            label="Timezone"
            options={[{ id: 'all', label: 'All' }, ...timezoneJson]}
            onChange={t => setTimezone(t)}
            value={timezone}
          />

          <Select
            label="Location Type"
            value={locationType}
            options={[
              { id: 'all', label: 'All' },
              { id: 2, label: 'Classroom' },
              { id: 1, label: 'Yard' }
            ]}
            onChange={type => {
              setLocationType(type);
            }}
          />

          <Select
            label="Status"
            value={status}
            options={[
              { id: 'all', label: 'All' },
              { id: 'enrolled', label: 'Enrolled' },
              { id: 'graduated', label: 'Graduated' },
              { id: 'hold', label: 'On Hold' }
            ]}
            onChange={stat => {
              setStatus(stat);
            }}
          />
          <RangePicker
            label="Date"
            allowClear={false}
            ranges={{
              Today: [now, now],
              'Last Week': [moment().subtract(7, 'd'), moment()],
              'Last Month': [moment().subtract(1, 'months'), moment()]
            }}
            defaultValue={[startDate, endDate]}
            onChange={dates => {
              const [startDate, endDate] = dates;
              setDateRanges({
                startDate,
                endDate
              });
              getStatistics({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
              });
            }}
          />
        </Row>

        <Button
          type="primary"
          size="large"
          style={{ width: '100%', marginBottom: 20 }}
          loading={getBusy(resource)}
          onClick={() => {
            setTableParams({
              ...(fullName !== '' && { fullName }),
              ...(!sponsors.includes('all') && sponsors.length > 0 && { sponsors }),
              ...(status !== 'all' && { status }),
              ...(timezone !== 'all' && { timezone }),
              ...(!location.includes('all') && location.length > 0 && { branchIds: location }),
              ...(locationType !== 'all' && { locationType }),
              startDate: dateRanges.startDate.format('YYYY-MM-DD'),
              endDate: dateRanges.endDate.format('YYYY-MM-DD')
            });
          }}
        >
          Search
        </Button>

        <BlockSection>
          <InlineFlexRow>
            {statistics &&
              statisticsCards.map(
                ({ label, indexLabel, firstCard = false, lastCard = false }, index) => (
                  <CardBlock key={index} borderLeft={!!firstCard} borderRight={!!lastCard}>
                    <LabelCard>{label}</LabelCard>
                    <Totals>{statistics[indexLabel]}</Totals>
                  </CardBlock>
                )
              )}
          </InlineFlexRow>
        </BlockSection>

        <ActionRow>
          <a target="_blank" href={baseApi + '/' + config.download.path + '?' + getParams()}>
            <Button type="default">
              <Icon type="download" />
            </Button>
          </a>
          <a>
            <Button type="primary" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <Icon type="printer" />
            </Button>
          </a>
          <Button
            loading={getBusy(resource)}
            onClick={() => setDisabledDailyReport(true)}
            type="default"
          >
            <Icon type="download" /> Download daily attendance report
          </Button>
        </ActionRow>

        <AsyncTable
          pagination
          resetPageOn={[
            'branchIds',
            'startDate',
            'endDate',
            'fullName',
            'status',
            'sponsors',
            'locationType',
            'name'
          ]}
          fileName="reports"
          className="reports-table"
          resource={resource}
          params={tableParams}
          onChange={(pagination, filters, sorter) => {
            if (!!sorter.field) {
              setTableParams({
                ...tableParams,
                ...(sorter.field && {
                  order: sorter.order === 'ascend' ? 'asc' : 'desc',
                  orderBy: camelToSnakeCase(sorter.field === 'name' ? 'last_name' : sorter.field)
                })
              });
            }
          }}
        />
      </Card>

      <AsyncModal
        showDelete={false}
        saveText="Submit"
        title={
          <>
            <HeaderModal key="header">
              <ModalTitle key="title">{student ? student.name : ''}</ModalTitle>
              {student.amazonEmployee && student.amazonEmployee === 'Yes' && (
                <Amazon src={amazonLogo} />
              )}
            </HeaderModal>
          </>
        }
        resource={resource}
        closeModal={() => setShown(resource, false)}
        initialValues={initialValues}
      >
        <Space vertical={30} />
      </AsyncModal>

      <Modal
        title="Download daily attendace report"
        visible={disableDailyReport}
        footer={[
          <button
            onClick={() => setDisabledDailyReport(false)}
            style={{
              padding: '3px 12px',
              border: '2px solid #1890ff',
              borderRadius: '8px',
              color: '#1890ff',
              fontWeight: 500
            }}
          >
            Close
          </button>
        ]}
      >
        <div style={{ fontSize: '16px', fontWeight: 700 }}>
          Report is currently under development !
        </div>
      </Modal>

      {getShown(resourceTimeline) && <AdminStudentTimeline />}
    </PageContainer>
  );
};
