import styled from 'styled-components';

const Title = styled.span`
  font-size: 18px;
`;

// Modal
const ModalContainer = styled.div`
  margin: 0px -25px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SessionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding-bottom: 14px;
`;

const SessionsWrapper = styled.div`
  margin-top: 10px;
`;

const SessionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: 100px;
  margin-right: 60px;
`;

const OdometerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const OdometersWrapper = styled.div`
  column-count: 2;
  column-gap: 40px;
  margin-top: 10px;
`;

const HoursContainer = styled.div`
  display: flex;
  margin-top: 36px;
`;

const HoursCounterContainer = styled.div`
  display: grid;
  max-width: 600px;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  width: 100%;
`;

const HourCounterItem = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

const HoursCounterLabel = styled.span`
  line-height: 30px;
  font-weight: bold;
  font-size: 13px;
`;

const TotalHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* text-align: right; */
  min-width: 100px;
  margin-right: 60px;
`;

const TotalHoursTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
`;

const TotalHoursValue = styled.span`
  font-size: 16px;
  line-height: 32px;
`;

const InitialsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
`;

const FormButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const HourDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const HourDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
`;

// End Modal

export {
  Title,
  ModalContainer,
  ModalHeaderContainer,
  SessionsContainer,
  SessionColumn,
  SessionsWrapper,
  OdometerColumn,
  OdometersWrapper,
  HourCounterItem,
  HoursCounterContainer,
  HoursCounterLabel,
  TotalHoursWrapper,
  HoursContainer,
  TotalHoursTitle,
  TotalHoursValue,
  InitialsContainer,
  FormButtonsContainer,
  HourDetailContainer,
  HourDetail
};
