import table from './table.js';
import schema from './schema';
import fields from './fields';
import { transformAll, transformCreate, transformBeforeUpdate } from './transform';

const name = 'characterQuestions';
const path = 'testing/questions';

const save = {
  name,
  path
};
export default {
  restrict: 'testing/questions',
  title: 'Character Questions',
  createButton: 'Create a new question',
  sortable: true,
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: [],
    params: {
      type: 4
    }
  },
  create: {
    ...save,
    transform: transformCreate
  },
  edit: {
    ...save,
    method: 'patch',
    path: `${path}/:id`,
    transform: transformBeforeUpdate
  },
  // delete: {
  //   name,
  //   path: `${path}/:id`,
  //   params: {
  //     type: 2
  //   }
  // },
  ordering: {
    ...save,
    method: 'post',
    path: `${path}/ordering`
  },
  table,
  modal: {
    title: 'Save character question',
    fields,
    schema
  }
};
