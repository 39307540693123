import React from 'react';
import warningImg from '_src/assets/images/exclamation.png';
import { Container, Label } from './styles';

export default () => {
  return (
    <Container>
      <img src={warningImg} height={25} width={25} />
      <Label>Please make sure all yard students have a “yard start date” in Salesforce.</Label>
    </Container>
  );
};
