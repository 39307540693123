import React from 'react';
import { InputNumber, Form } from 'antd';
import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

const Container = styled.div`
  display: block
  align-items: center;
`;

export default ({ label, error, touched, dataCy = '', name = '', ...rest }) => {
  const hasError = error && touched;
  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      <Container>
        {(label || hasError) && <Label hasError={hasError}>{hasError ? error : label}</Label>}
        <InputNumber data-cy={dataCy || 'field-input-' + name} {...rest} />
      </Container>
    </Form.Item>
  );
};
