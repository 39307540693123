import { formatDate } from '_src/utils';

const transformAll = res => {
  if (res.success !== 1) {
    return [];
  }

  const total = res.data.students?.total || 0;
  const parseToNumber = number => Number(number || 0).toFixed(2);

  const data = (res.data.students?.responses || []).map(student => {
    const {
      id,
      firstName,
      lastName,
      status,
      dateClassBegins,
      graduationDate,
      totalHoursTrained,
      locationType,
      hasPermit = 0,
      branchName,
      state,
      preferredNickname,
      archived,
      totalClassHours,
      totalObservationRoadHours,
      totalPretripHours,
      totalRemedialHours,
      totalRoadHours,
      totalSkillsHours,
      totalObservationSkillHours
    } = student;
    const locationState = GLOBALS.states.find(s => s.id === state);

    return {
      id: id,
      studentName: `${firstName} ${preferredNickname ? `"${preferredNickname}"` : ''} ${lastName}`,
      studentType: locationType === 1 ? 'Yard Student' : 'Class Student',
      locationCity: branchName,
      locationState: locationState?.label || state,
      classStarted: formatDate(dateClassBegins, 'MM/DD/YYYY') || '',
      graduationDate: formatDate(graduationDate, 'MM/DD/YYYY') || '',
      totalHours: parseToNumber(totalHoursTrained),
      classHours: parseToNumber(totalClassHours),
      skillsHours: parseToNumber(totalSkillsHours),
      preTripHours: parseToNumber(totalPretripHours),
      obsSkillHours: parseToNumber(totalObservationSkillHours),
      obsRoadHours: parseToNumber(totalObservationRoadHours),
      remedialHours: parseToNumber(totalRemedialHours),
      roadHours: parseToNumber(totalRoadHours),
      status: status,
      hasPermit: hasPermit,
      archived
    };
  });

  return { data, total };
};

const transformLocations = ([branchesRes, yardsRes, classroomsRes, locationsRes]) => {
  const branchLocations = locationsRes.data?.branchLocations || [];

  const classroomToBranch =
    (classroomsRes.data?.classrooms || []).reduce(
      (acc, { branchId, id }) => ({ ...acc, [branchId]: id }),
      {}
    ) || [];

  const yardToBranch =
    yardsRes.data?.yards.reduce((acc, { branchId, id }) => ({ ...acc, [branchId]: id }), {}) || [];

  const classrooms = branchLocations.reduce((acc, { branchId, classroomId }) => {
    if (classroomId === classroomToBranch[branchId]) {
      return { ...acc, [branchId]: `classroomx${classroomId}x${branchId}` };
    }

    return acc;
  }, {});

  const yards = branchLocations.reduce((acc, { branchId, yardId }) => {
    if (yardId === yardToBranch[branchId]) {
      return { ...acc, [branchId]: `yardx${yardId}x${branchId}` };
    }

    return acc;
  }, {});

  return branchesRes.data?.branches
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, { id, name, timezone }) => {
      let items = [
        {
          id: `allxallx${id}`,
          label: `${name} Branch`,
          type: 0,
          branchId: id,
          branchLocation: branchLocations.find(({ branchId }) => branchId === id),
          timezone
        }
      ];

      if (yards[id]) {
        items.push({
          id: `${yards[id]}`,
          label: `${name} Yard`,
          type: 1,
          branchId: id,
          timezone
        });
      }

      if (classrooms[id]) {
        items.push({
          id: `${classrooms[id]}`,
          label: `${name} Classroom`,
          type: 2,
          branchId: id,
          timezone
        });
      }

      return [...acc, ...items];
    }, []);
};

export { transformAll, transformLocations };
