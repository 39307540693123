import moment from 'moment';

function camelCaseToNormalWithSpaces(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase()
    .replace(/^./, char => char.toUpperCase());
}
export default [
  {
    title: 'Testing Date',
    dataIndex: 'appointmentDate',
    render: (_, { appointmentDate }) =>
      `${appointmentDate ? moment.parseZone(appointmentDate).format('MM/DD/YYYY') : ''}`
  },
  {
    title: 'Category',
    dataIndex: 'testType',
    render: (_, { testType }) =>
      testType === 1 ? 'PreTrip' : testType === 2 ? 'Road' : testType === 3 ? 'Skills' : ''
  },
  {
    title: 'Status',
    dataIndex: 'testPass',
    render: (_, { testPass }) => camelCaseToNormalWithSpaces(testPass)
  },
  {
    title: 'Fail Reasons',
    dataIndex: 'failReasons',
    render: (_, { failReasons }) =>
      failReasons && JSON.parse(failReasons).length ? JSON.parse(failReasons).join(', ') : ''
  },
  {
    title: 'Appointment Canceled',
    dataIndex: 'appointmentCanceled',
    render: (_, { appointmentCanceled }) => (appointmentCanceled ? 'Yes' : 'No')
  },
  {
    title: 'Appointment Canceled Reason',
    dataIndex: 'appointmentCanceledReason',
    render: (_, { appointmentCanceledReason }) =>
      appointmentCanceledReason ? appointmentCanceledReason.replace(/{|}/g, '') : ''
  },
  {
    title: 'Comment',
    dataIndex: 'comments',
    render: (_, { comments }) => comments
  }
];
