import React, { useContext } from 'react';
import { PersistentRepo } from '_src/shared/repo';

const Location = ({ branchId, locationType }) => {
  const locations = PersistentRepo.get('locations');
  const location = (locations || []).find(
    loc => branchId === loc.branchId && locationType === loc.type
  );

  return location ? location.label : '';
};

export default [
  {
    title: 'Student',
    dataIndex: 'name'
  },
  { title: 'Status', dataIndex: 'status' },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, { branchId, locationType }) => (
      <Location branchId={branchId} locationType={locationType} />
    )
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor'
  }
];
