const transformAll = ([yardsRes, branchesRes]) => {
  const { yards } = yardsRes.data;
  const branches = branchesRes.data.branches.reduce(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {}
  );

  return yards.map(({ id, name, branchId }) => ({
    id,
    name,
    branch: branches[branchId],
    branchId
  }));
};

export { transformAll };
