export default [
  {
    title: 'Question',
    dataIndex: 'question',
    component: {
      type: 'input'
    }
  },
  {
    title: 'Type',
    dataIndex: 'questionType',
    render: type => (type === 1 ? 'Skills Evaluation' : 'Student Characteristics')
  },
  {
    title: 'Location Type',
    dataIndex: 'locationType',
    component: {
      type: 'select',
      options: [
        { id: 'classroom', label: 'Classroom' },
        { id: 'yard', label: 'Yard' },
        { id: 'both', label: 'Both' },
        { id: 'inactive', label: 'Inactive' }
      ]
    }
  },
  {
    title: 'Active',
    dataIndex: 'active',
    render: active => (active ? 'Active' : 'Inactive')
  }
];
