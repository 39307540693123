import { stripNullObj } from 'utils';

const transformTestsScore = (data, tests) => {
  tests = tests.reduce((acc, [countKey, scoreKey, dataCountKey, dataScoreKey]) => {
    const testCount = Math.round(data[dataCountKey || countKey]);
    const testScore = data[dataScoreKey || scoreKey] || 0;

    return {
      ...acc,
      [countKey]: testCount,
      [scoreKey]: testCount > 0 ? Math.round(testScore * 100) + '%' : '-'
    };
  }, {});

  return tests;
};

const transformData = (data, type) => {
  const tests = [
    ['pretrips', 'pretripsScore'],
    ['road', 'roadsScore'],
    ['skills', 'skillsScore']
  ].map(([countKey, scoreKey]) => [
    countKey,
    scoreKey,
    countKey + type,
    scoreKey + (type === 'Branch' ? 'Branch' : '')
  ]);

  return {
    ...transformTestsScore(data, tests),
    yardHoursTrained: data['yardHourstrainedInstructor'].toFixed(2),
    classHoursTrained: data['classHourstrainedInstructor'].toFixed(2),
    openSessions: data['openSessions' + type].toFixed(0),
    totalCheckins: data['totalCheckins' + type].toFixed(0),
    students: data['students' + type].toFixed(0)
  };
};

const transform = res => {
  const data = res.data.usageReport[0];
  let { usage, company } = data;
  const {
    studentsWhoAttended = 0,
    yardHourstraining = 0,
    classHourstraining,
    ...restCompany
  } = stripNullObj(company[0]);

  company = {
    studentsWhoAttended,
    hourstraining: `${classHourstraining.toFixed(2)} / ${yardHourstraining.toFixed(2)}`,
    ...restCompany,
    ...transformTestsScore(restCompany, [
      ['companyPretrips', 'companyScorePretrips'],
      ['companyRoads', 'companyScoreRoads'],
      ['companySkills', 'companyScoreSkills']
    ])
  };

  usage = usage.map(school => {
    const children = school.children
      .map(child => ({
        id: `${child.userId}x${child.branchId}`,
        name: child.instructorName,
        activeYardStudents: child.activeYardStudents,
        ...transformData(child, 'Instructor')
      }))

    return {
      id: school.branchId,
      name: school.branchName,
      activeYardStudents: school.activeYardStudents,
      ...transformData(school, 'Branch'),
      children
    };
  });

  return { company, usage };
};

export { transform };
