import React, { useContext, useEffect, useState } from 'react';
import { useFetch, Context } from 'store';

import services from '_src/services';
import PerformanceCard from '../PerformanceCard';
const config = services.reports;
import { Container } from './styles';
import { Skeleton } from 'antd';

const PerformanceCardContainer = ({
  id,
  onMoreInfoClick,
  onCardButtonPress,
  performance,
  parentCallback,
  studentEvaluations
}) => {
  const { getData } = useContext(Context);
  const { locationType } = getData('studentTimeline') || {};
  const isCardButtonDisabled = locationType != 1;

  const [performanceCards, setPerformanceCards] = useState(performance || {});
  const { get: getStudentPerformance } = useFetch(config.getPerformance);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (!performance) {
      getStudentPerformance({ replace: { id } }).then(data => {
        setPerformanceCards(data);
        setLoading(false);
        parentCallback(false);
        studentEvaluations(data);
      });
    }
  }, [performance]);

  useEffect(() => {
    if (performance) {
      setPerformanceCards(performance);
    }
  }, [performance]);

  const getCrdInfo = (obj = {}) => obj;

  return (
    <Skeleton loading={loading} paragraph={{ rows: 4 }} active={true}>
      <Container>
        <PerformanceCard
          type={1}
          title="Pre-Trip"
          status={getCrdInfo(performanceCards[1]).status}
          currPerformance={getCrdInfo(performanceCards[1]).currPerformance}
          buttonDisabled={isCardButtonDisabled}
          onButtonPress={onCardButtonPress}
          onMoreInfoPress={onMoreInfoClick}
        />

        <PerformanceCard
          type={3}
          title="Skills"
          status={getCrdInfo(performanceCards[3]).status}
          currPerformance={getCrdInfo(performanceCards[3]).currPerformance}
          buttonDisabled={isCardButtonDisabled}
          onButtonPress={onCardButtonPress}
          onMoreInfoPress={onMoreInfoClick}
        />

        <PerformanceCard
          type={2}
          title="Road"
          status={getCrdInfo(performanceCards[2]).status}
          currPerformance={getCrdInfo(performanceCards[2]).currPerformance}
          buttonDisabled={isCardButtonDisabled}
          onButtonPress={onCardButtonPress}
          onMoreInfoPress={onMoreInfoClick}
        />

        <PerformanceCard
          type={4}
          title="Character"
          status={getCrdInfo(performanceCards[4]).status}
          currPerformance={getCrdInfo(performanceCards[4]).currPerformance}
          buttonDisabled={!performance}
          onButtonPress={onCardButtonPress}
          onMoreInfoPress={onMoreInfoClick}
        />
      </Container>
    </Skeleton>
  );
};

export default PerformanceCardContainer;
