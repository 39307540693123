import React, { useContext, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Card, DatePicker, Spin, Popover, notification } from 'antd';

import services from '_src/services';
import { Context, useFetch, useSave, useDelete } from 'store';
import { AsyncTable, Modal } from '_src/components';
import { debounce } from '_src/utils';
import { PersistentRepo } from '_src/shared/repo';
import { Select, Input } from '_src/shared/form-helpers';
import { StudentPerformanceEvaluator } from '../Attendance/components';
import { PageContainer } from '_src/shared/styles';
import StyledButton from '../../ui/Button/styles';
import { Button } from '_src/ui';

import {
  FilterRow,
  EvaluationsContainer,
  ButtonContainer,
  PopoverContentContainer,
  PopoverOption,
  OdometerContainer,
  FooterContainer,
  ModalHeader,
  FilterContainer,
  OdometerButtonContainer,
  CreateButtonContainer,
  ModalHeaderContainer,
  CreateMileageContainer,
  LoadingContainer
} from './styles';

let timezoneParam = moment().utcOffset() / 60;

if (timezoneParam > 0) {
  timezoneParam = 'GMT ' + timezoneParam;
} else if (timezoneParam < 0) {
  timezoneParam = 'GMT' + timezoneParam;
} else if (timezoneParam === 0) {
  timezoneParam = 'GMT 0';
}

export default () => {
  const attendanceConfig = services['attendance'];
  const evaluationTestsConfig = services['evaluationTests'];
  const { getShown, getData, setShown } = useContext(Context);
  const { get: getStudentMileage } = useFetch(evaluationTestsConfig.getStudentMileage);
  const { save: editMileage, busy: editLoading } = useSave(evaluationTestsConfig.editMileage);
  const { save: createMileage, busy: createLoading } = useSave(evaluationTestsConfig.createMileage);
  const { destroy: deleteMileage } = useDelete(evaluationTestsConfig.deleteMileage);
  const [getMileagesLoading, setMileagesLoading] = useState(false);

  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const [location, setLocation] = useState(getInitialLocation());
  const [studentName, setStudentName] = useState('');
  const [type, setType] = useState('all');
  const [date, setDate] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [testingType, setTestingType] = useState(-1);
  const [studentMileage, setStudentMileage] = useState();
  const [newMileages, setNewMileages] = useState([]);
  const [mileageDate, setMileageDate] = useState(date ? date : moment());
  const [showMilageEdit, setShowMileageEdit] = useState(false);
  const [editMileages, setEditMileages] = useState({});
  const [errors, setError] = useState({});
  const [newMileageErrors, setNewMileageErrors] = useState({});
  const [trigger, setTrigger] = useState(false);

  const userId = getData('user').id;

  const getMileages = date => {
    setMileagesLoading(true);
    getStudentMileage({
      params: {
        studentId: selectedStudent.id,
        responses: true,
        evaluationDate: moment(date ? date : mileageDate).format('DD-MM-YYYY'),
        type: 1,
        userId
      }
    }).then(studentEvaluations => {
      setMileagesLoading(false);
      if (studentEvaluations[0]) {
        let editMileagesObject = {};
        studentEvaluations[0].details.trucks.map(
          (item, index) => (editMileagesObject[index] = item)
        );
        setEditMileages(editMileagesObject);
      }
      setStudentMileage(studentEvaluations[0]);
    });
  };

  useEffect(() => {
    if (showMilageEdit) {
      getMileages();
    }
  }, [showMilageEdit]);

  const [filterParams, setFilterParams] = useState({
    branchId: location.split('x')[2],
    locationType: location.split('x')[0] == 'all' ? 0 : location.split('x')[0] === 'yard' ? 1 : 2,
    ...(date && { testDate: date.format('MM/DD/YYYY') }),
    ...(studentName && { student: studentName })
  });

  const debounceCallback = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );

  const formatLatestResult = (lastestResult, type) => {
    if (lastestResult) {
      lastestResult = type
        ? `${Math.round(lastestResult.split('%')[0])}% Performance`
        : Math.round(lastestResult * 100) + '% Completed';

      return lastestResult;
    }

    return '-';
  };

  const handleEditMileage = create => {
    if (studentMileage) {
      const { id, type, evaluationDate, responses, details, studentId } = studentMileage;
      const newMilesAdded = newMileages.filter(
        ({ odometerStart, odometerEnd }) => odometerStart != null && odometerEnd != null
      );

      editMileage({
        id: id,
        type: type,
        evaluationDate: evaluationDate,
        responses: responses,
        details: {
          trucks: [...Object.values(editMileages).map(item => item), ...newMilesAdded]
        }
      }).then(() => {
        getMileages(mileageDate);
        create && setNewMileages([]);
        notification.success({
          message: 'Success',
          description: 'Mileages has been successfully submited',
          duration: 3
        });
      });
    } else {
      const newMilesAdded = newMileages.filter(
        ({ odometerStart, odometerEnd }) => odometerStart != null && odometerEnd != null
      );
      createMileage({
        userId: userId,
        studentId: selectedStudent.id,
        type: 1,
        responses: [],
        evaluationDate: mileageDate.unix(),
        details: {
          trucks: newMilesAdded
        }
      }).then(() => {
        getMileages(mileageDate);
        setNewMileages([]);
        notification.success({
          mesage: 'Success',
          description: 'Mileages has been successfully submited',
          duration: 3
        });
      });
    }
  };

  const roadOptions = (clickType, item, id, type) => {
    return (
      <PopoverContentContainer>
        <PopoverOption
          onClick={() => {
            if (clickType) {
              setShowMileageEdit(true);
              setSelectedStudent(item);
            }
          }}
        >
          Edit Mileage
        </PopoverOption>
        <PopoverOption
          onClick={() => {
            if (clickType) {
              setSelectedStudent(item);
              setTestingType(id);
              setShown(type);
            }
          }}
        >
          Edit Tests
        </PopoverOption>
      </PopoverContentContainer>
    );
  };

  return (
    <PageContainer>
      <Card title="Edit Evaluation Test">
        <FilterRow>
          <Select
            label="Filter by Branch"
            value={location}
            showSearch
            onChange={loc => {
              setLocation(loc);
              PersistentRepo.set('location', loc);
              const [locationType, locationId, branchId] = loc.split('x');

              setFilterParams({
                ...filterParams,
                branchId: branchId,
                locationType: locationType === 'yard' ? 1 : locationType === 'all' ? 0 : 2
              });
            }}
            {...attendanceConfig.location}
          />

          <Input
            label="Filter by Student"
            value={studentName}
            onChange={value => {
              setStudentName(value);
              debounceCallback({ ...filterParams, student: value });
            }}
          />

          <div>
            <span>Filter by date</span>
            <DatePicker
              label="Date"
              allowClear={false}
              value={date}
              onChange={value => {
                const formatedDate = moment(value).format('MM/DD/YYYY');
                setDate(value);
                setFilterParams({ ...filterParams, testDate: formatedDate });
              }}
            />
          </div>

          <Select
            label="Filter by Type"
            value={type}
            onChange={value => setType(value)}
            options={[{ id: 'all', label: 'All' }, ...evaluationTestsConfig.types]}
          />
        </FilterRow>

        <AsyncTable
          resource={'evaluationTests'}
          pagination
          fileName={'evaluationTests'}
          params={filterParams}
          config={{
            ...evaluationTestsConfig,
            table: [
              ...evaluationTestsConfig.table,
              {
                title: 'Evaluations',
                key: 'evaluations',
                render: (_, item) => {
                  return (
                    <EvaluationsContainer>
                      {evaluationTestsConfig.types
                        .filter(item => (type === 'all' ? item : item.id === type))
                        .map(({ id, label, showForBranch }) => {
                          const clickType =
                            !(item.locationType === 2 && !showForBranch) &&
                            item.performance[id] &&
                            true;

                          const completed = item.performance
                            ? formatLatestResult(item.performance.testPercentages[id], 0)
                            : '';

                          const performance = item.performance
                            ? formatLatestResult(item.performance[id]?.lastestResult, 1)
                            : '';

                          return (
                            <ButtonContainer
                              key={id}
                              onClick={() => {
                                if (
                                  !(item.locationType === 2 && !showForBranch) &&
                                  item.performance[id] &&
                                  id != 2
                                ) {
                                  setSelectedStudent(item);
                                  setTestingType(id);
                                  setShown('testing');
                                }
                              }}
                            >
                              <div style={{ marginBottom: '5px' }}>{label}</div>
                              {/* <Tooltip
                              title={
                                item.performance[id] ? item.performance[id].currPerformance : ''
                              }
                            > */}
                              <Popover
                                content={
                                  id === 2
                                    ? roadOptions(clickType, item, id, 'testing')
                                    : item.performance[id]
                                    ? item.performance[id].currPerformance
                                    : ''
                                }
                                title={id === 2 ? 'Please Select' : ''}
                                trigger="hover"
                              >
                                <StyledButton
                                  key={id}
                                  filled={true}
                                  color={
                                    item.performance[id]
                                      ? evaluationTestsConfig.performanceColor[
                                          item.performance[id].currPerformance
                                        ]
                                      : '#c1c1c1'
                                  }
                                  text={label}
                                  height={55}
                                  width={155}
                                  disabled={item.locationType === 2 && !showForBranch}
                                  style={{ flexDirection: 'column' }}
                                >
                                  <span>{performance == '-' ? '-' : completed}</span>

                                  <span>{performance}</span>
                                </StyledButton>
                              </Popover>

                              {/* </Tooltip> */}
                            </ButtonContainer>
                          );
                        })}
                    </EvaluationsContainer>
                  );
                }
              }
            ]
          }}
        />

        {getShown('testing') && (
          <>
            <StudentPerformanceEvaluator
              resource="testing"
              type={testingType}
              student={selectedStudent}
              evaluationTests={true}
            />
          </>
        )}

        <Modal visible={showMilageEdit} footer={null} width="55%">
          <ModalHeaderContainer>
            <ModalHeader> Edit Student Mileage</ModalHeader>
            <Button
              loading={false}
              onClick={() => {
                setShowMileageEdit(false);
                setNewMileages([]);
                setEditMileages([]);
              }}
              height={'40'}
              width={'100'}
              text="Cancel"
            />
          </ModalHeaderContainer>

          <FilterContainer>
            <div>
              <span>Filter by date</span>
              <DatePicker
                label="Date"
                allowClear={false}
                value={moment(mileageDate)}
                onChange={value => {
                  setMileageDate(value);
                  setNewMileages([]);
                  getMileages(value);
                }}
              />
            </div>

            <div>
              <Button
                loading={false}
                onClick={() =>
                  setNewMileages([
                    ...newMileages,
                    {
                      odometerEnd: null,
                      odometerStart: null,
                      sticker: null
                    }
                  ])
                }
                height={'30'}
                width={'100'}
                text="Create New"
              />
            </div>
          </FilterContainer>

          {getMileagesLoading ? (
            <LoadingContainer>
              <Spin size="large" />
            </LoadingContainer>
          ) : (
            <div>
              {newMileages.length > 0 && (
                <CreateMileageContainer>
                  {newMileages.map(({ odometerEnd, odometerStart, sticker }, index) => {
                    return (
                      <OdometerContainer key={index}>
                        <Input
                          name="sticker"
                          label="SOS Sticker #"
                          placeholder="SOS Sticker #"
                          value={sticker}
                          onChange={value => {
                            let oldNewMileages = newMileages;
                            oldNewMileages[index].sticker = value;

                            setNewMileages(oldNewMileages);
                            setTrigger(!trigger);
                          }}
                        />
                        <Input
                          name="odometerStart"
                          label="Odometer Start"
                          placeholder="Odometer Start"
                          value={odometerStart}
                          min={0}
                          onChange={value => {
                            let checkedValue = value < 0 ? 0 : value;
                            parseInt(checkedValue) > parseInt(odometerEnd)
                              ? setNewMileageErrors({
                                  ...newMileageErrors,
                                  [index]: {
                                    odometerStart:
                                      'Start value must be smaller than oddometer end value'
                                  }
                                })
                              : setNewMileageErrors({
                                  ...newMileageErrors,
                                  [index]: { odometerStart: '' }
                                });

                            let oldNewMileages = newMileages;
                            oldNewMileages[index].odometerStart = checkedValue;
                            setNewMileages(oldNewMileages);
                          }}
                          touched
                          error={newMileageErrors[index]?.odometerStart}
                          type="number"
                        />
                        <Input
                          name="odometerEnd"
                          label="Odometer End"
                          placeholder="Odometer End"
                          value={odometerEnd}
                          min={0}
                          onChange={value => {
                            let checkedValue = value < 0 ? 0 : value;
                            parseInt(checkedValue) < parseInt(odometerStart)
                              ? setNewMileageErrors({
                                  ...newMileageErrors,
                                  [index]: {
                                    odometerEnd:
                                      'End value must be greater than oddometer start value'
                                  }
                                })
                              : setNewMileageErrors({
                                  ...newMileageErrors,
                                  [index]: { odometerEnd: '' }
                                });

                            let oldNewMileages = newMileages;
                            oldNewMileages[index].odometerEnd = checkedValue;
                            setNewMileages(oldNewMileages);
                          }}
                          touched
                          error={newMileageErrors[index]?.odometerEnd}
                          type="number"
                        />

                        <OdometerButtonContainer>
                          <Button
                            color={GLOBALS.colors.RED}
                            loading={false}
                            onClick={() => {
                              setNewMileages(newMileages.filter((_, i) => i != index));
                            }}
                            height={'30'}
                            width={'100'}
                            text="Remove"
                          />
                        </OdometerButtonContainer>
                      </OdometerContainer>
                    );
                  })}

                  <CreateButtonContainer>
                    <Button
                      disabled={
                        editLoading ||
                        createLoading ||
                        getMileagesLoading ||
                        newMileages.filter(item => item.odometerStart && item.odometerEnd).length ==
                          0
                      }
                      onClick={() => handleEditMileage('create')}
                      height={'35'}
                      width={'100'}
                      shadow
                      filled
                      text="Create"
                    />
                  </CreateButtonContainer>
                </CreateMileageContainer>
              )}

              {studentMileage?.details.trucks.map(
                ({ odometerStart, odometerEnd, sticker }, index) => {
                  return (
                    <OdometerContainer key={index}>
                      <Input
                        name="sticker"
                        label="SOS Sticker #"
                        placeholder="SOS Sticker #"
                        value={editMileages[index]?.sticker}
                        onChange={value => {
                          setEditMileages({
                            ...editMileages,
                            [index]: { ...editMileages[index], sticker: value }
                          });
                        }}
                      />
                      <Input
                        name="odometerStart"
                        label="Odometer Start"
                        placeholder="Odometer Start"
                        value={editMileages[index]?.odometerStart}
                        onChange={value => {
                          let checkedValue = value < 0 ? 0 : value;

                          parseInt(checkedValue) > parseInt(editMileages[index]?.odometerEnd)
                            ? setError({
                                ...errors,
                                [index]: 'Start value must be smaller than oddometer end value'
                              })
                            : setError({
                                ...errors,
                                [index]: ''
                              });
                          setEditMileages({
                            ...editMileages,
                            [index]: { ...editMileages[index], odometerStart: checkedValue }
                          });
                        }}
                        touched
                        min={0}
                        error={errors[index]?.length > 0 ? errors[index] : ''}
                        type="number"
                      />
                      <Input
                        name="odometerEnd"
                        label="Odometer End"
                        placeholder="Odometer End"
                        min={0}
                        value={editMileages[index]?.odometerEnd}
                        onChange={value => {
                          let checkedValue = value < 0 ? 0 : value;

                          parseInt(checkedValue) < parseInt(editMileages[index]?.odometerStart)
                            ? setError({
                                ...errors,
                                [index]: 'End value must be greater than start value'
                              })
                            : setError({
                                ...errors,
                                [index]: ''
                              });
                          setEditMileages({
                            ...editMileages,
                            [index]: { ...editMileages[index], odometerEnd: checkedValue }
                          });
                        }}
                        touched
                        error={errors[index]?.length > 0 ? errors[index] : ''}
                        type="number"
                      />

                      <OdometerButtonContainer>
                        <Button
                          color={GLOBALS.colors.RED}
                          disabled={editLoading || createLoading || getMileagesLoading}
                          onClick={() => {
                            let oldMileages = editMileages;
                            delete oldMileages[index];
                            setEditMileages(oldMileages);
                            handleEditMileage();
                          }}
                          height={'30'}
                          width={'100'}
                          shadow
                          filled
                          text="Delete"
                        />
                      </OdometerButtonContainer>
                    </OdometerContainer>
                  );
                }
              )}

              {Object.keys(editMileages).length > 0 && (
                <FooterContainer>
                  <Button
                    disabled={
                      editLoading ||
                      createLoading ||
                      Object.values(editMileages).filter(
                        item => item.odometerStart?.length != 0 && item.odometerEnd?.length != 0
                      ).length == 0
                    }
                    onClick={() => handleEditMileage()}
                    height={'35'}
                    width={'100'}
                    shadow
                    filled
                    text="Edit"
                  />
                </FooterContainer>
              )}
            </div>
          )}
        </Modal>
      </Card>
    </PageContainer>
  );
};
