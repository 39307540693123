import moment from 'moment';

const transformAll = resp => {
  const total = resp.data?.students?.total;
  const data = (resp.data?.students?.responses || []).map(item => {
    return {
      ...item,
      submitDate: moment(item.interventionCreatedAt).parseZone().format('MM/DD/YYYY'),
      key: moment(item.interventionCreatedAt).unix()
    };
  });

  return { data, total };
};

export { transformAll };
