import React from 'react';
import { Spin } from 'antd';

import StyledButton from './styles';

const colors = GLOBALS.colors;

const Button = ({
  filled = false,
  shadow = false,
  color = colors.GREEN,
  onClick = () => {},
  disabled = false,
  width,
  height,
  text = '',
  margin = '0',
  alignSelf = 'stretch',
  loading
}) => {
  return (
    <StyledButton
      onClick={() => {
        if (!loading && !disabled) {
          onClick();
        }
      }}
      disabled={disabled}
      filled={filled}
      shadow={shadow}
      color={color}
      width={width}
      height={height}
      margin={margin}
      alignSelf={alignSelf}
    >
      {loading ? <Spin size="small" /> : text}
    </StyledButton>
  );
};

export default Button;
