import styled from 'styled-components';

const FilterRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(24%, auto));
  grid-gap: 1.3%;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
`;

const EvaluationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PopoverContentContainer = styled.div``;

const PopoverOption = styled.div`
  font-size: 15px;
  color: 'black';
  cursor: pointer;
  margin-top: 10px;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const ModalHeader = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
`;

const OdometerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const OdometerButtonContainer = styled.div`
  display: flex;
  padding-top: 15px;
`;

const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateMileageContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
`;

const LoadingContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px 0;
`;

export {
  FilterRow,
  ButtonContainer,
  EvaluationsContainer,
  PopoverContentContainer,
  PopoverOption,
  OdometerContainer,
  FooterContainer,
  ModalHeader,
  FilterContainer,
  OdometerButtonContainer,
  CreateButtonContainer,
  ModalHeaderContainer,
  CreateMileageContainer,
  LoadingContainer
};
