import React from 'react';

import { Blueprint, ErrorBoundary } from '_src/components';
import { Restricted } from '_src/shared/restrict';
import services from '_src/services';

import AttendanceView from './Attendance';
import ReportsView from './Reports';
import LoginView from './Login';
import AppView from './App';
import { Drivers } from './DrivingScore';
import { Employers } from './EmployersList';
import LmsUsageView from './LmsUsage';
import CustomReportView from './CustomReports';
import ProfileView from './Profile';
import StudentProgressView from './StudentProgress';
import AdminAttendanceView from './AdminAttendance';
import BulkYardManagementView from './BulkYardManagement';
import ActivityLogsView from './ActivityLogs';
import ManageLocationTypeView from './ManageLocationType';
import YardScheduleView from './YardSchedule';
import ManageDuplicatesView from './ManageDuplicates';
import ForgotPasswordView from './ForgotPassword';
import StudentRecordsView from './StudentRecords';
import SingleStudentRecordView from './SingleStudentRecord';
import InstructorReportsView from './InstructorReports';
import ClassroomScoreView from './ClassroomScore';
import FmcsaSubmissionsView from './FmcsaSubmissions';
import UsersView from './Users';
import SubmissionReportView from './SubmissionReport';
import EvaluationTestsView from './EvaluationTests';
import CDLTestingView from './CDLTesting';
import CDLTestingOfStudentView from './CDLTestingOfStudent';
import StudentGroupsView from './StudentGroups';
import StudentAgreementsView from './StudentAgreements';
import DocumentsView from './Documents';
import AFPDriversReportView from './AFPDriversReport';
import StudentInterventionView from './StudentIntervention';

import { Refresh } from '_src/components';
const ErrorBoundBlueprint = ({ resource }) => {
  const config = services[resource];
  const { restrict = resource } = config;

  return (
    <ErrorBoundary>
      <Restricted resource={restrict} method="GET">
        <Refresh errorBoundery={true} />
        <Blueprint resource={resource} />
      </Restricted>
    </ErrorBoundary>
  );
};

// const Groups = () => <ErrorBoundBlueprint resource="groups" />;
const Branches = () => <ErrorBoundBlueprint resource="branches" />;
const Regions = () => <ErrorBoundBlueprint resource="regions" />;
const AutomatedEmails = () => <ErrorBoundBlueprint resource="automatedEmails" />;
const CharacterQuestions = () => <ErrorBoundBlueprint resource="characterQuestions" />;
const RoadQuestions = () => <ErrorBoundBlueprint resource="roadQuestions" />;
const PreTripsQuestions = () => <ErrorBoundBlueprint resource="preTripsQuestions" />;

const Attendance = () => (
  <ErrorBoundary>
    <Restricted resource="attendance" method="GET">
      <AttendanceView />
    </Restricted>
  </ErrorBoundary>
);

const EvaluationTests = () => (
  <ErrorBoundary>
    <Restricted resource="admin/testing" method="GET">
      <EvaluationTestsView />
    </Restricted>
  </ErrorBoundary>
);

const StudentGroups = () => (
  <ErrorBoundary>
    <Restricted resource="students/groups" method="POST">
      <StudentGroupsView />
    </Restricted>
  </ErrorBoundary>
);

const Login = () => (
  <ErrorBoundary>
    <LoginView />
  </ErrorBoundary>
);

const StudentRecords = () => (
  <ErrorBoundary>
    <StudentRecordsView />
  </ErrorBoundary>
);

const SingleStudentRecord = () => (
  <ErrorBoundary>
    <SingleStudentRecordView />
  </ErrorBoundary>
);

const Reports = () => (
  <ErrorBoundary>
    <Restricted resource="reports/attendance" method="GET">
      <ReportsView />
    </Restricted>
  </ErrorBoundary>
);

const InstructorReports = () => (
  <ErrorBoundary>
    <Restricted>
      <InstructorReportsView resource="reports/attendance" method="GET" />
    </Restricted>
  </ErrorBoundary>
);

const FmcsaSubmissions = () => (
  <ErrorBoundary>
    <Restricted>
      <FmcsaSubmissionsView resource="fmcsa" />
    </Restricted>
  </ErrorBoundary>
);

const SkillQuestions = () => (
  <ErrorBoundary>
    <Restricted>
      <SkillQuestionsView resource="skillQuestions" />
    </Restricted>
  </ErrorBoundary>
);

const Users = () => (
  <ErrorBoundary>
    <Restricted>
      <UsersView resource="users" />
    </Restricted>
  </ErrorBoundary>
);

const CDLTesting = () => (
  <ErrorBoundary>
    <Restricted>
      <CDLTestingView resource="cdl/testing" />
    </Restricted>
  </ErrorBoundary>
);

const CDLTestingOfStudent = () => (
  <ErrorBoundary>
    <CDLTestingOfStudentView />
  </ErrorBoundary>
);

const StudentAgreements = () => (
  <ErrorBoundary>
    <Restricted>
      <StudentAgreementsView resource="hazmat" />
    </Restricted>
  </ErrorBoundary>
);

const Documents = () => (
  <ErrorBoundary>
    <Restricted>
      <DocumentsView resource="afp_documents" />
    </Restricted>
  </ErrorBoundary>
);

const CustomReports = () => (
  <ErrorBoundary>
    <CustomReportView />
  </ErrorBoundary>
);

const LmsUsage = () => (
  <ErrorBoundary>
    <Restricted resource="reports/usage" method="GET">
      <LmsUsageView />
    </Restricted>
  </ErrorBoundary>
);

const SubmissionReport = () => (
  <ErrorBoundary>
    <SubmissionReportView />
  </ErrorBoundary>
);

const DriverList = () => (
  <ErrorBoundary>
    <Restricted resource="driver/scores/search" method="GET">
      <Drivers />
    </Restricted>
  </ErrorBoundary>
);

const EmployersList = () => (
  <ErrorBoundary>
    <Restricted resource="driver/scores/search" method="GET">
      <Employers />
    </Restricted>
  </ErrorBoundary>
);

const App = () => (
  <ErrorBoundary>
    <AppView />
  </ErrorBoundary>
);

const Profile = () => (
  <ErrorBoundary>
    <ProfileView />
  </ErrorBoundary>
);

const StudentProgress = props => (
  <ErrorBoundary>
    <StudentProgressView {...props} />
  </ErrorBoundary>
);

const AdminAttendance = () => (
  <ErrorBoundary>
    <Restricted resource="admin/attendance" method="GET">
      <AdminAttendanceView />
    </Restricted>
  </ErrorBoundary>
);

const BulkYardManagement = () => (
  <ErrorBoundary>
    <Restricted resource="admin/attendance" method="GET">
      <BulkYardManagementView />
    </Restricted>
  </ErrorBoundary>
);

const ActivityLogs = () => (
  <ErrorBoundary>
    <ActivityLogsView />
  </ErrorBoundary>
);

const ManageLocationType = () => (
  <ErrorBoundary>
    <Restricted resource={['attendance', 'users']} method="POST">
      <ManageLocationTypeView />
    </Restricted>
  </ErrorBoundary>
);

const YardSchedule = () => (
  <ErrorBoundary>
    <Restricted resource={['attendance', 'users']} method="POST">
      <YardScheduleView />
    </Restricted>
  </ErrorBoundary>
);

const StudentIntervention = () => (
  <ErrorBoundary>
    <Restricted resource="student_intervention" method="GET">
      <StudentInterventionView />
    </Restricted>
  </ErrorBoundary>
);

const ManageDuplicates = () => (
  <ErrorBoundary>
    <Restricted resource={['attendance', 'users']} method="POST">
      <ManageDuplicatesView />
    </Restricted>
  </ErrorBoundary>
);

const ClassroomScore = () => (
  <ErrorBoundary>
    <Restricted>
      <ClassroomScoreView />
    </Restricted>
  </ErrorBoundary>
);

const ForgotPassword = () => (
  <ErrorBoundary>
    <ForgotPasswordView />
  </ErrorBoundary>
);

const AFPDriversReport = () => (
  <ErrorBoundary>
    <Restricted>
      <AFPDriversReportView resource="afp_documents" />
    </Restricted>
  </ErrorBoundary>
);

export {
  Users,
  // Groups,
  Regions,
  Branches,
  Attendance,
  Login,
  StudentRecords,
  SingleStudentRecord,
  Reports,
  App,
  CharacterQuestions,
  RoadQuestions,
  PreTripsQuestions,
  AutomatedEmails,
  SkillQuestions,
  DriverList,
  EmployersList,
  LmsUsage,
  CustomReports,
  Profile,
  StudentProgress,
  AdminAttendance,
  BulkYardManagement,
  ActivityLogs,
  ManageLocationType,
  ManageDuplicates,
  ForgotPassword,
  InstructorReports,
  ClassroomScore,
  FmcsaSubmissions,
  SubmissionReport,
  EvaluationTests,
  CDLTesting,
  CDLTestingOfStudent,
  StudentGroups,
  StudentAgreements,
  Documents,
  AFPDriversReport,
  YardSchedule,
  StudentIntervention
};
