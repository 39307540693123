import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFetch, useSave } from 'store';
import { Card, Button, notification, DatePicker } from 'antd';
import { useParams } from 'react-router-dom';
import { Modal } from '_src/components';
import { Table, Loading } from '_src/components';
import { Select, Input } from '_src/shared/form-helpers';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { ModalTitle } from './styles';
import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

const categoriesOptions = [
  { id: 'preTrip', label: 'PreTrip' },
  { id: 'skills', label: 'Skills' },
  { id: 'road', label: 'Road' }
];

const allOptions = {
  preTripReasons: [
    { id: 'Timed Out', label: 'Timed Out' },
    { id: 'Too Many Points', label: 'Too Many Points' },
    { id: 'Air Brakes', label: 'Air Brakes' }
  ],
  skillReasons: [
    { id: 'Timed Out', label: 'Timed Out' },
    { id: 'Too Many Points', label: 'Too Many Points' },
    { id: 'Auto Fail', label: 'Auto Fail' }
  ],
  roadReasons: [
    { id: 'Coasting - Auto Fail', label: 'Coasting - Auto Fail' },
    { id: 'Too Many Points', label: 'Too Many Points' },
    { id: 'Speeding - Auto Fail', label: 'Speeding - Auto Fail' },
    { id: 'Rolling Back - Auto Fail', label: 'Rolling Back - Auto Fail' },
    { id: 'Examiner Intervention - Auto Fail', label: 'Examiner Intervention - Auto Fail' },
    { id: 'Driving Too Slowly - Auto Fail', label: 'Driving Too Slowly - Auto Fail' },
    { id: 'Hitting a curb - Auto Fail', label: 'Hitting a curb - Auto Fail' },
    { id: 'Hitting an Object - Auto Fail', label: 'Hitting an Object - Auto Fail' }
  ]
};

const CDLTest = () => {
  const [cdlTestingRes, setCdlTestingRes] = useState([]);
  const config = services['cdlTesting'];
  const { id: cdlTestingId } = useParams();
  const { get: getCdlTesting } = useFetch(config.getCdlTestingById);
  const [initialValues, setInitialValues] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [edit, setEdit] = useState(true);
  const [currentEditData, setCurrentEditData] = useState({
    appointmentDate: '',
    testPass: '',
    testType: null,
    studentId: '',
    failReasons: []
  });
  const [errors, setError] = useState({});
  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(false);

  const { save: updateFailReason } = useSave({ ...config.edit });
  const { save: createCDLTesting } = useSave({ ...config.create });

  const submitStudent = async () => {
    setLoading(true);
    setError({
      appointmentDate: !currentEditData.appointmentDate ? 'Testing Date is required' : '',
      testPass: currentEditData.testPass === '' ? 'Result is required' : '',
      testType: !currentEditData.testType ? 'Testing Category is required' : '',
      studentId: !currentEditData.studentId ? 'Student is required' : ''
    });

    if (
      !currentEditData.appointmentDate ||
      currentEditData.testPass === '' ||
      !currentEditData.testType ||
      !currentEditData.studentId
    ) {
      setLoading(false);
      return false;
    }

    const payload = {
      appointmentDate: currentEditData.appointmentDate.unix(),
      testPass: currentEditData.testPass,
      testType: currentEditData.testType,
      studentId: currentEditData.studentId,
      failReasons: currentEditData.testPass !== 'pass' ? currentEditData.failReasons : [],
      ...((currentEditData.testPass === 'fail' ||
        currentEditData.testPass === 'timedOut' ||
        currentEditData.testPass === 'unableToTest' ||
        currentEditData.testPass === 'studentNoShow' ||
        currentEditData.testPass === 'incompleteTest') && { comments: currentEditData.comments }),
      id: initialValues.id
    };

    try {
      const res = await updateFailReason(payload);
      setLoading(false);
      if (res.success === 1) {
        setShowEdit(false);
        setCurrentEditData({
          appointmentDate: '',
          testPass: '',
          testType: null,
          studentId: '',
          failReasons: []
        });
        fetchCDLTesting();
        notification.success({
          message: `Test updated successfully`,
          duration: 5
        });
      } else {
        notification.error({
          message: res.data.message,
          duration: 5
        });
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'An error has occurred',
        duration: 5
      });
    }
  };

  const submitStudentCreate = async () => {
    setLoading(true);
    setError({
      appointmentDate: !currentEditData.appointmentDate ? 'Testing Date is required' : '',
      testPass: currentEditData.testPass === '' ? 'Result is required' : '',
      testType: !currentEditData.testType ? 'Testing Category is required' : '',
      studentId: !student.id ? 'Student is required' : ''
    });

    if (
      !currentEditData.appointmentDate ||
      currentEditData.testPass === '' ||
      !currentEditData.testType ||
      !student.id
    ) {
      setLoading(false);
      return false;
    }

    const payload = {
      appointmentDate: currentEditData.appointmentDate.unix(),
      testPass: currentEditData.testPass,
      testType: currentEditData.testType,
      studentId: student.id,
      failReasons: currentEditData.failReasons || []
    };

    try {
      const res = await createCDLTesting(payload);
      setLoading(false);
      if (res.success === 1) {
        setShowEdit(false);
        setCurrentEditData({
          appointmentDate: '',
          testPass: '',
          testType: null,
          studentId: '',
          failReasons: []
        });
        fetchCDLTesting();
        notification.success({
          message: `Test updated successfully`,
          duration: 5
        });
      } else {
        notification.error({
          message: res.data.message,
          duration: 5
        });
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'An error has occurred',
        duration: 5
      });
    }
  };

  const handleModalClose = () => {
    setCurrentEditData({
      appointmentDate: '',
      testPass: false,
      testType: null,
      studentId: '',
      failReasons: []
    });
    setShowEdit(false);
  };

  const disabledDate = current => {
    const currentDate = new Date();
    return current && current > currentDate;
  };

  const fetchCDLTesting = async () => {
    setLoading(true);
    try {
      const res = await getCdlTesting({ replace: { id: cdlTestingId } });
      setCdlTestingRes(res[0].data.data.cdlTest);
      setStudent(res[0].data.data.student);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: err.message,
        duration: 3
      });
    }
  };

  useEffect(() => {
    fetchCDLTesting();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      {!!student && (
        <Card title={`CDL tests for ${student.firstName} ${student.lastName}`}>
          <div style={{ display: 'flex' }}>
            <p>First time pass: {student.cdlExamFirstTimePass ? <b>Yes</b> : <b>No</b>}</p>
          </div>
          <Table
            columns={[
              ...config.cdlTestingOfStudentTable,
              {
                id: 'submit',
                render: (_, student) =>
                  !student.appointmentCanceled && (
                    <Button
                      type="primary"
                      onClick={() => {
                        setInitialValues({ ...student });
                        setCurrentEditData({
                          appointmentDate: moment.parseZone(student.appointmentDate),
                          testPass: student.testPass,
                          testType: student.testType,
                          studentId: student.studentId,
                          failReasons: JSON.parse(student.failReasons),
                          comments: student.comments
                        });
                        setShowEdit(true);
                        setEdit(true);
                      }}
                    >
                      Edit
                    </Button>
                  )
              }
            ]}
            data={cdlTestingRes}
            bordered={false}
            loading={loading}
            dataKey="_id"
          />
        </Card>
      )}
      <Modal
        className="editCDLStudent"
        width={'60%'}
        title={
          <ModalTitle key="title">
            {edit ? 'Edit CDL Testing' : 'Create CDL Test Result'}
          </ModalTitle>
        }
        visible={showEdit}
        onCancel={() => setShowEdit(false)}
        footer={[
          <Button key="submitClose" type="default" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="submitEdit"
            type="primary"
            loading={loading}
            onClick={() => (edit ? submitStudent() : submitStudentCreate())}
            disabled={loading}
          >
            Submit
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Select
            label="Testing Category"
            options={categoriesOptions}
            onChange={value => {
              setCurrentEditData({
                ...currentEditData,
                failReasons: [],
                testType: value === 'preTrip' ? 1 : value === 'skills' ? 3 : 2
              });
              setError({ ...errors, testType: '' });
            }}
            value={
              currentEditData.testType === 1
                ? 'preTrip'
                : currentEditData.testType === 3
                ? 'skills'
                : currentEditData.testType === 2
                ? 'road'
                : ''
            }
            error={errors.testType ? errors.testType : ''}
            touched
            disabled
          ></Select>
          <div>
            <Label hasError={!!errors.appointmentDate}>
              {errors.appointmentDate ? errors.appointmentDate : 'Testing Date'}
            </Label>
            <DatePicker
              allowClear={false}
              value={currentEditData.appointmentDate}
              onChange={value => {
                setCurrentEditData({
                  ...currentEditData,
                  appointmentDate: value
                });
                setError({ ...errors, appointmentDate: '' });
              }}
              error={errors.appointmentDate ? errors.appointmentDate : ''}
              touched
              disabledDate={disabledDate}
            />
          </div>

          <Select
            label="Result"
            options={[
              { id: 'fail', label: 'Failed' },
              { id: 'pass', label: 'Passed' },
              { id: 'timedOut', label: 'Timed Out' },
              { id: 'unableToTest', label: 'Unable to Test' },
              { id: 'testSiteCancelled', label: 'Test Site Cancelled' },
              { id: 'studentNoShow', label: 'Student No-Show' },
              { id: 'incompleteTest', label: 'Incomplete Test' }
            ]}
            onChange={value =>
              setCurrentEditData({
                ...currentEditData,
                testPass: value,
                failReasons: []
              })
            }
            value={currentEditData.testPass}
            style={{ marginBottom: '10px' }}
            error={currentEditData.testPass === '' ? errors.testPass : ''}
            touched
          ></Select>
          {currentEditData.testPass === 'fail' && (
            <Select
              mode="multiple"
              label="Failed reason"
              options={
                allOptions[
                  `${
                    currentEditData.testType === 1
                      ? 'preTrip'
                      : currentEditData.testType === 3
                      ? 'skill'
                      : 'road'
                  }Reasons`
                ]
              }
              onChange={e => {
                setCurrentEditData({
                  ...currentEditData,
                  failReasons: [...e]
                });
              }}
              value={currentEditData.failReasons}
              style={{ marginBottom: '10px' }}
            ></Select>
          )}

          {currentEditData.testPass !== 'pass' && (
            <Input
              label="Comment"
              value={
                currentEditData.testPass === 'fail' ||
                currentEditData.testPass === 'timedOut' ||
                currentEditData.testPass === 'unableToTest' ||
                currentEditData.testPass === 'studentNoShow' ||
                currentEditData.testPass === 'incompleteTest'
                  ? currentEditData.comments
                  : currentEditData.failReasons.join()
              }
              onChange={note => {
                if (
                  currentEditData.testPass === 'fail' ||
                  currentEditData.testPass === 'timedOut' ||
                  currentEditData.testPass === 'unableToTest' ||
                  currentEditData.testPass === 'studentNoShow' ||
                  currentEditData.testPass === 'incompleteTest'
                ) {
                  setCurrentEditData({
                    ...currentEditData,
                    comments: note
                  });
                } else {
                  setCurrentEditData({
                    ...currentEditData,
                    failReasons: [note]
                  });
                }
              }}
            />
          )}
        </div>
      </Modal>
    </PageContainer>
  );
};

export default CDLTest;
