import React, { useContext, useState, useEffect } from 'react';
import { Context } from 'store';
import { Icon } from 'antd';

import { Modal } from '_src/components';

import { AbsentRow } from '../styles';

export default ({ students, save }) => {
  const resource = 'absence';
  const { setShown } = useContext(Context);
  students = students.filter(({ hasAttended }) => !hasAttended);
  const previousAbsent = students
    .filter(({ absent }) => absent)
    .reduce(
      (acc, { id, absent, attendance }) => ({
        ...acc,
        [id]: { isExcused: absent.isExcused, attendanceId: attendance.id }
      }),
      {}
    );

  const [absent, setAbsent] = useState({});

  const getAbsentStatus = studentId => {
    const absentText = {
      EXCUSED: 'Absent excused',
      UNEXCUSED: 'Absent unexcused'
    };
    const currentStatus = absent[studentId];

    if (currentStatus) {
      return absentText[currentStatus];
    }

    if (previousAbsent[studentId]) {
      return previousAbsent[studentId].isExcused ? absentText.EXCUSED : absentText.UNEXCUSED;
    }
  };

  const closeModal = () => {
    setShown(resource, false);
  };

  const onOk = () => {
    const data = Object.entries(absent).reduce((acc, [id, status]) => {
      if (status === '') {
        return acc;
      }

      const isExcused = status === 'EXCUSED';
      let entry = { id, isExcused };
      const previousEntry = previousAbsent[id];

      if (previousEntry) {
        if (previousEntry.isExcused === isExcused) {
          return acc;
        } else {
          entry.attendanceId = previousEntry.attendanceId;
        }
      }

      return [...acc, entry];
    }, []);

    save(data);
    closeModal();
  };

  const handleAbsence = id => {
    const current = absent[id] || '';
    const { isExcused = true } = previousAbsent[id] || {};

    if (!current) {
      setAbsent({
        ...absent,
        [id]: isExcused ? 'UNEXCUSED' : 'EXCUSED'
      });
    } else if (current === 'UNEXCUSED') {
      setAbsent({ ...absent, [id]: 'EXCUSED' });
    } else if (current === 'EXCUSED') {
      setAbsent({ ...absent, [id]: previousAbsent[id] ? 'UNEXCUSED' : '' });
    }
  };

  return (
    <Modal visible={true} title="Absent students" onCancel={closeModal} onOk={onOk} okText="Save">
      <div style={{ marginBottom: 15 }}>
        Click on the name of the student which you want to mark as absent
      </div>

      {students.map(({ name, id }) => {
        return (
          <AbsentRow key={id} onClick={() => handleAbsence(id)}>
            <div>{name}</div>

            {getAbsentStatus(id)}
          </AbsentRow>
        );
      })}
    </Modal>
  );
};
