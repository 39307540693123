import React from 'react';

import moment from 'moment';

export default [
  {
    title: 'Code',
    dataIndex: 'code'
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    sorter: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 270
  },
  {
    title: 'Employers',
    key: 'employers',
    render: (_, { employers }) => <div style={{ minWidth: 200, maxWidth: 200 }}>{employers}</div>
  },
  {
    title: 'Testing Date',
    key: 'testing_date',
    dataIndex: 'testingDate',
    render: (_, { testingDate }) =>
      testingDate ? moment(testingDate).parseZone().format('MM/DD/YYYY') : '',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filters: [
      { text: 'Successful', value: 'Successful' },
      { text: 'Incomplete', value: 'Incomplete' }
    ]
  },
  {
    title: 'Incomplete Reason',
    dataIndex: 'incompleteReason',
    render: (_, { incompleteReason }) => <div>{incompleteReason}</div>
  },
  {
    title: 'Score',
    dataIndex: 'score',
    sorter: true
  },
  {
    title: 'Sections',
    children: [
      {
        title: 'Attention Signaling',
        key: 'attention_signaling',
        width: 100,
        render: (_, { sections }) => sections.attentionSignaling
      },
      {
        title: 'Inspection Safety',
        key: 'inspection_safety',
        width: 100,
        render: (_, { sections }) => sections.inspectionSafety
      },
      {
        title: 'Parking Docking Backing',
        key: 'parking_docking_backing',
        width: 100,
        render: (_, { sections }) => sections.parkingDockingBacking
      },
      {
        title: 'Shifting',
        key: 'shifting',
        width: 100,
        render: (_, { sections }) => sections.shifting
      },
      {
        title: 'Soft Skills',
        key: 'soft_skills',
        width: 100,
        render: (_, { sections }) => sections.softSkills
      },
      {
        title: 'Speed Stopping Accelerating',
        key: 'speed_stopping_accelerating',
        width: 100,
        render: (_, { sections }) => sections.speedStoppingAccelerating
      },
      {
        title: 'Turns',
        key: 'turns',
        width: 100,
        render: (_, { sections }) => sections.turns
      }
    ]
  },
  {
    title: 'Feedback Notes',
    key: 'feedback_notes',
    width: 200,
    render: (_, { feedbackNotes }) => <div>{feedbackNotes}</div>
  }
];
