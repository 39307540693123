import styled from 'styled-components';

const FallbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  font-size: 20px;
  margin-bottom: 50px;
`;

export { FallbackContainer, ErrorMessage };
