import React, { useContext, useEffect, useState } from 'react';
import { Context, useGet, useFetch, useSave } from 'store';
import { Card, Modal, notification } from 'antd';
import moment from 'moment';
import { Refresh } from '_src/components';
const { confirm } = Modal;

import { Table } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select } from '_src/shared/form-helpers';
import { FilterRow } from './styles';
const resource = 'manageDuplicates';
const attendanceConfig = services.attendance;
const config = services[resource];
const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };
var timezone = moment().utcOffset() / 60;

if (timezone > 0) {
  timezone = 'GMT ' + timezone;
} else if (timezone < 0) {
  timezone = 'GMT' + timezone;
} else if (timezone === 0) {
  timezone = 'GMT 0';
}
export default () => {
  const allBranchesLocation = PersistentRepo.get('allBranches');
  const initialLocation = allBranchesLocation
    ? allBranchesLocation
    : PersistentRepo.get('location', 'allxallx1');

  const { getData, refreshResource } = useContext(Context);
  const [location, setLocation] = useState(initialLocation);
  const [loading, setLoading] = useState(false);

  const getAttendanceParams = () => {
    const [locationType, locationId, branchId] = location.split('x');
    const date = moment().format('DD-MM-YYYY');

    let attendanceParams = { date, locationType: 0, locationId: branchId, timezone };

    if (locationType !== 'all') {
      attendanceParams.locationType = locationType === 'yard' ? 1 : 2;
      attendanceParams.locationId = locationId;
    }

    return attendanceParams;
  };

  const { data, busy: loadingGet } = useGet({
    ...attendanceConfig.get,
    params: getAttendanceParams()
  });

  const { get: getDuplicates } = useFetch(config.getDuplicates);
  const { save: mergeDuplicates, busy: loadingMerge } = useSave(config.mergeDuplicates);

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      if (!allLocations.find(({ id }) => id === location)) {
        onLocationChange(allLocations[0].id);
      }
    }
  });

  const onLocationChange = loc => {
    setLocation(loc);
    PersistentRepo.set('location', loc);
    allBranchesLocation && PersistentRepo.delete('allBranches');
  };

  const handleRowClick = ({ id }) => {
    setLoading(true);

    getDuplicates({
      replace: { id }
    }).then(candidates => {
      setLoading(false);

      if (candidates.length === 1) {
        notification.info({
          message: 'This student does not have any duplicates'
        });
      } else {
        confirm({
          icon: null,
          content: 'Multiple entries exist for this student. Do you want to merge them?',
          onOk() {
            const ids = candidates.map(({ id }) => id);
            setLoading(true);
            mergeDuplicates({ students: ids }).then(({ success }) => {
              setLoading(false);

              if (success === 1) {
                notification.success({ message: 'Student entries merged' });
                refreshResource('attendance');
              } else {
                notification.error({ message: 'Failed to merge student entries' });
              }
            });
          },
          okText: 'Merge'
        });
      }
    });
  };

  return (
    <PageContainer style={stylesTablet}>
      <Refresh />
      <Card title="Manage duplicates">
        <FilterRow>
          <Select
            showSearch
            onChange={onLocationChange}
            {...attendanceConfig.location}
            value={location}
            label="Branch"
            style={{ width: '250px' }}
          />
        </FilterRow>

        <Table
          columns={config.table}
          data={data}
          loading={loadingGet || loading}
          onClick={handleRowClick}
        />
      </Card>
    </PageContainer>
  );
};
