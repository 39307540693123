import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useSave, useFetch } from 'store';
import { Card, Button, DatePicker, notification } from 'antd';
import download from 'downloadjs';
import moment from 'moment';

import { Table } from '_src/components';
import services from '_src/services';
import { debounce } from '_src/utils';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Input, Select } from '_src/shared/form-helpers';
import { camelToSnakeCase } from '../../utils/helpers';
import { stylesTablet } from './styles';

const resource = 'driversReport';
const employersListResource = 'employersList';
const config = services[resource];

const baseApi = GLOBALS.BASE_API;

export default () => {
  const token = PersistentRepo.get('token');

  const prevFilterParamsRef = useRef();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [employers, setEmployers] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [filterParams, setFilterParams] = useState();

  const { get: getData } = useFetch(services[employersListResource].get);
  const { save } = useSave(config.getDriversReport);

  useEffect(() => {
    getEmployers();
  }, []);

  useEffect(() => {
    getAFDDriversReport(page);
  }, [page]);

  useEffect(() => {
    if (JSON.stringify(prevFilterParamsRef.current) !== JSON.stringify(filterParams)) {
      //filterParams have changed
      if (page !== 1) {
        setPage(1);
      } else {
        getAFDDriversReport(page);
      }
    }

    // Update prevFilterParamsRef with the current value of filterParams
    prevFilterParamsRef.current = filterParams;
  }, [filterParams]);

  const getAFDDriversReport = pageNumber => {
    save({
      driver_ids: [1],
      page: pageNumber,
      per_page: 20,
      ...filterParams
    }).then(res => {
      if (res.success === 1) {
        setData(res.data.scores);
      }
    });
  };

  const getEmployers = () => {
    getData()
      .then(drivers => {
        setEmployers(drivers.map(e => ({ id: e.name, label: e.name })));
      })
      .catch(err => {});
  };

  const handleDownloadAFDReport = async () => {
    try {
      const resp = await fetch(`${baseApi}/driver_score/download`, {
        method: 'POST',
        body: JSON.stringify({ ...filterParams, driver_ids: [1] }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const filename = resp.headers.get('x-suggested-filename');
      const blob = await resp.blob();

      download(blob, filename);
    } catch (error) {
      notification.error({
        message: 'Something went wrong!',
        duration: 3
      });
    }
  };

  const debounceCallback = useCallback(
    debounce(
      val => {
        setFilterParams(prevState => {
          return {
            ...prevState,
            ...val
          };
        });
      },
      1000,
      false
    ),
    []
  );

  return (
    <PageContainer style={stylesTablet} id="drivers_scores">
      <Card title="Driver Scoring Report">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
            <Input
              size="large"
              label="Full Name"
              onChange={e => {
                const [first, last] = e.split(' ');
                setFirstName(e);
                debounceCallback({ ...filterParams, first_name: first, last_name: last });
              }}
              placeholder=""
              value={firstName}
            />
            <Input
              size="large"
              label="Code"
              onChange={e => {
                setCode(e);
                debounceCallback({ ...filterParams, code: e });
              }}
              placeholder=""
              value={code}
            />
            <Input
              size="large"
              label="Email"
              onChange={e => {
                setEmail(e);
                debounceCallback({ ...filterParams, email: e });
              }}
              placeholder=""
              value={email}
            />

            <div>
              <div>Filter By Date</div>
              <DatePicker
                label="Date"
                allowClear={true}
                size="large"
                onChange={value => {
                  if (value) {
                    setFilterParams({
                      ...filterParams,
                      testing_date: moment(value).format('YYYY-MM-DD')
                    });
                  } else {
                    const { testing_date, ...rest } = filterParams;
                    setFilterParams(rest);
                  }
                }}
              />
            </div>

            <Select
              label="Filter by Employer"
              options={employers}
              value={selectedEmployers}
              onChange={e => {
                setSelectedEmployers(e);

                if (e.length) {
                  setFilterParams({
                    ...filterParams,
                    employers: e
                  });
                } else {
                  const { employers, ...rest } = filterParams;
                  setFilterParams(rest);
                }
              }}
              mode="multiple"
              size="large"
              style={{ width: 200 }}
            />
          </div>

          <Button type="primary" icon="download" size="large" onClick={handleDownloadAFDReport}>
            Download
          </Button>
        </div>
        <Table
          columns={config.table}
          scroll={{ x: `120vw`, y: `calc(100vh - 300px)` }}
          data={data.driverInfoList}
          pagination={{
            ...data.pagination,
            current: page,
            pageSize: 20,
            total: data.pagination ? data.pagination.totalResults : 0
          }}
          onChange={(pagination, filter, sorter) => {
            setPage(pagination.current);
            if (sorter.field) {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  sort_field: camelToSnakeCase(sorter.field),
                  sort_order: sorter.order === 'ascend' ? 'asc' : 'desc'
                })
              });
            }
            if (filter.status) {
              if (filter.status.length === 1) {
                setFilterParams({
                  ...filterParams,
                  status: filter.status[0]
                });
              } else {
                const { status, ...rest } = filterParams;
                setFilterParams(rest);
              }
            }
          }}
        />
      </Card>
    </PageContainer>
  );
};
