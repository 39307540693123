import { transformAll, transformStudentProgress, transformBeforeSave } from './transform';
import schema from './schema';
import fields from './fields';
import table from './table';

const name = 'automatedEmails';
const path = 'reports/automated_reports';

const save = {
  name,
  path,
  transform: transformBeforeSave
};

export default {
  restrict: 'reports/automated_reports',
  title: 'Automated Emails',
  createButton: 'Create a new automated email',
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  create: save,
  edit: { ...save, method: 'patch', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  table,
  modal: {
    title: 'Save',
    fields,
    schema
  },
  getStudentProgress: {
    path: 'reports/automated_reports/data/:token',
    transform: transformStudentProgress,
    defaultValue: []
  }
};
