export default [
  {
    title: 'Section',
    key: 'sectionType',
    dataIndex: 'sectionType',
    render: sectionType => {
      const sectionName = GLOBALS.testing.sections[3].find(section => section.id == sectionType);
      return sectionName ? sectionName.label : '';
    },
    width: '35%'
  }
];
