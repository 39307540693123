import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Context, useSave, useFetch } from 'store';
import { PageContainer } from '_src/shared/styles';
import qs from 'qs';

import { Card, Button, notification, Modal, Popconfirm, Icon, Menu, Dropdown } from 'antd';
import { Refresh, AsyncTable, AsyncModal } from '_src/components';
import { Select, Input, RangePicker } from '_src/shared/form-helpers';
import services from '_src/services';
import { PersistentRepo } from '_src/shared/repo';
import { Row, TotalCard } from './styles';
import StyledButton from '../../ui/Button/styles';
import { debounce, filterObjectEmptyValues, objToSnakeCase } from '_src/utils';
import { StudentTimeline } from '_src/modules/Students/components';
import { camelToSnakeCase, snakeToCamelCase } from '../../utils/helpers';
import Filepicker from '_src/shared/form-helpers/Filepicker';
import moment from 'moment';

import {
  ModalTitle,
  HourDetailContainer,
  HourDetail,
  ButtonContainer,
  EvaluationButtonContainer,
  EvaluationsContainer,
  StyledAsyncTable
} from './styles';

export default () => {
  const now = moment();

  const reportTypes = [
    { id: 0, label: 'FMCSA Submissions' },
    { id: 1, label: 'FMCSA Reports' },
    { id: 2, label: 'Staff Submissions' }
  ];

  const locationTypes = [
    { id: 0, label: 'All' },
    { id: 1, label: 'Yard' },
    { id: 2, label: 'Classroom' }
  ];

  const { getData, setShown, getShown, setData, refreshResource } = useContext(Context);

  const getInitialLocation = () => {
    const allBranchesSelected = PersistentRepo.get('allBranches');

    const location = allBranchesSelected ? allBranchesSelected : PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const config = services['fmcsaSubmissions'];
  const locationsConfig = services['studentRecords'];
  const evaluationTestsConfig = services['evaluationTests'];
  const resource = 'fmcsaSubmissions';
  const resourceReport = 'fmcsaReports';
  const resourceStaff = 'staffSubmissions';

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(getInitialLocation());
  const [initialValues, setInitialValues] = useState({});
  const [student, setStudent] = useState({});
  const [reportType, setReportType] = useState(0);
  const [fullName, setFullName] = useState('');
  const [refreshAction, setRefreshAction] = useState(false);
  const [statusCode, setStatusCode] = useState('all');
  const [locType, setLocType] = useState(0);
  const [contactNumber, setContactNumber] = useState(null);
  const [dateRanges, setDateRanges] = useState({
    startDate: moment().subtract(1, 'months'),
    endDate: moment()
  });
  const [popupVisibility, setPopupVisibility] = useState(
    (student.locationType === 1 && student.yardSubmittedToFmsca) ||
      (student.locationType === 2 && student.classSubmittedToFmsca)
  );
  const [isHazmat, setHazmat] = useState(false);

  const [filterParams, setFilterParams] = useState({
    performance: true,
    branchId: getInitialId('branchId'),
    locationType: locType
      ? locType
      : getInitialId('locationType') === 'all'
      ? 0
      : getInitialId('locationType') === 'yard'
      ? 1
      : 2,
    startDate: dateRanges.startDate.parseZone().format('YYYY-MM-DD'),
    endDate: dateRanges.endDate.parseZone().format('YYYY-MM-DD'),
    ...(statusCode !== 'all' && { status: statusCode }),
    ...(contactNumber !== null && { contactNumber })
  });

  const { get: getLocations, busy } = useFetch({ ...locationsConfig.getLocations });
  const { get: getAllStudents, busy: loading } = useFetch({ ...config.get });
  const { save: editStudent, busy: busyEdit } = useSave(config.editStudent);
  const { save: submitStudent, busy: busySubmit } = useSave(config.submitStudent);
  const [confirm, setConfirm] = useState(false);
  const [saveBtn, setSave] = useState(false);
  const [total, setTotal] = useState(null);
  const [bulkUploadLoading, setBulkUploadLoading] = useState(false);
  const [reportDownload, setReportDownload] = useState(false);
  const baseApi = GLOBALS.BASE_API;

  const debounceCallback = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );
  useEffect(() => {
    if (confirm) setSave(false);
  }, [confirm]);

  useEffect(() => {
    getLocations().then(data => {
      setLocations([{ id: `all`, label: `All Branches` }, ...data]);
    });
  }, []);

  const handleSubmit = () => {
    submitStudent({ studentInfoId: student.id }).then(res => {
      if (res.success === 1) {
        notification.success({
          message: 'Student info submitted to FMCSA',
          duration: 5
        });
        setRefreshAction(!refreshAction);
        setShown('confirm', false);
        setFilterParams({ ...filterParams });
        refreshResource(resource);
      }

      if (res.success === 0) {
        notification.error({
          message: 'An error has occurred',
          description: res.data.message,
          duration: 5
        });
      }
    });
  };

  const declineStudent = student => {
    editStudent({ id: student.id, status: 'declined' }).then(res => {
      if (res.success === 1) {
        notification.success({
          message: 'Student status changed to DECLINED',
          duration: 5
        });
        setFilterParams({ ...filterParams });
        refreshResource(resource);
      }

      if (res.success === 0) {
        notification.error({
          message: 'An error has occurred',
          description: 'Student info was not changed.',
          duration: 5
        });
      }
    });
  };

  const renderCancel = () => (
    <Button key="cancel" onClick={() => setConfirm(false)}>
      Cancel
    </Button>
  );

  const renderSave = () => (
    <Button key="save" type="primary" onClick={() => setSave(true)}>
      Submit
    </Button>
  );

  const statusCodes = [
    { label: 'All', id: 'all' },
    { label: 'Pending', id: 'pending' },
    { label: 'Declined', id: 'declined' },
    { label: 'Success', id: 'success' },
    { label: 'Fail', id: 'fail' }
  ];

  const renderDropdownCell = (_, record) => {
    const { firstName, studentId, ...rest } = record;
    const options = (
      <Menu>
        <Menu.Item
          onClick={() => {
            setData('studentTimeline', {
              name: `${firstName}`,
              ...rest,
              id: studentId
            });

            if (rest.branchName === 'Hazmat') {
              setHazmat(true);
            } else {
              setHazmat(false);
            }
            setShown('fmcsaStudentTimeline');
          }}
        >
          View Report Card
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={options}>
        <div style={{ color: status === 'Hold' ? GLOBALS.colors.DARK_YELLOW : 'unset' }}>
          {firstName}
        </div>
      </Dropdown>
    );
  };
  const formatLatestResult = (lastestResult, type) => {
    if (lastestResult) {
      lastestResult = type
        ? `${Math.round(lastestResult.split('%')[0])}% Performance`
        : Math.round(lastestResult * 100) + '% Completed';

      return lastestResult;
    }

    return '-';
  };

  const handleExcelUpload = e => {
    setBulkUploadLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    fetch(`${baseApi}/student_infos/upload_info`, {
      method: 'POST',
      body: formData
    })
      .then(() => {
        setBulkUploadLoading(false);
        notification.success({
          message: 'Student successfully uploaded!',
          duration: 5
        });
      })
      .catch(() => {
        setBulkUploadLoading(false);
        notification.warning({
          message: 'Upload failed!',
          duration: 5
        });
      });
  };

  const handleReportDownload = () => {
    setReportDownload(true);
    const queryParams = qs.stringify({ ...objToSnakeCase(filterParams) }, { arrayFormat: 'comma' });
    fetch(`${baseApi}/${config.download.path}?${queryParams}`, {
      method: 'POST',
      body: JSON.stringify(objToSnakeCase(filterParams)),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Staff_Submissions_${moment().format('MM/DD/YYYY hh:mm')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setReportDownload(false);
        return;
      });
  };

  config.table.find(({ dataIndex }) => dataIndex === 'firstName').render = renderDropdownCell;

  return (
    <PageContainer>
      <Refresh />
      <Card title={reportTypes[reportType].label}>
        <Row>
          <Select
            label="Report type"
            onChange={val => {
              setReportType(val);
              setStatusCode('all');
              setFilterParams({
                ...filterParams,
                ...(reportType === 1 && { performance: true })
              });
            }}
            options={reportTypes}
            value={reportType}
          />
          <Select
            label="Branches"
            showSearch
            onChange={locationSelected => {
              setLocation(locationSelected);

              if (locationSelected === 'all') {
                setFilterParams({
                  ...(statusCode != 'all' && { status: statusCode }),
                  performance: true,
                  locationType: locType ? locType : 0
                });
                PersistentRepo.set('allBranches', locationSelected);
              } else {
                const [locationType, locationId, branchId] = locationSelected.split('x');
                const definedLocationType = locationSelected
                  ? locationType === 'all'
                    ? 0
                    : locationType === 'yard'
                    ? 1
                    : 2
                  : getInitialId('locationType');
                setLocType(definedLocationType ? definedLocationType : locType);
                setFilterParams(() => {
                  return {
                    ...filterParams,
                    branchId: locationSelected ? branchId : getInitialId('branchId'),
                    locationType: definedLocationType ? definedLocationType : locType
                  };
                });
                PersistentRepo.set('location', locationSelected);
                PersistentRepo.delete('allBranches');
              }
            }}
            options={locations}
            value={location}
          />

          {(reportType === 0 || reportType === 2) && (
            <Select
              label="Branch Type"
              onChange={e => {
                setLocType(e);
                setFilterParams({
                  ...filterParams,
                  locationType: e
                });
              }}
              disabled={getInitialId('locationType') !== 'all'}
              options={locationTypes}
              value={locType}
            />
          )}

          {reportType === 0 && (
            <Select
              label="Status"
              options={statusCodes}
              value={statusCode}
              onChange={status => {
                setStatusCode(status);
                let newFilters = filterParams;
                if (status === 'all') {
                  delete newFilters.status;
                } else {
                  newFilters = { ...newFilters, status };
                }
                setFilterParams(newFilters);
              }}
            />
          )}

          {reportType !== 2 && (
            <Input
              label="Full Name"
              onChange={e => {
                setFullName(e);
                debounceCallback({ ...filterParams, student: e });
              }}
              value={fullName}
            />
          )}

          {reportType === 0 && (
            <Input
              label="Contact Number"
              onChange={e => {
                const newValue = e.length === 0 ? null : e;
                setContactNumber(newValue);
                debounceCallback({ ...filterParams, contactNumber: newValue });
              }}
              value={contactNumber}
              type="number"
            />
          )}
          {reportType === 0 && (
            <div>
              <Filepicker
                data-cy="excelUpload"
                label="Bulk submission"
                toBase64={false}
                type="excel"
                buttonStyles={{ width: '100%' }}
                onChange={handleExcelUpload}
                loading={bulkUploadLoading}
              />
            </div>
          )}
          {reportType === 2 && (
            <RangePicker
              label="Date"
              allowClear={false}
              ranges={{
                Today: [now, now],
                'Last Week': [moment().subtract(7, 'd'), moment()],
                'Last Month': [moment().subtract(1, 'months'), moment()]
              }}
              defaultValue={[dateRanges.startDate, dateRanges.endDate]}
              onChange={dates => {
                const [startDate, endDate] = dates;
                setDateRanges({
                  startDate,
                  endDate
                });
                setFilterParams({
                  ...filterParams,
                  startDate: startDate.parseZone().format('YYYY-MM-DD'),
                  endDate: endDate.parseZone().format('YYYY-MM-DD')
                });
              }}
            />
          )}
          {reportType === 2 && (
            <a disabled={reportDownload} onClick={handleReportDownload}>
              <Button type="default">
                <Icon type="download" />
                Download report
              </Button>
            </a>
          )}
        </Row>
        {total !== null && reportType === 0 && (
          <TotalCard>
            Total students: <span>{total}</span>
          </TotalCard>
        )}

        {reportType === 0 && (
          <StyledAsyncTable
            resource={resource}
            pagination
            config={{
              ...config,
              table: [
                ...config.table,
                {
                  title: 'Evaluations',
                  key: 'evaluations',
                  render: (_, item) => {
                    return (
                      <EvaluationsContainer>
                        {evaluationTestsConfig.types.map(({ id, label, showForBranch }) => {
                          const completed = item.performance
                            ? formatLatestResult(item.performance.testPercentages[id], 0)
                            : '';
                          const performance = item.performance
                            ? formatLatestResult(item.performance[id]?.lastestResult, 1)
                            : '';

                          return (
                            item.performance && (
                              <EvaluationButtonContainer
                                key={id}
                                onClick={() => {
                                  if (
                                    !(item.locationType === 2 && !showForBranch) &&
                                    item.performance[id] &&
                                    id != 2
                                  ) {
                                    /**
                                     * TODO: Update logic
                                     * setSelectedStudent(item);
                                     * setTestingType(id);
                                     *  */
                                    setShown('testing');
                                  }
                                }}
                              >
                                <div style={{ marginBottom: '5px' }}>{label}</div>
                                <StyledButton
                                  key={id}
                                  filled={true}
                                  color={
                                    item.performance[id]
                                      ? evaluationTestsConfig.performanceColor[
                                          item?.performance[id].currPerformance
                                        ]
                                      : '#c1c1c1'
                                  }
                                  text={label}
                                  height={55}
                                  width={155}
                                  disabled={item.locationType === 2 && !showForBranch}
                                  style={{ flexDirection: 'column' }}
                                >
                                  <span>{performance == '-' ? '-' : completed}</span>

                                  <span>{performance}</span>
                                </StyledButton>
                              </EvaluationButtonContainer>
                            )
                          );
                        })}
                      </EvaluationsContainer>
                    );
                  },
                  width: '700px'
                },
                {
                  id: 'submit',
                  render: (_, student) => {
                    const showPopup =
                      (student.locationType === 1 &&
                        (student.yardSubmittedToFmsca || student.classSubmittedToFmsca)) ||
                      (student.locationType === 2 && student.classSubmittedToFmsca);
                    return (
                      <span style={{ display: 'grid' }}>
                        {(student.status === 'pending' ||
                          student.status === 'declined' ||
                          student.status === 'fail') && (
                          <Button
                            type="primary"
                            style={{
                              marginBottom: 10,
                              background: '#FF8400',
                              borderColor: '#FF8400'
                            }}
                            onClick={() => {
                              setStudent({ ...student });
                              setInitialValues({
                                ...student,
                                branch: student.branchId
                              });
                              setShown(resource);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        {student.status === 'pending' && (
                          <Button
                            type="danger"
                            style={{ marginBottom: 10 }}
                            onClick={() => declineStudent(student)}
                          >
                            Decline
                          </Button>
                        )}
                        {(student.status === 'pending' || student.status === 'fail') &&
                          student.branchName !== 'Hazmat' &&
                          renderSave() && (
                            <Popconfirm
                              disabled={!showPopup}
                              placement="topLeft"
                              title={`This student already shows as submitted, are you sure you want to submit again?`}
                              okType="warning"
                              okText="Yes"
                              onConfirm={() => {
                                setStudent({ ...student });
                                setShown('confirm', true);
                              }}
                              icon={<Icon type="question-circle-o" style={{ color: 'orange' }} />}
                            >
                              <ButtonContainer>
                                <Button
                                  onClick={() => {
                                    !showPopup && setStudent({ ...student });
                                    !showPopup && setShown('confirm', true);
                                  }}
                                  style={{
                                    marginBottom: 10,
                                    background: '#5AB55B',
                                    borderColor: '#5AB55B'
                                  }}
                                  type="primary"
                                >
                                  Submit
                                </Button>
                              </ButtonContainer>
                            </Popconfirm>
                          )}
                      </span>
                    );
                  }
                }
              ]
            }}
            onChange={(pagination, filters, sorter) => {
              if (!!sorter.field) {
                setFilterParams({
                  ...filterParams,
                  ...(sorter.field && {
                    orderBy: camelToSnakeCase(
                      sorter.field === 'name' ? 'first_name' : sorter.field
                    ),
                    order: sorter.order === 'ascend' ? 'asc' : 'desc'
                  })
                });
              }
            }}
            fileName={resource}
            loading={loading}
            resetPageOn={['status', 'firstName']}
            params={filterParams}
            onTotal={setTotal}
            scroll={{ x: `200vw`, y: `calc(100vh - 300px)` }}
          />
        )}
        {reportType === 1 && (
          <StyledAsyncTable
            resource={resourceReport}
            pagination
            config={{
              ...config,
              table: [...config.reportsTable]
            }}
            fileName={resourceReport}
            loading={loading}
            params={filterObjectEmptyValues(filterParams)}
            scroll={{ x: `200vw`, y: `calc(100vh - 300px)` }}
          />
        )}
        {reportType === 2 && (
          <StyledAsyncTable
            resource={resourceStaff}
            pagination
            config={{
              ...config,
              table: [...config.staffSubmissionsTable]
            }}
            onChange={(pagination, filters, sorter) => {
              if (!!sorter.field) {
                setFilterParams({
                  ...filterParams,
                  ...(sorter.field && {
                    orderBy: camelToSnakeCase(sorter.field),
                    order: sorter.order === 'ascend' ? 'asc' : 'desc'
                  })
                });
              }
            }}
            fileName={resourceStaff}
            loading={loading}
            params={filterObjectEmptyValues(filterParams)}
            scroll={{ x: `85vw`, y: `calc(100vh - 300px)` }}
          />
        )}
        {confirm && (
          <Modal
            className="editStudent"
            title="Edit"
            visible={true}
            onCancel={() => setConfirm(false)}
            footer={[renderCancel(), renderSave()]}
            // footer={[renderCancel(), student.branchId !== 124 && renderSave()]}
            style={{ zIndex: 10000 }}
          >
            Are you sure you want to submit less than 15 hours?
          </Modal>
        )}
        <AsyncModal
          showDelete={false}
          saveText="Save"
          title={
            <ModalTitle key="title">
              {student ? `${student.firstName} ${student.lastName}` : ''}
            </ModalTitle>
          }
          resource={resource}
          closeModal={() => setShown(resource, false)}
          initialValues={initialValues}
          refreshTable={setRefreshAction}
          confirmation={setConfirm}
          confirmSave={saveBtn}
          // showSave={student.branchId === 124 ? false : true}
        />
        <Modal
          width={'50%'}
          visible={getShown('confirm')}
          title="Confirm student info"
          onCancel={() => setShown('confirm', false)}
          onOk={!busySubmit && !busyEdit && handleSubmit}
          okText="Save"
        >
          <span style={{ display: 'flex' }}>
            <HourDetailContainer>
              <HourDetail>
                Name
                <span>
                  {student.firstName} {student.lastName}
                </span>
              </HourDetail>
              <HourDetail>
                Permit Rec. Date <span>{student.permitRecDate}</span>
              </HourDetail>
              <HourDetail>
                Branch Code <span>{student.branchStateCode}</span>
              </HourDetail>
              <HourDetail>
                Location <span>{student.location}</span>
              </HourDetail>
              <HourDetail>
                Birthdate <span>{student.birthdate}</span>
              </HourDetail>
              <HourDetail>
                License <span>{student.license}</span>
              </HourDetail>
            </HourDetailContainer>
            <HourDetailContainer>
              <HourDetail>
                Endorsement Code <span>{student.endorsementCode}</span>
              </HourDetail>
              <HourDetail>
                Completion Date <span>{student.completionDate}</span>
              </HourDetail>
              <HourDetail>
                FMCSA Location ID <span>{student.fmcsaLocationId}</span>
              </HourDetail>
              <HourDetail>
                Road <span>{student.road}</span>
              </HourDetail>
              <HourDetail>
                Skills <span>{student.skills}</span>
              </HourDetail>
              <HourDetail>
                Classroom Score <span>{student.score}</span>
              </HourDetail>
            </HourDetailContainer>
          </span>
        </Modal>
      </Card>
      {getShown('fmcsaStudentTimeline') && <StudentTimeline isFmcsa={true} isHazmat={isHazmat} />}
    </PageContainer>
  );
};
