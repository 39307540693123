import React from 'react';

export default () => {
  return (
    <p
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '25px'
      }}
    >
      There is no attendance information about this student
    </p>
  );
};
