import React from 'react';
import { Tag } from 'antd';

export default [
  {
    title: 'Employer name',
    dataIndex: 'name',
    sorter: (a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }
  },
  {
    title: 'Recipients',
    dataIndex: 'emails',
    render: emails => (
      <>
        {emails &&
          emails.map(email => (
            <Tag color="blue" key={email}>
              {email}
            </Tag>
          ))}
      </>
    )
  }
];
