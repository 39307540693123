import React from 'react';

import { Button } from '_src/ui';
import {
  needsImprovementPerformance,
  proficientPerformance,
  satisfactoryPerformance,
  unsuccessfulPerformance,
  noInfoFace
} from '_src/assets/images';
import { Container, Title, Trend, Icon, Status } from './styles';

const buttonColor = GLOBALS.colors;

const statusConfig = {
  DECLINING: {
    trendTitle: 'Declining',
    trendColor: buttonColor.RED
  },
  IMPROVING: {
    trendTitle: 'Improving',
    trendColor: buttonColor.GREEN
  },
  NEUTRAL: {
    trendTitle: 'Neutral',
    trendColor: buttonColor.GREY
  },

  SAME: {
    trendTitle: 'Same',
    trendColor: buttonColor.GREY
  },
  EMPTY: {
    trendTitle: 'No Results Yet',
    trendColor: buttonColor.GREY
  }
};

const performanceConfig = {
  'NEEDS IMPROVEMENT': {
    title: 'Needs Improvement',
    color: buttonColor.ORANGE,
    imgSrc: needsImprovementPerformance
  },
  SATISFACTORY: {
    title: 'Satisfactory',
    color: buttonColor.GREEN,
    imgSrc: satisfactoryPerformance
  },
  UNSUCCESFUL: {
    title: 'Unsuccessful',
    color: buttonColor.RED,
    imgSrc: unsuccessfulPerformance
  },
  PROFICIENT: {
    title: 'Proficient',
    color: buttonColor.GREEN,
    imgSrc: proficientPerformance
  },
  EMPTY: {
    title: 'No Info',
    color: buttonColor.GREY,
    imgSrc: noInfoFace
  }
};

const PerformanceCard = ({
  status = 'EMPTY',
  currPerformance = 'EMPTY',
  title = 'Pre-Trip',
  onMoreInfoPress,
  onButtonPress,
  type = 1,
  buttonDisabled = false
}) => {
  const { trendColor, trendTitle } = statusConfig[status] || statusConfig.EMPTY;
  const { color, imgSrc, statusTitle } =
    performanceConfig[currPerformance] || performanceConfig.EMPTY;

  return (
    <Container>
      <Title>{title}</Title>
      <Trend color={trendColor}>{trendTitle}</Trend>
      <Icon src={imgSrc} isEmpty={status === 'EMPTY'} />
      <Status>{statusTitle}</Status>

      {onButtonPress && (
        <Button
          disabled={buttonDisabled}
          filled={true}
          color={color}
          text={title}
          onClick={() => onButtonPress({ title, type })}
          height={33}
        />
      )}

      {onMoreInfoPress && (
        <Button
          disabled={status === 'EMPTY'}
          color={color}
          text="More Info"
          onClick={() => onMoreInfoPress({ title, type })}
          height={33}
          margin={'6px 0 0 0'}
        />
      )}
    </Container>
  );
};

export default PerformanceCard;
