import {
  transformLocations,
  transformAll,
  transformOverride,
  transformSingleAttendance,
  transformTodaysHours,
  transformYards
} from './transform';
import table from './table';
import schema from './schema';

const name = 'attendance';
const path = 'attendance';

const save = {
  name,
  path
};

export default {
  title: 'Attendance',
  get: {
    name: 'attendance',
    path: ['attendance/daily', 'attendance/unfilled-student-hours'],
    transform: transformAll,
    defaultValue: []
  },
  create: save,
  edit: { ...save, method: 'patch', path: `${path}/:id` },
  delete: {
    name,
    path: `${path}/:id`
  },
  getStudentAttendance: {
    name,
    path: `${path}/:id`,
    defaultValue: {},
    transform: transformSingleAttendance
  },
  editStudent: { method: 'patch', path: `students/:id` },
  submitFmcsa: { method: 'post', path: `student_infos` },
  archiveStudent: { method: 'patch', path: `students/archive/:id` },
  updateProfilePicture: { method: 'patch', path: `students/update_by_instructor/:id` },
  location: {
    placeholder: 'Location',
    component: 'select',
    async: true,
    get: {
      name: 'locations',
      path: ['branches', 'yards', 'classrooms', 'branch_locations'],
      transform: transformLocations,
      defaultValue: [],
      memoize: false
    }
  },
  yards: {
    name: 'getYards',
    path: ['branches', 'yards', 'branch_locations'],
    transform: transformYards,
    defaultValue: [],
    memoize: false
  },
  table,
  override: {
    name: 'override',
    path: 'attendance',
    transform: transformOverride,
    defaultValue: []
  },
  getOpenSessions: {
    name: 'students',
    path: `students/open_sessions/:id`,
    defaultValue: []
  },
  getUnfilledHBD: {
    name: 'studentsHBD',
    path: `attendance/hours-break-down-date/:id`,
    defaultValue: []
  },
  getQr: {
    name: 'members',
    path: 'members/:studentId/qr'
  },
  renewInvite: {
    path: 'students/:id/dc_registration_request_data/renew',
    method: 'patch'
  },
  todaysHoursSchema: schema.todaysHoursSchema,
  todaysHoursSchemaNotRequired: schema.todaysHoursSchemaNotRequired,
  getTodaysHours: {
    name: 'getTodaysHours',
    path: 'students/student_records/date',
    transform: transformTodaysHours,
    defaultValue: {}
  },
  updateStudentHours: {
    name: 'updateStudentHours',
    path: 'students/upsert-student-hours',
    method: 'post'
  },
  sendOtpPassword: {
    name: 'sendOtpPassword',
    path: 'students/otp',
    method: 'post'
  },
  sendStudentIntervention: {
    name: 'sendStudentIntervention',
    path: 'student_intervention',
    method: 'post'
  },
  getStudentsPerformance: {
    method: 'post',
    path: 'testing/student_performance/multiple'
  },
  getStudentsProficiency: {
    method: 'post',
    path: 'testing/proficiency'
  }
};
