const findLocationtype = (inYard, inClassroom) => {
  if (inYard && inClassroom) {
    return 'both';
  } else if (inYard) {
    return 'yard';
  } else if (inClassroom) {
    return 'classroom';
  } else {
    return 'inactive';
  }
};

const transformAll = res => {
  if (res.data.questions < 0) {
    return [];
  }
  return res.data.questions.map(({ id, question, type, inClassroom, inYard }) => ({
    id,
    question,
    locationType: findLocationtype(inYard, inClassroom),
    active: inYard == false && inClassroom == false ? false : true,
    questionType: type
  }));
};

const transformCreate = ({ question, questionType, locationType }) => {
  const extractLocationType = {
    1: { classroom: true, yard: false },
    2: { classroom: false, yard: true },
    3: { classroom: true, yard: true }
  };

  return {
    question: question,
    type: questionType,
    inYard: extractLocationType[locationType].yard,
    inClassroom: extractLocationType[locationType].classroom
  };
};

const transformUpdate = obj => {
  if (obj.hasOwnProperty('locationType')) {
    const extractLocationType = {
      yard: { classroom: true, yard: false },
      classroom: { classroom: false, yard: true },
      both: { classroom: true, yard: true },
      inactive: { classroom: false, yard: false }
    };

    return {
      id: obj.id,
      inYard: extractLocationType[obj.locationType].yard,
      inClassroom: extractLocationType[obj.locationType].classroom
    };
  }

  return obj;
};

const transformUpdateUrl = (url, data) => {
  if (data.hasOwnProperty('question')) {
    return url + '/text';
  } else if (data.hasOwnProperty('locationType')) {
    return url + '/location';
  }

  return url;
};
export { transformAll, transformCreate, transformUpdate, transformUpdateUrl };
