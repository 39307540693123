import React, { useContext, useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { Context } from 'store';

import { PersistentRepo } from '_src/shared/repo';
import { Restricted } from '_src/shared/restrict';
import logo from '_src/assets/images/logo.png';
import { VersionNumber } from '_src/components';

import links from './links';
import { Logo, MenuWrapper } from './styles';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const renderSidebarItem = item => {
  const { subItems, label, key, path, restrict, method = 'GET' } = item;

  if (Array.isArray(subItems)) {
    return (
      <Restricted key={key} resource={restrict} method={method}>
        <SubMenu title={label}>{subItems.map(subItem => renderSidebarItem(subItem))}</SubMenu>
      </Restricted>
    );
  }

  return (
    <Restricted key={key} resource={restrict} method={method}>
      <Item>
        <Link to={path}>{label}</Link>
      </Item>
    </Restricted>
  );
};

export default () => {
  const location = PersistentRepo.get('location', 'allxallx1');
  const [branchId, setBranchId] = useState(location.split('x')[2]);
  const { setData, setShown } = useContext(Context);

  useEffect(() => {
    setBranchId(location.split('x')[2]);
  }, [location]);

  return (
    <Sider trigger={null}>
      <Link to="/reports">
        <Logo src={logo} />
      </Link>
      <MenuWrapper>
        <Menu selectable={false} theme="dark" mode="inline">
          <SubMenu title="Student Attendance">
            {links.attendance.map(link => renderSidebarItem(link))}
            {links.bulkAttendance.map(link => renderSidebarItem(link))}
            {links.bulkYardManagement.map(link => renderSidebarItem(link))}
            {links.studentGroups.map(link => renderSidebarItem({ ...link, method: 'POST' }))}
            {links.evaluationTests.map(link => renderSidebarItem(link))}
          </SubMenu>

          <SubMenu title="All Reports">
            {links.allReports.map(link => renderSidebarItem(link))}
            {links.records.map(link => renderSidebarItem(link))}
          </SubMenu>

          {links.studentIntervention.map(link => renderSidebarItem(link))}
          {links.fmcsaSubmissions.map(link => renderSidebarItem(link))}
          {links.cdlTesting.map(link => renderSidebarItem(link))}
          {links.studentAgreements.map(link => renderSidebarItem(link))}
          {links.classroomScore.map(link => renderSidebarItem(link))}
          {links.organization.map(link => renderSidebarItem(link))}
          {links.userManagement.map(link => renderSidebarItem(link))}
          {links.activityLogs.map(link => renderSidebarItem(link))}
          {links.manageLocationType.map(link => renderSidebarItem(link))}
          {links.yardSchedule.map(link => renderSidebarItem(link))}
          {links.manageDuplicates.map(link => renderSidebarItem(link))}

          <SubMenu title="Settings">
            {links.settings.map(link => renderSidebarItem(link))}

            <Item onClick={() => setShown('changePassword')}>Change password</Item>
          </SubMenu>

          {links.driverScore.map(link => renderSidebarItem(link))}
          <Item>
            <a
              href="https://160digital.atlassian.net/servicedesk/customer/portal/10"
              target="_blank"
            >
              FMCSA Support
            </a>
          </Item>
          <Item>
            <a
              href="https://160digital.atlassian.net/servicedesk/customer/portal/2/group/3/create/11"
              target="_blank"
            >
              Technical Support
            </a>
          </Item>

          <Item
            onClick={() => {
              PersistentRepo.clear();
              setData('authenticated', false);
            }}
          >
            Log out
          </Item>
        </Menu>
        <VersionNumber plain light style={sersionNumberStyles} />
      </MenuWrapper>
    </Sider>
  );
};

const sersionNumberStyles = {
  marginRight: '16px',
  textAlign: 'right',
  color: ' rgba(0, 0, 0, 0.65)',
  fontSize: '12px',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  fontVariant: 'tabular-nums',
  lineHeight: 1.5
};
