import styled from 'styled-components';

const Title = styled.div`
  font-family: 'SF Display Heavy';
  color: #ff8400;
  font-size: 21px;
`;

const Name = styled.div`
  font-family: 'SF Display Bold';
  color: #3b3b3b;
  font-size: 16.5px;
  margin-bottom: 15px;
`;

const SectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const SectionContainer = styled.div`
  width: 25%;
`;

const Question = styled.div`
  font-size: 16px;
  font-family: 'SF Display Bold';
  color: #3b3b3b;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionsContainer = styled.div`
  width: 100%;
  border-right: 1px solid rgba(112, 112, 112, 0.27);
  ${({ type }) => type !== 4 && 'border-left: 1px solid rgba(112, 112, 112, 0.27);'}
  padding: 0 15px;
`;

const QuestionRow = styled.div`
  ${({ type }) =>
    type === 1 &&
    `
      display: flex;
      justify-content: space-between;
      min-height: 55px;
      align-items: center;
      border-bottom: 1px solid rgba(112, 112, 112, 0.27);
  `};
`;

const HeaderContainer = styled.div`
  display: flex;
  font-family: 'SF Display Bold';
  color: #5f5e5e;
  font-size: 14.5px;
  padding-bottom: 5px;

  & > div:first-child {
    width: 25%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > div:first-child {
      width: 120px;
      margin-right: 15px;
      text-align: center;
    }

    & > div:last-child {
      width: 100%;
      padding-left: 15px;
    }
  }
`;

const CategoryContainer = styled.div`
  border-right: 1px solid rgba(112, 112, 112, 0.27);
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 52px;
  top: 25px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 23px;
  z-index: 1;
`;

const PerformanceContainer = styled.div`
  border-right: 1px solid rgba(112, 112, 112, 0.27);
  width: 145px;
  padding-right: 5px;
  margin-right: 15px;

  ${({ type }) =>
    type === 1 &&
    `
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `};
`;

const Trucks = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 20px;
`;

const MileageSection = styled.div`
  margin-bottom: 25px;

  button {
    float: right;
  }
`;

const MileageTitle = styled.div`
  font-family: 'SF Display Bold';
  color: #5f5e5e;
  font-size: 14.5px;
  padding-bottom: 5px;
`;
const Comment = styled.div`
  margin-left: 20%;
  margin-top: 20px;
`;

const CommentsContainer = styled.div`
  max-height: 150px;
  overflow: auto;
  margin-right: 20px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CommentField = styled.span`
  font-family: 'SF Display Medium';
  font-size: 13px;
  display: flex;
  margin-top: 5px;
`;

const CommentFieldBold = styled(CommentField)`
  font-family: 'SF Display Heavy';
`;

const InstructorCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditIcon = styled.img`
  height: 13px;
  width: 13px;
`;

const EditIconContainer = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditComentTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StudentDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  column-gap: 25px;
`;

const StudentDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
  display: grid;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};

  span {
    font-weight: 300;
    min-height: 23px;
  }
`;

const ModalTitle = styled.div`
  font-size: 24px;
  color: rgb(55, 159, 255);
  font-weight: bolder;
  display: inline-block;
  vertical-align: middle;

  div {
    display: grid;
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    margin-left: 13px;
  }
`;

const NotesContainer = styled.div``;

const NotesField = styled.span`
  font-family: 'SF Display Medium';
  font-size: 13px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectMainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5%;
  grid-gap: 20px;
`;

export {
  Title,
  SectionRow,
  SectionContainer,
  Question,
  QuestionsContainer,
  QuestionRow,
  HeaderContainer,
  CategoryContainer,
  Name,
  ButtonContainer,
  PerformanceContainer,
  Trucks,
  MileageSection,
  MileageTitle,
  Comment,
  CommentsContainer,
  CommentField,
  CommentFieldBold,
  StudentDetailContainer,
  StudentDetail,
  ModalTitle,
  NotesContainer,
  NotesField,
  InstructorCommentContainer,
  EditIcon,
  EditIconContainer,
  EditComentTextAreaContainer,
  RadioContainer,
  SelectMainContainer
};
