const aamvaSectionIds = [8, 9, 10, 11];

const transformAll = ({ success, data }) => {
  if (success === 0) {
    return [];
  }

  return data.testingQuestions.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
};

const transformBeforeUpdate = ({ question, sectionType, ordering, branchIds, removedBranches }) => {
  return {
    question,
    sectionType,
    ordering,
    branchIds,
    aamva: GLOBALS.testing.sections[3].find(skill => skill.id === sectionType).isAamva,
    removedBranches
  };
};

const transformCreate = obj => {
  return {
    ...obj,
    type: 3,
    ordering: 1,
    aamva: GLOBALS.testing.sections[3].find(skill => skill.id === obj.sectionType).isAamva
  };
};

export { transformAll, transformBeforeUpdate, transformCreate };
