import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 53px;
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  min-width: 300px;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border-bottom: 1px solid #70707025;
  font-weight: bold;
  font-size: 15px;
  color: DARK_TEXT;
  font-family: 'SF Display Bold';
`;

const StatusContainer = styled.div`
  display: flex;
  width: 200px;
  text-align: center;
  margin: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
`;

const StatusTitle = styled.div`
  font-size: 13.5px;
  width: 200px;
  font-weight: bold;
  color: ${props => props.color};
  font-family: 'SF Display Bold';
`;

const Img = styled.img`
  src: ${props => props.src};
  margin-left: 6px;
`;

const RightBorder = styled.div`
  width: 1px;
  background-color: #70707040;
`;

export { Container, Title, StatusContainer, StatusTitle, Img, RightBorder };
