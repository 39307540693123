import React, { useState } from 'react';
import { Select, Form } from 'antd';
import styled from 'styled-components';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

import { parseAddress } from '_src/utils';

const Label = styled.div`
  margin-bottom: ${({ inline }) => (inline ? '0' : '5px')};
  margin-right: ${({ inline }) => (inline ? '5px' : '0')};
  min-width: ${({ inline }) => (inline ? '30%' : '0')};
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

const Container = styled.div`
  display: ${({ inline }) => (inline ? 'flex' : 'block')};
  align-items: center;
`;

const assembleAddress = (address = {}) => {
  let assembled = '';

  if (address === null) {
    return assembled;
  }

  if (address.street) {
    assembled += address.street + ', ';
  }

  if (address.city) {
    assembled += address.city + ', ';
  }

  if (address.state) {
    assembled += address.state + ' ' + (address.postalCode || '') + ', ';
  }

  if (address.country) {
    assembled += address.country;
  }

  return assembled;
};

export default ({ value, error, onChange, touched, label, inline = false, ...rest }) => {
  const [address, setAddress] = useState(assembleAddress(value));
  const hasError = error && touched;

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      <Container inline={inline}>
        {(label || hasError) && (
          <Label inline={inline} hasError={hasError}>
            {hasError ? error : label}
          </Label>
        )}

        <PlacesAutocomplete value={address} onChange={value => setAddress(value)} debounce={500}>
          {({ getInputProps, suggestions, _, loading }) => {
            const inputProps = getInputProps();

            return (
              <Select
                value={address}
                showSearch
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                onSearch={value => {
                  inputProps.onChange({ target: { value } });
                }}
                onChange={value => {
                  const selected = suggestions.find(({ id }) => id === value);
                  const { placeId } = selected;

                  geocodeByPlaceId(placeId).then(address => {
                    setAddress(address[0].formatted_address);
                    const parsed = parseAddress(address);
                    onChange(parsed);
                  });
                }}
                loading={loading}
              >
                {suggestions.map(({ id, description }) => (
                  <Select.Option key={id} value={id}>
                    {description}
                  </Select.Option>
                ))}
              </Select>
            );
          }}
        </PlacesAutocomplete>
      </Container>
    </Form.Item>
  );
};
