import React from 'react';

import { FallbackContainer, ErrorMessage } from './styles';

const Fallback = () => (
  <FallbackContainer>
    <ErrorMessage>Something went wrong</ErrorMessage>
    <img src="https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg" />
  </FallbackContainer>
);

export default Fallback;
