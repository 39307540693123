import React from 'react';
import { Button, Icon } from 'antd';
import * as XLSX from "xlsx";

const handleDownload = record => {
  const ws = XLSX.utils.json_to_sheet(record.children.map(({ id, key, ...rest }) => ({ ...rest })));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  var range = XLSX.utils.decode_range(ws['!ref']);
  for (var C = range.s.c; C <= range.e.c; ++C) {
    var address = XLSX.utils.encode_col(C) + "1";
    if (!ws[address]) continue;
    ws[address].v = ws[address].v
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) { return str.toUpperCase(); })
  }
  XLSX.writeFile(wb, record.name + ".xlsx")
}

export default [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    }
  },
  {
    title: 'Total checkins',
    dataIndex: 'totalCheckins',
    key: 'totalCheckins',
    sorter: (a, b) => a.totalCheckins - b.totalCheckins
  },
  {
    title: 'Open sessions',
    dataIndex: 'openSessions',
    key: 'openSessions',
    sorter: (a, b) => a.openSessions - b.openSessions
  },
  {
    title: 'Number of students trained',
    dataIndex: 'students',
    key: 'students',
    sorter: (a, b) => a.students - b.students
  },
  {
    title: 'Pretrips scores',
    dataIndex: 'pretripsScore',
    key: 'pretripsScore',
    sorter: (a, b) => a.pretripsScore - b.pretripsScore
  },
  {
    title: 'Pretrips test taken',
    dataIndex: 'pretrips',
    key: 'pretrips',
    sorter: (a, b) => a.pretrips - b.pretrips
  },
  {
    title: 'Skills Score',
    dataIndex: 'skillsScore',
    key: 'skillsScore',
    sorter: (a, b) => a.skillsScore - b.skillsScore
  },
  {
    title: 'Skill test taken',
    dataIndex: 'skills',
    key: 'skills',
    sorter: (a, b) => a.skills - b.skills
  },
  {
    title: 'Road score',
    dataIndex: 'roadsScore',
    key: 'roadsScore',
    sorter: (a, b) => a.roadsScore - b.roadsScore
  },
  {
    title: 'Road test taken',
    dataIndex: 'road',
    key: 'road',
    sorter: (a, b) => a.road - b.road
  },
  {
    title: 'Hours trained in yard',
    dataIndex: 'yardHoursTrained',
    key: 'yardHoursTrained',
    sorter: (a, b) => a.yardHoursTrained - b.yardHoursTrained
  },
  {
    title: 'Hours trained in classroom',
    dataIndex: 'classHoursTrained',
    key: 'classHoursTrained',
    sorter: (a, b) => a.classHoursTrained - b.classHoursTrained
  },
  {
    title: 'Active yard students',
    dataIndex: 'activeYardStudents',
    key: 'activeYardStudents'
  },
  {
    title: '',
    dataIndex: '',
    key: 'downloadXls',
    render: (_, record) =>
      record.children ? <a onClick={() => handleDownload(record)}>
        <Button type="default">
          <Icon type="download" />
        </Button></a> : ''
  }
];
