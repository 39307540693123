const transformAll = ({ success, data }) => {
  if (success === 0) {
    return [];
  }
  return data.testingQuestions.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
};

const transformCreate = ({ question, sectionType }) => {
  return { question, sectionType, type: 1, ordering: 1 };
};

const transformBeforeUpdate = ({ question, sectionType, ordering, branchIds }) => {
  return { question, sectionType, ordering, branchIds };
};

export { transformAll, transformCreate, transformBeforeUpdate };
