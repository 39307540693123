const snakeToCamelCase = string => string.replace(/\B_(\w)/g, (_, group) => group.toUpperCase());

const camelToSnakeCase = string =>
  string.replace(/\B([A-Z])/g, (_, group) => `_${group.toLowerCase()}`);

const getGroupedPrivileges = () => {
  const { PRIVILEGES, GROUPED_PRIVILEGE_RESOURCES } = GLOBALS;

  const privilegesForResource = PRIVILEGES.reduce((acc, privilege) => {
    const { id, resource, method } = privilege;
    const current = acc[resource] || [];

    return { ...acc, [resource]: [...current, { id, method }] };
  }, []);

  return GROUPED_PRIVILEGE_RESOURCES.reduce((acc, { id, resources, methods }) => {
    const resourcePrivilegs = resources.reduce((acc, resource, index) => {
      const resourceMethods = Array.isArray(methods[0]) ? methods[index] : methods;

      const privilegeIds = privilegesForResource[resource]
        .filter(({ method }) => resourceMethods.includes(method))
        .map(({ id }) => id);

      return [...acc, ...privilegeIds];
    }, []);

    return { ...acc, [id]: resourcePrivilegs };
  }, {});
};

export { snakeToCamelCase, camelToSnakeCase, getGroupedPrivileges };
