const transformAll = res => {
  if (res.success !== 1) {
    return [];
  }

  return res.data.driverSearchResult.map(
    ({ address, firstName, lastName, code, certificate, id, email, employer }) => ({
      id,
      key: id,
      name: `${firstName} ${lastName}`,
      email,
      address: address.street || '',
      code,
      certificate: certificate || [],
      employer
    })
  );
};

export { transformAll };
