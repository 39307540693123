import { stripNullObj } from 'utils';

const transformBeforeSave = data => {
  const { pin = '', license, branchId } = data;

  if (pin.length === 0) {
    delete data.pin;
  }

  if (!license) {
    delete data.license;
  }

  if (!branchId) {
    delete data.branchId;
  }

  return data;
};

const transformUser = ({ firstName, lastName, email, branchId, license, username, groupName }) =>
  stripNullObj({
    firstName,
    lastName,
    email,
    branchId,
    license,
    username,
    groupName
  });

export { transformBeforeSave, transformUser };
