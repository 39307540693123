import React, { useContext } from 'react';
import { Context } from 'store';

export default [
  {
    title: 'Submissioner',
    dataIndex: 'submittedByFirstName',
    key: 'submittedByFirstName',
    width: '200px',
    render: (_, { submittedByFirstName, submittedByLastName }) =>
      `${submittedByFirstName} ${submittedByLastName}`
  },
  {
    title: 'Student',
    dataIndex: 'studentFirstName',
    key: 'studentFirstName',
    render: (_, { studentFirstName, studentLastName }) => (
      <div style={{ whiteSpace: 'initial' }}>
        {studentFirstName} {studentLastName}
      </div>
    )
  },
  {
    title: 'Submited for',
    dataIndex: 'submittedFor',
    key: 'submittedFor'
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },

  {
    title: 'Date of submission',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
];
