import styled from 'styled-components';

const LabeledFieldStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Label = styled.div`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '15px')};
  color: #ff8400;
  font-family: 'SF Display Heavy';
`;

const Field = styled.div`
  font-family: 'SF Display Bold';
  font-weight: bold;
  font-size: 15px;
  margin-left: 15px;
  color: #3b3b3b;
`;

export { LabeledFieldStyle, Label, Field };
