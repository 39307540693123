import React, { useState } from 'react';
import { Table, Button } from 'antd';
import { uniq } from 'utils';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

import DndTable from './DndTable';

export default ({
  data = [],
  dataKey = 'id',
  onClick = () => {},
  columns = [],
  fileName = '160Digital',
  exportable = false,
  sortable = false,
  pagination = false,
  onOrderChange = () => {},
  onChange = () => {},
  ...rest
}) => {
  const dataSource = data.length
    ? data.map(row => {
        if (row.children) {
          const children = row.children.map(child => ({
            ...child,
            key: child[dataKey]
          }));
          return { ...row, key: row[dataKey], children };
        }
        return { ...row, key: row.key ? row.key : row[dataKey] };
      })
    : [];

  const [_exporter, setExporter] = useState();

  columns = columns.map(column => {
    const { dataIndex, filterType } = column;
    if (filterType === 'all') {
      column.filters = uniq(data, dataIndex)
        .map(row => row[dataIndex])
        .filter(row => !!row)
        .map(text => ({ text, value: text }));
    }
    return column;
  });

  const exportToExcel = () => {
    _exporter.save();
  };

  const prepareExcelData = data => {
    let dataObj = [];

    data.forEach(row => {
      let rowData = {};
      columns.forEach(({ dataIndex, render, renderExport }) => {
        const cell = row[dataIndex];
        rowData[dataIndex] = render ? render(cell, row) : cell;
        if (renderExport) {
          rowData[dataIndex] = renderExport(cell, row);
        }
      });
      dataObj.push(rowData);
    });

    return dataObj;
  };

  const renderTitleHeader = () => (
    <div style={{ textAlign: 'right' }}>
      <Button type="primary" onClick={() => exportToExcel()}>
        Export To Excel
      </Button>
    </div>
  );

  const renderExcelTable = () => {
    if (!exportable) {
      return null;
    }

    return (
      <ExcelExport
        data={prepareExcelData(dataSource)}
        fileName={`${fileName}.xlsx`}
        ref={setExporter}
      >
        {columns.map(({ title, dataIndex }) => (
          <ExcelExportColumn key={dataIndex} title={title} field={dataIndex} />
        ))}
      </ExcelExport>
    );
  };

  const renderTable = () => {
    return (
      <Table
        bordered
        onChange={onChange}
        pagination={pagination}
        columns={columns}
        title={exportable ? () => renderTitleHeader() : undefined}
        dataSource={dataSource}
        onRow={(...params) => ({
          onClick: () => onClick(...params)
        })}
        {...rest}
      />
    );
  };

  return (
    <>
      {sortable ? (
        <DndTable
          dataSource={dataSource}
          columns={columns}
          onClick={onClick}
          onOrderChange={onOrderChange}
        />
      ) : (
        renderTable()
      )}
      {renderExcelTable()}
    </>
  );
};
