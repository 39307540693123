import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context, useGet, useSave } from 'store';
import { Card, notification } from 'antd';
import Filepicker from '_src/shared/form-helpers/Filepicker'
import { PageContainer } from '_src/shared/styles';
import { Refresh } from '_src/components';
import * as XLSX from 'xlsx';

import services from '_src/services';

export default () => {

    const resource = 'classroomScore';
    const config = services[resource];

    const { save: uploadFile } = useSave(config.uploadScore);

    const handleExcelUpload = e => {
        const file = e.target.files[0]

        if (file) {
            const reader = new FileReader()

            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary', bookVBA: true });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);

                uploadFile({
                    students: data
                }).then(({ success, data }) => {
                    if (success === 1) {
                        notification.success({
                            message: `Successfully uploaded score`,
                            duration: 5
                        });
                    }
                    if (success === 0) {
                        notification.error({
                            message: `There are some data missing`,
                            duration: 5
                        });
                    }
                });
            }
            reader.readAsBinaryString(file)
        }
    }

    return (
        <PageContainer>
            <Refresh />
            <Card title="Classroom Score">
                <Filepicker
                    data-cy="excelUpload"
                    label="Choose file with classroom scores"
                    toBase64={false}
                    type="excel"
                    style={{ marginLeft: 'auto' }}
                    input={() => <div>Import jobs</div>}
                    onChange={handleExcelUpload}
                />
            </Card>
        </PageContainer>
    )
}