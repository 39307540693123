import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /** Heavy */
  @font-face {
    font-family: 'SF Display Heavy';
    font-weight: 800;
    font-style: normal;
    src: url('/fonts/sf-pro-text-heavy.woff') format('truetype');
  }

  /** Bold */
  @font-face {
    font-family: 'SF Display Bold';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/sf-pro-text-bold.woff') format('truetype');
  }

  /** Medium */
  @font-face {
    font-family: 'SF Display Medium';
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/sf-pro-text-medium.woff') format('truetype');
  }

  /** Regular */
  @font-face {
    font-family: 'SF Display Regular';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/sf-pro-text-regular.woff') format('truetype');
  }

  /** Light */
  @font-face {
    font-family: 'SF Display Light';
    font-weight: 200;
    font-style: normal;
    src: url('/fonts/sf-pro-text-light.woff') format('truetype');
  }

  /** Semi bold */
  @font-face {
    font-family: 'SF Display Semi bold';
    font-weight: 600;
    font-style: normal;
    src: url('/fonts/sf-pro-text-semibold.woff') format('truetype');
  }

  body {
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
    font-family: 'Roboto' !important;
  }

  p,h3,h4 {
    margin: 0px;
    padding: 0px;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  
  #app  .ant-layout {
    min-height: 100vh;
  }
  
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  
  .ant-form-item-control {
    line-height: initial !important;
  }

  .ant-select {
    width: 100%;
  }

  .ant-skeleton-paragraph {
    padding: 0;
  }

  .ant-table-tbody > tr > td {
    white-space: nowrap;
  }

  .custom-td {
    padding: 0 !important;
    text-align: center !important;
    height: 1px;  
  }

  .attendance .ant-select-selection {
    border-color: #949292 !important;
  }
  
  .ant-table-title {
    display: grid;
    justify-content: flex-end;
  }
  
  .ant-calendar-picker {
    width: 100%;
  }

  .ant-time-picker {
    width: 100% !important;
  }

  .attendance .ant-select-selection {
    border-color: #949292 !important;
  }

  *[data-keypad="number"] {
    -webkit-text-security: disc;
  }
  
  .ant-checkbox-group { 
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin-top: 5px !important;
  }

  .ant-menu-item {
    height: 50px !important;
    line-height: 50px !important;
  }

  .ant-calendar-picker-input {
    background-color: #fff !important; 
    color: rgba(0, 0, 0, 0.65) !important;
    cursor: default !important;
  }

  .ant-radio-inner {
    width: 18px !important;
    height: 18px !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #222 !important;
  }

  .ant-radio-inner::after {
    background-color: #222 !important;
    width: 10px !important;
    height: 10px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #222 !important;
    border-color: #222 !important;
  }

  .ant-checkbox-inner {
      width: 21px !important;
      height: 21px !important;
  }

  .ant-checkbox-inner::after {
    left: 30% !important;
  }

  .ant-modal-wrap {
    left: 19px;
    right: 19px;
  }

  .ant-modal-body {
    padding: 25px 52px !important;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-layout-sider-children {
    position: fixed;
    max-width: 200px;
    overflow-y: scroll;
  }

   #drivers_scores .ant-table-tbody > tr > td {
    white-space: normal !important;
  }
`;

export default GlobalStyles;
