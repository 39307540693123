import React, { useContext, useState, useEffect } from 'react';
import { useDelete, Context, useGet, useSave, useFetch } from 'store';
import { notification, Skeleton, Button as AntButton } from 'antd';
import { Form } from 'form';
import moment from 'moment';

import { Modal, Fields } from '_src/components';
import { Category, Performance, Radio, Button, GradientValue } from '_src/ui';
import { Restricted } from '_src/shared/restrict';
import services from '_src/services';
import { Textarea, Select } from '_src/shared/form-helpers';
import editImg from '_src/assets/images/edit.png';

import {
  PerformanceContainer,
  SectionContainer,
  Title,
  QuestionsContainer,
  Question,
  QuestionRow,
  HeaderContainer,
  CategoryContainer,
  Name,
  ButtonContainer,
  Trucks,
  MileageSection,
  MileageTitle,
  Comment,
  CommentsContainer,
  CommentField,
  CommentFieldBold,
  StudentDetailContainer,
  StudentDetail,
  ModalTitle,
  NotesContainer,
  NotesField,
  InstructorCommentContainer,
  EditIcon,
  EditIconContainer,
  EditComentTextAreaContainer,
  SelectMainContainer
} from './styles';

const performanceToIndex = {
  UNSUCCESFUL: 0,
  'NEEDS IMPROVEMENT': 1,
  SATISFACTORY: 2,
  PROFICIENT: 3
};

export default ({ student, type, resource, updateTotalHours, evaluationTests }) => {
  const config = services.studentPerformanceEvaluator;
  const reportsConfig = services['reports'];

  const configSecond = services.hoursField;
  const evaluationTestsConfig = services['evaluationTests'];
  const [formApi, setFormApi] = useState();
  const { setShown, getData, getShown, refreshResource } = useContext(Context);
  const [selectedSection, selectSection] = useState();
  const [answers, setAnswers] = useState({});
  const [sections, setSections] = useState([]);
  const [preRequests, setPreRequests] = useState([]);
  const [responsesResult, setResponsesResult] = useState('');
  const [currentEvaulation, setCurrentEvaluation] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPreSubmitting, setIsPreSubmitting] = useState(false);
  const [testsDate, setTestsDate] = useState({});
  const [selectedTestDate, setSelectedTestDate] = useState();
  const [selectedTestHour, setSelectedTestHour] = useState(undefined);
  // const [totalHours, setTotalDrivenHours] = useState('00:00');
  const [commentToEdit, setCommentToEdit] = useState(false);
  const userId = getData('user').id;
  const userData = getData('user');
  const { get: getTodayEvaluation } = useFetch(config.getTodayEvaluation);
  const { get: getResponses } = useFetch(evaluationTestsConfig.getResponses);
  const [note, setNote] = useState();
  // const [errors, setError] = useState({});
  const [comments, setComments] = useState([]);
  const [params, setParams] = useState({});
  const [totalAnswered, setTotalAnswered] = useState(0);
  const [allQuestions, setAllQuestions] = useState([]);

  const { destroy: deleteTest } = useDelete(evaluationTestsConfig.deleteTest);

  const { save: createMileage } = useSave({
    ...config.createMileage,
    validate: () => formApi.validate()
  });

  const { save: updateMileage } = useSave({
    ...config.updateMileage,
    validate: () => formApi.validate()
  });

  const { save: updateComments } = useSave({
    ...config.updateComments
  });

  const { get: getComment } = useFetch(reportsConfig.comment);

  useEffect(() => {
    getTodayEvaluation({
      params: {
        studentId: student.id,
        responses: true,
        evaluationDate: moment().format('DD-MM-YYYY'),
        type: student?.locationType,
        userId
      }
    }).then(data => {
      if (data.length > 0) {
        setCurrentEvaluation(data[0]);
      }
    });

    getComment({
      params: {
        studentId: student.id,
        type: type
      }
    }).then(data => {
      setComments(data);
    });
  }, []);

  const { data: groupedQuestions, busy } = useGet({
    name: 'testingQuestins',
    path: 'testing/questions',
    transform: ({ data }) => {
      let sections = GLOBALS.testing.sections[type];

      setAllQuestions(data.testingQuestions);

      const questions = data.testingQuestions
        ? data.testingQuestions.map(question => ({
            ...question,
            performance: performanceToIndex[question.lastPerformance]
          }))
        : [];

      const grouped = questions.reduce((acc, question) => {
        const { sectionType } = question;
        const current = acc[sectionType] || [];
        const sectionQuestions = [...current, question].sort((a, b) =>
          a.ordering > b.ordering ? 1 : -1
        );

        return { ...acc, [sectionType]: sectionQuestions };
      }, {});

      const firstSection = (sections || []).find(({ id }) =>
        questions.find(({ sectionType }) => sectionType == id)
      );

      if (firstSection) {
        selectSection(firstSection?.id);
      } else if (data.testingQuestions.length !== 0) {
        selectSection(data.testingQuestions[0].sectionType);
      }

      sections = (sections || []).map(({ id, label }) => {
        const sectionQuestions = (grouped[id] || []).filter(
          ({ performance }) => performance !== undefined
        );

        const score = sectionQuestions.reduce((acc, { performance }) => acc + performance, 0);

        return {
          id,
          label,
          performance: sectionQuestions.length ? Math.round(score / sectionQuestions.length) : -1
        };
      });

      setSections(sections);

      return grouped;
    },
    defaultValue: {},
    params: {
      type,
      studentId: student?.id,
      branchId: typeof student?.branchId === 'number' ? student?.branchId : student?.studentBranch
    }
  });

  const { data: evaluationTest } = useGet({
    name: 'evaluationTests',
    path: 'testing/responses',
    transform: ({ data }) => {
      const tests = data.testingResponses;

      let testsTime = {};

      const grouped = (tests || []).reduce((acc, test) => {
        return {
          ...acc,
          [test.responseDate]: {
            ...test,
            questions: test.questions
              .map(item => ({
                ...item,
                response: test.responses.find(
                  ({ testingQuestionId }) => testingQuestionId === item.id
                )
              }))
              .reduce((accumulator, currentValue) => {
                const { sectionType } = currentValue;
                const current = accumulator[sectionType] || [];
                const sectionQuestions = [...current, currentValue];

                return { ...accumulator, [sectionType]: sectionQuestions };
              }, {})
          }
        };
      }, {});

      Object.keys(grouped).map(time => {
        const date = moment(time).format('MM/DD/YYYY');

        if (testsTime[date]) {
          testsTime[date] = [...testsTime[date], time];
        } else {
          testsTime = {
            ...testsTime,
            [date]: [time]
          };
        }
      });

      if (evaluationTests) {
        const sortTestsTime = Object.keys(testsTime || [])
          .sort((first, second) => moment(second).unix() - moment(first).unix())
          .reduce((acc, key) => {
            acc[key] = testsTime[key].sort(
              (first, second) => moment(second).unix() - moment(first).unix()
            );
            return acc;
          }, {});

        const initialSelectedTestDate = Object.keys(sortTestsTime)[0];
        const initialSelectedTestTime = sortTestsTime[initialSelectedTestDate][0];
        const initialQuestions = grouped[initialSelectedTestTime]?.questions || [];
        const initialSelectedSection = Object.keys(initialQuestions)[0];
        const initialSections = Object.keys(initialQuestions).map(questionSection => {
          const sections = GLOBALS.testing.sections[type];
          const section = sections.find(({ id }) => id == questionSection);

          return section;
        });

        let initialAnswers = {};

        Object.keys(initialQuestions).map(section => {
          initialQuestions[section].map(
            ({ response }) =>
              (initialAnswers[response.testingQuestionId] = response.details.response
                ? response.details.response
                : 0)
          );
        });

        evaluationTests && setAnswers(initialAnswers);
        setTestsDate(sortTestsTime);
        setSelectedTestDate(initialSelectedTestDate);
        setSelectedTestHour(initialSelectedTestTime);
        selectSection(initialSelectedSection);
        setSections(initialSections);
      }

      return grouped;
    },
    defaultValue: {},
    params: { type, studentId: student?.id }
  });

  const questions = evaluationTests
    ? evaluationTest[selectedTestHour]?.questions[selectedSection] || []
    : groupedQuestions[selectedSection] || [];

  const { save: create } = useSave({
    path: 'testing/responses'
  });

  const { save: createResponsesResults } = useSave({
    path: 'testing/responses/results'
  });

  const handleEditComment = commentData => {
    setCommentToEdit(commentData);
  };

  const renderComments = () => {
    if (comments.length > 0) {
      return (
        <div style={{ marginTop: '20px' }}>
          {type !== 4 && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                maxWidth: '300px',
                color: '#5f5e5e',
                fontWeight: '500',
                fontSize: '17px'
              }}
            >
              Comments
            </div>
          )}
          <CommentsContainer>
            {comments.map(({ id, comment, createdAt, instructor }, index) => (
              <InstructorCommentContainer key={id}>
                <CommentFieldBold>
                  {moment(createdAt).format('MMMM Do, h:mm')} - {instructor}
                </CommentFieldBold>
                <CommentField>
                  <Restricted resource="comment/update" method="POST">
                    {
                      <EditIconContainer>
                        <EditIcon
                          src={editImg}
                          onClick={() => handleEditComment({ comment, id, instructor })}
                        />
                      </EditIconContainer>
                    }
                  </Restricted>
                  {commentToEdit && commentToEdit?.id === id ? (
                    <EditComentTextAreaContainer>
                      <Textarea
                        value={commentToEdit.comment}
                        onChange={note => {
                          setCommentToEdit({ ...commentToEdit, comment: note });
                        }}
                        rows={3}
                        style={{ marginBottom: '10px' }}
                      />

                      <Button
                        loading={isSubmitting}
                        onClick={handleUpdateComment}
                        height={20}
                        width={'100%'}
                        shadow
                        filled
                        text="SAVE"
                      />
                    </EditComentTextAreaContainer>
                  ) : (
                    <p>- {comment}</p>
                  )}
                </CommentField>
              </InstructorCommentContainer>
            ))}
          </CommentsContainer>
        </div>
      );
    }
  };

  const closeModal = () => {
    setShown(resource, false);
  };

  const handlePreOk = () => {
    setIsPreSubmitting(true);
    const responses = Object.entries(answers)
      .filter(([_, value]) => value !== undefined)
      .map(([id, value]) => ({
        questionId: id,
        response: value.toString()
      }));

    if (type === 2 && !formApi.validate()) {
      setIsPreSubmitting(false);
      return;
    }

    if (responses.length === 0) {
      notification.error({
        message: 'You need to answer at least one question',
        duration: 3
      });
      setIsPreSubmitting(false);
      return;
    }

    var tempParams = {
      details: responses,
      studentId: student?.id,
      type,
      responseDate: moment().unix()
    };
    if (note) {
      tempParams.comment = note;
    }

    setParams(tempParams);

    setPreRequests([
      ...preRequests,
      createResponsesResults({ ...tempParams }).then(({ data }) => {
        setResponsesResult(Math.round(data.testingResponse?.result * 100));
      })
    ]);

    setShown('confirmPreTest', true);
  };

  const handleOk = () => {
    if (evaluationTests) {
      deleteTest({ id: evaluationTest[selectedTestHour]?.id }).then(({ success }) => {
        if (success === 1) {
          refreshResource('attendance');
          notification.success({
            message: 'Test deleted successfully!',
            duration: 3
          });
          setShown('confirmPreTest', false);
          closeModal();
        } else {
          notification.error({
            message: 'Test delete failed!',
            duration: 5
          });
        }
      });
    } else {
      setIsSubmitting(true);
      let requests = [];

      const aamva = allQuestions.find(q => q.id === +params.details[0].questionId)?.aamva;

      const elementsWithoutLastPerformance = params.details.filter(element => {
        const matchingQuestion = allQuestions.find(q => q.id === parseInt(element.questionId));
        return !matchingQuestion || !matchingQuestion.lastPerformance;
      }).length;

      const elementsWithLastPerformance = allQuestions.filter(q => !!q?.lastPerformance).length;

      requests.push(
        create({
          ...params,
          answered: elementsWithLastPerformance + elementsWithoutLastPerformance,
          aamva
        })
      );

      if (type === 2) {
        const mileageRequest = currentEvaulation ? updateMileage : createMileage;
        const truck = formApi.values();
        let dataToPost = {};

        if (currentEvaulation) {
          dataToPost = {
            id: currentEvaulation?.id,
            details: {
              trucks: [...currentEvaulation.details.trucks, { ...truck }]
            }
          };
        } else {
          dataToPost = {
            userId,
            studentId: student?.id,
            type: student?.locationType,
            evaluationDate: moment().unix(),
            responses: [],
            details: {
              trucks: [{ ...truck }]
            }
          };
        }

        requests.push(mileageRequest(dataToPost));
      }

      Promise.all(requests)
        .then(allRes => {
          const isValid = allRes.every(({ success }) => success === 1);

          if (isValid) {
            notification.success({
              message: 'Test was created successfully',
              duration: 3
            });
            refreshResource('attendance');
            if (updateTotalHours) {
              updateTotalHours(Date.now());
            }
            closeModal();
            setShown('confirmPreTest', false);
            setShown('studentTimeline', false);
          } else {
            const errorReq = allRes.filter(({ success }) => success === 0);
            errorReq.forEach(error => {
              notification.error({
                message: error.data.message,
                duration: 3
              });
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const deleteSingleTest = () => {
    setShown('confirmPreTest', true);
  };

  const handleUpdateComment = () => {
    updateComments({
      id: commentToEdit?.id,
      comment: commentToEdit.comment,
      type: 'comment'
    }).then(() => {
      getComment({
        params: {
          studentId: student?.id,
          type: type
        }
      }).then(data => {
        setComments(data);
        setCommentToEdit(false);
      });
    });
  };

  const getTitle = () => {
    const titles = {
      1: 'Pre-Trip',
      2: 'Road',
      3: 'Skills',
      4: 'Characteristics'
    };
    return titles[type];
  };

  const hasQuestions = sectionId =>
    evaluationTests
      ? (evaluationTest[selectedTestHour]?.questions[sectionId] || []).length
      : (groupedQuestions[sectionId] || []).length;

  const getPercentageDone = sectionId => {
    if (sectionId === selectedSection) {
      return 'Taking Now';
    }
    const performance_chart = {
      PROFICIENT: 1,
      UNSUCCESFUL: 0,
      SATISFACTORY: 0.66,
      'NEEDS IMPROVEMENT': 0.33
    };

    const sectionQuestions = (groupedQuestions[sectionId] || []).filter(
      ({ sectionType }) => sectionType === sectionId
    );

    const answered = sectionQuestions.filter(res => res.lastPerformance);

    const completedQuestionsPercentage = (answered.length / sectionQuestions.length) * 100;

    return `${Math.round(completedQuestionsPercentage)}% Done`;
  };

  const onHourChange = value => {
    const questionSections = Object.keys(evaluationTest[value]?.questions).map(questionSection => {
      const sections = GLOBALS.testing.sections[type];
      const section = sections.find(({ id }) => id == questionSection);

      return section;
    });

    let initialAnswers = {};

    Object.keys(evaluationTest[value]?.questions).map(section => {
      evaluationTest[value]?.questions[section].map(
        ({ response }) =>
          (initialAnswers[response.testingQuestionId] = response.details.response
            ? response.details.response
            : 0)
      );
    });

    setAnswers(initialAnswers);
    setSelectedTestHour(value);
    setSections(questionSections);
    selectSection(Object.keys(evaluationTest[value]?.questions)[0]);
  };

  return (
    <>
      <Modal width="100%" visible onCancel={closeModal} footer={null}>
        <Skeleton loading={busy}>
          <Title>{getTitle()}</Title>
          <Name>For {student.name}</Name>

          <ButtonContainer>
            {evaluationTests ? (
              <></>
            ) : (
              <Button
                loading={isSubmitting}
                onClick={handlePreOk}
                height={40}
                width={84}
                shadow
                filled
                text="SAVE"
              />
            )}

            {evaluationTests && (
              <Button
                color={GLOBALS.colors.RED}
                loading={isSubmitting}
                onClick={deleteSingleTest}
                height={40}
                width={84}
                shadow
                filled
                text="DELETE"
              />
            )}

            <Button height={40} width={84} text="CANCEL" onClick={closeModal} />
          </ButtonContainer>

          {evaluationTests && (
            <SelectMainContainer>
              <Select
                label="Date of test"
                options={Object.keys(testsDate).map(item => ({ id: item, label: item }))}
                value={moment(selectedTestDate).format('YYYY-MM-DD')}
                onChange={value => {
                  setSelectedTestDate(value);
                  onHourChange(testsDate[value][0]);
                }}
              />

              <Select
                label="Time of test"
                options={
                  selectedTestDate
                    ? testsDate[selectedTestDate].map(item => ({
                        id: item,
                        label: moment(item).format('hh:mm:ss')
                      }))
                    : []
                }
                value={selectedTestHour}
                onChange={value => onHourChange(value)}
              />
            </SelectMainContainer>
          )}

          {type === 2 && !evaluationTests && (
            <MileageSection>
              <MileageTitle>Add Mileage</MileageTitle>
              <Form schema={config.schema} formApi={api => setFormApi(api)}>
                <Trucks>
                  <Fields fields={config.fields} />
                </Trucks>
              </Form>
            </MileageSection>
          )}

          <HeaderContainer>
            {type !== 4 && <div>Category</div>}
            <div>
              {!evaluationTests && <div>Previous Test Performance</div>}
              <div>Evaluation</div>
            </div>
          </HeaderContainer>

          <div style={{ display: 'flex' }}>
            {type !== 4 && (
              <SectionContainer>
                {sections
                  .filter(({ id }) => hasQuestions(id))
                  .map(({ id, label, performance }) => (
                    <CategoryContainer key={id}>
                      <Category
                        performance={performance}
                        title={label}
                        subtitle={
                          label === 'Shifting' && student.automaticGear
                            ? 'Not available for automatic students'
                            : getPercentageDone(id)
                        }
                        selected={selectedSection == id}
                        onClick={() =>
                          label === 'Shifting' && student.automaticGear ? {} : selectSection(id)
                        }
                      />
                    </CategoryContainer>
                  ))}

                {renderComments()}
              </SectionContainer>
            )}
            <QuestionsContainer type={type}>
              {questions.map(({ id, question, lastPerformance, response }, index) => (
                <div key={id} style={{ display: 'flex' }}>
                  {!evaluationTests && (
                    <PerformanceContainer type={type}>
                      <Performance iconOnly={true} type={lastPerformance} />
                    </PerformanceContainer>
                  )}

                  <div style={{ width: '100%' }}>
                    <QuestionRow type={type} key={id}>
                      <Question>
                        {index + 1}.{question}
                      </Question>

                      {type === 1 && (
                        <Radio
                          value={answers[id]}
                          disabled={evaluationTests}
                          onChange={value =>
                            setAnswers({
                              ...answers,
                              [id]: value === undefined ? value : !!value ? 1 : 0
                            })
                          }
                        />
                      )}

                      {(type === 2 || type === 3 || type === 4) && (
                        <GradientValue
                          type={type}
                          value={answers[id]}
                          disabled={evaluationTests}
                          onChange={value =>
                            setAnswers({
                              ...answers,
                              [id]: value
                            })
                          }
                        />
                      )}
                    </QuestionRow>
                  </div>
                </div>
              ))}
            </QuestionsContainer>
          </div>
          <Comment>
            <Textarea
              value={note}
              onChange={note => {
                setNote(note);
              }}
              touched
              label="Comments"
              rows={3}
            />
            {type === 4 && renderComments()}
          </Comment>
        </Skeleton>
      </Modal>

      <Modal
        width={'50%'}
        visible={getShown('confirmPreTest')}
        title={
          <ModalTitle key="title">
            {evaluationTests ? 'Are you sure you want to delete this test ?' : student.name}
          </ModalTitle>
        }
        footer={[
          <AntButton
            key="submitClose"
            type="default"
            onClick={() => setShown('confirmPreTest', false)}
          >
            Cancel
          </AntButton>,
          <AntButton
            key="submitEdit"
            type={evaluationTests ? 'danger' : 'primary'}
            loading={isSubmitting}
            onClick={handleOk}
          >
            {evaluationTests ? 'Delete' : 'Submit'}
          </AntButton>
        ]}
      >
        {evaluationTests ? (
          <span style={{ display: 'flex', marginBottom: 20 }}>
            <StudentDetailContainer>
              <StudentDetail>
                Student <span>{student.name}</span>
              </StudentDetail>
              <StudentDetail>
                Date of test <span>{moment(selectedTestHour).format('DD-MM-YYYY')}</span>
              </StudentDetail>
              <StudentDetail>
                Time of test <span>{moment(selectedTestHour).format('hh:mm:ss')}</span>
              </StudentDetail>
            </StudentDetailContainer>

            <StudentDetailContainer>
              <StudentDetail>
                Performance <span>{student.performance[type].lastestResult}%</span>
              </StudentDetail>

              <StudentDetail style={{ fontWeight: 'bold' }}>
                User of this action{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {userData.firstName} {userData.lastName}
                </span>
              </StudentDetail>
            </StudentDetailContainer>
          </span>
        ) : (
          <span style={{ display: 'flex', marginBottom: 20 }}>
            <StudentDetailContainer>
              <StudentDetail>
                Email <span>{student?.email}</span>
              </StudentDetail>
              <StudentDetail>
                Birthdate <span>{moment(student.birthdate).parseZone().format('MM/DD/YYYY')}</span>
              </StudentDetail>
              <StudentDetail>
                Salesforce contact number <span>{student.contactNumber}</span>
              </StudentDetail>
              <StudentDetail>
                Permit Rec. Date{' '}
                <span>{moment(student.permitRecDate).parseZone().format('MM/DD/YYYY')}</span>
              </StudentDetail>
              <StudentDetail>
                Location <span>{student?.locationType === 1 ? 'Yard' : 'Classroom'}</span>
              </StudentDetail>
              <StudentDetail>
                Performance <span>{responsesResult}%</span>
              </StudentDetail>
            </StudentDetailContainer>
            <StudentDetailContainer>
              <StudentDetail>
                Gender <span>{student.gender}</span>
              </StudentDetail>
              <StudentDetail>
                Phone <span>{student.phone}</span>
              </StudentDetail>
              <StudentDetail>
                Sponsor <span>{student.sponsor}</span>
              </StudentDetail>
              <StudentDetail>
                Status <span>{student.status}</span>
              </StudentDetail>
              <StudentDetail>
                License <span>{student.license}</span>
              </StudentDetail>
            </StudentDetailContainer>
          </span>
        )}
      </Modal>
    </>
  );
};
