import React from 'react';

export default [
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (_, { firstName }) => <div>{firstName}</div>,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName)
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastNAme',
    render: (_, { lastName }) => <div> {lastName}</div>,
    sorter: (a, b) => a.lastName.localeCompare(b.lastName)
  },
  {
    title: 'Contact number',
    dataIndex: 'contactNumber'
  },
  {
    title: 'Branch name',
    dataIndex: 'branchName'
  },
  {
    title: 'Location type',
    dataIndex: 'locationType'
  },
  {
    title: 'Road completion',
    dataIndex: 'roadCompletion'
  },
  {
    title: 'Road performance',
    dataIndex: 'roadPerformance'
  },
  {
    title: 'Skills completion',
    dataIndex: 'skillsCompletion'
  },
  {
    title: 'Skills performance',
    dataIndex: 'skillsPerformance'
  },
  {
    title: 'Total class hours',
    dataIndex: 'totalClassHours'
  },
  {
    title: 'Total pre trip hours',
    dataIndex: 'totalPreTripHours'
  },
  {
    title: 'Total road hours',
    dataIndex: 'totalRoadHours'
  },
  {
    title: 'Total road observation hours',
    dataIndex: 'totalRoadObservationHours'
  },
  {
    title: 'Total skill hours',
    dataIndex: 'totalSkillHours'
  },
  {
    title: 'Total skill observation hours',
    dataIndex: 'totalSkillObservationHours'
  },
  {
    title: 'Total training hours',
    dataIndex: 'totalTrainingHours'
  }
];
