export default [
  {
    name: 'question',
    label: 'Road Question',
    component: 'input'
  },
  {
    name: 'sectionType',
    label: 'Section',
    component: 'select',
    options: GLOBALS.testing.sections[2]
  }
];
