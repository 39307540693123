import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  padding: 25px;
  overflow: hidden;
  background-color: #fff;
  font-family: 'SF Display Heavy';

  .ant-select-selection {
    font-size: 13px !important;
    font-family: 'SF Display Bold';
    color: #707070;
    border-radius: 18px;
    border-color: #727272;
    background-color: #fff;
  }

  .ant-select-selection--single {
    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: 8px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #707070;
    }
  }

  .ant-select-selection-selected-value {
    padding-right: 15px;
  }
`;

const TwoColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;

  @media (max-width: 1200px) {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 20px;
  }
`;

const OneColumn = styled.div`
  display: contents;

  @media (max-width: 1200px) {
    display: flex;
    grid-column-gap: 20px;
    ${({ justifyContent }) => `
    justify-content: ${justifyContent ? 'end' : 'initial'}
  `};
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const EqualColumns = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-self: center;
`;

const PageTitle = styled.div`
  font-size: 16px;
  color: #707070;
  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const CenterCol = styled.div`
  align-self: center;
`;

const TableContainer = styled.div`
  margin-top: 15px;
  overflow: auto;
`;

const THeader = styled.div`
  grid-template-columns: minmax(200px, 20%) minmax(200px, 20%) minmax(60%, 1fr);
  display: grid;
  font-size: 16px;
  font-family: 'SF Display Bold';
  color: #707070;
`;

const FirstCol = styled.div`
  border-right: 1px solid #727272;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 30px;
  display: flex;

  .anticon-exclamation {
    font-size: 30px;
    position: relative;
    padding-left: 7px;
    color: #fa8403;
  }
`;

const MiddleCol = styled.div`
  border-right: 1px solid #727272;
  padding: 20px;
  display: flex;
`;

const ThirdCol = styled.div`
  padding-left: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;

  align-self: center;
  ${({ borderBottom }) => `
    border-bottom: ${borderBottom ? '1px solid #727272' : 'none'}
  `};

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    gap: 10px;
  }
`;

const TRow = styled.div`
  grid-template-columns: minmax(200px, 20%) minmax(200px, 20%) minmax(60%, 1fr);
  display: grid;
  font-size: 16px;
  font-family: 'SF Display Bold';
  color: #3b3b3b;

  img {
    align-self: center;
  }
  .ant-dropdown-trigger {
    display: flex;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    grid-template-columns: minmax(200px, 20%) minmax(200px, 20%) minmax(75%, 1fr);
  }
`;

const Name = styled.span`
  padding-left: 15px;
  align-self: center;
  cursor: pointer;
`;

const AttendanceValue = styled.div`
  font-size: 13px;
  font-family: 'SF Display Heavy';
  border: 2px solid #ef5350;
  border-radius: 35px;
  color: #ef5350;
  align-self: center;
  width: 100px;
  text-align: center;
  padding: 5px 20px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 15px;
`;

const StudentContainer = styled.div`
  border-right: 1px solid #ececec;
  margin-top: 15px;
`;

const StudentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10%;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #ececec;

  :hover {
    background-color: #ececec;
  }
`;

const InstructionContainer = styled.div`
  margin-top: 30px;
`;

const Section = styled.div`
  :not(:last-child) {
    margin-bottom: 30px;
  }
`;

const Text = styled.div`
  font-size: 13px;
  ${({ color }) => `
    color: ${color}
  `};
`;

const GridImage = styled.img`
  ${({ w, h, mLeft = 0 }) => `
    width: ${w}px;
    height: ${h}px;
    margin-left: ${mLeft}px;
  `};
  align-self: center;
`;

const AbsentRow = styled(StudentRow)`
  border-bottom: 1px solid #ececec;
  padding: 10px 20px;

  :hover {
    background-color: #ececec;
  }
`;
const OverrideCell = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon {
    cursor: pointer;
  }
`;

const OverrideContainer = styled.div``;

const OverrideFormContainer = styled.div`
  display: grid;
  grid-gap: 15px 0;
`;

const OverrideRow = styled.div`
  display: grid;
  grid-template-columns: ${({ columns = 3 }) => `repeat(${columns}, 1fr) 0.3fr;`};
  text-align: center;
  border-bottom: 1px solid #ececec;

  :first-child {
    border-top: 1px solid #ececec;
  }

  ${OverrideCell}:not(:last-child) {
    border-right: 1px solid #ececec;
  }
`;

const Row = styled.div`
  display: inline-flex;
  width: 100%;
  font-family: 'SF Display Light';
  margin-top: 35px;
`;

const CardBlock = styled.div`
  padding: 0;
  color: #000;
  margin-bottom: 5px;
  text-align: center;

  ${({ colSpan }) => `
    width: ${colSpan}%;
  `};
`;

const LabelCard = styled.p`
  color: #000;
  font-family: 'SF Display Medium';
`;

const Totals = styled.p`
  font-size: 24px;
`;

const AttendanceIcons = styled.img`
  width: 25px;
  margin-left: 20px;
  margin-bottom: -5px;
  cursor: pointer;
`;

const ClockIcon = styled(AttendanceIcons)`
  width: 22px;
`;

const CenterText = styled.div`
  text-align: center;
`;

const StudentIcons = styled.div`
  margin-left: auto;
  display: flex;
`;
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export {
  PageContainer,
  TwoColumns,
  OneColumn,
  PageTitle,
  CenterCol,
  TableContainer,
  THeader,
  FirstCol,
  MiddleCol,
  ThirdCol,
  TRow,
  Name,
  AttendanceValue,
  EqualColumns,
  ModalContent,
  StudentContainer,
  StudentRow,
  InstructionContainer,
  Section,
  Text,
  GridImage,
  TitleRow,
  AbsentRow,
  OverrideRow,
  OverrideContainer,
  OverrideCell,
  OverrideFormContainer,
  CardBlock,
  LabelCard,
  Totals,
  Row,
  AttendanceIcons,
  ClockIcon,
  CenterText,
  StudentIcons,
  LoadingContainer,
  ButtonContainer
};
