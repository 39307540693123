import React, { useRef, useEffect } from 'react';
import { Form } from 'form';
import { useGet } from 'store';

import { Modal, Fields } from '_src/components';
import services from '_src/services';
import { Space } from '_src/shared/styles';
import { HeaderModal, ModalTitle, Amazon, FieldsContainer } from '../Reports/styles';

export default ({ student, onCloseClick, onOkClick, busy = false }) => {
  const config = services['reports'];
  const formApi = useRef(null);
  const { data: studentInfo } = useGet({
    ...config.getStudent,
    replace: { id: student.id }
  });

  if (!studentInfo.id) {
    return null;
  }

  return (
    <>
      <Modal
        confirmLoading={busy}
        width={'100%'}
        visible
        okText="Continue"
        title={
          <>
            <div style={{ textAlign: 'center' }}>
              <div>Please show this to the student</div>
            </div>

            <HeaderModal key="header">
              <ModalTitle key="title">{student ? student.name : ''}</ModalTitle>
              {student.amazonEmployee && <Amazon src={amazonLogo} />}
            </HeaderModal>
          </>
        }
        onCancel={onCloseClick}
        onOk={() => {
          const isFormValid = formApi.current.validate();
          if (!isFormValid) {
            return;
          }

          const values = formApi.current.getTouchedValues();
          onOkClick && onOkClick({ id: student.studentId, ...values });
        }}
      >
        <FieldsContainer columns={2}>
          <Form
            formApi={api => (formApi.current = api)}
            initialValues={studentInfo}
            schema={config.modal.schema.graduationSchema}
          >
            <Fields fields={config.modal.fields} />
          </Form>
        </FieldsContainer>
        <Space vertical={30} />
      </Modal>
    </>
  );
};
