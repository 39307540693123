const transformAll = ({ success, data }) => {
  if (success === 0) {
    return [];
  }

  return data.testingQuestions.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
};

const transformBeforeUpdate = ({ question, sectionType, ordering, branchIds }) => {
  return { question, sectionType, ordering, branchIds };
};

const transformCreate = obj => {
  return { ...obj, type: 4, ordering: 1, sectionType: -1 };
};

export { transformAll, transformBeforeUpdate, transformCreate };
