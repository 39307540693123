import React from 'react';
import moment from 'moment';

import {
  needsImprovementPerformance,
  proficientPerformance,
  unsuccessfulPerformance,
  satisfactoryPerformance
} from '_src/assets/images';
import { Container, Title, Img, Date } from './styles';

const colors = GLOBALS.colors;

const performanceConfig = {
  UNSUCCESFUL: {
    title: 'Unsuccessful',
    imgSrc: unsuccessfulPerformance,
    color: colors.RED
  },
  'NEEDS IMPROVEMENT': {
    title: 'Needs Improvement',
    imgSrc: needsImprovementPerformance,
    color: colors.ORANGE
  },
  SATISFACTORY: {
    title: 'Satisfactory',
    imgSrc: satisfactoryPerformance,
    color: colors.YELLOW
  },
  PROFICIENT: {
    title: 'Proficient',
    imgSrc: proficientPerformance,
    color: colors.GREEN
  },
  EMPTY: {
    title: 'No Info',
    color: colors.GREY,
    imgSrc: needsImprovementPerformance
  }
};

const Performance = ({ type, iconOnly = false, createdAt }) => {
  if (!performanceConfig[type]) {
    return null;
  }

  const { title, imgSrc, color } = performanceConfig[type];

  return (
    <Container iconOnly={iconOnly}>
      {!iconOnly && <Title color={color}>{title}</Title>}
      <Img iconOnly={iconOnly} src={imgSrc} />
      {type === 'PROFICIENT' && <Date color={color}>{moment(createdAt).format('MM/DD/YYYY')}</Date>}
    </Container>
  );
};

export default Performance;
