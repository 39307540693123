import React from 'react';

export default [
  {
    title: 'Student',
    dataIndex: 'studentName',
    render: studentName => (
      <div style={{ cursor: 'pointer', textDecoration: 'underline' }}>{studentName}</div>
    )
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor'
  },
  {
    title: 'Location',
    dataIndex: 'branchName'
  }
];
