import moment from 'moment';

export default [
  {
    title: 'Name',
    dataIndex: 'studentFullName',
    sorter: true,
    render: (_, { studentFullName }) => studentFullName
  },
  {
    title: 'Primary Pay Type',
    dataIndex: 'sponsor'
  },
  {
    title: 'DL#',
    dataIndex: 'driversLicenseNumber'
  },
  {
    title: 'Class A or B',
    dataIndex: 'cdlClass'
  }
];
