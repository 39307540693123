export default [
  { label: 'Total Students', indexLabel: 'totalStudents', firstCard: true },
  { label: 'Total Hours Classroom / Yard', indexLabel: 'totalHrs' },
  { label: 'Avg Hours', indexLabel: 'avgHrs' },
  { label: 'No of students in classroom', indexLabel: 'classroomStudents' },
  { label: 'No of students in yard', indexLabel: 'yardStudents' },
  { label: 'Current / Graduates', indexLabel: 'studentStatus', lastCard: true },
  { label: 'No. of Pre-trips', indexLabel: 'pretripsNo', firstCard: true },
  { label: 'Pre-trip Score', indexLabel: 'pretripsScore' },
  { label: 'No. of Skills', indexLabel: 'skillsNo' },
  { label: 'Skills Score', indexLabel: 'skillsScore' },
  { label: 'No. of Road', indexLabel: 'roadsNo' },
  { label: 'Road Score', indexLabel: 'roadsScore', lastCard: true }
];
