import React from 'react';
import { LabeledFieldStyle, Label, Field } from './styles';

const LabeledField = ({ label, field }) => (
  <LabeledFieldStyle>
    <Label>{`${label}:`}</Label>
    <Field>{field}</Field>
  </LabeledFieldStyle>
);

export default LabeledField;
