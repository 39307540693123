import qs from 'qs';
import moment from 'moment';

import table from './table';
import schema from './schema';
import fields from './fields';
import {
  transformAll,
  transformStudentAttendance,
  transformBeforeStudentUpdate,
  transformTestingQuestions,
  transformProfile,
  transformPerformance,
  transformMiles,
  transformSponsors,
  transformLocations,
  transformStatistics,
  transformDuration,
  transformNotes,
  transformComment,
  transformStudentNotes,
  transformStudentSchedule,
  transformStudentById
} from './transform';

const name = 'reports';
const path = 'reports/attendance';

export default {
  restrict: false,
  title: 'Reports',
  table,
  location: {
    placeholder: 'Location',
    component: 'select',
    async: true,
    options: [{ id: 'all', label: 'All' }],
    get: {
      path: 'branches',
      transform: transformLocations,
      defaultValue: [],
      memoize: false
    }
  },
  sponsors: {
    component: 'select',
    async: true,
    get: {
      name: 'sponsors',
      path: 'students/sponsors',
      transform: transformSponsors,
      defaultValue: [],
      memoize: false
    },
    placeholder: 'Select a sponsor',
    options: [{ id: 'all', label: 'All' }]
  },
  getStudentReport: {
    method: 'get',
    name: 'student',
    path: 'reports/attendance',
    defaultValue: [],
    transform: result => result.data
  },
  confirmGraduationModalSchema: schema['confirmGraduationSchema'],
  saveConfirmGraduation: {
    method: 'patch',
    name: 'saveConfirmGraduation',
    path: 'students/graduation/:id'
  },
  statistics: {
    name: 'reportStatistics',
    path: `${path}/statistics`,
    transform: transformStatistics,
    defaultValue: {}
  },
  get: {
    name,
    path: path,
    transform: transformAll,
    defaultValue: [],
    paramsSerializer: params => qs.stringify({ ...params }, { arrayFormat: 'comma' })
  },
  getAttendance: {
    name: 'studentAttendance',
    path: 'attendance',
    transform: transformStudentAttendance,
    defaultValue: []
  },
  getTokenQr: {
    name: 'student',
    path: 'students/:id/qr_code'
  },
  getStudentSchedule: {
    name: 'student_schedule',
    path: 'students/:id',
    defaultValue: {},
    transform: transformStudentSchedule
  },
  edit: {
    method: 'patch',
    path: `students/:id`,
    transform: transformBeforeStudentUpdate
  },
  getTestingQuestions: {
    name: 'testingQuestions',
    path: 'testing/questions',
    defaultValue: undefined,
    transform: transformTestingQuestions
  },
  getStudent: {
    name: 'student_profile',
    path: 'students/:id',
    defaultValue: {},
    transform: transformStudentById
  },
  getPerformance: {
    name: 'student_performance',
    path: 'testing/performance/student_card/:id',
    defaultValue: [],
    transform: transformPerformance
  },
  getMiles: {
    name: 'miles',
    path: 'student_evaluations',
    transform: transformMiles,
    defaultValue: {}
  },
  modal: {
    title: 'Save Student',
    fields,
    schema,
    props: {
      width: '60%'
    },
    noColumns: 2
  },
  download: {
    name: 'downloadReports',
    path: 'reports/download'
  },
  print: {
    name: 'printReports',
    path: 'reports/print'
  },
  dowloadDaily: {
    path: 'reports/download/daily'
  },
  getLogs: {
    path: ['/logs', 'users?group_id=4'],
    transform: ([logs, instructors]) => {
      instructors = instructors.data.users.reduce(
        (acc, { id, firstName, lastName }) => ({ ...acc, [id]: `${firstName} ${lastName}` }),
        {}
      );

      logs = logs.data.logs.map(({ _id, timestamp, action, userId, response }) => ({
        id: _id,
        date: moment.utc(timestamp).local().format('MM/DD/YYYY'),
        time: moment.utc(timestamp).local().format('HH:mm'),
        action: action,
        instructor: instructors[userId],
        value: response.statusCode,
        notes: response.message
      }));

      return logs;
    }
  },
  getAttendanceTable: {
    path: ['attendance', 'users?group_id=4'],
    transform: ([attendance, instructors]) => {
      attendance = attendance.data.attendance
        .map(
          ({
            _id,
            attendanceDate,
            archivedDate,
            checkIn,
            checkOut,
            checkinNotes,
            checkoutNotes,
            notes
          }) => ({
            id: _id,
            archivedDate: archivedDate
              ? moment.utc(archivedDate).local().format('MM/DD/YYYY HH:mm')
              : null,
            attendanceDate: moment.utc(attendanceDate).local().format('MM/DD/YYYY'),
            checkInTime: checkIn ? moment.parseZone(checkIn).format('hh:mm a') : 'Absent',
            checkOutTime: checkOut
              ? moment.parseZone(checkOut).format('hh:mm a')
              : checkIn
              ? 'Unclosed session'
              : 'Absent',
            duration: transformDuration({ checkIn, checkOut }),
            notes: transformNotes({ checkinNotes, checkoutNotes, notes })
          })
        )
        .sort((a, b) => {
          const aAttendanceDate = moment(a.attendanceDate);
          const bAttendanceDate = moment(b.attendanceDate);
          return aAttendanceDate.isBefore(bAttendanceDate) ? 1 : -1;
        });

      return attendance;
    }
  },
  comment: {
    name: 'getComment',
    path: ['testing/comments'],
    transform: transformComment
  },
  getStudentNotes: {
    name: 'studentNotes',
    path: 'student_notes?student_id=:id',
    defaultValue: [],
    transform: transformStudentNotes
  },
  createStudentNotes: {
    method: 'post',
    path: `student_notes`
  }
};
