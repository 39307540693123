import React from 'react';
import moment from 'moment';
import { PersistentRepo } from '_src/shared/repo';

const Location = ({ branchId }) => {
  const locations = PersistentRepo.get('locations');

  const location = (locations || []).find(loc => branchId === loc.branchId);

  return location ? location.label : '';
};

export default [
  {
    title: 'Full name',
    dataIndex: 'firstName',
    render: (_, { lastName, firstName }) => `${firstName} ${lastName}`,
    className: 'textCapitalize'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    className: 'textCapitalize'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'BranchName',
    dataIndex: 'branchId',
    render: (_, { branchId }) => <Location branchId={branchId} />
  },
  {
    title: 'Course Name',
    dataIndex: 'courseName',
    className: 'textCapitalize'
  },
  {
    title: 'Hazmat Registered Date',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: true,
    // sorter: (a, b) => {
    //   let dateA = new Date(a.createdAt);
    //   let dateB = new Date(b.createdAt);
    //   if (!a.date && b.date) return 1;
    //   else if (a.date && !b.date) return -1;
    //   else if (dateA === dateB) return 0;
    //   else return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
    // },
    render: (_, { createdAt }) =>
      createdAt ? moment(createdAt).parseZone().format('MM/DD/YYYY') : ''
  },
  {
    title: 'Hazmat Start Date',
    dataIndex: 'hazmatStartDate',
    defaultSortOrder: 'hazmatStartDate',
    sorter: true,
    // sorter: (a, b) => {
    //   let dateA = new Date(a.hazmatStartDate);
    //   let dateB = new Date(b.hazmatStartDate);
    //   if (!a.date && b.date) return 1;
    //   else if (a.date && !b.date) return -1;
    //   else if (dateA === dateB) return 0;
    //   else return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
    // },
    render: (_, { hazmatStartDate }) =>
      hazmatStartDate ? moment(hazmatStartDate).parseZone().format('MM/DD/YYYY') : ''
  }
];
