import { string, object } from 'yup';

const schema = object({
  branchId: string().nullable(),
  student: string().required('Student is required'),
  testingCategory: string().required('Testing Category is required'),
  testingDate: string().required('Testing Date is required'),
  results: string().required('Result is required'),
  failedReason: string().nullable()
});

const createSchema = schema.shape({
  branchId: string().required('Username is required'),
  student: string().required('Student is required'),
  testingCategory: string().required('Testing Category is required'),
  testingDate: string().required('Testing Date is required'),
  results: string().required('Result is required'),
  failedReason: string().nullable()
});

export default { create: createSchema, edit: schema };
