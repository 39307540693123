import React from 'react';
import moment from 'moment';

import LabeledField from '../LabeledField';
import { LabelsContainer } from './styles';

const StudentInfo = ({ studentInfo: studentInfoData }) => {
  return (
    <LabelsContainer>
      <LabeledField label="Email" field={studentInfoData?.email} />
      <LabeledField label="Gender" field={studentInfoData.gender} />
      <LabeledField label="Phone" field={studentInfoData.phone} />
      <LabeledField label="Status" field={studentInfoData.status} />
      <LabeledField
        label="Class Started"
        field={
          studentInfoData.dateClassBegins &&
          moment.utc(studentInfoData.dateClassBegins).format('MM/DD/YYYY')
        }
      />
      <LabeledField
        label="Permit Expiration"
        field={
          studentInfoData.permitExpiration &&
          moment.utc(studentInfoData.permitExpiration).format('MM/DD/YYYY')
        }
      />
      <LabeledField
        label="CDL End Date"
        field={studentInfoData.cdlDate && moment.utc(studentInfoData.cdlDate).format('MM/DD/YYYY')}
      />
      <LabeledField
        label="Testing Date"
        field={
          studentInfoData.testingDate
            ? moment.utc(studentInfoData.testingDate).format('MM/DD/YYYY')
            : '-'
        }
      />
    </LabelsContainer>
  );
};

export default StudentInfo;
