import React, { useState, useContext } from 'react';
import { Card, Button, Icon, notification } from 'antd';
import moment from 'moment';
import { Context } from 'store';
import download from 'downloadjs';

import services from '_src/services';
import { Refresh } from '_src/components';
import { objToSnakeCase } from '_src/utils';
import { PersistentRepo } from '_src/shared/repo';
import timezoneJson from '../../services/branches/timezones';
import { PageContainer, ActionRow } from '_src/shared/styles';
import { Select, RangePicker, Datepicker } from '_src/shared/form-helpers';

import { Row } from './styles';
import './styles.css';

const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };

const baseApi = GLOBALS.BASE_API;

export default () => {
  const now = moment();
  const resource = 'instructorReports';
  const token = PersistentRepo.get('token');
  const config = services[resource];

  const { getBusy, getData } = useContext(Context);
  const { branchId, groupName } = getData('user');
  const reportEntries = [
    { id: 0, label: 'Attendance entries' },
    { id: 1, label: 'Enrolled students with no attendance' },
    { id: 2, label: 'Rare evaluations' },
    { id: 3, label: 'Enrolled yard students with no evaluations' },
    { id: 4, label: 'Number of evaluations' },
    { id: 5, label: 'Hours breakdown' }
  ];
  const [reportId, setReportId] = useState(0);
  const [reportName, setReportName] = useState(reportEntries[0].label);
  const [sponsors, setSponsor] = useState(['all']);
  const [timezone, setTimezone] = useState('all');
  const [location, setLocation] = useState(groupName == 'Instructor' ? [branchId] : ['all']);
  const [dateRanges, setDateRanges] = useState({
    startDate: moment().subtract(7, 'd'),
    endDate: moment()
  });
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(moment().parseZone().format('YYYY-MM-DD'));
  const { startDate, endDate } = dateRanges;

  async function getRaport() {
    let params = {};
    if (reportId !== 5) {
      params = {
        startDate: dateRanges.startDate.format('YYYY-MM-DD'),
        endDate: dateRanges.endDate.format('YYYY-MM-DD')
      };
    }

    if (!location.includes('all') && location.length > 0) {
      params.branchIds = location;
    }

    if (!sponsors.includes('all') && sponsors.length > 0) {
      params.sponsors = sponsors;
    }

    if (timezone !== 'all') {
      params.timezone = timezone;
    }

    let path = '';
    if (reportId === 0) {
      path = config.downloadEntries.path;
      params.attendance = true;
    } else if (reportId === 1) {
      path = config.downloadEntries.path;
      params.attendance = false;
    } else if (reportId === 2) {
      path = config.downloadEvaluations.path;
      params.evaluation = true;
    } else if (reportId === 3) {
      path = config.downloadEvaluations.path;
      params.evaluation = false;
    } else if (reportId === 4) {
      path = config.numberOfEvaluations.path;
    } else if (reportId === 5) {
      path = config.studentHours.path;
      params.evaluation = true;
      params.date = date;
    }

    try {
      const resp = await fetch(`${baseApi}/${path}`, {
        method: 'POST',
        body: JSON.stringify(objToSnakeCase(params)),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const filename = resp.headers.get('x-suggested-filename');
      const blob = await resp.blob();

      download(blob, filename);
    } catch (error) {
      notification.error({
        message: 'Something went wrong!',
        duration: 3
      });
    }
  }

  const disabledOneMonth = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 0;
    const tooEarly = dates[0] && dates[0].diff(current, 'months') > 0;
    return tooLate || tooEarly;
  };

  const disabledTwoMonths = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 1;
    const tooEarly = dates[0] && dates[0].diff(current, 'months') > 1;
    return tooLate || tooEarly;
  };

  const onOpenChange = open => {
    if (open) {
      setDates([]);
    }
  };

  return (
    <PageContainer style={stylesTablet}>
      <Refresh />
      <Card title={config.title}>
        <Row>
          <Select
            label="Report name"
            value={reportId}
            options={reportEntries}
            onChange={type => {
              setReportId(type);
              setReportName(reportEntries[type].label);
            }}
          />

          <Select
            mode="multiple"
            label="Branches"
            onChange={loc => {
              const lastSelection = loc.length - 1;
              const all = loc.filter(l => l === 'all');
              let locationSelected = [...loc];

              if (all.length > 0) {
                if (loc[lastSelection] === 'all') {
                  locationSelected = all;
                } else {
                  locationSelected = loc.filter(l => l !== 'all');
                }
              }
              setLocation([...locationSelected]);
            }}
            {...config.location}
            value={location}
            disabled={groupName === 'Instructor'}
          />

          <Select
            label="Timezone"
            options={[{ id: 'all', label: 'All' }, ...timezoneJson]}
            onChange={t => setTimezone(t)}
            value={timezone}
          />

          <Select
            label="Sponsors"
            {...config.sponsors}
            value={sponsors}
            onChange={e => {
              const lastSelection = e.length - 1;
              const all = e.filter(s => s === 'all');
              let sponsorSelected = [...e];
              if (all.length > 0) {
                if (e[lastSelection] === 'all') {
                  sponsorSelected = all;
                } else {
                  sponsorSelected = e.filter(s => s !== 'all');
                }
              }
              setSponsor([...sponsorSelected]);
            }}
            mode="multiple"
          />

          {reportId !== 5 && (
            <RangePicker
              label="Date"
              allowClear={false}
              ranges={{
                Today: [now, now],
                'Last Week': [moment().subtract(7, 'd'), moment()],
                'Last Month': [moment().subtract(1, 'months'), moment()]
              }}
              defaultValue={[startDate, endDate]}
              onChange={dates => {
                const [startDate, endDate] = dates;

                setDateRanges({
                  startDate,
                  endDate
                });
              }}
              disabledDate={reportId == 0 || reportId == 2 ? disabledOneMonth : disabledTwoMonths}
              onOpenChange={onOpenChange}
              onCalendarChange={val => setDates(val)}
            />
          )}
          {reportId === 5 && (
            <Datepicker
              allowClear={false}
              label="Select a date"
              name="hbdDate"
              value={date}
              onChange={date => {
                setDate(date.parseZone().format('YYYY-MM-DD'));
              }}
            />
          )}
        </Row>

        <ActionRow>
          <Button
            loading={getBusy(resource)}
            onClick={getRaport}
            type="default"
            disabled={
              !location.filter(l => l !== 'all').length && !sponsors.filter(s => s !== 'all').length
            }
          >
            <Icon type="download" /> Download {reportName.toLowerCase()}
          </Button>
        </ActionRow>
      </Card>
    </PageContainer>
  );
};
