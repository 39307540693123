import React, { useState, useContext, useEffect } from 'react';
import { Context, useSave, useFetch } from 'store';

import { Refresh, Table } from '_src/components';
import { PageContainer } from '_src/shared/styles';
import { Card } from 'antd';
import { Row } from './styles';
import { Select } from '_src/shared/form-helpers';
import moment from 'moment';

import services from '_src/services';
import { PersistentRepo } from '_src/shared/repo';

const groups = [
  { id: 'Group 1', label: 'Group 1' },
  { id: 'Group 2', label: 'Group 2' },
  { id: 'Group 3', label: 'Group 3' },
  { id: 'Group 4', label: 'Group 4' },
  { id: 'Group 5', label: 'Group 5' },
  { id: 'Group 6', label: 'Group 6' },
  { id: 'Group 7', label: 'Group 7' },
  { id: 'Group 8', label: 'Group 8' },
  { id: 'Group 9', label: 'Group 9' },
  { id: 'Group 10', label: 'Group 10' },
  { id: 'Group 11', label: 'Group 11' },
  { id: 'Group 12', label: 'Group 12' }
];

const daysOptions = [
  { id: 'all', label: 'All' },
  { id: 'monday', label: 'Monday' },
  { id: 'tuesday', label: 'Tuesday' },
  { id: 'wednesday', label: 'Wednesday' },
  { id: 'thursday', label: 'Thursday' },
  { id: 'friday', label: 'Friday' },
  { id: 'saturday', label: 'Saturday' },
  { id: 'sunday', label: 'Sunday' },
  { id: 'night_class', label: 'Night class' },
  { id: 'weekend_class', label: 'Weekend class' }
];

let timezoneParam = moment().utcOffset() / 60;

if (timezoneParam > 0) {
  timezoneParam = 'GMT ' + timezoneParam;
} else if (timezoneParam < 0) {
  timezoneParam = 'GMT' + timezoneParam;
} else if (timezoneParam === 0) {
  timezoneParam = 'GMT 0';
}

export default () => {
  const config = services.studentGroups;
  const locationsConfig = services['studentRecords'];
  const instructorReportsConfig = services['instructorReports'];

  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const { getData } = useContext(Context);
  const { get: getAllStudents } = useFetch({ ...config.get });
  const { get: getLocations } = useFetch({ ...locationsConfig.getLocations });
  const { save: assignGroups, busy } = useSave({ ...config.assignGroups });

  const [location, setLocation] = useState(getInitialLocation());
  const [locations, setLocations] = useState([]);
  const [sponsors, setSponsor] = useState(['all']);
  const [days, setDays] = useState(['all']);
  const [assignedGroups, setAssignGroup] = useState({});
  const [studentGroups, setStudentGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  let initialStudentGroups = {};

  if (studentGroups) {
    studentGroups.map(
      ({ groups }) => (initialStudentGroups = { ...initialStudentGroups, ...groups })
    );
  }

  const getInitialId = type => {
    const [locationType, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  useEffect(() => {
    getLocations().then(data => {
      setLocations(data);
      PersistentRepo.set('locations', data);
    });
  }, []);

  const sponsorsFilter = sponsors.filter(sponsor => sponsor !== 'all');

  const [filterParams, setFilterParams] = useState({
    ...(sponsorsFilter.length && { sponsors: sponsorsFilter.join(',') }),
    locationId: location.split('x')[2],
    locationType:
      getInitialId('locationType') === 'all' ? 0 : getInitialId('locationType') === 'yard' ? 1 : 2,
    date: moment().format('DD-MM-YYYY'),
    timezone: timezoneParam
  });

  useEffect(() => {
    setLoading(true);
    getAllStudents({ params: filterParams })
      .then(students => {
        setStudentGroups(students);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: err.message,
          duration: 3
        });
      });
  }, [filterParams]);

  return (
    <PageContainer>
      <Refresh />
      <Card title={'Student Groups'}>
        <Row>
          <Select
            label="Filter by branch"
            onChange={locationSelected => {
              setLocation(locationSelected);
              if (locationSelected === 'all') {
                setFilterParams();
                PersistentRepo.set('allBranches', locationSelected);
              } else {
                const [locationType, locationId, branchId] = locationSelected.split('x');
                setFilterParams(() => {
                  return {
                    ...filterParams,
                    locationId: locationType === 'all' ? branchId : locationId,
                    locationType: locationSelected
                      ? locationType === 'all'
                        ? 0
                        : locationType === 'yard'
                        ? 1
                        : 2
                      : getInitialId('locationType')
                  };
                });
                PersistentRepo.set('location', locationSelected);
                PersistentRepo.delete('allBranches');
              }
            }}
            options={locations}
            showSearch
            value={location}
          />
          <Select
            label="Filter by Sponsor"
            {...instructorReportsConfig.sponsors}
            value={sponsors}
            onChange={e => {
              const lastSelection = e.length - 1;
              const all = e.filter(s => s === 'all');
              let sponsorSelected = [...e];

              if (all.length > 0) {
                if (e[lastSelection] === 'all') {
                  sponsorSelected = all;
                } else {
                  sponsorSelected = e.filter(s => s !== 'all');
                }
              }

              const sponsorsFilter = sponsorSelected.filter(sponsor => sponsor !== 'all');
              let newParams = filterParams;

              sponsorsFilter.length === 0
                ? delete newParams.sponsors
                : (newParams = { ...newParams, sponsors: sponsorsFilter.join(',') });

              setFilterParams({ ...newParams });
              setSponsor([...sponsorSelected]);
            }}
            mode="multiple"
          />
          <Select
            options={daysOptions}
            mode="multiple"
            label="Filter by Day"
            value={days}
            showArrow={false}
            placeholder="Select a day"
            onChange={e => {
              const lastSelection = e.length - 1;
              const all = e.filter(s => s === 'all');
              let daySelected = [...e];

              if (all.length > 0) {
                if (e[lastSelection] === 'all') {
                  daySelected = all;
                } else {
                  daySelected = e.filter(s => s !== 'all');
                }
              }

              let oldParams = filterParams;

              daysOptions.map(d => {
                delete oldParams[d.id];
              });

              const daysFilter = daySelected
                .filter(day => day !== 'all')
                .reduce((acc, curr) => ((acc[curr] = 1), acc), {});

              setDays([...daySelected]);
              setFilterParams({ ...oldParams, ...daysFilter });
            }}
          />
        </Row>

        <Table
          columns={[
            ...config.table,
            {
              id: 'submit',
              name: 'Groups',
              title: 'Groups',
              render: (_, student) => {
                return (
                  <Select
                    options={groups}
                    mode="multiple"
                    disabled={busy}
                    value={
                      assignedGroups[student.id]
                        ? assignedGroups[student.id]
                        : initialStudentGroups[student.id]
                    }
                    placeholder="Assign to a group"
                    onChange={group => {
                      const newGroup = assignedGroups.id ? [...assignedGroups.id, ...group] : group;
                      setAssignGroup({ ...assignedGroups, [student.id]: newGroup });
                      assignGroups({ id: student.id, groups: newGroup });
                    }}
                    style={{ width: '300px' }}
                  />
                );
              }
            }
          ]}
          data={studentGroups}
          loading={loading}
        />
      </Card>
    </PageContainer>
  );
};
