import schema from './schema';
import fields from './fields';

export default {
  form: { fields, schema },
  forgotPassword: {
    path: 'users/reset_password',
    method: 'post'
  }
};
