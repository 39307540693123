import React, { useContext } from 'react';
import { Context } from 'store';
import moment from 'moment';
import { formatDate } from '_src/utils';
const fmt = 'HH:mm';
const Location = ({ branchId, locationType }) => {
  const { getData } = useContext(Context);
  const locations = getData('locations') || [];
  const location = locations.find(loc => branchId === loc.branchId && locationType === loc.type);

  return location ? location.label : '';
};

export default [
  {
    title: 'Student',
    dataIndex: 'name',
    render: (_, item) => {
      return (
        <div style={{ color: item.status === 'Hold' && '#DE970B', fontWeight: 'bold' }}>
          {item.name}
        </div>
      );
    }
  }
];
