import { string, object } from 'yup';

const schema = object({
  question: string().required('Question is required')
});

export default {
  create: schema,
  edit: schema
};
