import React, { useContext, useState, useCallback } from 'react';
import { useGet, Context, useSave, useFetch } from 'store';
import { Button as AntButton, notification } from 'antd';
import moment from 'moment';

import { Modal, Table } from '_src/components';
import { Button } from '_src/ui';
import services from '_src/services';
import { isEmptyObj } from '_src/utils';

import MoreInfo from '../Evaluations/MoreInfo';
import { StudentPerformanceEvaluator } from '_src/modules/Attendance/components';
import ProfileModal from '../ProfileModal';
import ConfirmGraduation from '../../Reports/ConfirmGraduation';
import { Checkbox, Input, Select } from '_src/shared/form-helpers';

import {
  Container,
  ProfileContainer,
  ReportContainer,
  BubbleTimelineContainer,
  DailyInfoContainer,
  Label,
  VerticalSeparator,
  Field,
  AbsenceStatus,
  Row,
  CardBlock,
  Totals,
  LabelCard,
  ArchivedLabel,
  Column,
  DayDetail,
  StudentDetailContainer,
  StudentDetail,
  ModalTitle
} from './styles';

import {
  BubbleTimeline,
  NoAttendanceInfo,
  SessionSection,
  StudentInfo,
  PerformanceCardsContainer
} from './components';

import speedometer from '_src/assets/images/speedometer.png';

const buttonColor = GLOBALS.colors;

export default () => {
  const { setShown, getShown, getData, setData, refreshResource } = useContext(Context);

  const [showYardConfirmModal, setShowYardConfirmModal] = useState();
  const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState();
  const [showDoubleArchiveConfirmModal, setShowDoubleArchiveConfirmModal] = useState();
  const [selectedDateToArchive, setSelectedDateToArchive] = useState('all');
  const [selectedAttendance, selectAttendance] = useState({});
  const [moreInfoModalTitle, setMoreInfoModalTitle] = useState();
  const [moreInfoModalType, setMoreInfoModalType] = useState();
  const [trainHoursCompleted, setTrainHoursCompleted] = useState();
  const [isProficient, setIsProficient] = useState();
  const [testType, setTestType] = useState();
  const [isSubmitFmcsa, setIsSubmitFmcsa] = useState(false);
  const { sessions = [], absent } = selectedAttendance;

  const [hasStudentLessThan160Hrs, setHasStudentLessThan160Hrs] = useState(false);
  const [hasStudentFailedEveryTest, setHasStudentFailedEveryTest] = useState(false);
  const [studentNotReadyForGraduate, setStudentNotReadyForGraduate] = useState(false);

  const [totalHoursDriven, setTotalHoursDriven] = useState('');

  const resource = 'studentTimeline';
  const resourceMoreInfo = 'resourceMoreInfo';
  const config = services['reports'];
  const attendanceConfig = services['attendance'];

  const student = getData('studentTimeline');
  const { id, name, locationType, programHours, programType, permitRecDate, sponsor } = student;
  const permitRecDateBefore = moment(permitRecDate).parseZone().isSameOrBefore('2022-02-06', 'day');
  const { save: editStudent, busy: busyEditStudent } = useSave(attendanceConfig.editStudent);
  const { save: editArchiveStudent } = useSave(attendanceConfig.archiveStudent);
  const { save: submitFmcsaAction, busy: busySubmitFmcsa } = useSave(attendanceConfig.submitFmcsa);
  const [typeOfArchive, setTypeOfArchive] = useState(null);
  const [showSelectDateConfirmModal, setShowSelectDateConfirmModal] = useState(false);

  const { data: allAttendance } = useGet({
    ...config.getAttendance,
    params: { studentId: id, reportCard: false }
  });

  const { data: milesData } = useGet({
    ...config.getMiles,
    params: { studentId: id }
  });
  // const { data: logs, busy: busyLogs } = useGet({
  //   ...config.getLogs,
  //   params: { studentId: id }
  // });

  const { data: attendanceTable, busy: busyAttendanceTable } = useGet({
    ...config.getAttendanceTable,
    params: { studentId: id, reportCard: true }
  });
  const { data: studentPerformance } = useGet({
    ...config.getPerformance,
    replace: { id },
    defaultValue: {}
  });

  const { data: studentReport } = useGet({
    ...config.get,
    params: {
      fullName: name,
      startDate: moment().subtract(2, 'years').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      reportCard: true
    },
    defaultValue: false
  });

  const { get: getPerformance } = useFetch({ ...config.getPerformance });
  const { get: getStudentReport } = useFetch({ ...config.getStudentReport });

  const { get: getSchedule } = useFetch({ ...config.getStudentSchedule });
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });
  const [grade, setGrade] = useState(null);
  const [studentInfo, setStudentInfo] = useState({});
  const [errors, setError] = useState({});
  const [disabledYard, setDisabledYard] = useState(false);
  const [disabledFmcsa, setDisabledFmcsa] = useState(false);

  const attendance = allAttendance.filter(({ upcoming }) => !upcoming);

  const graduateStudent = () => {
    const requests = [
      getStudentReport({
        params: {
          studentId: id,
          date: moment().format('YYYY-MM-DD')
        }
      }),
      getPerformance({
        replace: {
          id: id
        }
      })
    ];

    Promise.all(requests).then(([studentResponse, performanceResponse]) => {
      const studentReport = studentResponse.students[0];
      const performances = Object.values(performanceResponse);
      setTrainHoursCompleted(student.totalHoursTrained > programHours);

      setIsProficient(
        !isEmptyObj(performances) &&
          !!performances.filter(item => item.currPerformance === 'PROFICIENT').length
      );

      let trainHoursCompletedVar = student.totalHoursTrained > programHours;
      let isProficientVar =
        !isEmptyObj(performances) &&
        !!performances.filter(item => item.currPerformance === 'PROFICIENT').length;

      if (!trainHoursCompletedVar) {
        if (student.totalHoursTrained < programHours && !isProficientVar) {
          setHasStudentLessThan160Hrs(false);
          setHasStudentFailedEveryTest(false);
          setStudentNotReadyForGraduate(true);
        } else {
          setHasStudentLessThan160Hrs(student.totalHoursTrained < programHours);
          setHasStudentFailedEveryTest(!isProficientVar);
          setStudentNotReadyForGraduate(false);
        }
        setShown('continueGraduation');
      } else {
        setHasStudentLessThan160Hrs(false);
        setHasStudentFailedEveryTest(false);
        setStudentNotReadyForGraduate(false);
        setShown('StudentProfileModal');
      }
    });
  };

  const downloadReport = () => {
    console.log(id);
  };

  const getStudentSchedule = showYardConfirmModal => {
    getSchedule({ replace: { id: id } }).then(res => {
      setDays(res.days);
      setGrade(res.grade);
      setStudentInfo(res.studentInfo);
      if (showYardConfirmModal) {
        setShowYardConfirmModal(true);
      }
    });
  };

  const renderTimeline = () => {
    if (attendance.length === 0) {
      return <NoAttendanceInfo />;
    }

    return (
      <BubbleTimeline
        attendance={allAttendance}
        onBubbleSelect={attendance => {
          selectAttendance(attendance);
        }}
      />
    );
  };

  const closeModal = () => {
    setShown(resource, false);
    setShown('adminStudentTimeline', false);
    setShown('fmcsaStudentTimeline', false);
  };

  const renderCancel = () => (
    <AntButton key="cancel" onClick={() => setShowYardConfirmModal(false)}>
      Cancel
    </AntButton>
  );

  const renderMoveToYardConfirmButton = () => (
    <AntButton
      key="move"
      type="primary"
      onClick={() => {
        if (!errors.grade) setShown('confirm', true);
      }}
    >
      Move
    </AntButton>
  );
  const renderArchiveCancel = () => (
    <AntButton key="cancel" onClick={() => setShowArchiveConfirmModal(false)}>
      Cancel
    </AntButton>
  );

  const renderArchiveConfirmButton = record => (
    <AntButton
      key="confirmArchive"
      type="primary"
      onClick={() => setShowSelectDateConfirmModal(true)}
    >
      Archive
    </AntButton>
  );

  const renderDoubleArchiveCancel = () => (
    <AntButton
      key="cancel"
      onClick={() => {
        showDoubleArchiveConfirmModal
          ? setShowDoubleArchiveConfirmModal(false)
          : setShowSelectDateConfirmModal(false);
      }}
    >
      Cancel
    </AntButton>
  );

  const renderDoubleArchiveConfirmButton = record => (
    <AntButton
      key="confirmArchive"
      type="primary"
      onClick={() => {
        selectedDateToArchive === 'all' &&
        (!showDoubleArchiveConfirmModal || showDoubleArchiveConfirmModal === undefined)
          ? setShowDoubleArchiveConfirmModal(true)
          : archiveStudent(record.id);
      }}
    >
      Archive
    </AntButton>
  );

  const renderClassroomScore = () =>
    !permitRecDateBefore && (
      <Input
        name="grade"
        label="Classroom grade"
        placeholder="Classroom grade"
        value={grade}
        onChange={grade => {
          const numberGrade = Number(grade);
          grade ? setGrade(numberGrade) : setGrade(null);
          setError({
            grade:
              !grade && (numberGrade < 80 || numberGrade > 100)
                ? 'Grade must be between 80 and 100'
                : null
          });
        }}
        error={errors.grade ? errors.grade : ''}
        touched
        type="number"
      />
    );

  const moveToYard = ({ id: studentId, name, attendance }) => {
    let payload = {
      id: studentId,
      locationType: 1,
      ...days,
      grade: grade ? grade : 0
    };
    if (attendance.checkIn && !attendance.checkOut) {
      notification.error({
        message: 'Failed to move student to yard',
        description: 'You have to checkout the student before being able to move them to yard',
        duration: 5
      });
    } else if (!errors.grade) {
      editStudent(payload).then(res => {
        if (res.success === 1) {
          setShowYardConfirmModal(false);
          setShown('confirm', false);
          setDisabledYard(true);
          notification.success({
            message: `Successfully moved ${name} to yard`,
            duration: 5
          });
          refreshResource('reports');
        }
        if (res.success === 0) {
          notification.error({
            message: 'Failed to move student to yard',
            description: res.data.message,
            duration: 5
          });
        }
      });
    }
  };

  const submitFmcsa = ({ id: studentId, name, classOnly }) => {
    const submit = () => {
      submitFmcsaAction({ studentId: student.id }).then(res => {
        if (res.success === 1) {
          setDisabledFmcsa(true);
          refreshResource('attendance');
          setShown('confirm', false);
          setShowYardConfirmModal(false);
          notification.success({
            message: `Successfully submitted ${name} to FMCSA`,
            duration: 5
          });
        }
        if (res.success === 0) {
          notification.error({
            message: res.data.message,
            duration: 5
          });
        }
      });
    };

    if (classOnly) {
      editStudent({ id: studentId, grade: grade }).then(() => {
        submit();
      });
    } else {
      submit();
    }
  };

  const formatScore = performance => {
    if (performance) {
      performance = performance.lastestResult;
      performance = performance.substr(0, performance.length - 1);
      performance = Math.round(performance) + '%';

      return performance;
    }

    return '-';
  };

  const getTotalHours = () => {
    if (studentReport) {
      return studentReport.data[0].totalHoursTrained;
    }

    return 0;
  };

  const getAbsences = () => {
    if (studentReport) {
      return studentReport.data[0].absences;
    }

    return 0;
  };

  const loadedProfileCallback = useCallback(
    (loadedProfile, totalHoursDriven, studentEvaluations) => {
      setTotalHoursDriven(totalHoursDriven);
    },
    []
  );

  const archiveStudent = studentId => {
    editArchiveStudent({
      id: studentId,
      archived: true,
      type: typeOfArchive || '',
      all: selectedDateToArchive === 'all',

      ...(selectedDateToArchive !== 'all' && {
        date: moment(selectedDateToArchive).format('MM/DD/YYYY')
      })
    }).then(res => {
      if (res.success === 1) {
        closeModal();
        setShowDoubleArchiveConfirmModal(false);
        setShowArchiveConfirmModal(false);
        notification.success({
          message: `Successfully archived student`,
          duration: 5
        });
      }
    });
  };

  const renderFmcsaButton = () => {
    // const {
    //   classSubmittedToFmsca,
    //   yardSubmittedToFmsca,
    //   programType,
    //   hazmatSubmittedToFmsca,
    //   hazmatStartDate
    // } = student;
    // const isBefore = permitRecDateBefore || programType === 'Refresher';
    // const showButton =
    //   ((locationType === 2 && !classSubmittedToFmsca && programType === 'Permit Only') ||
    //     (locationType === 1 && !yardSubmittedToFmsca) ||
    //     (locationType === 2 && !hazmatSubmittedToFmsca && hazmatStartDate)) &&
    //   !isBefore;

    const {
      yardSubmittedToFmsca,
      yardStartDate,
      yardOnly,
      classOnly,
      classSubmittedToFmsca,
      programType
    } = student;

    if (
      (!yardSubmittedToFmsca && !!yardStartDate && (yardOnly || yardOnly === undefined)) ||
      (programType === 'Permit Only' && classOnly && !classSubmittedToFmsca) ||
      (programType === 'VPC Only' && classOnly && !classSubmittedToFmsca) ||
      (!classOnly && !yardOnly)
    ) {
      return (
        <AntButton
          key="submitFMCSA"
          type="primary"
          disabled={disabledFmcsa}
          onClick={() => {
            if (student.classOnly) {
              getStudentSchedule(true);
              setIsSubmitFmcsa(true);
            } else {
              getStudentSchedule(false);
              setShown('confirm', true);
            }
          }}
        >
          Submit FMCSA
        </AntButton>
      );
    }
  };

  const renderGraduateButton = () => {
    // const { yardSubmittedToFmsca, status } = student;
    // let showButton = false;
    // const isBefore = permitRecDateBefore || programType === 'Refresher';
    // if (isBefore) {
    //   showButton = (locationType === 2 && programType === 'Permit Only') || locationType === 1;
    // } else {
    //   showButton = yardSubmittedToFmsca;
    // }

    const { status, classSubmittedToFmsca, yardSubmittedToFmsca } = student;

    // if (showButton && status !== 'Graduated' && !isHazmat) {
    if (status === 'Passed CDL Exam' || (classSubmittedToFmsca && yardSubmittedToFmsca)) {
      return (
        <AntButton key="submitAccept" type="primary" onClick={() => graduateStudent()}>
          Graduate
        </AntButton>
      );
    }
  };

  const allAttendanceDays = [
    { id: 'all', label: 'All' },
    ...allAttendance
      .filter(item => item.sessions)
      .map(filteredDays => {
        return { id: filteredDays.date, label: filteredDays.date };
      })
  ];

  return (
    <Modal
      width={'100%'}
      closable={false}
      bodyStyle={{ padding: '5px 5px 0px 5px' }}
      visible
      footer={[
        attendance.length !== 0 && (
          <AntButton
            key="submitArchive"
            type="primary"
            onClick={() => setShowArchiveConfirmModal(true)}
          >
            Archive
          </AntButton>
        ),
        // <AntButton key="downloadReport" type="primary" onClick={() => downloadReport()}>
        //   Save
        // </AntButton>,
        attendance.length !== 0 && renderGraduateButton(),
        attendance.length !== 0 && locationType === 2 && programType !== 'Permit Only' && (
          <AntButton key="submitYard" type="primary" onClick={() => getStudentSchedule(true)}>
            Move to yard
          </AntButton>
        ),
        attendance.length !== 0 && renderFmcsaButton(),
        <AntButton key="close" type="ghost" onClick={() => closeModal()}>
          Close
        </AntButton>
      ]}
    >
      <Container>
        <ProfileContainer>
          <Label fontSize={32}>
            <div>
              {name} {sponsor === 'Self-pays' && '(SP)'}
            </div>

            <Field>
              <img src={speedometer} />
              <div>{(milesData.total || 0).toFixed(2)} Miles Driven</div>
              <div>{totalHoursDriven || 0} Hours driven</div>
            </Field>
          </Label>

          <Button
            color={getTotalHours() > 160 ? buttonColor.RED : buttonColor.GREEN}
            width={86}
            height={35}
            margin={'4px 0 0 0'}
            text={`${getTotalHours()}/${programHours ? programHours : 160}`}
          />
          <StudentInfo id={id} parentCallback={loadedProfileCallback} />
        </ProfileContainer>

        <ReportContainer>
          <BubbleTimelineContainer>{renderTimeline()}</BubbleTimelineContainer>

          <Row>
            <CardBlock borderLeft={true} borderRight={false} colSpan={20}>
              <LabelCard>Total Hours</LabelCard>
              <Totals>{getTotalHours() || 0}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Miles driven</LabelCard>
              <Totals>{(milesData.total || 0).toFixed(2)}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Absences</LabelCard>
              <Totals>{getAbsences() || 0}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Pre trip score</LabelCard>
              <Totals>{formatScore(studentPerformance[1])}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Skills score</LabelCard>
              <Totals>{formatScore(studentPerformance[3])}</Totals>
            </CardBlock>

            <CardBlock colSpan={20}>
              <LabelCard>Road score</LabelCard>
              <Totals>{formatScore(studentPerformance[2])}</Totals>
            </CardBlock>

            <CardBlock borderLeft={false} borderRight={true} colSpan={20}>
              <LabelCard>Character score</LabelCard>
              <Totals>{formatScore(studentPerformance[4])}</Totals>
            </CardBlock>
          </Row>

          <div style={{ marginTop: 30 }}>
            <Table
              columns={[
                {
                  title: 'Attendance date',
                  dataIndex: 'attendanceDate',
                  key: 'attendanceDate'
                },
                {
                  title: 'Checkin',
                  dataIndex: 'checkInTime',
                  key: 'checkin'
                },
                {
                  title: 'Checkout',
                  dataIndex: 'checkOutTime',
                  key: 'checkout'
                },
                {
                  title: 'Duration',
                  dataIndex: 'duration',
                  key: 'duration'
                },
                {
                  title: 'Notes',
                  dataIndex: 'notes',
                  key: 'notes'
                },
                {
                  title: 'Archived',
                  key: 'archived',
                  render: (_, student) => {
                    return !!student.archivedDate ? 'Yes' : '';
                  }
                }
              ]}
              data={attendanceTable}
              loading={busyAttendanceTable}
            />
          </div>
        </ReportContainer>
      </Container>

      {getShown('StudentProfileModal') && (
        <ProfileModal
          busy={busyEditStudent}
          student={student}
          onCloseClick={() => {
            setShown('StudentProfileModal', false);
          }}
          onOkClick={newStudentValues => {
            editStudent(newStudentValues).then(({ success }) => {
              if (success === 1) {
                setShown('confirmGraduation');
              }
            });
          }}
        />
      )}

      {getShown('confirmGraduation') && (
        <ConfirmGraduation studentId={student.id} resourceFrom="studentTimeline" />
      )}

      {getShown('continueGraduation') && (
        <Modal
          visible
          closeModal={() => setShown('continueGraduation', false)}
          title="Graduating the student"
          okText="Continue"
          footer={[
            <AntButton
              key="close"
              type="ghost"
              onClick={() => {
                setShown('continueGraduation', false);
              }}
            >
              Close
            </AntButton>,
            <AntButton
              key="continue"
              type="danger"
              onClick={() => {
                setShown('continueGraduation', false);
                setShown('StudentProfileModal', true);
                setData('studentTimeline', {
                  name: `${student.name}`,
                  ...student
                });
              }}
            >
              Continue
            </AntButton>
          ]}
        >
          {hasStudentLessThan160Hrs && <div> The student has less than {programHours} hours.</div>}
          {hasStudentFailedEveryTest && <div>The student has not passed any tests.</div>}
          {studentNotReadyForGraduate && (
            <div> The student has less than {programHours} hours and has not passed any tests.</div>
          )}
          <div>Do you still want to graduate the student?</div>
        </Modal>
      )}

      {getShown(resourceMoreInfo) && (
        <MoreInfo
          studentId={id}
          title={moreInfoModalTitle}
          type={moreInfoModalType}
          onCloseClick={() => {
            setShown(resourceMoreInfo, false);
          }}
        />
      )}

      {getShown('testingTimeline') && (
        <StudentPerformanceEvaluator
          resource="testingTimeline"
          type={testType}
          student={{ name, id, locationType }}
        />
      )}

      <Modal
        className="moveToYard"
        title={isSubmitFmcsa ? 'Classroom Grade' : 'Moving student to yard'}
        visible={showYardConfirmModal}
        onCancel={() => setShowYardConfirmModal(false)}
        footer={[renderCancel(), renderMoveToYardConfirmButton()]}
      >
        {!isSubmitFmcsa && (
          <>
            <div style={{ marginBottom: 15 }}>
              Will the student’s schedule on the yard stay as: Monday to Friday? If not, please
              select the yard training schedule
            </div>
            <span style={{ display: 'flex', columnGap: 15, marginBottom: 20 }}>
              <Column>
                <DayDetail>
                  <Checkbox
                    label="Monday"
                    checked={days.monday}
                    onChange={value =>
                      setDays({
                        ...days,
                        monday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Tuesday"
                    checked={days.tuesday}
                    onChange={value =>
                      setDays({
                        ...days,
                        tuesday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Wednesday"
                    checked={days.wednesday}
                    onChange={value =>
                      setDays({
                        ...days,
                        wednesday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Thursday"
                    checked={days.thursday}
                    onChange={value =>
                      setDays({
                        ...days,
                        thursday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Friday"
                    checked={days.friday}
                    onChange={value =>
                      setDays({
                        ...days,
                        friday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Saturday"
                    checked={days.saturday}
                    onChange={value =>
                      setDays({
                        ...days,
                        saturday: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Sunday"
                    checked={days.sunday}
                    onChange={value =>
                      setDays({
                        ...days,
                        sunday: value
                      })
                    }
                  />
                </DayDetail>
              </Column>
              <Column>
                <DayDetail>
                  <Checkbox
                    label="Night class"
                    checked={days.nightClass}
                    onChange={value =>
                      setDays({
                        ...days,
                        nightClass: value
                      })
                    }
                  />
                </DayDetail>
                <DayDetail>
                  <Checkbox
                    label="Weekend class"
                    checked={days.weekendClass}
                    onChange={value =>
                      setDays({
                        ...days,
                        weekendClass: value
                      })
                    }
                  />
                </DayDetail>
              </Column>
            </span>
          </>
        )}

        {renderClassroomScore()}
      </Modal>

      <Modal
        className="moveToYard"
        title="Archiving the student data"
        visible={showArchiveConfirmModal}
        onCancel={() => setShowArchiveConfirmModal(false)}
        footer={[renderArchiveCancel(), renderArchiveConfirmButton(student)]}
      >
        Are you sure you want to archive this student?
      </Modal>

      {
        <Modal
          width={'50%'}
          visible={getShown('confirm')}
          title={<ModalTitle key="title">{studentInfo.name}</ModalTitle>}
          footer={[
            <AntButton key="submitClose" type="default" onClick={() => setShown('confirm', false)}>
              Cancel
            </AntButton>,
            <AntButton
              key="submitEdit"
              type="primary"
              loading={busyEditStudent}
              onClick={() => (locationType === 2 ? moveToYard(student) : submitFmcsa(student))}
              disabled={busyEditStudent}
            >
              Submit
            </AntButton>
          ]}
        >
          <span style={{ display: 'flex', marginBottom: 20 }}>
            <StudentDetailContainer>
              <StudentDetail>
                Permit Rec. Date <span>{studentInfo.permitRecDate}</span>
              </StudentDetail>
              <StudentDetail>
                License State <span>{studentInfo.licenseState}</span>
              </StudentDetail>
              <StudentDetail>
                Location <span>{studentInfo.locationType}</span>
              </StudentDetail>
              <StudentDetail>
                Completion Date <span>{moment().format('MM/DD/YYYY')}</span>
              </StudentDetail>
              {studentInfo?.locationType === 'Yard' && (
                <StudentDetail fontWeight={700} color={'#ef5350'}>
                  Skill <span>{studentInfo.totalSkills}</span>
                </StudentDetail>
              )}
            </StudentDetailContainer>
            <StudentDetailContainer>
              <StudentDetail>
                Birthdate <span>{studentInfo.birthdate}</span>
              </StudentDetail>
              <StudentDetail>
                License <span>{studentInfo.license}</span>
              </StudentDetail>
              <StudentDetail>
                Endorsement Code <span>{studentInfo.cdlClass}</span>
              </StudentDetail>
              {studentInfo?.locationType === 'Classroom' && (
                <StudentDetail
                  fontWeight={700}
                  color={studentInfo?.locationType === 'Classroom' ? '#ef5350' : ''}
                >
                  Score <span>{grade}</span>
                </StudentDetail>
              )}
              {studentInfo?.locationType === 'Yard' && (
                <StudentDetail fontWeight={700} color={'#ef5350'}>
                  Road <span>{studentInfo.totalRoad}</span>
                </StudentDetail>
              )}
            </StudentDetailContainer>
          </span>
          <span style={{ fontSize: 13, color: '#ef5350' }}>
            *Please look closely at the above fields! If any are incorrect, please adjust in SF,
            refresh the LMS until the changes are reflected (20 min), and “move to yard” again.
            {studentInfo?.locationType === 'Classroom' && (
              <span> Scores must be at 80% or above.</span>
            )}
            {studentInfo?.locationType === 'Yard' && (
              <span>
                {' '}
                Skill and Road hours come from the "Hours Break Down", and must be adequate.
              </span>
            )}
          </span>
        </Modal>
      }

      <Modal
        className="moveToYard"
        title="Are you sure you want to archive this student's attendance and evaluations?"
        visible={showDoubleArchiveConfirmModal}
        onCancel={() => setShowDoubleArchiveConfirmModal(false)}
        footer={[renderDoubleArchiveCancel(), renderDoubleArchiveConfirmButton(student)]}
      >
        Student information cannot be retrieved after archiving. Are you sure you want to archive
        this student's attendance and evaluations?
      </Modal>

      <Modal
        className="moveToYard"
        title="Please select dates to archive student data"
        visible={showSelectDateConfirmModal}
        onCancel={() => setShowDoubleArchiveConfirmModal(false)}
        footer={[renderDoubleArchiveCancel(), renderDoubleArchiveConfirmButton(student)]}
      >
        <Select
          label="Select date"
          showSearch
          onChange={value => setSelectedDateToArchive(value)}
          value={selectedDateToArchive}
          options={allAttendanceDays}
          style={{ width: '250px', marginBottom: '20px' }}
        />

        <Select
          disabled={selectedDateToArchive === 'all'}
          label="Select type"
          placeholder="Type"
          options={[
            { id: 'sessions', label: 'Session' },
            { id: 'test', label: 'Tests' }
          ]}
          value={typeOfArchive || undefined}
          onChange={value => setTypeOfArchive(value)}
          style={{ width: '250px' }}
        />
      </Modal>
    </Modal>
  );
};
