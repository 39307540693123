import React from 'react';
import { useGet } from 'store';
import { Card } from 'antd';

import { Table } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { stylesTablet } from './styles';

const resource = 'documents';
const config = services[resource];

export default () => {
  const { data, busy: loadingGet } = useGet({
    ...config.getDocuments
  });

  return (
    <PageContainer style={stylesTablet}>
      <Card title="AFP Documents">
        <Table columns={config.table} data={data} loading={loadingGet} />
      </Card>
    </PageContainer>
  );
};
