// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoursCounter .ant-input-affix-wrapper .ant-input-prefix  {
    left: 0;
}

.hoursCounter .ant-input-affix-wrapper .ant-input-suffix {
    right: 0;
}

.hoursCounter .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    cursor: pointer;
}

.hoursCounter .ant-input-affix-wrapper:hover, .ant-input:not(:last-child):hover {
    box-shadow: none;
}

.hoursCounter .ant-input:focus, .ant-input:hover {
    border-color: #d9d9d9;
    box-shadow: none;
}

/* Remove input number browser arrows */
.hoursCounter .ant-input::-webkit-outer-spin-button,
.hoursCounter .ant-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.hoursCounter .ant-input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/modules/Attendance/components/CheckoutModal/styles.css"],"names":[],"mappings":"AAAA;IACI,OAAO;AACX;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA,uCAAuC;AACvC;;EAEE,wBAAwB;EACxB,SAAS;AACX;AACA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":[".hoursCounter .ant-input-affix-wrapper .ant-input-prefix  {\n    left: 0;\n}\n\n.hoursCounter .ant-input-affix-wrapper .ant-input-suffix {\n    right: 0;\n}\n\n.hoursCounter .ant-input-affix-wrapper .ant-input:not(:last-child) {\n    padding-left: 40px;\n    padding-right: 40px;\n    text-align: center;\n    cursor: pointer;\n}\n\n.hoursCounter .ant-input-affix-wrapper:hover, .ant-input:not(:last-child):hover {\n    box-shadow: none;\n}\n\n.hoursCounter .ant-input:focus, .ant-input:hover {\n    border-color: #d9d9d9;\n    box-shadow: none;\n}\n\n/* Remove input number browser arrows */\n.hoursCounter .ant-input::-webkit-outer-spin-button,\n.hoursCounter .ant-input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n/* Firefox */\n.hoursCounter .ant-input[type=number] {\n  -moz-appearance: textfield;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
