import moment from 'moment';

export default [
  {
    title: 'Instructor',
    dataIndex: 'body',
    key: 'userId',
    render: (body, row) => {
      return row.name || (body && body.email) || body.username;
    }
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: timestamp => moment(timestamp).format('MM/DD/YYYY')
  },
  {
    title: 'Time',
    dataIndex: '_id',
    key: '_id',
    render: (_, { timestamp }) => moment(timestamp).format('HH:mm ')
  },
  {
    title: 'Activity',
    dataIndex: 'action',
    key: 'action'
  },
  {
    title: 'Value',
    dataIndex: 'response.statusCode',
    key: 'method'
  },
  {
    title: 'Notes',
    dataIndex: 'response.message',
    key: 'userType'
  }
];
