import React, { useState, useContext, useEffect } from 'react';
import { Context, useSave, useFetch } from 'store';
import { notification } from 'antd';
import { Form, Field } from 'form';
import { Modal, Divider, Button } from 'antd';

import { SignaturePad } from '_src/shared/form-helpers';
import HoursCounter from './HoursCounter';
import services from '_src/services';
import {
  ModalContainer,
  Title,
  ModalHeaderContainer,
  SessionsContainer,
  SessionsWrapper,
  SessionColumn,
  OdometerColumn,
  OdometersWrapper,
  HoursCounterContainer,
  TotalHoursWrapper,
  HoursContainer,
  TotalHoursTitle,
  TotalHoursValue,
  InitialsContainer,
  FormButtonsContainer,
  HourDetailContainer,
  HourDetail
} from './styles';

import moment from 'moment';

const resource = 'attendance';
const config = services[resource];

function CheckoutModal({ student, checkoutTime, onClose }) {
  const [formApi, setFormApi] = useState(null);
  const [data, setData] = useState({});
  const { get: getTodaysHours } = useFetch(config.getTodaysHours);
  const { save: updateHours, busy } = useSave({
    ...config.updateStudentHours,
    validate: () => formApi.validate()
  });
  const { setShown, getShown, getData, refreshResource } = useContext(Context);

  const userId = getData('user').id;
  const modalVisible = getShown('checkoutModal');

  const { studentId, name } = student || {};

  useEffect(() => {
    if (!modalVisible) return;
    getTodaysHours({
      params: {
        studentId,
        date: checkoutTime
      }
    }).then(res => {
      setData({
        attendanceDate: res.attendanceDate,
        sessions: res.sessions,
        sessionsTotal: res.sessionsTotal,
        odometer: res.odometer,
        classHours: res.recordHours?.classHours || 0.0,
        roadHours: res.recordHours?.roadHours || 0.0,
        skillsHours: res.recordHours?.skillsHours || 0.0,
        observationSkillHours: res.recordHours?.observationSkillHours || 0.0,
        observationRoadHours: res.recordHours?.observationRoadHours || 0.0,
        pretripHours: res.recordHours?.pretripHours || 0.0,
        remedialHours: res.recordHours?.remedialHours || 0.0,
        locationType: res?.locationType
      });
    });
  }, [studentId, modalVisible]);

  const handlePreSubmit = () => {
    const isFormValid = formApi.validate();
    if (!isFormValid) {
      return;
    }

    setShown('confirm', true);
  };

  const handleSubmit = () => {
    const values = {
      ...formApi.values(),
      classHours: data.classHours,
      roadHours: data.roadHours,
      skillsHours: data.skillsHours,
      observationSkillHours: data.observationSkillHours,
      observationRoadHours: data.observationRoadHours,
      pretripHours: data.pretripHours,
      remedialHours: data.remedialHours,
      studentId,
      userId,
      hoursBreakdownDate: moment(data.attendanceDate).parseZone().format('YYYY-MM-DD')
    };
    updateHours(values).then(({ success }) => {
      setShown('confirm', false);
      refreshResource('attendance');
      if (success === 1) {
        notification.success({
          message: 'Student has been updated successfully',
          duration: 3
        });
        onClose();
      } else {
        notification.error({
          message: 'Failed to update the student',
          duration: 3
        });
      }
    });
  };

  const studentType = data.locationType === 1 ? 'Yard' : 'Class';
  const {
    classHours,
    roadHours,
    skillsHours,
    observationSkillHours,
    observationRoadHours,
    pretripHours,
    sessionsTotal,
    remedialHours
  } = data;
  const totalHours = (
    classHours +
    roadHours +
    skillsHours +
    observationSkillHours +
    observationRoadHours +
    pretripHours +
    remedialHours
  ).toFixed(2);
  let sessionsTotalFixed;
  if (sessionsTotal) {
    sessionsTotalFixed = sessionsTotal.toFixed(2);
  }

  useEffect(() => {
    if (parseFloat(totalHours) > parseFloat(sessionsTotalFixed)) {
      notification.error({
        message: `Hours Breakdown should be less than ${sessionsTotalFixed} hours`,
        duration: 5
      });
    }
  }, [totalHours]);

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={onClose}
        title={null}
        footer={null}
        width="100%"
        style={{ maxWidth: 1200 }}
      >
        <ModalContainer>
          <ModalHeaderContainer>
            <div>
              <Title>
                <b>Check Out</b> for <b>{name}</b>
              </Title>
              <p>{studentType} Student</p>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Title>
                <b>Date:</b>
              </Title>
              <p>{moment(checkoutTime, 'DD-MM-YYYY').format('MM/DD/YYYY')}</p>
            </div>
          </ModalHeaderContainer>

          <Divider />

          <SessionsContainer>
            <div style={{ display: 'flex' }}>
              <SessionColumn>
                <Title>
                  <b>Sessions:</b>
                </Title>
                <SessionsWrapper>
                  {(data?.sessions || []).map((sesion, index) => (
                    <div key={index}>
                      <span>
                        <b>{sesion.checkIn}</b> to <b>{sesion.checkOut}</b>
                      </span>
                    </div>
                  ))}
                </SessionsWrapper>
              </SessionColumn>
              <OdometerColumn>
                <Title>
                  <b>Odometer:</b>
                </Title>
                <OdometersWrapper>
                  {(data?.odometer || []).map((odometer, index) => (
                    <div key={index} style={{ whiteSpace: 'nowrap' }}>
                      <span>
                        <b>Session {index + 1}:&nbsp;</b>
                        {odometer.start}-{odometer.end}
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <b>SOS Sticker:&nbsp;</b>
                        {odometer.sticker}
                      </span>
                    </div>
                  ))}
                </OdometersWrapper>
              </OdometerColumn>
            </div>

            <HoursContainer>
              <TotalHoursWrapper>
                <TotalHoursTitle>Total hours:</TotalHoursTitle>
                <TotalHoursValue>
                  {totalHours} / {sessionsTotalFixed}
                </TotalHoursValue>
              </TotalHoursWrapper>
              <HoursCounterContainer>
                <HoursCounter
                  label="Class"
                  disabled={data?.locationType === 1}
                  defaultValue={classHours}
                  onChange={value => {
                    setData({
                      ...data,
                      classHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Pre Trip"
                  disabled={data?.locationType === 2}
                  defaultValue={pretripHours}
                  onChange={value => {
                    setData({
                      ...data,
                      pretripHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Road"
                  disabled={data.locationType === 2}
                  defaultValue={roadHours}
                  onChange={value => {
                    setData({
                      ...data,
                      roadHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Skills"
                  disabled={data?.locationType === 2}
                  defaultValue={skillsHours}
                  onChange={value => {
                    setData({
                      ...data,
                      skillsHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Skill Observation"
                  disabled={data.locationType === 2}
                  defaultValue={observationSkillHours}
                  onChange={value => {
                    setData({
                      ...data,
                      observationSkillHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Road Observation"
                  disabled={data?.locationType === 2}
                  defaultValue={observationRoadHours}
                  onChange={value => {
                    setData({
                      ...data,
                      observationRoadHours: value ? value : 0
                    });
                  }}
                />
                <HoursCounter
                  label="Remedial Hours"
                  defaultValue={remedialHours}
                  disabled
                  onChange={value => {
                    setData({
                      ...data,
                      remedialHours: value ? value : 0
                    });
                  }}
                />
              </HoursCounterContainer>
            </HoursContainer>
          </SessionsContainer>

          <Divider />

          <Form
            schema={
              data?.locationType === 1
                ? config.todaysHoursSchema
                : config.todaysHoursSchemaNotRequired
            }
            formApi={api => setFormApi(api)}
          >
            <InitialsContainer>
              <Field name="instructorInitials" label="Instructor Initials:" render={SignaturePad} />
              <Field name="studentInitials" label="Student Initials:" render={SignaturePad} />
            </InitialsContainer>
          </Form>
          <FormButtonsContainer>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={busy}
              onClick={handlePreSubmit}
              style={{
                backgroundColor: '#5AB55B',
                borderColor: '#5AB55B'
              }}
              disabled={sessionsTotalFixed - totalHours > 0.25 || +totalHours > +sessionsTotalFixed}
            >
              Submit
            </Button>
            <Button
              size="large"
              onClick={onClose}
              style={{
                borderColor: '#5AB55B',
                marginLeft: 20
              }}
            >
              Cancel
            </Button>
          </FormButtonsContainer>
        </ModalContainer>
      </Modal>

      <Modal
        visible={getShown('confirm')}
        title="Confirm hours breakdown"
        // onCancel={() => setShown('confirm', false)}
        // onOk={handleSubmit}
        // okText="Save"
        footer={[
          <Button key="cancel" onClick={() => setShown('confirm', false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit} disabled={busy}>
            Save
          </Button>
        ]}
      >
        <div style={{ marginBottom: 15 }}>Please be sure the Hours Break Down is correct:</div>
        <span style={{ display: 'flex' }}>
          <HourDetailContainer>
            <HourDetail>Class: {classHours}</HourDetail>
            <HourDetail>Pre Trip: {pretripHours}</HourDetail>
            <HourDetail>Road: {roadHours}</HourDetail>
            <HourDetail>Skills: {skillsHours}</HourDetail>
          </HourDetailContainer>
          <HourDetailContainer>
            <HourDetail>Skill Observation: {observationSkillHours}</HourDetail>
            <HourDetail>Road Observation: {observationRoadHours}</HourDetail>
            <HourDetail>Remedial: {remedialHours}</HourDetail>
            <HourDetail>Total: {totalHours}</HourDetail>
          </HourDetailContainer>
        </span>
      </Modal>
    </>
  );
}

export default CheckoutModal;
