import React from 'react';
import { LabeledFieldStyle, Label, Field } from './styles';

const LabeledField = ({ label, field }) => {
	return field ? (
		<LabeledFieldStyle>
			<Label>{`${label}:`}</Label>
			<Field>{field}</Field>
		</LabeledFieldStyle>
	) : null;
};

export default LabeledField;
