const transformAll = ([classroomsRes, branchesRes]) => {
  const { classrooms } = classroomsRes.data;
  const branches = (branchesRes.data?.branches || []).reduce(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {}
  );

  return classrooms.map(({ id, name, branchId }) => ({
    id,
    name,
    branch: branches[branchId],
    branchId
  }));
};

export { transformAll };
