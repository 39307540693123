import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
  @media (min-width: 1200px) {
    flex: 8;
    padding: 30px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: left;
  padding-right: 27px;
  @media (min-width: 1200px) {
    flex: 3;
  }
`;

const ReportContainer = styled.div`
  flex: 9.5;
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex: 8;
  }
`;
const BubbleTimelineContainer = styled.div`
  margin-bottom: 70px;

  @media (max-width: 1200px) {
    margin-left: -50px;
  }
`;

const DailyInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: ${props => (props.fontSize ? props.fontSize : 29) / 2}px;
  color: #ff8400;
  font-family: 'SF Display Heavy';
  display: flex;
  justify-content: space-between;
`;

const VerticalSeparator = styled.div`
  background-color: #ccc;
  width: 1px;
  height: 100%;
  margin: 0 30px;
`;

const Field = styled.div`
  font-family: 'SF Display Bold';
  font-weight: bold;
  font-size: 15px;
  color: #3b3b3b;
  display: flex;
  margin-left: 15px;

  img {
    width: 19px;
    height: 12px;
    margin-right: 8px;
    margin-top: 4px;
  }
`;

const AbsenceStatus = styled.div`
  text-align: center;
  margin: 15px 0 40px 0;
  font-family: 'SF Display Heavy';
  font-size: 16px;
`;

const Row = styled.div`
  display: inline-flex;
  width: 100%;
  font-family: 'SF Display Light';
`;

const CardBlock = styled.div`
  padding: 20px 10px;
  background-color: #359ac8;
  border-right: 1px solid #ddd;
  color: #fff;
  margin-bottom: 5px;

  ${({ borderRight, borderLeft, colSpan }) => `
    width: ${colSpan}%;
    ${borderLeft
      ? `border-top-left-radius: 7px;
       border-bottom-left-radius: 7px;
       
    `
      : `border-radius: none`
    }

    ${borderRight
      ? `border-bottom-right-radius: 7px;
       border-top-right-radius: 7px;
    `
      : `border-radius: none`
    }
  `}
`;

const Totals = styled.p`
  font-size: 24px;
`;

const LabelCard = styled.p`
  color: #f5f5f5;
`;

const ArchivedLabel = styled(Field)`
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const DayDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
`

const StudentDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  column-gap: 25px;
`

const StudentScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  column-gap: 25px;
  color: rgb(55, 159, 255);
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
  
  span {
    color: rgba(0, 0, 0, 0.64);
  }
`


const StudentDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 5px 0px;
  display: grid;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};

  span {
    font-weight: 300;
    min-height: 23px;
  }
`;

const ModalTitle = styled.div`
  font-size: 24px;
  color: rgb(55, 159, 255);
  font-weight: bolder;
  display: inline-block;
  vertical-align: middle;

  div {
    display: grid;
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    margin-left: 13px;
  }
`;

export {
  Container,
  ProfileContainer,
  ReportContainer,
  BubbleTimelineContainer,
  DailyInfoContainer,
  Label,
  VerticalSeparator,
  Field,
  AbsenceStatus,
  Row,
  CardBlock,
  Totals,
  LabelCard,
  ArchivedLabel,
  Column,
  DayDetail,
  StudentDetailContainer,
  StudentScoreContainer,
  StudentDetail,
  ModalTitle
};
