import React, { useState, useEffect, useContext, useCallback } from 'react';

import { Context, useGet, useSave, useFetch } from 'store';
import { AsyncTable, Refresh } from '_src/components';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select, Input } from '_src/shared/form-helpers';
import { Card, Button, Popconfirm, Icon, notification } from 'antd';
import services from '_src/services';
import { debounce } from '_src/utils';
import download from 'downloadjs';
import { camelToSnakeCase, snakeToCamelCase } from '../../utils/helpers';

import { FilterRow, ButtonsContainer } from './styles';

import moment from 'moment';

const stylesTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ? { marginTop: 30 }
  : { marginTop: 0 };
const baseApi = GLOBALS.BASE_API;

export default () => {
  const { getData } = useContext(Context);
  const token = PersistentRepo.get('token');

  const resource = 'studentIntervention';
  const config = services[resource];
  const attendanceConfig = services.attendance;

  const allBranchesLocation = PersistentRepo.get('allBranches');
  const initialLocation = allBranchesLocation
    ? allBranchesLocation
    : PersistentRepo.get('location', 'allxallx1');

  const [student, setStudent] = useState('');
  const [location, setLocation] = useState(initialLocation);

  const [filterParams, setFilterParams] = useState({
    branchId: location.split('x')[2],
    locationType: location.split('x')[0] === 'all' ? 0 : location.split('x')[0] === 'yard' ? 1 : 2,
    ...(student.length > 0 && { student: student })
  });

  const debounceCallback = useCallback(
    debounce(
      params => {
        setFilterParams(params);
      },
      1000,
      false
    ),
    []
  );

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      if (!allLocations.find(({ id }) => id === location) && location !== 'all') {
        onLocationChange(allLocations[0].id);
      }
    }
  }, []);

  const onLocationChange = loc => {
    const isAllBranches = loc === 'all';
    if (isAllBranches) {
      PersistentRepo.set('allBranches', loc);
    } else {
      allBranchesLocation && PersistentRepo.delete('allBranches');
    }

    setLocation(loc);
    setFilterParams(prevState => {
      return {
        ...prevState,
        branchId: isAllBranches ? undefined : loc.split('x')[2],
        locationType: loc.split('x')[0] === 'all' ? 0 : loc.split('x')[0] === 'yard' ? 1 : 2
      };
    });
  };

  const locations = {
    options: [{ id: 'all', label: 'All Branches' }],
    ...attendanceConfig.location
  };

  const downloadIntervention = async id => {
    try {
      const response = await fetch(`${baseApi}/student_intervention/${id}`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const fileBlob = await response.blob();
      download(fileBlob, 'intervention.pdf');
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <PageContainer style={stylesTablet}>
      <Card title="Student Intervention">
        <FilterRow>
          <Select
            showSearch
            onChange={onLocationChange}
            {...locations}
            value={location}
            label="Branch"
            style={{ width: '250px', marginRight: '20px' }}
          />

          <Input
            label="Full Name"
            onChange={e => {
              setStudent(e);
              debounceCallback({ ...filterParams, student: e });
            }}
            value={student}
          />
        </FilterRow>

        <AsyncTable
          pagination
          fileName={resource}
          resource={resource}
          config={{
            ...config,
            table: [
              ...config.table,
              {
                id: 'download',
                render: interventionRow => {
                  return (
                    <Button
                      type="primary"
                      onClick={() => {
                        downloadIntervention(interventionRow.interventionId);
                      }}
                      // style={{ width: '200px' }}
                    >
                      Download Intervention
                    </Button>
                  );
                }
              }
            ]
          }}
          params={{
            ...filterParams
          }}
          onChange={(pagination, filters, sorter) => {
            console.log(sorter.field);
            if (sorter.field) {
              setFilterParams({
                ...filterParams,
                ...(sorter.field && {
                  orderBy: camelToSnakeCase(
                    sorter.field === 'firstName'
                      ? 'firstName'
                      : sorter.field == 'lastName'
                      ? 'lastName'
                      : sorter.field === 'submitDate'
                      ? 'interventionCreatedAt'
                      : 'branchName'
                  ),
                  order: sorter.order === 'ascend' ? 'asc' : 'desc'
                })
              });
            }
          }}
        />
      </Card>
    </PageContainer>
  );
};
