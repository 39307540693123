import moment from 'moment';
import { stripNullObj, dateToUnix, isUrl } from '_src/utils';
import { PersistentRepo } from '_src/shared/repo';
import 'moment-timezone';

const transformAll = ({ data: { students, total } }) => {
  const token = PersistentRepo.get('token');

  const data = (students || []).map(row => {
    const {
      profilePicture,
      weekendClass,
      nightClass,
      attendance,
      totalHoursTrainedYard,
      totalHoursTrainedClassroom,
      pretripsScore,
      skillsScore,
      roadsScore,
      automaticGear,
      archived
    } = row;

    return {
      ...row,
      archived: archived ? 'Yes' : 'No',
      totalHoursTrained: Math.round((totalHoursTrainedYard + totalHoursTrainedClassroom) * 10) / 10,
      totalHoursTrainedYard: Math.round(totalHoursTrainedYard * 10) / 10,
      totalHoursTrainedClassroom: Math.round(totalHoursTrainedClassroom * 10) / 10,
      attendance: attendance ? 'Y' : 'N',
      classType: weekendClass ? 'Weekend Class' : nightClass ? 'Night Class' : 'Normal Class',
      profilePicture: profilePicture
        ? `${GLOBALS.BASE_API}/students/profile_picture/${profilePicture}/${token}`
        : profilePicture,
      pretripsScore: `${(pretripsScore * 100).toFixed(0)}%`,
      skillsScore: `${(skillsScore * 100).toFixed(0)}%`,
      roadsScore: `${(roadsScore * 100).toFixed(0)}%`,
      automaticGear: automaticGear ? true : false
    };
  });

  return { data, total };
};

const transformSponsors = ({ data }) => {
  const allSponsors = data.sponsors
    ? data.sponsors
        .map(e => e.toLowerCase())
        .filter(elm => !elm.includes('amazon'))
        .filter(elm => !elm.includes('wioa'))
    : [];
  const defaultSponsors = data.sponsors
    ? ['All Amazon', 'Amazon Career Choice', 'Amazon TOM', 'Amazon Freight Partner', 'Wioa']
    : [];
  allSponsors.push(...defaultSponsors);

  return data.sponsors
    ? allSponsors
        .filter(n => n)
        .sort((a, b) => a.localeCompare(b))
        .map(sponsor => ({
          id: sponsor,
          label: sponsor.charAt(0).toUpperCase() + sponsor.slice(1)
        }))
    : [];
};

const transformLocations = branchesRes => {
  return (branchesRes.data?.branches || [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, { id, name }) => {
      let items = [
        {
          id,
          label: `${name} Branch`
        }
      ];
      return [...acc, ...items];
    }, []);
};

export { transformAll, transformSponsors, transformLocations };
