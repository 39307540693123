import moment from 'moment';

const transform = resp => {
  const response = resp.data.evaluation ? resp.data.evaluation.allPerformance : [];

  const students = {
    data: Object.keys(response).map(id => {
      return {
        id: parseInt(id),
        studentId: parseInt(id),
        name: `${response[id].firstName} ${response[id].lastName}`,
        performance: response[id].performance,
        status: `${response[id].status}`
      };
    }),
    total: resp.data.evaluation ? resp.data.evaluation.total : null
  };

  return students;
};

export { transform };
