import schema from './schema';
import table from './table';
import { transformAll } from './transform';

const name = 'driver_score/driver/scores';

export default {
	get: {
		name,
		path: `${name}/search`,
		transform: transformAll
	},
	schema,
	table,
	downloadPdf: {
		name,
		path: `${name}/certificate/download/`
	}
};
