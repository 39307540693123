import { transformStudentAttendance, transformMiles } from '../reports/transform';

const transformAll = ({ data }) => {
  data = data.automatedReports.map(({ branches, sponsors, ...rest }) => ({
    ...rest,
    branches: branches[0] == -1 ? [] : branches,
    sponsors: sponsors[0] == -1 ? [] : sponsors
  }));

  return data;
};

const transformStudentProgress = data => {
  console.log(data);
  if (data.success === 0) {
    return null;
  }
  data = (data.data?.data.automatedReportData || []).map(
    ({
      id,
      attendance,
      name,
      totalHoursTrained,
      evaluations,
      email,
      gender,
      birthdate,
      phone,
      status,
      license,
      licenseState,
      dateClassBegins,
      permitExpiration,
      cdDate,
      sponsor,
      performance,
      branchName,
      testingDate,
      archived,
      timezone,
      programHours,
      ...rest
    }) => {
      if (attendance == null || evaluations == null) {
        return null;
      }
      attendance = transformStudentAttendance({ data: { attendance, timezone } });
      const milesData = transformMiles({ data: { studentEvaluations: evaluations } });

      return {
        id,
        name,
        attendance,
        archived,
        totalHoursTrained: Math.round(totalHoursTrained * 10) / 10,
        milesData,
        studentInfo: {
          email,
          gender,
          birthdate,
          phone,
          status,
          license,
          licenseState,
          dateClassBegins,
          permitExpiration,
          cdDate,
          sponsor,
          testingDate
        },
        performance,
        branchName,
        programHours: programHours ? programHours : '160'
      };
    }
  );

  return data.sort((a, b) => a.name.localeCompare(b.name));
};

const transformBeforeSave = ({ branches, sponsors, ...data }) => {
  branches = branches && branches.length ? branches : [-1];
  sponsors = sponsors && sponsors.length ? sponsors : ['-1'];

  return { ...data, branches, sponsors };
};

export { transformAll, transformStudentProgress, transformBeforeSave };
