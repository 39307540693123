import { transformBeforeSave, transformUser } from './transform';
import schema from './schema';
import fields from './fields';

export default {
  save: { method: 'patch', path: 'profile', transform: transformBeforeSave },
  fields,
  schema,
  transformUser
};
