import styled from 'styled-components';

const PageContainer = styled.div`
	width: 100%;
	padding: 25px;
	overflow: hidden;
	background-color: #fff;
	font-family: 'SF Display Heavy';

	.ant-table-thead > tr > th .anticon-filter,
	.ant-table-thead > tr > th .ant-table-filter-icon {
		font-size: 23px;
	}

	.ant-form-item {
		width: fit-content;
	}

	.ant-table-tbody > tr > td {
		white-space: normal;
	}
	.ant-table-thead > tr > th .ant-table-filter-icon {
		margin-right: 10px;
	}
	.ant-table-thead > tr > th .anticon-filter > svg,
	.ant-table-thead > tr > th .ant-table-filter-icon > svg {
		margin-top: -10px;
		margin-left: -10px;
	}
`;

const PageTitle = styled.div`
	font-size: 23px;
`;

const Row = styled.div`
	padding: 10px 0;
	display: flex;
	width: 50%;
	justify-content: space-between;

	@media screen and (max-width: 1580px) {
		width: 60%;
	}
	@media screen and (max-width: 1200px) {
		width: 100%;
	}

	input {
		border: 1px solid #ddd;
		border-radius: 10px;
		font-family: 'SF Display Regular';
		align-self: flex-end;
		padding: 5px 15px;
		outline: none;
		width: 240px;
	}

	.ant-select {
		font-family: 'SF Display Regular';
	}

	.ant-select-selection {
		border: 1px solid #ddd;
		border-radius: 10px;
		outline: none;
	}

	button {
		align-self: flex-end;
	}
`;

const FullRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
`;

export { PageContainer, PageTitle, Row, FullRow };
