import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'form';
import { Context, useSave } from 'store';
import { Button, notification } from 'antd';
import { Fields, VersionNumber } from '_src/components';
import services from '_src/services';
import { PersistentRepo } from '_src/shared/repo';

import logoTagline from '_src/assets/images/logoTagline.png';
import { Container, FormContainer, Logo, ForgotPassword } from './styles';

export default () => {
  const { setData } = useContext(Context);
  const config = services.login;
  const { save, busy } = useSave({ ...config.save, validate: () => formApi.current.validate() });
  const { save: resetPassword } = useSave({
    ...config.forgotPassword
  });

  const [mode, setMode] = useState('default');
  const formApi = useRef(null);
  let fieldsConfig = config.form.fields;

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  });

  useEffect(() => {
    formApi.current.resetFields();
  }, [mode]);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  const submit = () => {
    save(formApi.current.values())
      .then(response => handleSave(response))
      .catch(err => {});
  };

  const handleSave = ({ success, data }) => {
    if (success === 1) {
      const { token, privileges, user } = data;

      setData('privileges', privileges);
      setData('token', token);
      setData('user', user);
      setData('authenticated', true);

      PersistentRepo.set('token', token);
      PersistentRepo.set('user', user);
      PersistentRepo.set('privileges', privileges);
      closeModal();
    } else {
      handleError(data.message);
    }
  };

  const handleError = (message = 'Failed to log in') => {
    notification.error({
      message,
      description: 'Check if your credentials are correct',
      duration: 2.5
    });
  };

  const onPasswordReset = () => {
    const { forgotEmail } = formApi.current.values();

    resetPassword({ email: forgotEmail })
      .then(response => {
        if (response.success === 1) {
          notification.success({
            message: response.data.message,
            duration: 2.5
          });
        } else {
          notification.error({
            message: response.data.message,
            description: 'User not found',
            duration: 2.5
          });
        }
      })
      .catch(err => {});
  };

  const closeModal = (mode = 'default') => {
    setMode(mode);
  };

  return (
    <Container>
      <FormContainer>
        <Logo src={logoTagline} />

        <Form formApi={form => (formApi.current = form)} schema={config.form.schema[mode]}>
          {mode !== 'license' && <Fields fields={fieldsConfig[mode]} />}

          {mode === 'forgotPassword' && (
            <Button type="primary" onClick={onPasswordReset}>
              Send password reset email
            </Button>
          )}

          {mode === 'default' && (
            <>
              <ForgotPassword onClick={() => setMode('forgotPassword')}>
                Forgot your password?
              </ForgotPassword>

              <Button type="primary" loading={busy} onClick={submit}>
                Log in
              </Button>
            </>
          )}
        </Form>
        <VersionNumber plain style={{ position: 'absolute', bottom: '24px', right: '24px' }} />
      </FormContainer>
    </Container>
  );
};
