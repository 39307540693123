export default [
  {
    title: 'Username',
    dataIndex: 'username',
    sorter: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    sorter: true
  }
];
