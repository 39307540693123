import styled from 'styled-components';

// Header Start
const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 70px;
  height: auto;
  max-height: 94px;
  margin-right: 15px;
`;

const HeaderStatsWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

const Title = styled.h1`
  font-family: 'SF Display Bold';
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -20px;
`;

const StatItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 20px;
`;

const StatTitle = styled.span`
  font-family: 'SF Display Bold';
  display: block;
  margin-right: 4px;
  line-height: 18px;
  flex-shrink: 0;
`;

const StatValue = styled.span`
  line-height: 18px;
  flex-shrink: 0;
`;

const SearchLabel = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: 'SF Display Bold';
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
// Header End


// Filter Modal Start
const FilterModalContainer = styled.div`
  margin: 0px -25px;
`

const FilterModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`

const FilterModalTitle = styled.span`
  font-size: 17px;
  font-family: 'SF Display Bold';
`

const FilterWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`

const FilterTitle = styled.span`
  font-size: 15px;
  margin-bottom: 16px;
  display: block;
  font-family: 'SF Display Bold';
`

// Filter Modal End 

export {
  Header,
  Logo,
  HeaderStatsWrapper,
  Title,
  StatsContainer,
  StatItemWrapper,
  StatTitle,
  StatValue,
  SearchLabel,
  SearchWrapper,
  FilterModalContainer,
  FilterModalHeader,
  FilterModalTitle,
  FilterWrapper,
  FilterTitle,
};
