import React, { useState, useEffect } from 'react';

import { Container, Bubble, TextHolder, TopLabel, DateLabel } from './styles';

export default ({ attendance, onBubbleSelect = () => { } }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    let selectedIndex = [...attendance]
      .reverse()
      .findIndex(({ upcoming, absent }) => !upcoming && !absent);

    if (selectedIndex !== -1) {
      selectedIndex = attendance.length - selectedIndex - 1;
      setSelected(selectedIndex);
      onBubbleSelect(attendance[selectedIndex]);
    } else {
      setSelected(null);
      onBubbleSelect({});
    }
  }, [attendance]);

  return (
    <Container>
      {attendance.map(({ date, absent, upcoming, hasOpenSessions }, index) => {
        let isExcused = undefined
        if (absent) {
          isExcused = absent.isExcused
        }
        const isFirst = index === 0;
        const isLast = index === attendance.length - 1;
        const isSelected = index === selected;
        let topLabel = isFirst || isLast ? date : '';
        let dateLabel = isSelected && !upcoming ? date : '';

        return (
          <Bubble
            selected={index === selected}
            absent={!!absent}
            isExcused={isExcused}
            upcoming={upcoming}
            first={index === 0}
            hasOpenSessions={hasOpenSessions}
            onClick={() => {
              if (!upcoming) {
                setSelected(index);
                onBubbleSelect(attendance[index]);
              }
            }}
            key={index}
          >
            <TextHolder selected={isSelected}>
              <TopLabel isFirst={isFirst} isLast={isLast} key={index}>
                {topLabel}
              </TopLabel>
              <DateLabel absent={!!absent} isExcused={isExcused}>{dateLabel}</DateLabel>
            </TextHolder>
          </Bubble>
        );
      })}
    </Container>
  );
};
