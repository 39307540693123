import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
`;

const Title = styled.div`
  color: #ff8400;
  font-weight: bold;
  font-size: 16.5px;
  margin-right: 15px;
  font-family: 'SF Display Bold';
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const List = styled.div`
  display: grid;
  grid-column-gap: 30px;
`;

const Total = styled.div`
  font-size: 19.5px;
  font-weight: bold;
  color: #000000;
  font-family: 'SF Display Bold';
  background-color: #f4ca9e;
  padding: 8px;
  border: 2px solid #ff8400;
  border-radius: 11px;
  margin: 10px 0 0 0;
  text-align: center;
`;

const ListRow = styled.li`
  color: #3b3b3b;
  font-size: 15px;
  fontweight: bold;
  font-family: 'SF Display Bold';
`;

export { Container, Title, Info, List, Total, ListRow };
