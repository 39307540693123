import { privilegesToResources, resourcesToPrivileges } from '_src/utils';

const transformAll = ([userRes, groupRes, branchesRes]) => {
  let { users } = userRes.data;
  let { branches } = branchesRes.data;
  const groups = groupRes.data.groups.reduce(
    (acc, { id, name, privileges }) => ({
      ...acc,
      [id]: { name, privileges: privilegesToResources(privileges) }
    }),
    {}
  );

  const total = users?.total;

  users = users.responses.map(
    ({
      id,
      username,
      email,
      firstName,
      lastName,
      groupId,
      privileges,
      branchId,
      license,
      deletedAt
    }) => ({
      id,
      branchId,
      username,
      email,
      firstName,
      lastName,
      groupId,
      group: groups[groupId],
      privileges: privilegesToResources(privileges),
      license,
      groups,
      branchData: branches.find(branch => branchId === branch.id),
      deletedAt
    })
  );

  return { users, groups, total };
};

const transformBeforeSave = ({ privileges = [], group, location, groups, ...data }) => {
  const { pin = '', password = '', license, branchId } = data;

  if (pin.length === 0) {
    delete data.pin;
  }

  if (password.length === 0) {
    delete data.password;
  }

  if (!license) {
    delete data.license;
  }

  if (!branchId) {
    delete data.branchId;
  }

  return {
    ...data,
    privileges: resourcesToPrivileges(privileges)
  };
};

const transformGroups = groupRes => {
  const groups = groupRes.data.groups.reduce(
    (acc, { id, name, privileges }) => ({
      ...acc,
      [id]: { name, privileges: privilegesToResources(privileges) }
    }),
    {}
  );

  return { groups };
};

const transformAllGroups = allGroups => {
  return allGroups?.data?.groups.map(group => ({
    text: group.name,
    value: group.id
  }));
};

const transformAllBranches = branchesRes => {
  return branchesRes?.data?.branches
    .map(({ id, name }) => ({
      text: name,
      value: id
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
};

export {
  transformAll,
  transformBeforeSave,
  transformGroups,
  transformAllGroups,
  transformAllBranches
};
