import { string, object } from 'yup';

const todaysHoursSchema = object().shape({
    instructorInitials: string().required('Instructor Initials are required'),
    studentInitials: string().required('Student Initials are required'),
})

const todaysHoursSchemaNotRequired = object().shape({
    instructorInitials: string(),
    studentInitials: string(),
})

export default {
    todaysHoursSchema, todaysHoursSchemaNotRequired
}