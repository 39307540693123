export default [
  { name: 'name', label: 'Name', component: 'input' },
  {
    name: 'branchId',
    label: 'Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name'
  }
];
