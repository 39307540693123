import qs from 'qs';

import { transformAll, transformLocations } from './transform';
import table from './table';

export default {
  title: 'Records',
  table,
  get: {
    name: 'studentRecords',
    path: 'reports/student_records/students',
    transform: transformAll,
    defaultValue: [],
    paramsSerializer: params => qs.stringify({ ...params }, { arrayFormat: 'comma' })
  },
  getStats: {
    name: 'studentRecordsStats',
    path: 'reports/student_records/statistics',
    transform: res => res.data.studentStatistics[0],
    defaultValue: {}
  },
  getLocations: {
    name: 'locations',
    path: ['branches', 'yards', 'classrooms', 'branch_locations'],
    transform: transformLocations,
    defaultValue: [],
    memoize: false
  }
};
