import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 5px;
	padding-right: 5px;
	margin-left: 13px;
	margin-right: 13px;
	border-radius: 17px;
	box-shadow: 0px 0px 10px #00000012;

	@media (min-width: 1200px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;

const Title = styled.div`
	font-size: 16.5px;
	color: #3b3b3b;
	font-family: 'SF Display Heavy';
`;

const Trend = styled.div`
	font-size: 13.5px;
	font-weight: bold;
	color: ${(props) => props.color};
	font-family: 'SF Display Bold';
`;

const Icon = styled.img`
	padding-top: 22px;
	margin-left: ${({ isEmpty }) => (isEmpty ? '30px' : '0')};
`;

const Status = styled.div`
	margin-top: 15px;
	margin-bottom: 25px;
	font-weight: bold;
	font-color: #3b3b3b;
	font-family: 'SF Display Bold';
`;

export { Container, Title, Trend, Icon, Status };
