import React, { useEffect, useState, useContext } from 'react';
import { Context, useFetch, useSave } from 'store';

import services from '_src/services';

import LabeledField from '../LabeledField';
import moment from 'moment';
import {
  Container,
  LabelsContainer,
  NotesContainer,
  NotesField,
  NotesFieldBold,
  InstructorCommentContainer,
  CommentField,
  EditIconContainer,
  EditComentTextAreaContainer,
  EditIcon
} from './styles';
import { Input } from '_src/shared/form-helpers';
import { Button as AntButton, Skeleton } from 'antd';
import { textInputValueWithoutEmoji } from '_src/utils';
import { Restricted } from '_src/shared/restrict';
import { Textarea } from '_src/shared/form-helpers';
import { Button } from '_src/ui';

import editImg from '_src/assets/images/edit.png';

const config = services.reports;

const renderProfileSectionTitle = (title, onEdit) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 11,
      marginBottom: 11
    }}
  >
    <div
      style={{
        fontSize: '14px',
        color: '#3B3B3B',
        fontFamily: 'SF Display Heavy'
      }}
    >
      {title}
    </div>
    <button
      style={{
        color: '#7B7B7B',
        fontSize: '12px',
        fontFamily: 'SF Display Heavy'
      }}
      onClick={onEdit}
    />
  </div>
);

const getStudentType = locationType => {
  if (!locationType) {
    return '';
  }

  if (locationType === 1) return 'Yard';
  if (locationType === 2) return 'Classroom';
};

const getBranchName = (allLocations, branchId) => {
  let label = (allLocations || []).find(el => el.branchId === branchId)?.label;
  return label?.substring(0, label.lastIndexOf(' ')) || '';
};

const StudentInfo = ({ id, studentInfo, parentCallback }) => {
  const [studentInfoData, setStudentInfoData] = useState(studentInfo || {});
  const { get: getStudentInfo } = useFetch(config.getStudent);
  const [studentNotes, setStudentNotes] = useState([]);
  const [note, setNote] = useState('');
  const { get: getStudentNotes } = useFetch(config.getStudentNotes);
  const { save: createStudentNotes } = useSave(config.createStudentNotes);
  const { getData, setData } = useContext(Context);
  const allLocations = getData('locations');
  const [loading, setLoading] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState(false);

  const { get: getComment } = useFetch(services['reports'].comment);

  const { save: updateComments } = useSave({
    ...services.studentPerformanceEvaluator.updateComments
  });

  useEffect(() => {
    setLoading(true);
    if (!studentInfo) {
      getStudentInfo({ replace: { id } }).then(data => {
        setStudentInfoData(data);
        setData('archived', data.archived);
        setLoading(false);
        parentCallback(false, data.totalHoursDriven, data.studentEvaluations);
      });
    }

    getStudentNotes({ replace: { id } }).then(data => {
      const reorderStudentNotes = data.sort((a, b) => {
        return a.fmcsa === b.fmcsa ? 0 : a.fmcsa ? -1 : 1;
      });
      setStudentNotes(reorderStudentNotes);
    });
  }, []);

  const submitStudentNote = () => {
    if (note.trim()) {
      createStudentNotes({ studentId: id, note: note.trim() }).then(res => {
        if (res.success === 1) {
          setNote('');
          getStudentNotes({ replace: { id } }).then(data => {
            const reorderStudentNotes = data.sort((a, b) => {
              return a.fmcsa === b.fmcsa ? 0 : a.fmcsa ? -1 : 1;
            });
            setStudentNotes(reorderStudentNotes);
          });
        }
      });
    }
  };

  const handleEditComment = commentData => {
    setCommentToEdit(commentData);
  };

  const handleUpdateComment = () => {
    updateComments({
      id: commentToEdit.id,
      comment: commentToEdit.comment,
      type: 'note'
    }).then(() => {
      getStudentNotes({ replace: { id } }).then(data => {
        const reorderStudentNotes = data.sort((a, b) => {
          return a.fmcsa === b.fmcsa ? 0 : a.fmcsa ? -1 : 1;
        });
        setStudentNotes(reorderStudentNotes);
        setCommentToEdit(false);
      });
    });
  };

  return (
    <Skeleton loading={loading} paragraph={{ rows: 10 }} active={true}>
      <Container>
        {renderProfileSectionTitle('PERSONAL INFO', () => {})}

        <LabelsContainer>
          <LabeledField label="Email" field={studentInfoData?.email} />
          <LabeledField label="Gender" field={studentInfoData.gender} />
          <LabeledField
            label="Birthdate"
            field={
              studentInfoData.birthdate &&
              moment(studentInfoData.birthdate).parseZone().format('MM/DD/YYYY')
            }
          />
          <LabeledField label="Phone" field={studentInfoData.phone} />
          <LabeledField label="Salesforce Contact Number" field={studentInfoData.contactNumber} />
          <LabeledField
            label="Social Security Number"
            field={!!studentInfoData?.ssn ? `***-**-${studentInfoData.ssn}` : 'N/A'}
          />
          {studentInfoData.programType && (
            <LabeledField label="Program Type" field={studentInfoData.programType} />
          )}
        </LabelsContainer>

        {renderProfileSectionTitle('TRUCKING INFORMATION', () => {})}

        <LabelsContainer>
          <LabeledField label="Status" field={studentInfoData.status} />
          <LabeledField label="Driver&#39;s License" field={studentInfoData.license} />
          <LabeledField label="License State" field={studentInfoData.licenseState} />
          <LabeledField
            label="Class Started"
            field={
              studentInfoData.dateClassBegins &&
              moment(studentInfoData.dateClassBegins).parseZone().format('MM/DD/YYYY')
            }
          />
          <LabeledField
            label="Permit Rec' Date"
            field={
              studentInfoData?.permitRecDate
                ? moment(studentInfoData.permitRecDate).parseZone().format('MM/DD/YYYY')
                : 'N/A'
            }
          />
          <LabeledField
            label="Permit Expiration"
            field={
              studentInfoData.permitExpiration &&
              moment(studentInfoData.permitExpiration).parseZone().format('MM/DD/YYYY')
            }
          />
          <LabeledField
            label="Testing Date"
            field={
              studentInfoData.testingDate
                ? moment(studentInfoData.testingDate).parseZone().format('MM/DD/YYYY')
                : '-'
            }
          />
          <LabeledField
            label="CDL End Date"
            field={
              studentInfoData.cdlDate &&
              moment(studentInfoData.cdlDate).parseZone().format('MM/DD/YYYY')
            }
          />
          <LabeledField label="Sponsor" field={studentInfoData.sponsor} />
          <LabeledField
            label="Branch"
            field={`${getBranchName(allLocations, studentInfoData.branchId)} ${getStudentType(
              studentInfoData?.locationType
            )}`}
          />
          <LabeledField
            label="Transmission"
            field={studentInfoData.automaticGear ? `Automatic` : `Manual`}
          />
        </LabelsContainer>

        {renderProfileSectionTitle('NOTES', () => {})}

        <LabelsContainer
          style={{ maxHeight: '80px', overflowY: 'scroll' }}
          isNote={studentNotes.length > 3}
        >
          {studentNotes.length !== 0 &&
            studentNotes.map(({ id, note, createdAt, instructor, fmcsa }) => (
              <InstructorCommentContainer key={id}>
                <NotesFieldBold fmcsa={fmcsa}>
                  {moment(createdAt).format('MM/DD/YYYY, hh:mm A')} - {instructor}{' '}
                </NotesFieldBold>
                <CommentField fmcsa={fmcsa}>
                  <Restricted resource="comment/update" method="POST">
                    {!fmcsa && (
                      <EditIconContainer>
                        <EditIcon
                          src={editImg}
                          onClick={() => handleEditComment({ comment: note, id, instructor })}
                        />
                      </EditIconContainer>
                    )}
                  </Restricted>

                  {commentToEdit && commentToEdit.id === id ? (
                    <EditComentTextAreaContainer>
                      <Textarea
                        value={commentToEdit.comment}
                        onChange={newNote => {
                          setCommentToEdit({ ...commentToEdit, comment: newNote });
                        }}
                        rows={3}
                        style={{ marginBottom: '10px' }}
                      />

                      <Button
                        onClick={handleUpdateComment}
                        height={20}
                        width={'100%'}
                        shadow
                        filled
                        text="SAVE"
                      />
                    </EditComentTextAreaContainer>
                  ) : (
                    <p>- {note}</p>
                  )}
                </CommentField>
              </InstructorCommentContainer>
            ))}
          {studentNotes.length === 0 && <NotesField>No notes</NotesField>}
        </LabelsContainer>

        <Input
          name="note"
          style={{ marginTop: '10px' }}
          placeholder="Insert note"
          value={note}
          onChange={note => {
            setNote(textInputValueWithoutEmoji(note));
          }}
          touched
        />
        <AntButton
          key="submitStudentNote"
          type="primary"
          style={{ width: '80px', alignSelf: 'flex-end', marginTop: '10px' }}
          onClick={() => submitStudentNote()}
        >
          Create
        </AntButton>
      </Container>
    </Skeleton>
  );
};

export default StudentInfo;
