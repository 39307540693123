import React, { Fragment } from 'react';
import { Form, Radio } from 'antd';

export default ({
  options = [],
  onChange,
  valueKey = 'id',
  labelKey = 'label',
  value,
  label,
  ...rest
}) => {
  const RadioGroup = Radio.Group;

  const handleClick = optValue => {
    if (optValue === value) {
      onChange(null);
    }
  };

  return (
    <>
      {!!label && <label>{label}</label>}
      <Form.Item>
        <RadioGroup {...rest} value={value} onChange={e => onChange(e.target.value)}>
          {options.map(opt => {
            const optValue = opt[valueKey];
            const optLabel = opt[labelKey];
            return (
              <Fragment key={optValue}>
                {optLabel && <div>{optLabel}</div>}
                <Radio onClick={() => handleClick(optValue)} value={optValue} />
              </Fragment>
            );
          })}
        </RadioGroup>
      </Form.Item>
    </>
  );
};
