import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { get, useFetch, useGet } from 'store';
import moment from 'moment';

import services from '_src/services';
import { Button, Category } from '_src/ui';
import {
  Container,
  Header,
  Title,
  Subtitle,
  Description,
  TitlesContainer,
  InfoContainer,
  Categories,
  Evaluations,
  MoreInfoDiv,
  CommentContainer,
  CommentField,
  CommentFieldBold
} from './styles';
import Evaluation from './Evaluation';

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    objectsByKeyValue[value].sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
    return objectsByKeyValue;
  }, {});

const { GREEN } = GLOBALS.colors;

const MoreInfo = ({
  title = 'Pre-trip',
  subtitle = 'More Information',
  description = 'Based on student’s previous activity in this section',
  studentId = 2,
  type,
  onCloseClick
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [comments, setComments] = useState([]);
  const [sections, setSections] = useState({});
  const config = services['reports'];
  const { get: getQuestions } = useFetch(config.getTestingQuestions);
  const { get: getComment } = useFetch(config.comment);
  const sectionTitles = GLOBALS.testing.sections[type].reduce(
    (acc, { id, label }) => ({ ...acc, [id]: label }),
    {}
  );

  const performanceNameToId = {
    UNSUCCESFUL: 0,
    'NEEDS IMPROVEMENT': 1,
    SATISFACTORY: 2,
    PROFICIENT: 3,
    EMPTY: -1
  };

  useEffect(() => {
    getQuestions({ params: { studentId, type: type } }).then(data => {
      const groupBySection = groupBy('sectionType');
      const sections = groupBySection(data);
      setSections(sections);
      const categories = Object.keys(sections).map(key => {
        return {
          id: key,
          title: sectionTitles[key],
          subtitle: '',
          performance: sections[key][sections[key].length - 1].lastPerformance || 'EMPTY',
          createdAt: sections[key][sections[key].length - 1].createdAt
        };
      });
      const filteredCategories = categories.filter(({ title }) => title != '');
      setCategories(filteredCategories);
      setSelectedCategory(categories[0].id);
    });

    getComment({ params: { studentId, type: type } }).then(data => {
      setComments(data);
    });
  }, []);

  const hasCategories = categories.length > 0;
  const hasComments = comments.length > 0;

  return (
    <Modal width={'100%'} visible footer={null}>
      <Container>
        <Header>
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Description>{description}</Description>
          </div>
          <Button width={83.5} height={40} color={GREEN} text="CANCEL" onClick={onCloseClick} />
        </Header>

        <TitlesContainer hasCategories={hasCategories}>
          {hasCategories && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                maxWidth: '300px'
              }}
            >
              Category
            </div>
          )}
          <div style={{ flex: 1, minWidth: '300px' }}>Evaluation</div>
          <div style={{ width: '210px' }}>Performance</div>
          <div />
          <div style={{ width: '200px', textAlign: 'center' }}>Status</div>
        </TitlesContainer>

        <InfoContainer hasCategories={hasCategories}>
          <MoreInfoDiv>
            {hasCategories && (
              <Categories>
                {categories.map(({ title, subtitle, performance, id }) => (
                  <div style={{ flex: 1, maxWidth: '300px' }}>
                    {title && (
                      <Category
                        key={id}
                        title={title}
                        subtitle={subtitle}
                        performance={performanceNameToId[performance]}
                        selected={selectedCategory === id}
                        onClick={() => {
                          setSelectedCategory(id);
                        }}
                      />
                    )}
                  </div>
                ))}
              </Categories>
            )}
            {hasComments && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    maxWidth: '300px',
                    color: '#5f5e5e',
                    fontWeight: '500',
                    fontSize: '17px'
                  }}
                >
                  Comments
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'scroll',
                    marginRight: '20px'
                  }}
                >
                  {comments.map(({ id, comment, createdAt, instructor }, index) => (
                    <CommentContainer key={id}>
                      <CommentFieldBold>
                        {moment(createdAt).format('MMMM Do, h:mm')} - {instructor}
                      </CommentFieldBold>
                      <CommentField>- {comment}</CommentField>
                    </CommentContainer>
                  ))}
                </div>
              </div>
            )}
          </MoreInfoDiv>

          {sections[selectedCategory] && (
            <Evaluations>
              {sections[selectedCategory].map(
                ({ question, lastPerformance, performanceStatus, id, createdAt }, index) => (
                  <Evaluation
                    key={id}
                    title={question}
                    performance={lastPerformance}
                    index={index}
                    status={performanceStatus}
                    createdAt={createdAt}
                  />
                )
              )}
            </Evaluations>
          )}
        </InfoContainer>
      </Container>
    </Modal>
  );
};

export default MoreInfo;
