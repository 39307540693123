import React, { useState, useEffect } from 'react';
import { useFetch } from 'store';
import moment from 'moment';
import { Button } from '_src/ui';
import qs from 'qs';
import { objToSnakeCase } from '_src/utils';
import { PageWrapper } from '_src/shared/styles';
import { RangePicker } from '_src/shared/form-helpers';
import services from '_src/services';
import { PersistentRepo } from '_src/shared/repo';
import { Search, PageTitle, Label, ActionRow } from './styles';
import { Refresh } from '_src/components';
export default () => {
  const baseApi = GLOBALS.BASE_API;
  const token = PersistentRepo.get('token');
  const config = services.customReports;
  const [raport, setRaport] = useState();
  const { get: getRaport, busy } = useFetch(config.get);
  var filename = 'Yard volume attendance';
  var range = {
    token: token,
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  const [dates, setDates] = useState(getParams);

  // const getFile = () => {
  //   getRaport({ params: range })
  //     .then(res => {})
  //     .catch(err => {});
  // };

  const onChange = ([startDate, endDate]) => {
    range = {
      token: token,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    };
    setDates(getParams());
  };

  const disabledDate = current => current.isAfter(moment(), 'day');

  function getParams() {
    return qs.stringify({ ...objToSnakeCase(range) }, { arrayFormat: 'comma' });
  }

  return (
    <PageWrapper>
      <Refresh />
      <PageTitle>Yard attendance report</PageTitle>
      <Label>Select the range of dates to view the report</Label>
      <Search>
        <RangePicker
          allowClear={false}
          ranges={{
            Today: [moment(), moment()],
            'Last Week': [moment().subtract(7, 'd'), moment()],
            'Last Month': [moment().subtract(1, 'months'), moment()]
          }}
          defaultValue={[moment().subtract(1, 'months'), moment()]}
          onChange={onChange}
          disabledDate={disabledDate}
        />
      </Search>
      <ActionRow>
        <a href={baseApi + '/' + config.get.path + '?' + dates}>
          <Button
            filled={true}
            text="Download"
            height={30}
            width={115}
            // onClick={getFile}
            alignSelf="flex-end"
          />
        </a>
      </ActionRow>
    </PageWrapper>
  );
};
