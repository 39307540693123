import React from 'react';
import { Card, Button } from 'antd';
import { useGet } from 'store';
import { useParams } from 'react-router-dom';

import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { AsyncTable, Loading } from '_src/components';
import StatItem from '../StudentRecords/components/StatItem';
import {
  HeaderContainer,
  Title,
  StatsWrapper,
  StatColumn,
  BlockSection,
  InlineFlexRow,
  CardBlock,
  Totals,
  LabelCard
} from './styles';
import { Refresh } from '_src/components';
const baseApi = GLOBALS.BASE_API;
const resource = 'singleStudentRecord';

function SingleStudentRecord() {
  const config = services[resource];

  const { id: studentId } = useParams();
  const { data: totalHours, busy: totalHoursBusy } = useGet({
    ...config.getTotalHours,
    replace: { id: studentId }
  });
  const { data: studentStats, busy: isLoading } = useGet({
    ...config.getStats,
    replace: { id: studentId }
  });

  if (isLoading || totalHoursBusy) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <Refresh />
      <Card>
        <Title>
          <b>Reports</b> for <b>{studentStats.fullName}</b>
        </Title>
        <HeaderContainer>
          <StatsWrapper>
            <StatColumn>
              <StatItem label="Current Status" value={studentStats.status} />
              <StatItem label="Total Hours Breakdown" value={totalHours.totalHoursBreakdown} />
              <StatItem label="Total Hours Trained" value={totalHours.totalHours} />
            </StatColumn>
            <StatColumn>
              {!!studentStats.enrollmentDate && (
                <StatItem label="Enrollment Date" value={studentStats.enrollmentDate} />
              )}
              {!!studentStats.graduationDate && (
                <StatItem label="Graduation Date" value={studentStats.graduationDate} />
              )}
              {!!studentStats.dateClassBegins && (
                <StatItem label="Date Class Begins" value={studentStats.dateClassBegins} />
              )}
            </StatColumn>
          </StatsWrapper>
          <a target="_blank" href={`${baseApi}/${config.studentRecordPdfPath}/${studentId}`}>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: '#E55F17',
                borderColor: '#E55F17'
              }}
            >
              View Paperwork
            </Button>
          </a>
        </HeaderContainer>
        <BlockSection>
          <InlineFlexRow>
            <CardBlock borderLeft={true} borderRight={false}>
              <LabelCard>Total class hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumClassHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={false}>
              <LabelCard>Total road hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumRoadHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={false}>
              <LabelCard>Total skills hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumSkillsHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={false}>
              <LabelCard>Total pre trip hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumPretripHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={false}>
              <LabelCard>Total obs road hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumObservationRoadHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={false}>
              <LabelCard>Total obs skill hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumObservationSkillHours || 0}</Totals>
            </CardBlock>
            <CardBlock borderLeft={false} borderRight={true}>
              <LabelCard>Total remedial hours</LabelCard>
              <Totals>{totalHours?.categoriesTotals?.totalSumRemedialHours || 0}</Totals>
            </CardBlock>
          </InlineFlexRow>
        </BlockSection>
        <AsyncTable
          pagination
          params={{
            studentId: Number(studentId)
          }}
          fileName={resource}
          resource={resource}
        />
      </Card>
    </PageContainer>
  );
}

export default SingleStudentRecord;
