export default {
  forgotPassword: [
    {
      name: 'newPassword',
      label: 'New Password',
      component: 'input',
      type: 'password'
    },
    {
      name: 'confirmPassword',
      label: 'Confirm Password',
      component: 'input',
      type: 'password'
    }
  ]
};
