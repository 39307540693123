export default [
  {
    title: 'Name',
    dataIndex: 'name',
    render: () => 'Yard'
  },
  {
    title: 'Branch',
    dataIndex: 'branch'
  }
];
