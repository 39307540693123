export default {
  default: [
    { name: 'email', label: 'Email', component: 'input' },
    {
      name: 'password',
      label: 'Password',
      component: 'input',
      type: 'password'
    }
  ],
  forgotPassword: [{ name: 'forgotEmail', label: 'Email', component: 'input' }],
  license: [
    {
      autoFocus: true,
      name: 'pin',
      label: 'PIN',
      component: 'input',
      type: 'number',
      pattern: '[0-9]*',
      'data-keypad': 'number',
      normalize: value => value.replace(/[^0-9]/g, '').substr(0, 4)
    }
  ]
};
