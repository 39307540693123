import { transformAll, transformStats } from './transform';
import table from './table';

export default {
  title: 'Single Student Record',
  table,
  get: {
    name: 'singleStudentRecord',
    path: 'reports/student_hours',
    transform: transformAll,
    defaultValue: []
  },
  getStats: {
    name: 'singleStudentStats',
    path: '/students/:id',
    transform: transformStats,
    defaultValue: {}
  },
  getTotalHours: {
    name: 'getTotalHours',
    path: 'attendance/hours/:id',
    transform: ({ data }) => {
      return {
        totalHours: (Number(data?.attendanceHours[0]?.totalHoursTrained) || 0).toFixed(1),
        totalHoursBreakdown: (Number(data?.attendanceHours[0]?.totalHoursBreakdown) || 0).toFixed(
          1
        ),
        categoriesTotals: data?.attendanceHours[1]
      };
    },
    defaultValue: 0
  },
  studentRecordPdfPath: 'reports/student_records/print'
};
