import React, { useRef } from 'react';
import { Input, Form } from 'antd';
import styled from 'styled-components';
import { textInputValueWithoutEmoji } from '_src/utils';

const { TextArea } = Input;

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

export default ({ error, touched, label, onChange, name = '', dataCy = '', ...rest }) => {
  const hasError = error && touched;
  const textareaRef = useRef(null);

  const onLabelClick = () => {
    if (textareaRef.current) {
      textareaRef.current?.focus();
    }
  };

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      {(label || hasError) && (
        <Label onClick={onLabelClick} hasError={hasError}>
          {hasError ? error : label}
        </Label>
      )}
      <TextArea
        ref={textareaRef}
        {...rest}
        onChange={e => onChange && onChange(textInputValueWithoutEmoji(e.target.value))}
        data-cy={dataCy || 'field-textarea-' + name}
      />
    </Form.Item>
  );
};
