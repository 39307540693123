import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { useFetch } from 'store';

import services from '_src/services';
import { Button, Category } from '_src/ui';
import {
  Container,
  Header,
  Title,
  Subtitle,
  Description,
  TitlesContainer,
  InfoContainer,
  Categories,
  Evaluations
} from './styles';
import Evaluation from './Evaluation';

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const { GREEN } = GLOBALS.colors;

const MoreInfo = ({
  title = 'Pre-trip',
  subtitle = 'More Information',
  description = 'Based on student’s previous activity in this section',
  studentId = 2,
  type,
  onCloseClick
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState({});
  const config = services['reports'];
  const { get: getQuestions } = useFetch(config.getTestingQuestions);
  const sectionTitles = GLOBALS.testing.sections[type];

  const performanceNameToId = {
    UNSUCCESFUL: 0,
    'NEEDS IMPROVEMENT': 1,
    SATISFACTORY: 2,
    PROFICIENT: 3,
    EMPTY: -1
  };

  useEffect(() => {
    getQuestions({
      params: {
        studentId,
        type: type
      }
    }).then(data => {
      const groupBySection = groupBy('sectionType');
      const sections = groupBySection(data);
      setSections(sections);
      const categories = Object.keys(sections).map(key => {
        return {
          id: key,
          title: sectionTitles[key] ? sectionTitles[key].label : '',
          subtitle: '',
          performance: sections[key][sections[key].length - 1].lastPerformance || 'EMPTY',
          createdAt: sections[key][sections[key].length - 1].createdAt
        };
      });
      const filteredCategories = categories.filter(({ title }, index) => title != '');
      setCategories(filteredCategories);
      setSelectedCategory(categories[0].id);
    });
  }, []);
  const hasCategories = categories.length > 0;

  return (
    <Modal width={'100%'} visible footer={null}>
      <Container>
        <Header>
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Description>{description}</Description>
          </div>
          <Button width={83.5} height={40} color={GREEN} text="CANCEL" onClick={onCloseClick} />
        </Header>
        <TitlesContainer hasCategories={hasCategories}>
          {hasCategories && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                maxWidth: '300px'
              }}
            >
              Category
            </div>
          )}
          <div style={{ flex: 1, minWidth: '300px' }}>Evaluation</div>
          <div style={{ width: '210px' }}>Performance</div>
          <div />
          <div style={{ width: '200px', textAlign: 'center' }}>Status</div>
        </TitlesContainer>
        <InfoContainer hasCategories={hasCategories}>
          {hasCategories && (
            <Categories>
              {categories.map(({ title, subtitle, performance, id }) => (
                <div style={{ flex: 1, maxWidth: '300px' }}>
                  <Category
                    key={id}
                    title={title}
                    subtitle={subtitle}
                    performance={performanceNameToId[performance]}
                    selected={selectedCategory === id}
                    onClick={() => {
                      setSelectedCategory(id);
                    }}
                  />
                </div>
              ))}
            </Categories>
          )}
          {sections[selectedCategory] && (
            <Evaluations>
              {sections[selectedCategory].map(
                ({ question, lastPerformance, performanceStatus, id, createdAt }, index) => (
                  <Evaluation
                    key={id}
                    title={question}
                    performance={lastPerformance}
                    index={index}
                    status={performanceStatus}
                    createdAt={createdAt}
                  />
                )
              )}
            </Evaluations>
          )}
        </InfoContainer>
      </Container>
    </Modal>
  );
};

export default MoreInfo;
