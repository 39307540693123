import styled from 'styled-components';

const PageTitle = styled.p`
  font-family: 'SF Display Heavy';
  margin-bottom: 20px;
`;

const Label = styled.div`
  margin-bottom: 5px;
`;

const Search = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-end;

  button {
    align-self: flex-end;
    margin-left: 20px;
  }
`;

export { PageTitle, Label, Search };
