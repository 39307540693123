export default [
  { name: 'firstName', label: 'First name', component: 'input' },
  { name: 'lastName', label: 'Last name', component: 'input' },
  { name: 'contactNumber', label: 'Contact Number', component: 'input' },
  {
    name: 'branch',
    label: 'Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    showSearch: true
  },
  { name: 'completionDate', label: 'Completion date', component: 'date' },
  { name: 'studentNumber', label: 'License', component: 'input' },
  {
    name: 'licenseState',
    label: 'Driver license state',
    component: 'select',
    options: GLOBALS.states
  },
  { name: 'location', label: 'Location', component: 'input', disabled: true },
  { name: 'birthdate', label: 'Birthdate', component: 'date' },
  {
    name: 'status',
    label: 'Status',
    component: 'select',
    options: [
      { id: 'success', label: 'Successful' },
      { id: 'pending', label: 'Pending' },
      { id: 'declined', label: 'Declined' },
      { id: 'fail', label: 'Fail', disabled: true }
    ]
  },
  {
    name: 'endorsementCode',
    label: 'Endorsement Code',
    component: 'select',
    options: [
      { id: 'A', label: 'A' },
      { id: 'B', label: 'B' }
    ]
  },
  {
    name: 'road',
    label: 'Road',
    component: 'input',
    type: 'number'
  },
  { name: 'skills', label: 'Skills', component: 'input', type: 'number' },
  { name: 'score', label: 'Classroom Score', component: 'input', type: 'number' }
];
