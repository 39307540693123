export default [
  {
    title: 'Pre Trip Question',
    key: 'question',
    dataIndex: 'question'
  },
  {
    title: 'Section',
    key: 'sectionType',
    dataIndex: 'sectionType',
    render: sectionType => {
      const sectionName = GLOBALS.testing.sections[1].find(section => section.id == sectionType);
      return sectionName ? sectionName.label : '';
    }
  }
];
