import React from 'react';
import { SignaturePad } from '_src/components';

export default ({ label, error, onChange }) => {
  return (
    <SignaturePad
      label={label}
      error={error ? error : ''}
      onChange={signature => onChange(signature)}
    />
  );
};
