import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { Modal } from '_src/components';
import userPlaceholder from '_src/assets/images/user-placeholder.png';

const ProfileImage = styled.img`
  height: 200px;
  max-width: 100%;
  object-fit: cover;
  margin-top: 10px;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({ value, onChange, label }) => {
  const [isCameraShown, setCameraVisibility] = useState(false);
  const videoRef = useRef(null);
  const [videoStream, setVideoStream] = useState();

  const openCamera = () => {
    setCameraVisibility(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setVideoStream(stream);
      });
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');

    canvas.width = video.width;
    canvas.height = video.height;

    document.querySelector('body').appendChild(canvas);

    canvas.getContext('2d').drawImage(video, 0, 0, video.width, video.height);
    const snapshot = canvas.toDataURL('image/png');
    canvas.parentNode.removeChild(canvas);

    video.pause();
    video.src = '';
    videoStream.getTracks() && videoStream.getTracks()[0].stop();
    setVideoStream(undefined);
    setCameraVisibility(false);
    onChange(snapshot);
  };

  const cleanUp = () => {
    if (videoStream) {
      videoStream.getTracks() && videoStream.getTracks()[0].stop();
    }
    setCameraVisibility(false);
  };

  return (
    <div>
      <div>{label}</div>

      <ProfileImage onClick={openCamera} src={value ? value : userPlaceholder} />

      <VideoContainer>
        <Modal
          visible={isCameraShown}
          title="Change student profile picture"
          onCancel={cleanUp}
          okText="Take picture"
          onOk={takePhoto}
          style={{ display: 'flex' }}
        >
          <VideoContainer>
            <video ref={videoRef} width="400" height="300" autoPlay />
          </VideoContainer>
        </Modal>
      </VideoContainer>
    </div>
  );
};
