import schema from './schema';
import fields from './fields';

import { isEmail } from '_src/utils';

export default {
  form: { fields, schema },
  save: {
    path: 'login',
    transform: ({ email, password }) => ({
      [isEmail(email) ? 'email' : 'username']: email.toLowerCase(),
      password
    })
  },
  saveWithLicense: {
    path: 'login',
    transform: ({ pin, license }) => ({ pin, license })
  },
  forgotPassword: {
    path: 'users/forgot_password',
    method: 'post'
  }
};
