import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  padding: 25px;
  overflow: hidden;

  .ant-table-wrapper {
    margin-top: 25px;
    overflow-x: scroll;
  }

  .ant-card-body {
    width: 100%;
  }

  ul {
    padding-inline-start: 10px;
  }
  .float-right {
    float: right;
  }
`;

const ActionRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Space = styled.div`
  margin: ${({ vertical = 10, horizontal = 10 }) => `${vertical}px ${horizontal}px`};
`;

const PageWrapper = styled.div`
  width: 100%;
  padding: 25px;
  overflow: hidden;
  background-color: #fff;
  font-family: 'SF Display Regular';
`;

export { PageContainer, ActionRow, Space, PageWrapper };
