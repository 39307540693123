import React, { useContext, useState, useCallback } from 'react';

import { Context, useSave, useGet } from 'store';
import { Card, Button, notification, Icon } from 'antd';

import { PageContainer } from '_src/shared/styles';
import { Input } from '_src/shared/form-helpers';
import { debounce } from '_src/utils';
import services from '_src/services';
import { Refresh, AsyncTable, AsyncModal } from '_src/components';

import { camelToSnakeCase } from '../../utils/helpers';

import { ButtonContainer } from './styles';
import './styles.css';

export default () => {
  const { setShown, refreshResource } = useContext(Context);

  const config = services['users'];
  const resource = 'users';
  const branchesConfig = services['branches'];
  const [initialValues, setInitialValues] = useState({});
  const [fullName, setFullName] = useState(null);
  const [filterParams, setFilterParams] = useState({});
  const [previousFilters, setPreviousFilters] = useState({});

  const { save: reactivateUser } = useSave(config.activateUser);

  const { data: allGroups } = useGet({
    ...config.getGroups
  });

  const { data: allBranches } = useGet({
    ...config.getBranches
  });

  const debounceCallbackName = useCallback(
    debounce(
      params => {
        const isEmail = params.includes('@');
        setFilterParams({ fullName: isEmail ? '' : params, email: isEmail ? params : '' });
      },
      1000,
      false
    ),
    []
  );

  const handleReactivate = currentStudent => {
    reactivateUser({ id: currentStudent.id })
      .then(res => {
        if (res.success === 1) {
          refreshResource('users');
          notification.success({
            message: `Student with username '${currentStudent.username}' successfully reactivated!`,
            duration: 5
          });
        }
        if (res.success === 0) {
          notification.error({
            message: 'An error has occurred',
            duration: 5
          });
        }
      })
      .catch(err => {});
  };

  const onRowClick = record => {
    if (!record.deletedAt) {
      setInitialValues({ ...record });
      setShown(resource);
    }
  };

  const onFiltersChange = function (pagination, filters, sorter, extra) {
    if (sorter && sorter.columnKey) {
      const columnMappings = {
        'group.name': 'group',
        'branchData.name': 'branch'
      };

      const column = columnMappings[sorter.columnKey] || sorter.columnKey;
      const currentSort = {
        orderBy: camelToSnakeCase(column),
        order: sorter.order === 'descend' ? 'desc' : 'asc'
      };
      setFilterParams({
        ...filterParams,
        ...currentSort
      });
    }

    if (filters && JSON.stringify(previousFilters) !== JSON.stringify(filters)) {
      const currentFilters = {};
      if (filters['group.name'] && filters['group.name'].length > 0) {
        currentFilters.groupIds = filters['group.name'].join(',');
      }
      if (filters['branchData.name'] && filters['branchData.name'].length > 0) {
        currentFilters.branchIds = filters['branchData.name'].join(',');
      }
      setFilterParams({
        ...currentFilters
      });
      setPreviousFilters(filters);
    }
  };

  return (
    <PageContainer>
      <Refresh />
      <Card title="Users">
        <ButtonContainer>
          <div style={{ display: 'flex', gap: 15 }}>
            <Input
              size="large"
              label="Filter by name or email"
              onChange={e => {
                setFullName(e);
                debounceCallbackName(e);
              }}
              value={fullName}
              style={{ width: '300px' }}
              prefix={<Icon type="search" style={{ color: '#909090' }} />}
            />
          </div>

          <Button
            type="primary"
            onClick={() => {
              setInitialValues({});
              setShown(resource);
            }}
          >
            Create User
          </Button>
        </ButtonContainer>
        <AsyncTable
          resource={resource}
          rowClassName={record => (record.deletedAt ? 'redRow' : '')}
          pagination
          config={{
            ...config,
            table: [
              ...config.table,
              {
                title: 'Group',
                dataIndex: 'group.name',
                sorter: true,
                filters: allGroups
              },
              {
                title: 'Current branch',
                dataIndex: 'branchData.name',
                sorter: true,
                filters: allBranches
              },
              {
                title: '',
                render: (_, student) => {
                  return student.deletedAt ? (
                    <a onClick={event => event.stopPropagation()}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleReactivate(student);
                        }}
                      >
                        Re-activate user
                      </Button>
                    </a>
                  ) : (
                    ''
                  );
                }
              }
            ]
          }}
          fileName={resource}
          onRowClick={onRowClick}
          params={filterParams}
          resetPageOn={['email', 'fullName']}
          onChange={onFiltersChange}
        />
        <AsyncModal
          showDelete={true}
          saveText="Submit"
          title="Save User"
          resource={resource}
          closeModal={() => setShown(resource, false)}
          initialValues={initialValues}
        ></AsyncModal>
        <AsyncModal
          showDelete={true}
          saveText="Submit"
          title="Save User"
          resource={resource}
          closeModal={() => setShown(resource, false)}
          initialValues={initialValues}
        ></AsyncModal>
      </Card>
    </PageContainer>
  );
};
