import React, { useRef } from 'react';
import { Input, Form } from 'antd';
import styled from 'styled-components';
import { textInputValueWithoutEmoji } from '_src/utils';

const Label = styled.div`
  margin-bottom: ${({ inline }) => (inline ? '0' : '5px')};
  margin-right: ${({ inline }) => (inline ? '5px' : '0')};
  min-width: ${({ inline }) => (inline ? '30%' : '0')};
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

const Container = styled.div`
  display: ${({ inline }) => (inline ? 'flex' : 'block')};
  align-items: center;
`;

export default ({
  value = '',
  error,
  onChange,
  onBlur = {},
  touched,
  label,
  inline = false,
  dataCy = '',
  name = '',
  placeholder = '',
  ...rest
}) => {
  const hasError = error && touched;
  const inputRef = useRef(null);

  const onLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.input?.focus();
    }
  };

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      <Container inline={inline} onClick={onLabelClick}>
        {(label || hasError) && (
          <Label inline={inline} hasError={hasError}>
            {hasError ? error : label}
          </Label>
        )}
        <Input
          {...rest}
          ref={inputRef}
          value={value}
          data-cy={dataCy || 'field-input-' + name}
          placeholder={label || placeholder}
          onChange={e => onChange(textInputValueWithoutEmoji(e.target.value))}
          onBlur={e => {
            if (typeof onBlur === 'function') {
              onBlur(e.target.value);
            }
          }}
        />
      </Container>
    </Form.Item>
  );
};
