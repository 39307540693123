import table from './table';
import { transformDuplicates } from './transform';

export default {
  table,
  getDuplicates: {
    path: 'students/sf/merge_check/:id',
    transform: transformDuplicates
  },
  mergeDuplicates: {
    path: 'students/sf/merge'
  }
};
