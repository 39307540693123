import { transformAll } from './transform';
import table from './table';

export default {
  get: {
    name: 'studentIntervention',
    path: 'student_intervention',
    transform: transformAll,
    defaultValue: []
  },
  table,
  download: {
    name: 'downloadIntervention',
    path: 'student_intervention'
  }
};
