import React, { useState, useEffect } from 'react';
import { Provider, State } from 'store';
import * as Sentry from '@sentry/browser';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { notification } from 'antd';

import { PersistentRepo } from '_src/shared/repo';
import { objToCamelCase, objToSnakeCase } from '_src/utils';
import { RestrictProvider } from '_src/shared/restrict';

import { Router, GlobalStyles, ChangePassword } from './components';
import RotateDevice from './components/RotateDevice';
import 'antd/dist/antd.css';

const { version } = require('../../../package.json');

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://fde264c2b09f407d877fe1ff9f0c65c2@sentry.io/1426086',
    environment: GLOBALS.ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Network Error']
  });
}

export default () => {
  const [orientation, setOrientation] = useState('');
  const [currentVersion, setCurrentVersion] = useState(version);
  const token = PersistentRepo.get('token');
  const privileges = PersistentRepo.get('privileges');
  const user = PersistentRepo.get('user');
  const defaultState = { authenticated: !!token, token, privileges, attendance: [], user };

  useEffect(() => {
    const newVersionAvailable = version !== currentVersion;

    newVersionAvailable &&
      notification.info({
        message: 'New version is available, please reload to get new update !',
        duration: 15
      });
  }, [currentVersion]);

  const handleResponses = data => {
    if (token && data.code === 401) {
      PersistentRepo.clear();
      window.location.reload();
    }

    if (process.env.NODE_ENV === 'production') {
      const getUrl = window.location.href.split('/')[2];
      const frontEndUrl = `https://${getUrl}`;

      fetch(`${frontEndUrl}/manifest.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        cache: 'reload'
      })
        .then(response => {
          return response.json();
        })
        .then(res => {
          const newVersion = res.version;
          if (version !== newVersion) {
            setCurrentVersion(newVersion);
          }
        })
        .catch(error => console.log(error));
    }

    return objToCamelCase(data);
  };

  return (
    <DeviceOrientation
      lockOrientation={'landscape'}
      onOrientationChange={(orientation, type, angle) => {
        setOrientation(orientation);
      }}
    >
      <Orientation orientation="landscape" alwaysRender={true}>
        <GlobalStyles />
        <Provider
          apiUrl={GLOBALS.BASE_API}
          data={defaultState}
          headers={({ token }) => ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          })}
          beforeGet={objToSnakeCase}
          afterGet={handleResponses}
          beforeSave={objToSnakeCase}
          afterSave={handleResponses}
        >
          <State name="privileges">
            {userPrivileges => {
              return (
                <RestrictProvider privileges={userPrivileges}>
                  <Router />
                  <ChangePassword />
                </RestrictProvider>
              );
            }}
          </State>
        </Provider>
        {orientation === 'portrait' && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && (
          <RotateDevice />
        )}
      </Orientation>
    </DeviceOrientation>
  );
};
