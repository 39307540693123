import {
  transformAll,
  transformBeforeStudentUpdate,
  transformAllReports,
  transformStaffSubmissions
} from './transform';
import table from './table';
import reportsTable from './reportsTable';
import staffSubmissionsTable from './staffSubmissions';
import schema from './schema';
import fields from './fields';
import qs from 'qs';

const name = 'fmcsaSubmissions';
const path = 'student_infos';

export default {
  // restrict: 'driver_score/employers',
  // title: 'Employers list',
  restrict: false,
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  getUrl: {
    name: 'fmcsaReports',
    path: 'reports/fmcsa-data',
    transform: transformAllReports,
    defaultValue: [],
    paramsSerializer: params => qs.stringify({ ...params }, { arrayFormat: 'comma' })
  },
  getStaffSubmissions: {
    name: 'staffSubmissions',
    path: `${path}/new-submission_report`,
    transform: transformStaffSubmissions,
    defaultValue: [],
    paramsSerializer: params => qs.stringify({ ...params }, { arrayFormat: 'comma' })
  },
  download: {
    name: 'staffSubmissions',
    path: `reports/new-fmcsa-report`,
    method: 'post'
  },
  table,
  reportsTable,
  staffSubmissionsTable,
  modal: {
    title: 'Save Student',
    fields,
    schema,
    props: {
      width: '40%'
    },
    noColumns: 1
  },
  edit: {
    method: 'patch',
    path: `${path}/:id`,
    transform: transformBeforeStudentUpdate
  },
  editStudent: {
    method: 'patch',
    path: `${path}/:id`
  },
  submitStudent: {
    method: 'post',
    path: `${path}/submit`
  },
  getStudentsProficiency: {
    method: 'post',
    path: 'testing/proficiency'
  }
};
