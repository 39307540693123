import React, { useContext } from 'react';
import { Context } from 'store';
import moment from 'moment';

const Location = ({ branchId, locationType }) => {
  const { getData } = useContext(Context);
  const locations = getData('locations') || [];
  const location = locations.find(loc => branchId === loc.branchId && locationType === loc.type);

  return location ? location.label : '';
};

export default [
  {
    title: 'Student',
    dataIndex: 'name',
    key: 'name',
    render: value => {
      return (
        <>
          <p>{value}</p>
        </>
      );
    },
    sorter: true
  },
  { title: 'Status', dataIndex: 'status', sorter: true },
  {
    title: 'Yard Start Date',
    dataIndex: 'yardStartDate',
    key: 'yardStartDate',
    render: yardStartDate => (!!yardStartDate ? moment(yardStartDate).format('MM/DD/YYYY') : ''),
    sorter: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, { branchId, locationType }) => (
      <Location branchId={branchId} locationType={locationType} />
    )
  },
  {
    title: 'Groups',
    dataIndex: 'groups',
    render: e => (e ? <p>{e.join(' ')}</p> : '')
  }
];
