export default [
  {
    name: 'branchIds',
    label: 'Select Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    mode: 'multiple',
    showSearch: true
  },
  {
    name: 'question',
    label: 'Skill Question',
    component: 'input'
  },
  {
    name: 'sectionType',
    label: 'Section',
    component: 'select',
    options: GLOBALS.testing.sections[3]
  }
];
