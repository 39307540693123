import styled from 'styled-components';

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #707070;
  position: relative;

  ${({ value, type, disabled }) =>
    typeof value === 'boolean' &&
    value === !!type &&
    `
    border-color: ${value ? '#5ab55b' : '#EF5350'};
   
    &:after {
        content: '';
        background: ${value ? '#5ab55b' : '#EF5350'};
        width: 12px;
        height: 12px;
        position: absolute;
        left: 2px;
        top: 2px;
        
    }
  `}
`;

const RadionOption = styled.div`
  display: flex;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-family: 'SF Display Bold';
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;

  ${({ value, type, disabled }) => {
    const isActive = typeof value === 'boolean' && value === !!type;

    return isActive ? `color: ${value ? '#5ab55b' : '#3B3B3B'};` : '';
  }};
`;

const RadioContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 30px;
`;

export { StyledRadio, RadionOption, Label, RadioContainer };
