import React, { useState, useRef } from 'react';
import { Avatar } from 'antd';

import { Modal } from '_src/components';

import { ProfileImage, VideoContainer, ImageContainer, DeleteButton, Container } from './styles';

export default ({
  value,
  onChange,
  editable = false,
  fixed = true,
  showDelete = false,
  onDelete = () => {}
}) => {
  const [isCameraShown, setCameraVisibility] = useState(false);
  const [isDeletePictureShown, showDeletePictureModal] = useState(false);

  const videoRef = useRef(null);
  const [videoStream, setVideoStream] = useState();

  const openCamera = () => {
    if (editable) {
      setCameraVisibility(true);
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then(stream => {
            if (!videoRef.current) {
              return;
            }
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setVideoStream(stream);
          });
      }
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');

    canvas.width = video.width;
    canvas.height = video.height;

    document.querySelector('body').appendChild(canvas);

    canvas.getContext('2d').drawImage(video, 0, 0, video.width, video.height);
    const snapshot = canvas.toDataURL('image/png');
    canvas.parentNode.removeChild(canvas);

    video.pause();
    video.src = '';
    if (videoStream) {
      videoStream.getTracks() && videoStream.getTracks()[0].stop();
    }
    setVideoStream(undefined);
    setCameraVisibility(false);
    onChange(snapshot);
  };

  const cleanUp = () => {
    if (videoStream) {
      videoStream.getTracks() && videoStream.getTracks()[0].stop();
    }
    setCameraVisibility(false);
  };

  const deletePicture = () => {
    showDeletePictureModal(false);
    onDelete();
  };

  return (
    <div>
      <Container>
        {value && showDelete && <DeleteButton onClick={() => showDeletePictureModal(true)} />}
        <Modal
          visible={isDeletePictureShown}
          title="Delete student profile picture"
          onCancel={() => showDeletePictureModal(false)}
          okText="Delete picture"
          onOk={deletePicture}
          style={{ display: 'flex' }}
        >
          Are you sure you want to remove this photo?
        </Modal>
      </Container>
      <ImageContainer onClick={openCamera} fixed={fixed}>
        {value && <ProfileImage src={value} />}
        {!value && <Avatar size={80} icon="user" />}
      </ImageContainer>

      <VideoContainer>
        <Modal
          visible={isCameraShown}
          title="Change student profile picture"
          onCancel={cleanUp}
          okText="Take picture"
          onOk={takePhoto}
          style={{ display: 'flex' }}
        >
          <VideoContainer>
            <video ref={videoRef} width="400" height="300" autoPlay />
          </VideoContainer>
        </Modal>
      </VideoContainer>
    </div>
  );
};
