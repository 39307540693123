import React from 'react';
import { DatePicker, Form } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

export default ({ error, touched, label, defaultValue, onChange, ...rest }) => {
  const hasError = error && touched;

  const [start, end] = defaultValue;

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      {(label || hasError) && <Label hasError={hasError}>{hasError ? error : label}</Label>}
      <RangePicker format="MM/DD/YYYY" defaultValue={[start, end]} {...rest} onChange={onChange} />
    </Form.Item>
  );
};
