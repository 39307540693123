export default [
  { name: 'username', label: 'Username', component: 'viewOnly' },
  { name: 'groupName', label: 'Group', component: 'viewOnly' },
  { name: 'email', label: 'Email', component: 'input' },
  { name: 'firstName', label: 'First Name', component: 'input' },
  { name: 'lastName', label: 'Last Name', component: 'input' },
  {
    name: 'branchId',
    label: 'Current Branch',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    showSearch: true
  },
  { name: 'pin', label: 'Pin', component: 'input', type: 'password' }
];
