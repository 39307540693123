import React from 'react';
import { Modal } from 'antd';

const modalHeight = Math.floor(window.innerHeight - 100);

export default ({ children, bodyStyle = {}, ...rest }) => {
  return (
    <Modal
      {...rest}
      maskClosable={false}
      bodyStyle={{ ...bodyStyle, maxHeight: `80vh`, overflow: 'scroll' }}
      style={{ top: 50 }}
      destroyOnClose
    >
      {children}
    </Modal>
  );
};
