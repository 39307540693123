import React from 'react';

import { Container, Title, ItemsNo } from './styles';

const { RED, ORANGE, YELLOW, GREEN, GREY } = GLOBALS.colors;

const colorConfig = {
  0: RED,
  1: ORANGE,
  2: YELLOW,
  3: GREEN,
  '-1': GREY
};

const Category = ({
  performance,
  title = '',
  subtitle = '',
  selected = false,
  onClick = () => {}
}) => (
  <Container
    color={colorConfig[performance]}
    onClick={onClick}
    selected={selected}
  >
    <Title color={colorConfig[performance]}>{title}</Title>
    <ItemsNo>{subtitle}</ItemsNo>
  </Container>
);

export default Category;
