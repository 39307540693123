import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  z-index: 99999;
  background-color: #001529;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 34px;
  color: #ff8400;
  font-family: 'SF Display Heavy';
`;

function RotateDevice() {
  return (
    <Wrapper>
      <p>Please rotate your device</p>
    </Wrapper>
  );
}

export default RotateDevice;
