import styled from 'styled-components';

const ConfirmGraduationFormContainer = styled.div`
  display: grid;
  grid-gap: 0 50px;
  grid-template-columns: 1fr 1fr;
`;

const NoteSignaturePanel = styled.div`
  display: grid;
  grid-gap: 25px 0;
`;

export { ConfirmGraduationFormContainer, NoteSignaturePanel };
