import React from 'react';
import moment from 'moment';

import { Container, Title, List, Info, Total, ListRow } from './styles';

const findTotalHours = (sessions, type) => {
  if (type === 'Hour') {
    const hours = sessions
      .filter(({ checkIn, checkOut }) => !!checkIn && !!checkOut)
      .map(({ checkIn, checkOut }) => {
        const checkInTime = moment(`1970-01-01 ${checkIn.trim()}`);
        const checkOutTime = moment(`1970-01-01 ${checkOut.trim()}`);
        const duration = moment.duration(checkOutTime.diff(checkInTime)).asHours();

        return duration < 0 ? 24 - Math.abs(duration) : duration;
      })
      .reduce((acc, item) => acc + item, 0);

    return Math.round(hours * 100) / 100;
  }
  if (type === 'Odometer') {
    return sessions
      .reduce(
        (acc, { odometerStart = 0, odometerEnd = 0 }) =>
          acc + parseFloat(odometerEnd) - parseFloat(odometerStart),
        0
      )
      .toFixed(2);
  }

  return 0;
};

const config = {
  Hour: {
    unit: {
      single: 'Attendance Hour',
      multiple: 'Total Attendance Hours'
    },
    sessionTitle: 'Sessions:',
    leftKey: 'checkIn',
    rightKey: 'checkOut'
  },
  Odometer: {
    unit: {
      single: 'Mile',
      multiple: 'Miles'
    },
    sessionTitle: 'Odometer:',
    leftKey: 'odometerStart',
    rightKey: 'odometerEnd'
  }
};

const SessionSection = ({ sessionType, sessionData, studentEvaluations }) => {
  const { unit = '', sessionTitle = '', leftKey, rightKey } = config[sessionType] || {};
  const currentUrl = window.location.href;
  return (
    <Container>
      {(sessionData.length > 0 || !!studentEvaluations) && <Title> {sessionTitle}</Title>}
      <Info>
        <List singleCol={sessionData.length === 1}>
          <ul>
            {sessionData.length > 0 &&
              sessionData.map((row, index) => (
                <ListRow key={index}>
                  {row[leftKey]} - {row[rightKey] || ''}
                  {sessionType === 'Odometer' && ' miles'}
                </ListRow>
              ))}
          </ul>
          {!!studentEvaluations && (
            <ListRow>
              <ul>
                <div>{studentEvaluations} hours driven</div>
              </ul>
            </ListRow>
          )}
        </List>
        {sessionData.length !== 0 && (
          <Total>{`${findTotalHours(sessionData, sessionType)} ${
            findTotalHours(sessionData, sessionType) === 1 ? unit.single : unit.multiple
          }`}</Total>
        )}
      </Info>
    </Container>
  );
};

export default SessionSection;
