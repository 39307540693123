import styled from 'styled-components';

const { ORANGE, DARK_TEXT, GREY_TEXT } = GLOBALS.colors;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 45px 60px 19px;
`;

const Header = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 19px;
  margin-bottom: 31px;
`;

const Title = styled.div`
  font-size: 21px;
  color: ${ORANGE};
  font-family: 'SF Display Heavy';
`;

const Subtitle = styled.div`
  font-size: 16.5px;
  color: ${DARK_TEXT};
  font-weight: bold;
  font-family: 'SF Display Bold';
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${GREY_TEXT};
  font-family: 'SF Display Bold';
`;

const TitlesContainer = styled.div`
  align-self: stretch;
  margin-left: 19px;
  display: flex;
  flex-direction: row;
  div {
    font-size: 14.5px;
    font-weight: bold;
    color: #5f5e5e;
    text-align: left;
    font-family: 'SF Display Bold';
  }
`;

const InfoContainer = styled.div`
  margin-top: 30px;
  margin-left: 19px;
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Categories = styled.div`
  flex: 1;
  max-width: 300px;
`;
const Evaluations = styled.div`
  flex: 1;
`;

export {
  Container,
  Header,
  Title,
  Subtitle,
  Description,
  TitlesContainer,
  InfoContainer,
  Categories,
  Evaluations
};
