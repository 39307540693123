import { transformCrmBranches } from './transform';
import timezoneJson from './timezones';
export default ({ id }) => [
  { name: 'name', label: 'Name', component: 'input' },
  {
    name: 'regionId',
    label: 'Region',
    component: 'select',
    async: true,
    resource: 'regions',
    labelKey: 'name',
    showSearch: true
  },
  { name: 'city', label: 'City', component: 'input' },
  { name: 'state', label: 'State', component: 'input' },
  { name: 'zip', label: 'Zip', component: 'input' },
  { name: 'streetAddress', label: 'Street Address', component: 'input' },
  {
    name: 'yardId',
    label: 'Select the branch whose yard you want to use',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    options: [{ id: -1, name: 'Self' }],
    hide: [id],
    showSearch: true
  },
  {
    name: 'classroomId',
    label: 'Select the branch whose classroom you want to use',
    component: 'select',
    async: true,
    resource: 'branches',
    labelKey: 'name',
    options: [{ id: -1, name: 'Self' }],
    hide: [id],
    showSearch: true
  },
  {
    name: 'crmBranchId',
    label: 'Select salesforce branch',
    component: 'select',
    async: true,
    get: {
      name: 'crm_branches',
      path: 'crm_branches',
      transform: transformCrmBranches,
      defaultValue: []
    },
    labelKey: 'name',
    showSearch: true
  },
  {
    name: 'timezone',
    label: 'Select timezone',
    component: 'select',
    options: timezoneJson,
    showSearch: true
  }
];
