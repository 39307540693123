import React, { useContext, useRef, useState } from 'react';
import { Form } from 'form';
import { Context, useSave } from 'store';
import { Card, Button, notification } from 'antd';

import { Fields } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Refresh } from '_src/components';
import { ButtonContainer } from './styles';
export default () => {
  const config = services.profile;
  const formApi = useRef(null);
  const { getData, setData } = useContext(Context);
  const user = config.transformUser(getData('user'));

  const { save: updateProfile, busy } = useSave({
    ...config.save,
    validate: () => formApi.current.validate()
  });

  const submit = () => {
    const values = formApi.current.values();

    updateProfile(values)
      .then(response => handleSave(response))
      .catch(handleError);
  };

  const handleSave = ({ success, data }) => {
    if (success === 1) {
      const { user } = data;
      formApi.current.setFields({ pin: '' });
      setData('user', user);
      PersistentRepo.set('user', user);

      notification.success({
        message: 'Profile information updated successfully',
        duration: 3
      });
    } else {
      handleError();
    }
  };

  const handleError = () => {
    notification.error({
      message: 'Failed to update your profile information',
      duration: 3
    });
  };

  return (
    <PageContainer>
      <Refresh />
      <Card title="Profile">
        <Form
          formApi={form => (formApi.current = form)}
          schema={config.schema}
          initialValues={user}
        >
          <Fields fields={config.fields} />
        </Form>

        <ButtonContainer>
          <Button loading={busy} type="primary" onClick={submit}>
            Save profile information
          </Button>
        </ButtonContainer>
      </Card>
    </PageContainer>
  );
};
