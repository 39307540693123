import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useGet, Context, useSave, useFetch } from 'store';
import { Button as AntButton, message, notification, Skeleton } from 'antd';
import moment from 'moment';

import { Modal } from '_src/components';
import QRCode from 'qrcode.react';
import { Button } from '_src/ui';
import services from '_src/services';
import { isEmptyObj, dateToUnix } from '_src/utils';
import { Restricted } from '_src/shared/restrict';

import MoreInfo from '../Evaluations/MoreInfo';
import { StudentPerformanceEvaluator } from '_src/modules/Attendance/components';
import ProfileModal from '../ProfileModal';
import ConfirmGraduation from '../../../Reports/ConfirmGraduation';
import ProfilePicture from '_src/shared/form-helpers/ProfilePicture';
import { Checkbox, Input, Select } from '_src/shared/form-helpers';
import { useHistory } from 'react-router-dom';

import {
  Container,
  ProfileContainer,
  ReportContainer,
  BubbleTimelineContainer,
  DailyInfoContainer,
  Label,
  VerticalSeparator,
  Field,
  AbsenceStatus,
  HeadContainer,
  FormContainer,
  RegistrationDate,
  Column,
  DayDetail,
  StudentDetailContainer,
  StudentDetail,
  ModalTitle
} from './styles';

import {
  BubbleTimeline,
  NoAttendanceInfo,
  SessionSection,
  StudentInfo,
  PerformanceCardsContainer
} from './components';

import speedometer from '_src/assets/images/speedometer.png';

const buttonColor = GLOBALS.colors;

export default ({ rerenderEvaluations, isFmcsa = false, isHazmat = false }) => {
  const { setShown, getShown, getData, setData, refreshResource } = useContext(Context);
  const [showYardConfirmModal, setShowYardConfirmModal] = useState();
  const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState();
  const [showDoubleArchiveConfirmModal, setShowDoubleArchiveConfirmModal] = useState();
  const [selectedAttendance, selectAttendance] = useState({});
  const [moreInfoModalTitle, setMoreInfoModalTitle] = useState();
  const [moreInfoModalType, setMoreInfoModalType] = useState();
  const [testType, setTestType] = useState();
  const [refreshedAt, setRefereshedAt] = useState(null);
  const [milesData, setMilesData] = useState({});
  const [totalHoursDriven, setTotalHoursDriven] = useState('');
  const [studentEvaluationsData, setStudentEvaluations] = useState([]);
  const [testEvaluations, setTestEvaluations] = useState(null);
  const [isSubmitFmcsa, setIsSubmitFmcsa] = useState(false);
  const { sessions = [], absent } = selectedAttendance;

  const [hasStudentLessThan160Hrs, setHasStudentLessThan160Hrs] = useState(false);
  const [hasStudentFailedEveryTest, setHasStudentFailedEveryTest] = useState(false);
  const [studentNotReadyForGraduate, setStudentNotReadyForGraduate] = useState(false);
  const [busyInvite, setInviteBusy] = useState(false);
  const [disabledYard, setDisabledYard] = useState(false);
  const [disabledFmcsa, setDisabledFmcsa] = useState(false);
  const [selectedDateToArchive, setSelectedDateToArchive] = useState('all');
  const [showSelectDateConfirmModal, setShowSelectDateConfirmModal] = useState(false);
  const [typeOfArchive, setTypeOfArchive] = useState(null);
  const [image, setImage] = useState();
  const [loadedProfile, setLoadProfile] = useState(true);
  const [loadedPerformance, setLoadPerformance] = useState(true);
  const resource = 'studentTimeline';
  const resourceMoreInfo = 'resourceMoreInfo';
  const config = services['reports'];
  const attendanceConfig = services['attendance'];

  const student = getData('studentTimeline');
  const {
    id,
    name,
    lastName,
    totalHoursTrained,
    locationType,
    sponsor,
    programType,
    programHours,
    permitRecDate,
    studentBranch,
    endorsementCode,
    endorsement
  } = student;
  const [totalHoursFmcsa, setTotalHoursFmcsa] = useState(0);
  const [allYards, setAllYards] = useState([]);
  const [studentsYard, setStudentsYard] = useState(0);
  const permitRecDateBefore = moment(permitRecDate).parseZone().isSameOrBefore('2022-02-06', 'day');
  const { save: editStudent, busy: busyEditStudent } = useSave(attendanceConfig.editStudent);
  const { save: editArchiveStudent } = useSave(attendanceConfig.archiveStudent);
  const { save: updateProfilePicture } = useSave(attendanceConfig.updateProfilePicture);
  const { save: submitFmcsaAction, busy: busySubmitFmcsa } = useSave(attendanceConfig.submitFmcsa);
  const { save: sendOtpPass, busy: busyOtp } = useSave(attendanceConfig.sendOtpPassword);
  const { save: sendStudentIntervention, busy: busyStudentIntervention } = useSave(
    attendanceConfig.sendStudentIntervention
  );

  const { get: getLocations } = useFetch({ ...attendanceConfig.location.get });
  useEffect(() => {
    getLocations().then(data => {
      setAllYards(data);
      setStudentsYard(data.find(item => item.branchId === studentBranch)?.id || 0);
    });
  }, [id]);

  var timezone = moment().utcOffset() / 60;

  if (timezone > 0) {
    timezone = 'GMT ' + timezone;
  } else if (timezone < 0) {
    timezone = 'GMT' + timezone;
  } else if (timezone === 0) {
    timezone = 'GMT 0';
  }

  const history = useHistory();

  const { data: allAttendance, busy } = useGet({
    ...config.getAttendance,
    params: {
      studentId: id,
      timezone,
      regularAttendance: window.location.pathname !== '/studentAgreements'
    }
  });
  const { get: getMilesData } = useFetch({ ...config.getMiles });
  const { save: renewInvite } = useSave(attendanceConfig.renewInvite);
  const { get: getSchedule } = useFetch({ ...config.getStudentSchedule });
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });
  const [grade, setGrade] = useState(null);
  const [studentInfo, setStudentInfo] = useState({});
  const [errors, setError] = useState({});

  // qrcode
  const [qrValue, setQrValue] = useState('');

  const { get: studentToken } = useFetch({
    ...config.getTokenQr,
    params: { id }
  });

  useEffect(() => {
    // studentToken({ replace: { id } }).then(res => {
    //   res.filter(({ data }) =>
    //     setQrValue('https://truckersnetwork.app.link/mjnsNgrqFfb?qr_token=' + data.qrCode)
    //   );
    // });
  }, [id]);

  const attendance = allAttendance.filter(({ upcoming }) => !upcoming);

  const loadedProfileCallback = useCallback(
    (loadedProfile, totalHoursDriven, studentEvaluations, totalHoursTrainedFmcsa) => {
      setLoadProfile(loadedProfile);
      setTotalHoursDriven(totalHoursDriven);
      setStudentEvaluations(studentEvaluations);
      setTotalHoursFmcsa(totalHoursTrainedFmcsa);
    },
    []
  );

  const loadedPerformanceCallback = useCallback(loadedPerformance => {
    setLoadPerformance(loadedPerformance);
  }, []);

  const getStudentEvaluations = useCallback(testEvaluations => {
    setTestEvaluations(testEvaluations);
  }, []);

  useEffect(() => {
    getMilesData({ params: { studentId: id } }).then(res => {
      setMilesData(res);
    });
  }, [refreshedAt]);

  const getStudentSchedule = showYardConfirmModal => {
    getSchedule({ replace: { id: id } }).then(res => {
      setDays(res.days);
      setGrade(res.grade);
      setStudentInfo(res.studentInfo);
      if (showYardConfirmModal) {
        setShowYardConfirmModal(true);
      }
    });
  };

  const triggerRefresh = timestamp => {
    setRefereshedAt(timestamp);
  };

  const calculateTotalHoursTrained = data => {
    return data.reduce((totalHours, day) => {
      if (day.sessions && Array.isArray(day.sessions)) {
        const sessionHours = day.sessions.reduce((sessionTotal, session) => {
          return session.hoursTrained != null ? sessionTotal + session.hoursTrained : sessionTotal;
        }, 0);
        return totalHours + sessionHours;
      }
      return totalHours;
    }, 0);
  };

  const graduateStudent = () => {
    const performances = testEvaluations ? Object.values(testEvaluations) : [];
    const studentTotalHours = calculateTotalHoursTrained(allAttendance);
    let trainHoursCompletedVar = student.totalHoursTrained > programHours;
    let isProficientVar =
      !isEmptyObj(performances) &&
      !!performances.filter(item => item.currPerformance === 'PROFICIENT').length;

    if (!trainHoursCompletedVar) {
      if (student.totalHoursTrained < programHours && !isProficientVar) {
        setHasStudentLessThan160Hrs(false);
        setHasStudentFailedEveryTest(false);
        setStudentNotReadyForGraduate(true);
      } else {
        setHasStudentLessThan160Hrs(studentTotalHours < programHours);
        setHasStudentFailedEveryTest(!isProficientVar);
        setStudentNotReadyForGraduate(false);
      }
      setShown('continueGraduation');
    } else {
      setHasStudentLessThan160Hrs(false);
      setHasStudentFailedEveryTest(false);
      setStudentNotReadyForGraduate(false);
      setShown('StudentProfileModal');
    }
  };

  const renderTimeline = () => {
    if (attendance.length === 0) {
      return <NoAttendanceInfo />;
    }

    return (
      <BubbleTimeline
        attendance={allAttendance}
        onBubbleSelect={attendance => {
          selectAttendance(attendance);
        }}
      />
    );
  };

  const closeModal = () => {
    setShown(resource, false);
    setShown('fmcsaStudentTimeline', false);
    setShown('hazmatStudentTimeline', false);
  };

  const renderArchiveCancel = () => (
    <AntButton key="cancel" onClick={() => setShowArchiveConfirmModal(false)}>
      Cancel
    </AntButton>
  );

  const renderArchiveConfirmButton = record => (
    <AntButton
      key="confirmArchive"
      type="primary"
      onClick={() => setShowSelectDateConfirmModal(true)}
    >
      Archive
    </AntButton>
  );

  const renderDoubleArchiveCancel = () => (
    <AntButton
      key="cancel"
      onClick={() => {
        showDoubleArchiveConfirmModal
          ? setShowDoubleArchiveConfirmModal(false)
          : setShowSelectDateConfirmModal(false);
      }}
    >
      Cancel
    </AntButton>
  );

  const renderDoubleArchiveConfirmButton = record => (
    <AntButton
      key="confirmArchive"
      type="primary"
      onClick={() => {
        selectedDateToArchive === 'all' &&
        (!showDoubleArchiveConfirmModal || showDoubleArchiveConfirmModal === undefined)
          ? setShowDoubleArchiveConfirmModal(true)
          : archiveStudent(record.id);
      }}
    >
      Archive
    </AntButton>
  );

  const renderClassroomScore = () =>
    !permitRecDateBefore && (
      <Input
        name="grade"
        label="Classroom grade"
        placeholder="Classroom grade"
        value={grade}
        onChange={grade => {
          const numberGrade = Number(grade);
          grade ? setGrade(numberGrade) : setGrade(null);
          setError({
            grade: numberGrade < 80 || numberGrade > 100 ? 'Grade must be between 80 and 100' : null
          });
        }}
        error={errors.grade ? errors.grade : ''}
        touched
        type="number"
        style={{ marginBottom: 20 }}
      />
    );

  const moveToYard = ({ id: studentId, name, attendance, classOnly, classSubmittedToFmsca }) => {
    const [locationType, locationId, branchId] = studentsYard.split('x');
    let payload = {
      id: studentId,
      ...days,
      branchId: +branchId,
      ...(!classOnly && { locationId: studentBranch, locationType: 1 })
    };
    if (attendance.checkIn && !attendance.checkOut) {
      notification.error({
        message: 'Failed to move student to yard',
        description: 'You have to checkout the student before being able to move them to yard',
        duration: 5
      });
    } else if (!studentInfo.classSubmittedToFmsca) {
      notification.error({
        message: 'Failed to move student to yard',
        description:
          'You have to submit the student for theory before being able to move them to yard',
        duration: 5
      });
    } else if (!errors.grade) {
      editStudent(payload).then(res => {
        if (res.success === 1) {
          setShowYardConfirmModal(false);
          setShown('studentTimeline', false);
          rerenderEvaluations();
          setDisabledYard(true);
          setShown('confirm', false);
          notification.success({
            message: `Successfully moved ${name} to yard`,
            duration: 5
          });
        }
        if (res.success === 0) {
          notification.error({
            message: 'Failed to move student to yard',
            description: res.data.message,
            duration: 5
          });
        }
      });
    }
  };

  const submitFmcsa = ({ id: studentId, name, classOnly, locationType }) => {
    if (locationType === 2 && (errors.grade || !grade)) {
      const numberGrade = Number(grade);
      setError({
        grade: numberGrade < 80 || numberGrade > 100 ? 'Grade must be between 80 and 100' : null
      });
      return;
    }
    const submit = () => {
      const [locationType, locationId, branchId] = studentsYard.split('x');
      let payload = {
        studentId: student.id,
        branchId: +branchId,
        grade: grade,
        ...(!classOnly && { locationId: studentBranch, locationType: 1 })
      };
      submitFmcsaAction(payload).then(res => {
        if (res.success === 1) {
          if (locationType === 2) {
            setStudentInfo({
              ...studentInfo,
              classSubmittedToFmsca: true
            });
          }
          setDisabledFmcsa(true);
          refreshResource('attendance');
          refreshResource('bulkYardManagement');
          setShown('confirm', false);
          setShown('studentTimeline', false);
          setShowYardConfirmModal(false);
          notification.success({
            message: `Successfully submitted ${name} to FMCSA`,
            duration: 5
          });
        }
        if (res.success === 0) {
          notification.error({
            message: res.data.message,
            duration: 5
          });
        }
      });
    };

    if (classOnly) {
      editStudent({ id: studentId, grade: grade }).then(() => {
        submit();
      });
    } else {
      submit();
    }
  };

  const handleProfileImageChange = data => {
    setImage(data);
    const base64 = data.replace(/^data:image\/(png|jpg);base64,/, '');
    updateProfilePicture({ id: student.id, profile_picture: base64 });
  };

  const deleteProfilePicture = () => {
    setImage(null);
    updateProfilePicture({ id: student.id, profile_picture: null });
  };

  const sendInvite = () => {
    setInviteBusy(true);

    renewInvite({ id })
      .then(({ success }) => {
        if (success === 1) {
          notification.success({
            message: `Invite to Truckers Network sent`,
            duration: 3
          });
        } else {
          notification.error({
            message: `Failed to send an invite`,
            duration: 3
          });
        }

        setInviteBusy(false);
      })
      .catch(() => {
        notification.error({
          message: `Failed to send an invite`,
          duration: 3
        });

        setInviteBusy(false);
      });
  };

  const renderFmcsaButton = () => {
    const {
      yardSubmittedToFmsca,
      yardStartDate,
      yardOnly,
      classOnly,
      classSubmittedToFmsca,
      programType,
      locationType
    } = student;

    // const isBefore = permitRecDateBefore || programType === 'Refresher';
    // const showButton =
    //   ((locationType === 2 && !classSubmittedToFmsca && programType === 'Permit Only') ||
    //     (locationType === 1 && !yardSubmittedToFmsca) ||
    //     (locationType === 2 && !hazmatSubmittedToFmsca && hazmatStartDate)) &&
    //   !isBefore;

    if (
      (!yardSubmittedToFmsca && !!yardStartDate && (yardOnly || yardOnly === undefined)) ||
      (programType === 'Permit Only' && classOnly && !classSubmittedToFmsca) ||
      (programType === 'VPC Only' && classOnly && !classSubmittedToFmsca) ||
      (!classOnly && !yardOnly)
    ) {
      return (
        <AntButton
          key="submitFMCSA"
          type="primary"
          disabled={
            disabledFmcsa ||
            (locationType === 1 && yardSubmittedToFmsca) ||
            (locationType === 2 && classSubmittedToFmsca)
          }
          onClick={() => {
            getStudentSchedule(false);
            setShown('confirm', true);
          }}
        >
          {locationType === 1 ? 'Submit Range/Road' : 'Submit for theory'}
        </AntButton>
      );
    }
  };

  const renderGraduateButton = () => {
    const {
      classSubmittedToFmsca,
      yardSubmittedToFmsca,
      status,
      classOnly,
      yardOnly,
      locationType
    } = student;

    if (
      status === 'Passed CDL Exam' ||
      (classOnly && classSubmittedToFmsca && yardSubmittedToFmsca) ||
      (yardOnly && classSubmittedToFmsca && yardSubmittedToFmsca) ||
      (locationType === 1 &&
        !classOnly &&
        !yardOnly &&
        classSubmittedToFmsca &&
        yardSubmittedToFmsca)
    ) {
      return (
        <AntButton
          key="submitAccept"
          loading={(isHazmat && loadedProfile) || (isHazmat && loadedPerformance)}
          type="primary"
          onClick={() => graduateStudent()}
        >
          Graduate
        </AntButton>
      );
    }
  };

  const archiveStudent = studentId => {
    editArchiveStudent({
      id: studentId,
      archived: true,
      type: typeOfArchive || '',
      all: selectedDateToArchive === 'all',

      ...(selectedDateToArchive !== 'all' && {
        date: moment(selectedDateToArchive).format('MM/DD/YYYY')
      })
    }).then(res => {
      if (res.success === 1) {
        closeModal();
        refreshResource('attendance');
        setShowDoubleArchiveConfirmModal(false);
        setShowArchiveConfirmModal(false);
        notification.success({
          message: `Successfully archived student`,
          duration: 5
        });
      }
    });
  };
  const allAttendanceDays = [
    { id: 'all', label: 'All' },
    ...allAttendance
      .filter(item => item.sessions)
      .map(filteredDays => {
        return { id: filteredDays.date, label: filteredDays.date };
      })
  ];

  const sendOtpPassword = () => {
    sendOtpPass({ studentId: id, branchId: studentBranch }).then(({ success, data }) => {
      if (success === 1) {
        notification.success({
          message: 'OTP successfully sent to student!',
          duration: 5
        });
      }
      if (success === 0) {
        notification.error({
          message: data.message,
          duration: 5
        });
      }
    });
  };

  const studentIntervention = () => {};

  const onLocationChange = loc => {
    if (loc !== 0) {
      const [locationType, locationId, branchId] = loc.split('x');
      setStudentsYard(allYards.find(item => item.branchId === +branchId).id);
    } else {
      setStudentsYard(0);
    }
  };

  return (
    <Modal
      width={(!isHazmat && endorsement !== 'H') || isHazmat ? '100%' : '60%'}
      closable={false}
      bodyStyle={{ padding: '5px 5px 0px 5px' }}
      visible
      footer={[
        <AntButton
          key="intervention"
          type="primary"
          onClick={() => {
            setShown('studentIntervention');
            setShown('studentTimeline', false);
          }}
          disabled={busyStudentIntervention}
          loading={busyStudentIntervention}
        >
          Student Intervention
        </AntButton>,
        isFmcsa && !isHazmat && (
          <AntButton
            key="studentRecords"
            type="primary"
            onClick={() => history.push(`/records/${id}`)}
          >
            Student Records
          </AntButton>
        ),
        !isHazmat && (
          <AntButton
            key="otp"
            type="primary"
            onClick={() => sendOtpPassword()}
            disabled={busyOtp}
            loading={busyOtp}
          >
            Reset Student App Password
          </AntButton>
        ),
        !isHazmat && attendance.length !== 0 && (
          <AntButton
            key="submitArchive"
            type="primary"
            onClick={() => setShowArchiveConfirmModal(true)}
          >
            Archive
          </AntButton>
        ),
        !isHazmat && renderGraduateButton(),
        locationType === 2 && !isHazmat && !student.classOnly && (
          <AntButton
            key="submitYard"
            type="primary"
            disabled={disabledYard}
            onClick={() => getStudentSchedule(true)}
          >
            Move to yard
          </AntButton>
        ),

        renderFmcsaButton(),
        <AntButton key="close" type="ghost" onClick={() => closeModal()}>
          Close
        </AntButton>
      ]}
    >
      <Skeleton loading={busy}>
        <Container>
          <ProfileContainer>
            <HeadContainer>
              <FormContainer>
                <ProfilePicture
                  fixed={false}
                  editable={true}
                  name="profilePicture"
                  label="Student picture"
                  onChange={handleProfileImageChange}
                  value={image}
                  showDelete={true}
                  onDelete={deleteProfilePicture}
                />
              </FormContainer>
              <div>
                <Label fontSize={32}>
                  <div>
                    {name} {isFmcsa && lastName} {sponsor === 'Self-pays' && '(SP)'}
                  </div>

                  <Field>
                    <img src={speedometer} />
                    <div>{(milesData.total || 0).toFixed(2)} Miles Driven</div>
                    <div>{totalHoursDriven || 0} Hours driven</div>
                  </Field>
                </Label>

                <Button
                  color={totalHoursTrained > programHours ? buttonColor.RED : buttonColor.GREEN}
                  width={86}
                  height={35}
                  margin={'4px 0 0 0'}
                  text={`${totalHoursTrained ? totalHoursTrained : totalHoursFmcsa}/${
                    programHours ? programHours : 160
                  }`}
                />
              </div>
            </HeadContainer>
            <StudentInfo id={id} onStudentImage={setImage} parentCallback={loadedProfileCallback} />
          </ProfileContainer>
          {((!isHazmat && endorsement !== 'H') || isHazmat) && (
            <ReportContainer>
              <BubbleTimelineContainer>{renderTimeline()}</BubbleTimelineContainer>

              {absent && (
                <AbsenceStatus>
                  {absent.isExcused ? 'Excused' : 'Not excused'} absence
                </AbsenceStatus>
              )}

              {!absent && (
                <DailyInfoContainer>
                  <SessionSection sessionType="Hour" sessionData={sessions} />

                  {(!!studentEvaluationsData[selectedAttendance.date] ||
                    !!(milesData[selectedAttendance.date] || []).length) && <VerticalSeparator />}

                  <SessionSection
                    sessionType="Odometer"
                    sessionData={milesData[selectedAttendance.date] || []}
                    studentEvaluations={studentEvaluationsData[selectedAttendance.date]}
                  />
                </DailyInfoContainer>
              )}

              <PerformanceCardsContainer
                id={id}
                onMoreInfoClick={({ title, type }) => {
                  setMoreInfoModalTitle(title);
                  setMoreInfoModalType(type);
                  setShown(resourceMoreInfo, true);
                }}
                onCardButtonPress={({ type }) => {
                  setTestType(type);
                  setShown('testingTimeline');
                }}
                parentCallback={loadedPerformanceCallback}
                studentEvaluations={getStudentEvaluations}
              />
            </ReportContainer>
          )}
        </Container>

        <Modal
          className="moveToYard"
          title="Archiving the student data"
          visible={showArchiveConfirmModal}
          onCancel={() => setShowArchiveConfirmModal(false)}
          footer={[renderArchiveCancel(), renderArchiveConfirmButton(student)]}
        >
          Are you sure you want to archive this student?
        </Modal>

        <Modal
          className="moveToYard"
          title="Are you sure you want to archive this student's attendance and evaluations?"
          visible={showDoubleArchiveConfirmModal}
          onCancel={() => setShowDoubleArchiveConfirmModal(false)}
          footer={[renderDoubleArchiveCancel(), renderDoubleArchiveConfirmButton(student)]}
        >
          Student information cannot be retrieved after archiving. Are you sure you want to archive
          this student's attendance and evaluations?
        </Modal>

        <Modal
          className="moveToYard"
          title="Please select dates to archive student data"
          visible={showSelectDateConfirmModal}
          onCancel={() => setShowDoubleArchiveConfirmModal(false)}
          footer={[renderDoubleArchiveCancel(), renderDoubleArchiveConfirmButton(student)]}
        >
          <Select
            label="Select date"
            showSearch
            onChange={value => setSelectedDateToArchive(value)}
            value={selectedDateToArchive}
            options={allAttendanceDays}
            style={{ width: '250px', marginBottom: '20px' }}
          />

          <Select
            disabled={selectedDateToArchive === 'all'}
            label="Select type"
            placeholder="Type"
            options={[
              { id: 'sessions', label: 'Session' },
              { id: 'test', label: 'Tests' }
            ]}
            value={typeOfArchive || undefined}
            onChange={value => setTypeOfArchive(value)}
            style={{ width: '250px' }}
          />
        </Modal>

        {getShown('StudentProfileModal') && (
          <ProfileModal
            busy={busyEditStudent}
            student={student}
            onCloseClick={() => {
              setShown('StudentProfileModal', false);
            }}
            onOkClick={newStudentValues => {
              editStudent({
                id: newStudentValues.id,
                graduationDate: dateToUnix(newStudentValues.graduationDate || moment())
              }).then(({ success, data }) => {
                if (success === 1) {
                  setShown('confirmGraduation');
                }
                if (success === 0) {
                  notification.error({
                    message: 'Failed to graduate student!',
                    description: data.message,
                    duration: 5
                  });
                }
              });
            }}
          />
        )}

        {getShown('confirmGraduation') && (
          <ConfirmGraduation studentId={student.id} resourceFrom="studentTimeline" />
        )}

        {getShown('continueGraduation') && (
          <Modal
            visible
            closeModal={() => setShown('continueGraduation', false)}
            title="Graduating the student"
            okText="Continue"
            footer={[
              <AntButton
                key="close"
                type="ghost"
                onClick={() => {
                  setShown('continueGraduation', false);
                }}
              >
                Close
              </AntButton>,
              <AntButton
                key="continue"
                type="danger"
                onClick={() => {
                  setShown('continueGraduation', false);
                  setShown('StudentProfileModal', true);
                  setData('studentTimeline', {
                    name: `${student.name}`,
                    ...student
                  });
                }}
              >
                Continue
              </AntButton>
            ]}
          >
            {hasStudentLessThan160Hrs && (
              <div> The student has less than {programHours} hours.</div>
            )}
            {hasStudentFailedEveryTest && <div>The student has not passed any tests.</div>}
            {studentNotReadyForGraduate && (
              <div>
                {' '}
                The student has less than {programHours} hours and has not passed any tests.
              </div>
            )}
            <div>Do you still want to graduate the student?</div>
          </Modal>
        )}

        {getShown(resourceMoreInfo) && (
          <MoreInfo
            studentId={id}
            title={moreInfoModalTitle}
            type={moreInfoModalType}
            onCloseClick={() => {
              setShown(resourceMoreInfo, false);
            }}
          />
        )}

        {getShown('testingTimeline') && (
          <StudentPerformanceEvaluator
            resource="testingTimeline"
            type={testType}
            student={student}
            updateTotalHours={triggerRefresh}
          />
        )}
        {
          <Modal
            className="moveToYard"
            title={isSubmitFmcsa ? 'Classroom Grade' : 'Moving student to yard'}
            visible={showYardConfirmModal}
            onCancel={() => setShowYardConfirmModal(false)}
            footer={[
              <AntButton
                key="cancel"
                onClick={() => {
                  setShowYardConfirmModal(false);
                }}
              >
                Cancel
              </AntButton>,
              <AntButton
                key="move"
                type="primary"
                disabled={studentsYard === 0 || busyEditStudent}
                loading={busyEditStudent}
                onClick={() => {
                  moveToYard(student);
                }}
              >
                Move
              </AntButton>
            ]}
          >
            {!isSubmitFmcsa && (
              <>
                <div style={{ marginBottom: 15 }}>
                  Will the student’s schedule on the yard stay as: Monday to Friday? If not, please
                  select the yard training schedule
                </div>
                <span style={{ display: 'flex', columnGap: 15, marginBottom: 20 }}>
                  <Column>
                    <DayDetail>
                      <Checkbox
                        label="Monday"
                        checked={days.monday}
                        onChange={value =>
                          setDays({
                            ...days,
                            monday: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Tuesday"
                        checked={days.tuesday}
                        onChange={value =>
                          setDays({
                            ...days,
                            tuesday: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Wednesday"
                        checked={days.wednesday}
                        onChange={value =>
                          setDays({
                            ...days,
                            wednesday: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Thursday"
                        checked={days.thursday}
                        onChange={value =>
                          setDays({
                            ...days,
                            thursday: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Friday"
                        checked={days.friday}
                        onChange={value =>
                          setDays({
                            ...days,
                            friday: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Saturday"
                        checked={days.saturday}
                        onChange={value =>
                          setDays({
                            ...days,
                            saturday: value,
                            weekendClass: !(!value && !days.sunday)
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Sunday"
                        checked={days.sunday}
                        onChange={value =>
                          setDays({
                            ...days,
                            sunday: value,
                            weekendClass: !(!days.saturday && !value)
                          })
                        }
                      />
                    </DayDetail>
                  </Column>
                  <Column>
                    <DayDetail>
                      <Checkbox
                        label="Night class"
                        checked={days.nightClass}
                        onChange={value =>
                          setDays({
                            ...days,
                            nightClass: value
                          })
                        }
                      />
                    </DayDetail>
                    <DayDetail>
                      <Checkbox
                        label="Weekend class"
                        checked={days.weekendClass}
                        onChange={value => {
                          if (days.saturday || days.sunday) {
                            // setDays({
                            //   ...days,
                            //   weekendClass: value
                            // });
                          } else {
                            notification.warning({
                              message: 'You must select one of the weekend days',
                              duration: 3
                            });
                          }
                        }}
                      />
                    </DayDetail>
                  </Column>
                </span>
              </>
            )}

            <Select
              showSearch
              onChange={onLocationChange}
              label="Student's yard"
              placeholder="Select yard"
              options={[{ id: 0, label: 'Select Yard' }, ...allYards]}
              value={studentsYard}
              showArrow={false}
              style={{ width: '250px' }}
            />
          </Modal>
        }
        {
          <Modal
            width={'50%'}
            visible={getShown('confirm')}
            title={<ModalTitle key="title">{studentInfo.name}</ModalTitle>}
            footer={[
              <AntButton
                key="submitClose"
                type="default"
                onClick={() => setShown('confirm', false)}
              >
                Cancel
              </AntButton>,
              <AntButton
                key="submitEdit"
                type="primary"
                loading={busyEditStudent}
                onClick={() => submitFmcsa(student)}
                disabled={busyEditStudent}
              >
                Submit
              </AntButton>
            ]}
          >
            <span style={{ display: 'flex', marginBottom: 20 }}>
              <StudentDetailContainer>
                <StudentDetail>
                  Permit Rec. Date <span>{studentInfo.permitRecDate}</span>
                </StudentDetail>
                <StudentDetail>
                  License State <span>{studentInfo.licenseState}</span>
                </StudentDetail>
                <StudentDetail>
                  Location <span>{studentInfo.locationType}</span>
                </StudentDetail>
                <StudentDetail style={{ marginRight: 15 }}>
                  Completion Date <span>{moment().format('MM/DD/YYYY')}</span>
                </StudentDetail>
                {studentInfo?.locationType === 'Yard' && (
                  <StudentDetail fontWeight={700} color={'#ef5350'}>
                    Skill <span>{studentInfo.totalSkills}</span>
                  </StudentDetail>
                )}
              </StudentDetailContainer>
              <StudentDetailContainer>
                <StudentDetail>
                  Birthdate <span>{studentInfo.birthdate}</span>
                </StudentDetail>
                <StudentDetail>
                  License <span>{studentInfo.license}</span>
                </StudentDetail>
                <StudentDetail>
                  Endorsement Code <span>{studentInfo.cdlClass}</span>
                </StudentDetail>
                {studentInfo?.locationType === 'Classroom' && (
                  <StudentDetail
                    fontWeight={700}
                    color={studentInfo?.locationType === 'Classroom' ? '#ef5350' : ''}
                    style={{ borderBottom: 'none' }}
                  >
                    {renderClassroomScore()}
                  </StudentDetail>
                )}
                {studentInfo?.locationType === 'Yard' && (
                  <StudentDetail fontWeight={700} color={'#ef5350'}>
                    Road <span>{studentInfo.totalRoad}</span>
                  </StudentDetail>
                )}
              </StudentDetailContainer>
            </span>
            <span style={{ fontSize: 13, color: '#ef5350' }}>
              *Please look closely at the above fields! If any are incorrect, please adjust in SF,
              refresh the LMS until the changes are reflected (20 min), and “move to yard” again.
              {studentInfo?.locationType === 'Classroom' && (
                <span> Scores must be at 80% or above.</span>
              )}
              {studentInfo?.locationType === 'Yard' && (
                <span>
                  {' '}
                  Skill and Road hours come from the "Hours Break Down", and must be adequate.
                </span>
              )}
            </span>
          </Modal>
        }
      </Skeleton>
    </Modal>
  );
};
