import { transformAll } from './transform';
import table from './table';

const name = 'studentAgreements';
const path = 'students/hazmat_agreements';

export default {
  restrict: false,
  get: {
    name,
    path,
    transform: transformAll,
    defaultValue: []
  },
  table,
  submitFmcsa: {
    method: 'post',
    path: `student_infos/hazmat`
  },
  editAttendance: {
    method: 'patch',
    path: `${path}/:id`
  }
};
