export default [
  {
    versionNumber: '1.10.5',
    versionDate: 'Jul 23, 2024',
    notes: ['Hotfix CDL Testing - Edit modal testing date format']
  },
  {
    versionNumber: '1.10.4',
    versionDate: 'Jul 12, 2024',
    notes: ['Hotfix graduate issue']
  },
  {
    versionNumber: '1.10.3',
    versionDate: 'Jul 10, 2024',
    notes: [
      'Added a search box on Student Records page',
      'CR: 40h classroom training condition for submit for theory',
      'Added timestamp of the last yard move to Student Card',
      'Fixed the FMCSA Reports under FMCSA Submission page'
    ]
  },
  {
    versionNumber: '1.10.2',
    versionDate: 'Jun 24, 2024',
    notes: ['Automatically select checkout time']
  },
  {
    versionNumber: '1.10.1',
    versionDate: 'Jun 18, 2024',
    notes: ['Change size of signature on PDF']
  },
  {
    versionNumber: '1.10.0',
    versionDate: 'Jun 11, 2024',
    notes: ['Bulk students training in yards different than their branches.']
  },
  {
    versionNumber: '1.9.42',
    versionDate: 'Jun 3, 2024',
    notes: ['Test evaluations during students graduation.']
  },
  {
    versionNumber: '1.9.41',
    versionDate: 'May 20, 2024',
    notes: [
      'Separate Amazon-related students in LMS into: Amazon Career Choice, Amazon TOM, and Amazon Freight Partners.'
    ]
  },
  {
    versionNumber: '1.9.40',
    versionDate: 'May 15, 2024',
    notes: [
      'Driver Score/Driver Scoring Report - As an LMS user, I want to see a detailed report of all driver scores. This enables me to see the results of all drivers who took driver scoring tests and certificates in our schools.'
    ]
  },
  {
    versionNumber: '1.9.39',
    versionDate: 'May 3, 2024',
    notes: ['Mileage discrepancy - show all miles in hours breakdown modal.']
  },
  {
    versionNumber: '1.9.38',
    versionDate: 'Apr 2, 2024',
    notes: ['FMCSA submission - Submit manually students using file upload button']
  },
  {
    versionNumber: '1.9.37',
    versionDate: 'Mar 29, 2024',
    notes: ['Bulk attendance - added student report & highlights for clickable elements.']
  },
  {
    versionNumber: '1.9.36',
    versionDate: 'Mar 28, 2024',
    notes: [
      'Student graduation logic update: Student can’t be graduated! They have not passed the CDL exams!'
    ]
  },
  {
    versionNumber: '1.9.35',
    versionDate: 'Mar 11, 2024',
    notes: ['Users management table: added groups and branches filters']
  },
  {
    versionNumber: '1.9.34',
    versionDate: 'Mar 8, 2024',
    notes: ['Rename LMS Skills Questions']
  },
  {
    versionNumber: '1.9.33',
    versionDate: 'Feb 27, 2024',
    notes: ['CDL Testing: Added additional test result types']
  },
  {
    versionNumber: '1.9.32',
    versionDate: 'Feb 19, 2024',
    notes: ['Alt Class Type sync between Salesforce and LMS']
  },
  {
    versionNumber: '1.9.31',
    versionDate: 'Feb 8, 2024',
    notes: ['Hotfix - edit user']
  },
  {
    versionNumber: '1.9.30',
    versionDate: 'Feb 6, 2024',
    notes: ['Added filter by email on users table']
  },
  {
    versionNumber: '1.9.29',
    versionDate: 'Jan 25, 2024',
    notes: ['CDL Testing updates']
  },
  {
    versionNumber: '1.9.28',
    versionDate: 'Jan 24, 2024',
    notes: ['Added validation for existing users.']
  },
  {
    versionNumber: '1.9.27',
    versionDate: 'Nov 30, 2023',
    notes: ['Removed delete option on testing questions']
  },
  {
    versionNumber: '1.9.26',
    versionDate: 'Nov 20, 2023',
    notes: ['Submit to FMCSA hotfix']
  },
  {
    versionNumber: '1.9.25',
    versionDate: 'Nov 16, 2023',
    notes: ['CDL Testing permissions fix']
  },
  {
    versionNumber: '1.9.24',
    versionDate: 'Nov 14, 2023',
    notes: [
      'FMCSA Submission: Table order is sot by default the oldest on top.',
      'Map the nearest branch for hazmat students to the closest branch for which we have a TPR number.'
    ]
  },
  {
    versionNumber: '1.9.23',
    versionDate: 'Oct 27, 2023',
    notes: [
      'FMCSA Submission: Filter by location type added, after submit student to FMCSA update table with new data.'
    ]
  },
  {
    versionNumber: '1.9.22',
    versionDate: 'Oct 23, 2023',
    notes: ['Hotfix/Student Groups - Retrive correct yard students']
  },
  {
    versionNumber: '1.9.21',
    versionDate: 'Oct 20, 2023',
    notes: ['CDL Testing']
  },
  {
    versionNumber: '1.9.20',
    versionDate: 'Oct 6, 2023',
    notes: ['Download daily attendance report at report menu is temporary under development']
  },
  {
    versionNumber: '1.9.19',
    versionDate: 'Sep 26, 2023',
    notes: ['Modernization skills']
  },
  {
    versionNumber: '1.9.18',
    versionDate: 'Sep 14, 2023',
    notes: ['Hotfix Reports notification']
  },
  {
    versionNumber: '1.9.17',
    versionDate: 'Sep 14, 2023',
    notes: ['Refactor Performance']
  },
  {
    versionNumber: '1.9.16',
    versionDate: 'Aug 31, 2023',
    notes: [
      'AFP Tablet Document',
      'Hazmat Classroom Score',
      'Updated student records pdf generation'
    ]
  },
  {
    versionNumber: '1.9.15',
    versionDate: 'Aug 10, 2023',
    notes: [
      'Hotfix - class only students submit FMCSA',
      'Hotfix - FMCSA Submissions table, branch state code'
    ]
  },
  {
    versionNumber: '1.9.14',
    versionDate: 'Jul 31, 2023',
    notes: [
      'Yard scheduled days',
      'Student intervention',
      'Vpc only students',
      'Edit driver’s license state - fmcsa    '
    ]
  },
  {
    versionNumber: '1.9.13',
    versionDate: 'Jun 7, 2023',
    notes: ['Updated Technical Support link']
  },
  {
    versionNumber: '1.9.12',
    versionDate: 'Jun 1, 2023',
    notes: ['Allow students with VPC Only & Permit Only program type to submit to FMSCA']
  },
  {
    versionNumber: '1.9.11.1',
    versionDate: 'May 9, 2023',
    notes: ['Hotfix graduation issue.']
  },
  {
    versionNumber: '1.9.11',
    versionDate: 'May 8, 2023',
    notes: [
      '1. Hazmat 2nd Iteration Tasks, that includes: \n    a. Hazmat students are automatically added to the nearest branch (instead of Hazmat Branch). \n    b. LMS users can take attendance for hazmat students inside the Hazmat Portal \n    c. The Hazmat portal and hazmat metabase have more information for hazmat students. \n    d. Hazmat Students have been removed from all automated reports and the rest of LMS. \n    e. Hazmat portals now show dates in US format.\n2. Student Card has been optimized - this will solve the ‘graduate button’ not showing for some users.'
    ]
  },
  {
    versionNumber: '1.9.10',
    versionDate: 'Apr 24, 2023',
    notes: ['Added successful status on FMCSA Submissions']
  },
  {
    versionNumber: '1.9.9',
    versionDate: 'Apr 14, 2023',
    notes: [
      'Added ‘All Branches’ on LMS-Student Agreement page, added ‘Class Start Date’ column on LMS - Student Agreements page & renamed ‘Student Agreements’ page to ‘Hazmat Portal’ on LMS'
    ]
  },
  {
    versionNumber: '1.9.8',
    versionDate: 'Mar 31, 2023',
    notes: [
      'Date filter added on bulk attendance, day filter is synced with daily attendance filter & program type is added at students card'
    ]
  },
  {
    versionNumber: '1.9.7',
    versionDate: 'Mar 27, 2023',
    notes: ['Version history modal & Date Class Begins field added on Students Records.']
  }
];
